import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { SeaOrganizationFormViewModel } from '../types/SeaOrganizationFormViewModel';
import { SeaOrganizationListItemModel } from '../types/SeaOrganizationListItemModel';
import { SeaOrganizationModel } from '../types/SeaOrganizationModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const seaOrganizationApiPaths = {
  create: 'seaOrganization',
  update: 'seaOrganization',
  getListItems: 'seaOrganization',
  getById: (seaOrganizationId: string): string =>
    `seaOrganization/${seaOrganizationId}`,
  getForm: 'seaOrganization/form',
};

export const SeaOrganizationServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createSeaOrganization = async (
  body: SeaOrganizationModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await SeaOrganizationServiceClient(config).post(
      seaOrganizationApiPaths.create,
      body
    );
  });
};

export const updateSeaOrganization = async (
  body: SeaOrganizationModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await SeaOrganizationServiceClient(config).put(
      seaOrganizationApiPaths.update,
      body
    );
  });
};

export const getSeaOrganizationListItems = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<SeaOrganizationListItemModel[]>> => {
  return executeApiRequest<SeaOrganizationListItemModel[]>(async () => {
    return await SeaOrganizationServiceClient(config).get(
      seaOrganizationApiPaths.getListItems
    );
  });
};

export const getSeaOrganizationById = async (
  seaOrganizationId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SeaOrganizationModel>> => {
  return executeApiRequest<SeaOrganizationModel>(async () => {
    return await SeaOrganizationServiceClient(config).get(
      seaOrganizationApiPaths.getById(seaOrganizationId)
    );
  });
};

export const getSeaOrganizationFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<SeaOrganizationFormViewModel>> => {
  return executeApiRequest<SeaOrganizationFormViewModel>(async () => {
    return await SeaOrganizationServiceClient(config).get(
      seaOrganizationApiPaths.getForm
    );
  });
};
