/* eslint-disable max-len */
import { ReactElement } from 'react';
import NavigationPage from '../../../components/NavigationPage/NavigationPage';
import NavigationTabToolbar from '../../../components/NavigationTabToolbar/NavigationTabToolbar';
import { Tabs_DISTRICT_SCHOOL } from '../../../types/NavigationTabs';
import { DistrictSchoolInfoPagePropTypes } from '../../../types/propTypes/DistrictSchoolInfoPagePropTypes';
import { buildUserAccessNavigationTabItems } from '../../../utilities/routeUtilities';
import './DistrictSchoolInfoPage.css';

const DistrictSchoolInfoPage = ({
  children,
  isLoading,
  loadingDataId,
  backBarLocation,
  pageClass,
  APIError,
}: DistrictSchoolInfoPagePropTypes): ReactElement => {
  return (
    <NavigationPage
      heading={'LEA & School Info'}
      pageClass={`organization-page ${pageClass}`}
      isLoading={isLoading}
      loadingDataId={loadingDataId}
      backBarLocation={backBarLocation}
      headerChild={
        <>
          <p>{/* Description purposefully empty for now */}</p>
          <NavigationTabToolbar
            navigationTabs={buildUserAccessNavigationTabItems(
              Tabs_DISTRICT_SCHOOL
            )}
          />
        </>
      }
      APIError={APIError}
    >
      {children}
    </NavigationPage>
  );
};

export default DistrictSchoolInfoPage;
