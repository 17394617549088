import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import type { RootState } from '../store';

interface ErrorState {
  error: ApiErrorModel;
}

export const initialState: ErrorState = {
  error: {
    type: '',
    title: '',
    status: 0,
    detail: '',
  },
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearError: () => initialState,
    errorLoaded: (state, action: PayloadAction<ApiErrorModel>) => {
      state.error = action.payload;
    },
  },
});

export const { clearError, errorLoaded } = errorSlice.actions;

export const hasError = (state: RootState): boolean =>
  state.error.error.status > 0;
export const selectError = (state: RootState): ApiErrorModel =>
  state.error.error;

export default errorSlice.reducer;
