import { ReactElement } from 'react';
import { InfoPanelPropTypes } from '../../types/propTypes/InfoPanelPropTypes';
import './InfoPanel.css';

const InfoPanel = ({
  message,
  containerClass,
}: InfoPanelPropTypes): ReactElement => {
  const classForContainer = containerClass ? containerClass : '';

  return (
    <div className={classForContainer} role="info-panel">
      <div className="info--panel--parent">
        <div className="info--panel--icon"></div>
        <div className="info--panel--message">{message}</div>
      </div>
    </div>
  );
};

export default InfoPanel;
