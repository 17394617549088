/* istanbul ignore file */
import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { AddCustomDateValidation } from './DateExtendedSchema';
import { AddCustomStringValidation } from './StringExtendedSchema';

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    mustBeNumber(errorMessage?: string): Yup.StringSchema<TType, TContext>;
    alphaNumeric(errorMessage?: string): Yup.StringSchema<TType, TContext>;
    alphaNumericWithSpaces(
      errorMessage?: string
    ): Yup.StringSchema<TType, TContext>;
    isSSN(errorMessage?: string): Yup.StringSchema<TType, TContext>;
  }

  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    mustComeAfterOrEqualSpecifiedDate(
      compareDateFieldName: string,
      compareDisplayName: string,
      errorMessage?: string
    ): Yup.DateSchema<TType, TContext>;
  }
}

AddCustomStringValidation();
AddCustomDateValidation();

export default Yup;
