/* eslint-disable max-len */
/* istanbul ignore file */
// organize-imports-ignore
import './base.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ApiModalError from './components/ApiModalError/ApiModalError';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Notification from './components/Notification/Notification';
import AuthorizationContextProvider from './providers/AuthorizationContextProvider/AuthorizationContextProvider';
import ConfirmationModalContextProvider from './providers/ConfirmationModalContextProvider/ConfirmationModalContextProvider';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <Notification />
          <ConfirmationModalContextProvider>
            <AuthorizationContextProvider>
              <>
                <ApiModalError />
                <App />
              </>
            </AuthorizationContextProvider>
          </ConfirmationModalContextProvider>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
