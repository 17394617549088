import { ReactElement } from 'react';
import { FileDetailPagePropTypes } from '../../types/propTypes/FileDetailPagePropTypes';
import ApiErrorPanel from '../ApiErrorPanel/ApiErrorPanel';
import BackBar from '../BackBar/BackBar';
import Loader from '../Loader/Loader';
import './FileDetailPage.css';

const FileDetailPage = ({
  heading,
  pageClass,
  contentClass,
  isLoading,
  loadingDataId,
  loadingText,
  headerChild,
  displayHeaderChildWhileLoading,
  children,
  backBarLocation,
  APIError,
  supportedNonGenericErrors,
}: FileDetailPagePropTypes): ReactElement => {
  const loadText = loadingText ? loadingText : 'Loading...';
  return (
    <div data-testid={'page'} className={`page--grid ${pageClass}`}>
      {backBarLocation && (
        <BackBar to={backBarLocation} visibleOverride={true} />
      )}
      <h2
        className="file-detail-page--heading"
        data-testid="file-detail-header"
      >
        <span className="file-detail-page-heading-content">{heading}</span>
        {headerChild && (!isLoading || displayHeaderChildWhileLoading) && (
          <div className="file-detail-page--header-child">{headerChild}</div>
        )}
      </h2>
      {APIError && (
        <ApiErrorPanel
          error={APIError}
          containerClass={'page-error-panel'}
          includeTitle={true}
          supportedNonGenericErrors={supportedNonGenericErrors}
        />
      )}
      {isLoading ? (
        <div className="file-detail-loading-container">
          <Loader dataTestId={loadingDataId} message={loadText} />
        </div>
      ) : (
        <span
          data-testid="content-panel"
          className={`file-detail-page--content ${
            contentClass ? contentClass : ''
          }`}
        >
          {children}
        </span>
      )}
    </div>
  );
};

export default FileDetailPage;
