import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { ReportEmbedData } from '../../types/ReportEmbedData';
import { ReportListModel } from '../../types/ReportListModel';
import type { RootState } from '../store';
import { fetchTokenAndReportList } from '../thunks/reportThunks';

interface ReportState {
  loading: boolean;
  reportList: ReportListModel;
  reportError: ApiErrorModel | undefined;
  report: ReportEmbedData;
}

export const defaultReportList: ReportListModel = {
  categories: [],
};

export const defaultReportEmbedData: ReportEmbedData = {
  reportId: '',
  reportName: '',
  embedUrl: '',
};

export const initialState: ReportState = {
  loading: false,
  reportList: defaultReportList,
  report: defaultReportEmbedData,
  reportError: undefined,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReportList: (state, action: PayloadAction<ReportListModel>) => {
      state.reportList = action.payload;
    },
    setReportError: (state, action: PayloadAction<ApiErrorModel>) => {
      state.reportError = action.payload;
    },
    clearReportError: (state) => {
      state.reportError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTokenAndReportList.pending, (state) => {
      state.reportList = defaultReportList;
      state.loading = true;
    });
    builder.addCase(fetchTokenAndReportList.fulfilled, (state, action) => {
      state.reportList = action.payload.data;
      state.reportError = action.payload.error;
      state.loading = false;
    });
    builder.addCase(fetchTokenAndReportList.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
  },
});

export const { setReportList, setReportError, clearReportError } =
  reportSlice.actions;

export const selectReportLoading = (state: RootState): boolean =>
  state.report.loading;
export const selectReportList = (state: RootState): ReportListModel =>
  state.report.reportList;
export const selectReport = (state: RootState): ReportEmbedData =>
  state.report.report;
export const selectReportError = (
  state: RootState
): ApiErrorModel | undefined => state.report.reportError;

export default reportSlice.reducer;
