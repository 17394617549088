import { Component, ErrorInfo, ReactElement, ReactNode } from 'react';
import './ErrorBoundary.css';

interface ErrorBoundaryPropTypes {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// This was written in class format because the
// functional format is not possible at this time
class ErrorBoundary extends Component<
  ErrorBoundaryPropTypes,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): ReactElement | ReactNode {
    if (this.state.hasError) {
      return (
        <div data-testid="error-boundary" className="error-boundary-container">
          <section className="error-boundary-info">
            <div>
              <label>Sorry... there was an error</label>
              <div className="error-boundary-instruction">
                Go back or <a href="/logout">logout</a> and try again.
              </div>
            </div>
          </section>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
