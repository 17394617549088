import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const SummerActivityValidationSchema = yup.object().shape({
  schoolYear: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  leaIdentifier: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  leaName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  schoolIdentifier: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  schoolName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
});
