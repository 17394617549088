/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { SeaOrganizationFormViewModel } from '../../types/SeaOrganizationFormViewModel';
import { SeaOrganizationListItemModel } from '../../types/SeaOrganizationListItemModel';
import { SeaOrganizationModel } from '../../types/SeaOrganizationModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchSeaOrganizationById,
  fetchSeaOrganizationFormViewModel,
  fetchSeaOrganizationListItems,
} from '../thunks/seaOrganizationThunks';

interface SeaOrganizationState {
  loadingListItems: boolean;
  listItems: SeaOrganizationListItemModel[];

  loadingSeaOrganization: boolean;
  seaOrganization: SeaOrganizationModel;

  loadingFormViewModel: boolean;
  formViewModel: SeaOrganizationFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultListState: SeaOrganizationListItemModel[] = [];

export const defaultSeaOrganizationState: SeaOrganizationModel = {
  stateAgencyIdentifier: '',
  stateAgencyIdentificationSystem: 'State',
  organizationName: '',
  organizationType: 'SEA',
  mailingAddressStreetNumberAndName: '',
  mailingAddressApartmentRoomOrSuiteNumber: '',
  mailingAddressCity: '',
  mailingStateAbbreviation: '',
  mailingAddressPostalCode: '',
  physicalAddressStreetNumberAndName: '',
  physicalAddressApartmentRoomOrSuiteNumber: '',
  physicalAddressCity: '',
  physicalStateAbbreviation: '',
  physicalAddressPostalCode: '',
  stateANSICode: '28',
  positionTitle: '',
  webSiteAddress: '',
  firstName: '',
  middleName: '',
  lastOrSurname: '',
  generationCodeOrSuffix: '',
  personalTitleOrPrefix: '',
  electronicMailAddress: '',
  electronicMailAddressType: 'work',
  schoolYear: '',
};

export const defaultFormState: SeaOrganizationFormViewModel = {
  mailingStateAbbreviationOptions: [],
  physicalStateAbbreviationOptions: [],
  schoolYearOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: SeaOrganizationState = {
  loadingListItems: false,
  listItems: defaultListState,
  loadingSeaOrganization: false,
  seaOrganization: defaultSeaOrganizationState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: dataEntryMode,
};

export const seaOrganizationSlice = createSlice({
  name: 'seaOrganization',
  initialState,
  reducers: {
    clearSeaOrganization: (state) => {
      state.seaOrganization = defaultSeaOrganizationState;
    },
    saveSeaOrganization: (
      state,
      action: PayloadAction<SeaOrganizationModel>
    ) => {
      state.seaOrganization = action.payload;
    },
    setSeaOrganizationDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchSeaOrganizationList
    builder.addCase(fetchSeaOrganizationListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(
      fetchSeaOrganizationListItems.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.listItems = action.payload;
        }
        state.loadingListItems = false;
      }
    );
    builder.addCase(fetchSeaOrganizationListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchSeaOrganizationById
    builder.addCase(fetchSeaOrganizationById.pending, (state) => {
      state.seaOrganization = defaultSeaOrganizationState;
      state.loadingSeaOrganization = true;
    });
    builder.addCase(fetchSeaOrganizationById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.seaOrganization = action.payload;
      }
      state.loadingSeaOrganization = false;
    });
    builder.addCase(fetchSeaOrganizationById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingSeaOrganization = false;
      }
    });

    // fetchSeaOrganizationFormViewModel
    builder.addCase(fetchSeaOrganizationFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchSeaOrganizationFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchSeaOrganizationFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );
  },
});

//export reducers
export const {
  clearSeaOrganization,
  saveSeaOrganization,
  setSeaOrganizationDataEntryMode,
} = seaOrganizationSlice.actions;

// listItems
export const selectSeaOrganizationLoadingListItems = (
  state: RootState
): boolean => state.seaOrganization.loadingListItems;
export const selectSeaOrganizationListItems = (
  state: RootState
): SeaOrganizationListItemModel[] => state.seaOrganization.listItems;

// seaOrganization
export const selectSeaOrganizationLoadingSeaOrganization = (
  state: RootState
): boolean => state.seaOrganization.loadingSeaOrganization;
export const selectSeaOrganizationSeaOrganization = (
  state: RootState
): SeaOrganizationModel => state.seaOrganization.seaOrganization;

// formViewModel
export const selectSeaOrganizationLoadingFormViewModel = (
  state: RootState
): boolean => state.seaOrganization.loadingFormViewModel;
export const selectSeaOrganizationFormViewModel = (
  state: RootState
): SeaOrganizationFormViewModel => state.seaOrganization.formViewModel;

// dataEntryMode
export const selectSeaOrganizationDataEntryMode = (
  state: RootState
): DataEntryMode => state.seaOrganization.dataEntryMode;

export default seaOrganizationSlice.reducer;
