import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectFailureIds,
  selectPendingIds,
  selectSuccessIds,
} from '../../../redux/slices/dashboardSlice';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';
import FailureCard from '../FailureCard/FailureCard';
import SuccessCard from '../SuccessCard/SuccessCard';

const FileStatusCard = (): ReactElement => {
  const failureFileIds = useAppSelector(selectFailureIds);
  const successFileIds = useAppSelector(selectSuccessIds);
  const pendingFileIds = useAppSelector(selectPendingIds);

  const failureCount = failureFileIds.length;
  const successCount = successFileIds.length;
  const pendingCount = pendingFileIds.length;

  const hasFailure = failureCount > 0;
  const errorMessage =
    failureCount === 1 ? 'Failed file to review' : 'Failed files to review';
  const errorButtonText = failureCount === 1 ? 'View File' : 'View Files';
  const buttonLink =
    failureCount === 1
      ? `${appRoutePaths.FileDetail(failureFileIds[0])}`
      : `${appRoutePaths.SubmissionStatus}`;
  const buttonVisible =
    failureCount === 1
      ? canUserAccessRoute(appRoutePaths.FileDetail())
      : canUserAccessRoute(appRoutePaths.SubmissionStatus);

  if (failureCount === 0 && successCount === 0 && pendingCount === 0) {
    return (
      <EmptyStateCard
        message="No uploaded files"
        buttonText="Upload a file"
        buttonLink={appRoutePaths.FileUpload}
        buttonVisible={buttonVisible}
        tooltipText="Click to upload a file"
      />
    );
  }
  if (!hasFailure) {
    return <SuccessCard message="Files Uploaded" />;
  } else {
    return (
      <FailureCard
        message={errorMessage}
        buttonText={errorButtonText}
        buttonLink={buttonLink}
        buttonVisible={buttonVisible}
        tooltipText="Click to review files"
      />
    );
  }
};

export default FileStatusCard;
