/* eslint-disable max-len */
import {
  filenameFormat,
  SOLUTION_FAILURE,
  SOLUTION_SUCCESS,
  VALIDATION_BADFILEENDING,
  VALIDATION_BADFILEEXTENSION,
  VALIDATION_BADFILENAMEFORMAT,
  VALIDATION_MAXFILECOUNT,
  VALIDATION_MAXFILELENGTHSIZE,
  VALIDATION_MAXFILESIZE,
} from '../components/FileUpload/UploadConstants';
import {
  getAllowedFileUploadExtensions,
  getMaxFileUploadSizeMB,
  getMaxUploadFileCount,
  getMaxUploadFilenameLength,
} from '../services/fileUpload.service';
import { ValidationResults } from '../types/ValidationResult';

export const getFormatedListOfFileEndings = (fileEndings: string[]): string => {
  return fileEndings.reduce((acc, cur, index, arry) => {
    if (index === 0) {
      return acc + `\"${cur}\"`;
    }
    if (index === arry.length - 1) {
      return acc + ' and ' + `\"${cur}\"`;
    }
    return acc + ', ' + `\"${cur}\"`;
  }, '');
};

export const IsValidFileEnding = (
  fileName: string,
  fileEndings: string[]
): boolean => {
  let isValid = false;
  const fileNameWithoutExtension = fileName.substring(0, fileName.length - 4);

  fileEndings.forEach((ending: string) => {
    if (!isValid && fileNameWithoutExtension.endsWith(ending)) {
      isValid = true;
    }
  });
  return isValid;
};

export const validateFiles = (
  rawFiles: File[],
  fileEndings: string[]
): ValidationResults => {
  const maxFileSize = getMaxFileUploadSizeMB();
  const maxFileCount = getMaxUploadFileCount();
  const maxFilenameLength = getMaxUploadFilenameLength();
  const allowedExtensions = getAllowedFileUploadExtensions();
  const fileEndingList = getFormatedListOfFileEndings(fileEndings);

  const validationResult: ValidationResults = {
    validFiles: [],
    invalidFiles: [],
    errorMessages: [],
    solutions: [],
  };

  const rawFileArray = [...rawFiles];
  const isUnderFileCount = rawFileArray.length <= maxFileCount;

  if (!isUnderFileCount) {
    validationResult.validFiles = [];
    validationResult.invalidFiles = rawFileArray;
    validationResult.errorMessages = rawFileArray.map(() =>
      VALIDATION_MAXFILECOUNT(maxFileCount)
    );
  } else {
    const filenameRegEx = new RegExp(filenameFormat, 'g');
    validationResult.validFiles = rawFileArray.filter((fileinfo: File) => {
      let allowed = true;
      let error = '';

      const matchResults = fileinfo.name.match(filenameRegEx);
      if (
        matchResults === null ||
        matchResults.length !== 1 ||
        matchResults[0].length !== fileinfo.name.length
      ) {
        allowed = false;
        error = VALIDATION_BADFILENAMEFORMAT;
      }

      if (fileinfo.name.length > maxFilenameLength) {
        allowed = false;
        error = VALIDATION_MAXFILELENGTHSIZE(maxFilenameLength);
      }

      if (fileinfo.size > maxFileSize * 1000000) {
        allowed = false;
        error = VALIDATION_MAXFILESIZE(maxFileSize);
      }

      if (!allowedExtensions.includes(fileinfo.name.slice(-3))) {
        allowed = false;
        error = VALIDATION_BADFILEEXTENSION;
      }

      if (!IsValidFileEnding(fileinfo.name, fileEndings)) {
        allowed = false;
        error = VALIDATION_BADFILEENDING(fileEndingList);
      }

      if (!allowed) {
        validationResult.invalidFiles.push(fileinfo);
        validationResult.errorMessages.push(error);
      }

      return allowed;
    });
  }

  if (
    validationResult.invalidFiles.length > 0 &&
    validationResult.validFiles.length === 0
  ) {
    validationResult.solutions.push(SOLUTION_FAILURE);
  }
  if (
    validationResult.invalidFiles.length > 0 &&
    validationResult.validFiles.length > 0
  ) {
    validationResult.solutions.push(SOLUTION_FAILURE);
    validationResult.solutions.push(SOLUTION_SUCCESS);
  }

  return validationResult;
};
