/* eslint-disable max-len */
import { ReactElement } from 'react';
import { FormInputLabelPropTypes } from '../../../types/propTypes/FormInputLabelPropTypes';
import { extractRequiredFromSchema } from '../../../utilities/formUtilities';
import './FormInputLabel.css';

const FormInputLabel = ({
  field,
  displayName,
  validationSchema,
  classes,
  disabled,
}: FormInputLabelPropTypes): ReactElement => {
  const isRequired = extractRequiredFromSchema(validationSchema, field);

  const disabledClass = disabled ? 'disabled-label' : '';

  return displayName ? (
    <label
      className={
        classes
          ? `${classes} field-label ${disabledClass}`
          : `field-label ${disabledClass}`
      }
      data-testid="display-name-label"
      htmlFor={field}
    >
      {isRequired && <span className="required-asterisk">* </span>}
      {displayName}
    </label>
  ) : (
    <></>
  );
};

export default FormInputLabel;
