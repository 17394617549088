import { ReactElement, useEffect } from 'react';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import Pill from '../../components/Pill/Pill';
import { FileStatus } from '../../generated';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectSelectedDistrict } from '../../redux/slices/appSlice';
import { selectFileLoading, selectFiles } from '../../redux/slices/fileSlice';
import { downloadFileById, fetchFiles } from '../../redux/thunks/filesThunks';
import { TooltipAnchor } from '../../services/app.service';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { deleteFile } from '../../services/file.service';
import { appRoutePaths } from '../../services/route.service';
import {
  canExecuteAppAction,
  canUserAccessRoute,
} from '../../services/security.service';
import { PillType } from '../../types/PillType';
//import NavigationPage from '../../components/NavigationPage/NavigationPage';
import FileDetailPage from '../../components/FileDetailPage/FileDetailPage';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { AppActions } from '../../types/AppActions';
import { FileModel } from '../../types/FileModel';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { formatToDateTimeString } from '../../utilities/dateUtilities';
import { parseFilename } from '../../utilities/helperUtilities';
import { prependLastword } from '../../utilities/textUtilities';
import './Files.css';

const Files = (): ReactElement => {
  const confirmContext = useConfirmationModalContext();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectFileLoading);
  const { districtId } = useAppSelector(selectSelectedDistrict);
  const files: FileModel[] = useAppSelector(selectFiles);
  const selectedDistrict = useAppSelector(selectSelectedDistrict);

  useEffect(() => {
    const promise = dispatch(fetchFiles(districtId));
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, districtId]);

  const handleFileUpload = async (
    logId: string,
    fileName: string | undefined,
    leaId: string
  ): Promise<void> => {
    dispatch(downloadFileById({ fileId: logId, fileName, leaId }));
  };

  const UploadButton = (
    <NavigationButton
      route={appRoutePaths.FileUpload}
      classes="button no-wrap-text upload--button"
      tooltipText="Upload a File"
    >
      <>Upload a File</>
    </NavigationButton>
  );

  const showDelete = (file: FileModel): boolean =>
    file.status === FileStatus.FAILED &&
    canExecuteAppAction(AppActions.DELETE_FILE);

  const handleDelete = async (
    id: string,
    fileName: string | undefined
  ): Promise<void> => {
    confirmContext.setOptions({
      title: `Confirm File Delete?`,
      content: (
        <div className="confirm--message">
          Are you sure you want to delete the following file(s)?
          <div className="item--display--confirm">
            <ul>
              <li>{fileName}</li>
            </ul>
          </div>
        </div>
      ),
      confirmText: 'Yes, delete File',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deleteFile(id, districtId);
        if (isValidCode(response.status)) {
          dispatch(fetchFiles(districtId));
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  return (
    <FileDetailPage
      heading="Submission Status"
      pageClass="files--list"
      isLoading={isLoading}
      loadingDataId="files-loader"
      loadingText="Getting your Files"
      headerChild={UploadButton}
    >
      <table cellSpacing={0} className="page-view-table">
        <thead>
          <tr>
            <th data-cy="file-name">Name</th>
            <th data-cy="file-date">Date &amp; Time (CT)</th>
            <th data-cy="file-uploaded-by">Uploaded By</th>
            <th data-cy="file-status">Status</th>
            <th data-cy="file-action">Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.length > 0 &&
            files.map((file) => {
              const { filename } = parseFilename(file.fileName);

              return (
                <tr
                  data-status={file.status}
                  key={`${file.id}-${file.fileName}-${file.uploadDateTimeUtc}`}
                  data-testid={file.id}
                >
                  <td className="files--list--file-name">
                    <NavigationButton
                      route={appRoutePaths.FileDetail(file.id)}
                      visibleOverride={canUserAccessRoute(
                        appRoutePaths.FileDetail()
                      )}
                      tooltipText="Click to go to File Detail View"
                    >
                      <>{file.fileName?.split(';').pop()}</>
                    </NavigationButton>
                  </td>
                  <td className="files--list--date">
                    {formatToDateTimeString(file.uploadDateTimeUtc)}
                  </td>
                  <td className="files--list--uploaded-by">
                    {prependLastword(file.uploadedBy, ' ', ', ')}
                  </td>
                  <td className="files--list--status">
                    <Pill
                      status={file.status}
                      value={file.status}
                      pillType={PillType.FILE_STATUS}
                    />
                  </td>
                  <td className="files--list--action-container">
                    <div className="action--column">
                      {file.canDownload && (
                        <button
                          data-testid="download-file"
                          className="download-button"
                          aria-label="Download"
                          onClick={() =>
                            handleFileUpload(
                              file.id,
                              file.fileName,
                              selectedDistrict.districtId
                            )
                          }
                          data-tooltip-id={TooltipAnchor}
                          data-tooltip-content={'Download File'}
                        >
                          <img
                            className="download-button-img"
                            src={'/images/icon-file-large.svg'}
                          />
                        </button>
                      )}
                      {showDelete(file) && (
                        <button
                          data-testid="delete-file"
                          className="delete-button"
                          aria-label="Delete"
                          onClick={() => handleDelete(file.id, filename)}
                          data-tooltip-id={TooltipAnchor}
                          data-tooltip-content={'Delete File'}
                        >
                          <img
                            className="delete-button-img"
                            src={'/images/icon-trash copy.svg'}
                          />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {files.length === 0 && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>You currently have no uploaded files</p>
          <NavigationButton route={appRoutePaths.FileUpload}>
            <>Try uploading a file</>
          </NavigationButton>
        </EmptyGridContent>
      )}
    </FileDetailPage>
  );
};

export default Files;
