/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentEntryModel } from '../../../types/NonPublicStudentEntryModel';
import { NonPublicStudentFormViewModel } from '../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from '../../../types/propTypes/FormRadioListPropTypes';
import { NonPublicStudentEntryValidationSchema } from '../../../types/Validation/NonPublicStudentEntryValidationSchema';
import { isStateNonAdmin } from '../../../utilities/userUtilities';
import FormDropdown from '../../FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../FormInputs/FormRadioList/FormRadioList';
import Panel from '../../Panel/Panel';
import './NPSE_DemographicPanel.css';

const NPSE_DemographicPanel = (): ReactElement => {
  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );
  const [raceEnabled, setRaceEnabled] = useState(false);
  const { values, setFieldValue } =
    useFormikContext<NonPublicStudentEntryModel>();
  const [isRaceRequired, setIsRaceRequired] = useState(false);

  const getRequiredRaceLabel = (): string => {
    return isRaceRequired ? 'forced-required-label' : '';
  };
  useEffect(() => {
    console.log('hispanicOrLatinoEthnicity', values.hispanicOrLatinoEthnicity);
    if (values.hispanicOrLatinoEthnicity == 'Yes') {
      setRaceEnabled(false);
      setFieldValue('race', '');
      setIsRaceRequired(false);
    } else if (values.hispanicOrLatinoEthnicity == 'No') {
      setRaceEnabled(true);
      setIsRaceRequired(true);
    } else {
      setRaceEnabled(false);
      setIsRaceRequired(false);
    }
  }, [values, setFieldValue]);

  return (
    <Panel panelType={PanelType.INFO} heading="Student Demographics">
      <div className="field-row field-row-demographic">
        <FormDropdown
          displayName="Gender"
          field="sex"
          options={formViewModel.sexOptions}
          disabled={isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormDropdown
          displayName="Ethnicity"
          field="hispanicOrLatinoEthnicity"
          options={formViewModel.hispanicOrLatinoEthnicityOptions}
          disabled={isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormDropdown
          displayName="Race"
          field="race"
          classConfig={{ labelClasses: getRequiredRaceLabel() }}
          options={formViewModel.raceOptions}
          validationSchema={NonPublicStudentEntryValidationSchema}
          disabled={!raceEnabled || isStateNonAdmin()}
        />
        <FormRadioList
          displayName="Special Education"
          field="specialEducation"
          options={formViewModel.specialEducationOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          disabled={isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormRadioList
          displayName="English Learner"
          field="englishLearnerStatus"
          options={formViewModel.englishLearnerStatusOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          disabled={isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
      </div>
    </Panel>
  );
};

export default NPSE_DemographicPanel;
