import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { LEAManagementFormViewModel } from '../types/LEAManagementFormViewModel';
import { LEAManagementListItemModel } from '../types/LEAManagementListItemModel';
import { LEAManagementModel } from '../types/LEAManagementModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const leaManagementApiPaths = {
  create: 'leaManagement',
  update: 'leaManagement',
  getListItems: 'leaManagement',
  getById: (leaManagementId: string): string =>
    `leaManagement/${leaManagementId}`,
  getForm: 'leaManagement/form',
};

export const LeaManagementServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createLEAManagement = async (
  body: LEAManagementModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await LeaManagementServiceClient(config).post(
      leaManagementApiPaths.create,
      body
    );
  });
};

export const updateLEAManagement = async (
  body: LEAManagementModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await LeaManagementServiceClient(config).put(
      leaManagementApiPaths.update,
      body
    );
  });
};

export const getLEAManagementListItems = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<LEAManagementListItemModel[]>> => {
  return executeApiRequest<LEAManagementListItemModel[]>(async () => {
    return await LeaManagementServiceClient(config).get(
      leaManagementApiPaths.getListItems
    );
  });
};

export const getLEAManagementById = async (
  leaManagementId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<LEAManagementModel>> => {
  return executeApiRequest<LEAManagementModel>(async () => {
    return await LeaManagementServiceClient(config).get(
      leaManagementApiPaths.getById(leaManagementId)
    );
  });
};

export const getLEAManagementFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<LEAManagementFormViewModel>> => {
  return executeApiRequest<LEAManagementFormViewModel>(async () => {
    return await LeaManagementServiceClient(config).get(
      leaManagementApiPaths.getForm
    );
  });
};
