/* eslint-disable max-len */
import { ReactElement } from 'react';
import { LabelAndValuePropTypes } from '../../types/propTypes/LabelAndValuePropTypes';
import './LabelAndValue.css';

const LabelAndValue = ({
  displayName,
  value,
  containerClass,
  labelClass,
  valueClass,
  cypressDataId,
  highlighted,
  testId,
  blankValue,
  showColonOnLabel = true,
}: LabelAndValuePropTypes): ReactElement => {
  const STATIC_EMPTY_VALUE = '-';
  const labelClasses = labelClass ? labelClass : '';
  const containerClasses = containerClass ? containerClass : '';
  const valueClasses = valueClass ? valueClass : '';
  const dataTestId = testId ? testId : 'label-and-value-container';
  const emptyValue = blankValue ? blankValue : STATIC_EMPTY_VALUE;

  if (!value) {
    value = emptyValue;
  }

  const labelAndValue = (
    <>
      <label
        className={`label-and-val--label ${labelClasses}`}
        data-testid="label-and-value-label"
      >
        {displayName}
        {showColonOnLabel ? ':' : ''}
        &nbsp;
      </label>
      <div
        className={`label-and-val--value ${valueClasses}`}
        data-testid="label-and-value-value"
      >
        {value}
      </div>
    </>
  );

  return (
    <div
      className={`label-and-val--container ${containerClasses}`}
      data-cy={cypressDataId}
      data-testid={dataTestId}
    >
      {highlighted ? (
        <mark role="highlight">{labelAndValue}</mark>
      ) : (
        labelAndValue
      )}
    </div>
  );
};

export default LabelAndValue;
