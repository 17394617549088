/* eslint-disable max-len */
import { ReactElement } from 'react';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import { PanelType } from '../../types/PanelType';
import './Feedback.css';

const Feedback = (): ReactElement => {
  return (
    <NavigationPage
      heading={'Provide Feedback'}
      pageClass="feedback"
      isLoading={false}
    >
      <div className="feedback--directions">
        <Panel panelType={PanelType.INFO} heading="Directions">
          <p data-testid="feedback-directions-1">
            If you encounter any issues while using the system, please email the
            help desk at{' '}
            <a href="mailto:mdeapps@mdek12.org">mdeapps@mdek12.org</a>. Please
            include a screenshot of the error message, if applicable.
          </p>

          <p data-testid="feedback-directions-2">
            If you have a suggestion for a future enhancement, please click{' '}
            <a
              href="https://forms.office.com/r/9XqWp80xH2"
              target="_blank"
              rel="noreferrer"
            >
              this link
            </a>{' '}
            and complete our Feedback Form.
          </p>

          <p data-testid="feedback-directions-3">
            Please use the website for helpful resources and to view past or
            upcoming training events:{' '}
            <a
              href="https://www.mdek12.org/OTSS/MSIS"
              target="_blank"
              rel="noreferrer"
            >
              www.mdek12.org/otss/msis
            </a>
            . After reviewing the website, if you still have general questions,
            please email them to{' '}
            <a href="mailto:msis2@mdek12.org">msis2@mdek12.org</a>.
          </p>
        </Panel>
      </div>
    </NavigationPage>
  );
};

export default Feedback;
