/* eslint-disable max-len*/
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import StyledSingleDropdown from '../../../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectNonPublicStudentListItems,
  selectNonPublicStudentLoadingListItems,
  selectSchoolYear,
  setNonPublicStudentDataEntryMode,
  setSchoolYearFilter,
} from '../../../../redux/slices/nonPublicStudentSlice';
import { fetchNonPublicStudentListItems } from '../../../../redux/thunks/nonPublicStudentThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { NonPublicStudentListItemModel } from '../../../../types/NonPublicStudentListItemModel';
import { FormDropdownSelectOption } from '../../../../types/propTypes/FormDropdownPropTypes';
import { ReactSelectOption } from '../../../../types/ReactSelectOption';
import { getSchoolYearList } from '../../../../types/SchoolYearListModel';
import { isStateNonAdmin } from '../../../../utilities/userUtilities';
import StudentPage from '../StudentPage';
import './NonPublicStudentEntryList.css';

const NonPublicStudentEntryList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const schoolYear = useAppSelector(selectSchoolYear);
  const isLoading = useAppSelector(selectNonPublicStudentLoadingListItems);
  const listItems: NonPublicStudentListItemModel[] = useAppSelector(
    selectNonPublicStudentListItems
  );

  const selectedOption = schoolYear
    ? { value: schoolYear, label: schoolYear }
    : {
        value: getSchoolYearList()[0].value,
        label: getSchoolYearList()[0].value,
      };

  useEffect(() => {
    if (schoolYear === '') {
      dispatch(setSchoolYearFilter(getSchoolYearList()[0].value));
    }
  }, [dispatch, schoolYear]);

  useEffect(() => {
    let promise: unknown;
    if (schoolYear) {
      promise = dispatch(fetchNonPublicStudentListItems(schoolYear));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, schoolYear]);

  const handleOnChange = async (
    option: FormDropdownSelectOption
  ): Promise<void> => {
    const selectedValue = (option as SingleValue<ReactSelectOption>)?.value;
    selectedValue && dispatch(setSchoolYearFilter(selectedValue));
  };

  const handleEdit = (nonPublicStudentId: string): void => {
    navigate(appRoutePaths.NonPublicStudentEntryInput(nonPublicStudentId));
  };

  const handleNew = (): void => {
    dispatch(setNonPublicStudentDataEntryMode(DataEntryMode.NEW));
    navigate(appRoutePaths.NonPublicStudentEntryInput('new'));
  };

  const canAddNew = canUserAccessRoute(
    appRoutePaths.NonPublicStudentEntryInput()
  );

  return (
    <StudentPage
      pageClass="data-input--student--non-public-student-entry--list"
      isLoading={isLoading}
      loadingDataId={'non-public-student-entry-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div
            className={
              isStateNonAdmin()
                ? 'school-year-selection-container school-year-padding'
                : 'school-year-selection-container'
            }
          >
            <StyledSingleDropdown
              fieldName="schoolYear"
              options={getSchoolYearList()}
              onChange={handleOnChange}
              value={selectedOption}
            />
          </div>
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>
              Non-Public Student Entry
            </h4>
            {canAddNew && !isStateNonAdmin() && (
              <ActionButton
                onClick={handleNew}
                classes="no-wrap-text new-button"
                tooltipText="Add New Non-Public Student Entry"
              >
                <>Add New</>
              </ActionButton>
            )}
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>MSIS ID</th>
                  <th>District</th>
                  <th>School Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((nonPublicStudent) => (
                    <tr
                      key={`${nonPublicStudent.id}`}
                      data-testid={nonPublicStudent.id}
                    >
                      <td>{nonPublicStudent.lastOrSurname}</td>
                      <td>{nonPublicStudent.firstName}</td>
                      <td>{nonPublicStudent.studentIdentifier}</td>
                      <td>{nonPublicStudent.district}</td>
                      <td>{nonPublicStudent.schoolType}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${nonPublicStudent.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(nonPublicStudent.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'View/Edit Student'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StudentPage>
  );
};

export default NonPublicStudentEntryList;
