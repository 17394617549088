import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectAuthorizeTokenError,
  selectCurrentUser,
} from '../../redux/slices/loginSlice';
import { executeLogin } from '../../redux/thunks/loginThunks';
import {
  abortPromiseOnUnmount,
  clearLoginRoute,
  getCurrentUserAccess,
  getLoginRoute,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import './Authenticate.css';

const Authenticate = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const details = useAppSelector(selectCurrentUser);
  const tokenError = useAppSelector(selectAuthorizeTokenError);

  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  useEffect(() => {
    let promise: unknown = undefined;
    if (code) {
      promise = dispatch(executeLogin({ auth_code: code }));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, code]);

  useEffect(() => {
    const handleExistingUserLoginRoute = (): void => {
      const determinedRoute = getLoginRoute();
      if (determinedRoute) {
        clearLoginRoute();
        navigate(determinedRoute);
      } else {
        navigate(appRoutePaths.Dashboard);
      }
    };

    if (getCurrentUserAccess() && details?.name) {
      if (details.featureAccessPermissions.length === 0) {
        navigate(appRoutePaths.NewUser);
      } else {
        handleExistingUserLoginRoute();
      }
    }
  }, [dispatch, navigate, details]);

  useEffect(() => {
    if (tokenError) {
      navigate(appRoutePaths.Login);
    }
  }, [dispatch, navigate, tokenError]);

  return (
    <div className="login--loader">
      <Loader dataTestId="login-loader" message="Logging In..." />
    </div>
  );
};

export default Authenticate;
