/* eslint-disable max-len */
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { NonPublicStudentEntryModel } from '../types/NonPublicStudentEntryModel';
import { NonPublicStudentFormViewModel } from '../types/NonPublicStudentFormViewModel';
import { NonPublicStudentListItemModel } from '../types/NonPublicStudentListItemModel';
import { NonPublicStudentScheduleFormViewModel } from '../types/NonPublicStudentScheduleFormViewModel';
import { NonPublicStudentScheduleListItemModel } from '../types/NonPublicStudentScheduleListItemModel';
import { NonPublicStudentScheduleModel } from '../types/NonPublicStudentScheduleModel';
import { NonPublicStudentSearchResultModel } from '../types/NonPublicStudentSearchResultModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const nonPublicStudentApiPaths = {
  create: 'nonPublicStudent',
  update: 'nonPublicStudent',
  getSchoolYearListItems: 'nonPublicStudent/getSchoolYearListItems',
  getListItems: (calendarYear: string) =>
    `nonPublicStudent?calendarYear=${calendarYear}`,
  getById: (nonPublicStudentId: string): string =>
    `nonPublicStudent/${nonPublicStudentId}`,
  getStudentByMsisId: (msisId: string): string =>
    `nonPublicStudent/search/${msisId}`,
  getForm: 'nonPublicStudent/form',
  createSchedule: 'nonPublicStudentSchedule',
  updateSchedule: 'nonPublicStudentSchedule',
  getSchedulesByNPSId: (npsId: string): string =>
    `nonPublicStudentSchedule/${npsId}/schedule`,
  getByScheduleId: (scheduleId: string): string =>
    `nonPublicStudentSchedule/${scheduleId}`,
  getScheduleForm: 'nonPublicStudentSchedule/form',
  deleteSchedule: (scheduleId: string): string =>
    `nonPublicStudentSchedule/${scheduleId}`,
};

export const NonPublicStudentServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createNonPublicStudent = async (
  body: NonPublicStudentEntryModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await NonPublicStudentServiceClient(config).post(
      nonPublicStudentApiPaths.create,
      body
    );
  });
};

export const updateNonPublicStudent = async (
  body: NonPublicStudentEntryModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await NonPublicStudentServiceClient(config).put(
      nonPublicStudentApiPaths.update,
      body
    );
  });
};

export const createNonPublicStudentSchedule = async (
  body: NonPublicStudentScheduleModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await NonPublicStudentServiceClient(config).post(
      nonPublicStudentApiPaths.createSchedule,
      body
    );
  });
};

export const updateNonPublicStudentSchedule = async (
  body: NonPublicStudentScheduleModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await NonPublicStudentServiceClient(config).put(
      nonPublicStudentApiPaths.updateSchedule,
      body
    );
  });
};

export const getNonPublicStudentListItems = async (
  calendarYear: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentListItemModel[]>> => {
  return executeApiRequest<NonPublicStudentListItemModel[]>(async () => {
    return await NonPublicStudentServiceClient(config).get(
      nonPublicStudentApiPaths.getListItems(calendarYear)
    );
  });
};

export const getNonPublicStudentById = async (
  nonPublicStudentId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentEntryModel>> => {
  return executeApiRequest<NonPublicStudentEntryModel>(async () => {
    return await NonPublicStudentServiceClient(config).get(
      nonPublicStudentApiPaths.getById(nonPublicStudentId)
    );
  });
};

export const getNonPublicStudentScheduleFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentScheduleFormViewModel>> => {
  return executeApiRequest<NonPublicStudentScheduleFormViewModel>(async () => {
    return await NonPublicStudentServiceClient(config).get(
      nonPublicStudentApiPaths.getScheduleForm
    );
  });
};

export const getNonPublicStudentFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentFormViewModel>> => {
  return executeApiRequest<NonPublicStudentFormViewModel>(async () => {
    return await NonPublicStudentServiceClient(config).get(
      nonPublicStudentApiPaths.getForm
    );
  });
};

export const getStudentByMSISId = async (
  msisId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentSearchResultModel>> => {
  return executeApiRequest<NonPublicStudentSearchResultModel>(async () => {
    return await NonPublicStudentServiceClient(config).get(
      nonPublicStudentApiPaths.getStudentByMsisId(msisId)
    );
  });
};

export const getSchedulesByNpsId = async (
  npsId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentScheduleListItemModel[]>> => {
  return executeApiRequest<NonPublicStudentScheduleListItemModel[]>(
    async () => {
      return await NonPublicStudentServiceClient(config).get(
        nonPublicStudentApiPaths.getSchedulesByNPSId(npsId)
      );
    }
  );
};

export const getScheduleByScheduleId = async (
  scheduleId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<NonPublicStudentScheduleModel>> => {
  return executeApiRequest<NonPublicStudentScheduleModel>(async () => {
    return await NonPublicStudentServiceClient(config).get(
      nonPublicStudentApiPaths.getByScheduleId(scheduleId)
    );
  });
};

export const deleteSchedule = async (
  scheduleId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest(async () => {
    return await NonPublicStudentServiceClient(config).delete(
      nonPublicStudentApiPaths.deleteSchedule(scheduleId)
    );
  });
};
