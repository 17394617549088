export enum FileErrorCode {
  NONE = 'None',
  K12STUDENT_SCHEMA_ERROR = 'K12StudentSchemaError',
  K12SCHOOL_SCHEMA_ERROR = 'K12SchoolSchemaError',
  K12STAFF_SCHEMA_ERROR = 'K12StaffSchemaError',
  LEASCHEMA_ERROR = 'LEASchemaError',
  COURSE_SECTION_SCHEMA_ERROR = 'CourseSectionSchemaError',
  K12COURSE_SCHEMA_ERROR = 'K12CourseSchemaError',
  SCHEMA_ERROR_LOG = 'SchemaErrorLog',
}
