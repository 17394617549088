/* istanbul ignore file */
import * as Yup from 'yup';
import { VALIDATION_MESSAGE } from '../../ValidationMessage';

const regexAlphaNumericWithSpaces = new RegExp('^[\\dA-Za-z\\s]+$');
const regexAlphaNumeric = new RegExp('^[\\dA-Za-z]+$');
const regexDigits = new RegExp('^\\d+$');

/* 
The valid SSN (Social Security Number) must satisfy the following conditions: 

It should have 9 digits.
It should be divided into 3 parts by hyphen (-).
The first part should have 3 digits and should not be 000, 666, or between 900 and 999.
The second part should have 2 digits and it should be from 01 to 99.
The third part should have 4 digits and it should be from 0001 to 9999.
*/
const regexSSNNumber = new RegExp(
  '^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$'
);

enum STRING_VALIDATION_KEYS {
  MUST_BE_NUMBER = 'mustBeNumber',
  ALPHANUMERIC = 'alphaNumeric',
  ALPHANUMERIC_WITH_SPACES = 'alphaNumericWithSpaces',
  IS_SSN = 'isSSN',
}

export const AddCustomStringValidation = (): void => {
  Yup.addMethod(
    Yup.string,
    STRING_VALIDATION_KEYS.MUST_BE_NUMBER,
    function (errorMessage) {
      return this.test(
        STRING_VALIDATION_KEYS.MUST_BE_NUMBER,
        errorMessage,
        function (value) {
          const { path, createError } = this;

          let found = null;
          const data = value as unknown as string;
          if (data) {
            found = data.match(regexDigits);
          } else {
            return true;
          }
          return (
            found !== null ||
            createError({
              path,
              message: errorMessage || VALIDATION_MESSAGE.MUST_BE_NUMBER,
            })
          );
        }
      );
    }
  );

  Yup.addMethod(
    Yup.string,
    STRING_VALIDATION_KEYS.ALPHANUMERIC,
    function (errorMessage) {
      return this.test(
        STRING_VALIDATION_KEYS.ALPHANUMERIC,
        errorMessage,
        function (value) {
          const { path, createError } = this;

          let found = null;
          const data = value as unknown as string;
          if (data) {
            found = data.match(regexAlphaNumeric);
          } else {
            return true;
          }

          return (
            found !== null ||
            createError({
              path,
              message: errorMessage || VALIDATION_MESSAGE.ALPHANUMERIC,
            })
          );
        }
      );
    }
  );

  Yup.addMethod(
    Yup.string,
    STRING_VALIDATION_KEYS.ALPHANUMERIC_WITH_SPACES,
    function (errorMessage) {
      return this.test(
        STRING_VALIDATION_KEYS.ALPHANUMERIC_WITH_SPACES,
        errorMessage,
        function (value) {
          const { path, createError } = this;

          let found = null;
          const data = value as unknown as string;
          if (data) {
            found = data.match(regexAlphaNumericWithSpaces);
          } else {
            return true;
          }

          return (
            found !== null ||
            createError({
              path,
              message: errorMessage || VALIDATION_MESSAGE.NO_SPECIAL_CHARACTERS,
            })
          );
        }
      );
    }
  );

  Yup.addMethod(
    Yup.string,
    STRING_VALIDATION_KEYS.IS_SSN,
    function (errorMessage) {
      return this.test(
        STRING_VALIDATION_KEYS.IS_SSN,
        errorMessage,
        function (value) {
          const { path, createError } = this;

          let found = null;
          const data = value as unknown as string;
          if (data) {
            found = data.match(regexSSNNumber);
          } else {
            return true;
          }
          return (
            found !== null ||
            createError({
              path,
              message: errorMessage || VALIDATION_MESSAGE.MUST_BE_IN_SSN_FORMAT,
            })
          );
        }
      );
    }
  );
};
