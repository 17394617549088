import { ReactElement } from 'react';
import { FailureCardPropTypes } from '../../../types/propTypes/FailureCardPropTypes';
import DashboardCard from '../DashboardCard/DashboardCard';

const FailureCard = ({
  message,
  buttonText,
  buttonLink,
  buttonVisible,
  tooltipText,
}: FailureCardPropTypes): ReactElement => (
  <DashboardCard
    cardClassNames="dashboard--grid--badge 
    status--failure page--grid--d-6 
    page--grid--m-full"
    messageClassNames="dashboard--status-message"
    message={message}
    dataState="fail"
    buttonClassNames="button button--dashboard--small no-wrap-text"
    buttonLink={buttonLink}
    buttonText={buttonText}
    buttonVisible={buttonVisible}
    tooltipText={tooltipText}
  />
);

export default FailureCard;
