/* istanbul ignore file */
/* eslint-disable max-len */
import {
  DataGridPro,
  DataGridProProps,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import React, { ReactElement } from 'react';

// NOTE: This component is nothing but a style wrapper, so no need to test it

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledGrid = <R extends GridValidRowModel = any>(
  props: DataGridProProps<R> & React.RefAttributes<HTMLDivElement>
): ReactElement => {
  return (
    <DataGridPro
      sx={{
        borderLeft: 0,
        borderRight: 0,
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: '#eff8fa',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '& .Mui-error': {
          backgroundColor: 'rgb(126, 10, 15, 0.1)',
        },
      }}
      {...props}
    />
  );
};

export default StyledGrid;
