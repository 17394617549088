import { ReactNode } from 'react';
import { MSDE_APP_HELP_EMAIL } from './Constants';

/* eslint-disable max-len */
export interface ApiErrorDisplayOverrideModel {
  statusCode: number;
  howToFix?: ReactNode;
}

export const getDefaultStatusCodeConfiguration =
  (): ApiErrorDisplayOverrideModel[] => [
    {
      statusCode: 403,
      howToFix: (
        <>
          You do not have access to this resource or action. Reach out to your
          administrator if you believe you should have access.
        </>
      ),
    },
    {
      statusCode: 404,
      howToFix: (
        <>
          You may have followed an outdated link or bookmark. Return to the Home
          page and try to reach your destination from there.
        </>
      ),
    },
    {
      statusCode: 405,
      howToFix: (
        <>
          This action cannot be performed on this resource. The resource may
          have recently changed. Try refreshing the page and if the problem
          persists please reach out to the Help Desk at{' '}
          <a href={`mailto:${MSDE_APP_HELP_EMAIL}`}>{MSDE_APP_HELP_EMAIL}</a>{' '}
          and have your trace ID ready when contacting us.
        </>
      ),
    },
  ];
