/* eslint-disable max-len */
import { ReactElement } from 'react';
import LEAManagementInformationPanel from '../../../../components/FormLayouts/LEAManagement/LEAInformationPanel';
import LEAManagementMailingAddressPanel from '../../../../components/FormLayouts/LEAManagement/LEAManagementMailingAddressPanel';
import LEAManagementPhysicalAddressPanel from '../../../../components/FormLayouts/LEAManagement/LEAManagementPhysicalAddressPanel';
import LEAManagementPrimaryInformationPanel from '../../../../components/FormLayouts/LEAManagement/LEAManagementPrimaryInformationPanel';
import LEAManagementSecondaryInformationPanel from '../../../../components/FormLayouts/LEAManagement/LEAManagementSecondaryInformationPanel';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearLEAManagement,
  saveLEAManagement,
  selectLEAManagementDataEntryMode,
  selectLEAManagementFormViewModel,
  selectLEAManagementLEAManagement,
  selectLEAManagementLoadingFormViewModel,
  selectLEAManagementLoadingLEAManagement,
  setLEAManagementDataEntryMode,
} from '../../../../redux/slices/leaManagementSlice';
import {
  fetchLEAManagementById,
  fetchLEAManagementFormViewModel,
} from '../../../../redux/thunks/leaManagementThunks';
import {
  createLEAManagement,
  updateLEAManagement,
} from '../../../../services/leaManagement.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { LEAManagementFormViewModel } from '../../../../types/LEAManagementFormViewModel';
import { LEAManagementModel } from '../../../../types/LEAManagementModel';
import { Tabs_DISTRICT_SCHOOL } from '../../../../types/NavigationTabs';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { LEAManagementValidationEditSchema } from '../../../../types/Validation/LEAManagementValidationEditSchema';
import { LEAManagementValidationSchema } from '../../../../types/Validation/LEAManagementValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import './LEAManagementInput.css';

const LEAManagementInput = (): ReactElement => {
  const leaManagementModel: LEAManagementModel = useAppSelector(
    selectLEAManagementLEAManagement
  );
  const formViewModel: LEAManagementFormViewModel = useAppSelector(
    selectLEAManagementFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectLEAManagementLoadingFormViewModel
  );
  const isLoadingLEAManagementModel = useAppSelector(
    selectLEAManagementLoadingLEAManagement
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectLEAManagementDataEntryMode
  );
  const isLoading = isLoadingLEAManagementModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
      howToFix:
        'The provided LEA Management ID is already in the system. Use another value, or return to the list and edit the existing LEA Management.',
    },
  ];
  return (
    <ManualInputPage<LEAManagementModel, LEAManagementFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'lea-management-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_DISTRICT_SCHOOL)}
      classes={{ pageClass: 'data-input--lea-management--input' }}
      parameterOptions={{
        routeParameterName: 'leaId',
        identityName: leaManagementModel.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.LEAManagementList,
        cancelRoute: appRoutePaths.LEAManagementList,
        createSuccessRoute: appRoutePaths.LEAManagementInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchLEAManagementById,
        loadFormDetail: fetchLEAManagementFormViewModel,
        setEntryMode: setLEAManagementDataEntryMode,
        setData: saveLEAManagement,
        clearData: clearLEAManagement,
        onCreate: createLEAManagement,
        onUpdate: updateLEAManagement,
      }}
      formDetails={{
        validationSchema:
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema,
        initialFormValues: leaManagementModel,
        isFormModelEmpty: !formViewModel.leaTypeOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'LEA Management',
        pageHeader: 'LEA & School Info',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="lea-management-input-child">
        <LEAManagementInformationPanel
          dataEntryMode={dataEntryMode}
          formViewModel={formViewModel}
        />
        <LEAManagementPrimaryInformationPanel />
        <LEAManagementSecondaryInformationPanel />
        <LEAManagementPhysicalAddressPanel />
        <LEAManagementMailingAddressPanel />
      </div>
    </ManualInputPage>
  );
};

export default LEAManagementInput;
