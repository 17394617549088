import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { SchoolManagementFormViewModel } from '../types/SchoolManagementFormViewModel';
import { SchoolManagementListItemModel } from '../types/SchoolManagementListItemModel';
import { SchoolManagementModel } from '../types/SchoolManagementModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const schoolManagementApiPaths = {
  create: 'schoolManagement',
  update: 'schoolManagement',
  getListItems: 'schoolManagement',
  getById: (schoolManagementId: string): string =>
    `schoolManagement/${schoolManagementId}`,
  getForm: 'schoolManagement/form',
};

export const SchoolManagementServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createSchoolManagement = async (
  body: SchoolManagementModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await SchoolManagementServiceClient(config).post(
      schoolManagementApiPaths.create,
      body
    );
  });
};

export const updateSchoolManagement = async (
  body: SchoolManagementModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await SchoolManagementServiceClient(config).put(
      schoolManagementApiPaths.update,
      body
    );
  });
};

export const getSchoolManagementListItems = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<SchoolManagementListItemModel[]>> => {
  return executeApiRequest<SchoolManagementListItemModel[]>(async () => {
    return await SchoolManagementServiceClient(config).get(
      schoolManagementApiPaths.getListItems
    );
  });
};

export const getSchoolManagementById = async (
  schoolManagementId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SchoolManagementModel>> => {
  return executeApiRequest<SchoolManagementModel>(async () => {
    return await SchoolManagementServiceClient(config).get(
      schoolManagementApiPaths.getById(schoolManagementId)
    );
  });
};

export const getSchoolManagementFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<SchoolManagementFormViewModel>> => {
  return executeApiRequest<SchoolManagementFormViewModel>(async () => {
    return await SchoolManagementServiceClient(config).get(
      schoolManagementApiPaths.getForm
    );
  });
};
