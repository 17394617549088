/* eslint-disable max-len */
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationTabToolbarPropTypes } from '../../types/propTypes/NavigationTabToolbarPropTypes';
import './NavigationTabToolbar.css';

const NavigationTabToolbar = ({
  navigationTabs,
  ariaLabel,
}: NavigationTabToolbarPropTypes): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationPathname = location.pathname.toLowerCase();
  const matchingTabRoutePathIndex = navigationTabs.findIndex((x) =>
    locationPathname.startsWith(x.routePath.toLowerCase())
  );
  const selectedTabIndex =
    matchingTabRoutePathIndex > 0 ? matchingTabRoutePathIndex : 0;

  const handleChange = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ): void => {
    /* istanbul ignore next */
    if (navigationTabs.length > newTabIndex) {
      navigate(navigationTabs[newTabIndex].routePath);
    }
  };

  return (
    <div>
      <div>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={ariaLabel}
        >
          {navigationTabs.map((tab, index) => (
            <Tab
              key={`tab-text-${index}`}
              className="tab-text"
              label={tab.name}
              id={`tab-${index}`}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default NavigationTabToolbar;
