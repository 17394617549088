import { ReactElement } from 'react';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import { PanelType } from '../../types/PanelType';
import './NewUser.css';

const NewUser = (): ReactElement => {
  return (
    <NavigationPage
      pageClass="new-user"
      heading={`Welcome to MSIS 2.0`}
      isLoading={false}
    >
      <div>
        <div className="new-user--next-steps">
          <Panel panelType={PanelType.INFO} heading="Next Steps">
            <p data-testid="new-user-message-1">
              Congratulations! You have successfully registered for MSIS 2.0.
              Currently, you do not have permissions assigned nor is there any
              data in the system.
            </p>
            <p data-testid="new-user-message-2">
              <u>NEW District MSIS Primaries:</u> please contact{' '}
              <a href="mailto:MDEAPPS@mdek12.org">MDEAPPS@mdek12.org</a> to
              request access.
            </p>
            <p data-testid="new-user-message-3">
              <u>Existing District Users:</u> please contact your MSIS Primary
              to request access.
            </p>
          </Panel>
        </div>
      </div>
    </NavigationPage>
  );
};

export default NewUser;
