/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentFormViewModel } from '../../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../../types/PanelType';
import { FormRadioListOrientation } from '../../../../types/propTypes/FormRadioListPropTypes';
import { NPSE_CTEOnlyValidationSchema } from '../../../../types/Validation/NonPublicStudentEntryValidationSchema';
import { isStateNonAdmin } from '../../../../utilities/userUtilities';
import FormDropdown from '../../../FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../FormInputs/FormRadioList/FormRadioList';
import Panel from '../../../Panel/Panel';
import './NPSE_CTEPanel.css';

const NPSE_CTEPanel = (): ReactElement => {
  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  return (
    <Panel panelType={PanelType.INFO} heading="CTE">
      <div className="field-row field-row-cte-input">
        <FormRadioList
          displayName="Single Parent"
          field="singleParentOrSinglePregnantWomanStatus"
          options={formViewModel.singleParentOrSinglePregnantWomanStatusOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          disabled={isStateNonAdmin()}
          validationSchema={NPSE_CTEOnlyValidationSchema}
        />
        <FormRadioList
          displayName="Displaced Homemaker"
          field="cteaeDisplacedHomemakerIndicator"
          options={formViewModel.cteaeDisplacedHomemakerIndicatorOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          disabled={isStateNonAdmin()}
          validationSchema={NPSE_CTEOnlyValidationSchema}
        />
        <FormDropdown
          displayName="Placement"
          field="perkinsPostProgramPlacementIndicator"
          options={formViewModel.perkinsPostProgramPlacementIndicatorOptions}
          disabled={isStateNonAdmin()}
          validationSchema={NPSE_CTEOnlyValidationSchema}
        />
      </div>
    </Panel>
  );
};

export default NPSE_CTEPanel;
