import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const MSISRequestValidationSchema = yup.object().shape({
  localStudentIdentifier: yup
    .string()
    .max(40, VALIDATION_MESSAGE.AT_MOST_CHARS(40)),
  requestingDistrictId: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  requestingDistrictName: yup.string(),
  schoolID: yup.string().when('isSchoolRequired', {
    is: true,
    then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    otherwise: yup.string().notRequired(),
  }),
  schoolName: yup.string(),
  firstName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  middleName: yup.string(),
  lastName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  dateOfBirth: yup
    .date()
    .max(new Date(), VALIDATION_MESSAGE.EQUAL_TO_OR_EARLIER)
    .required(VALIDATION_MESSAGE.REQUIRED),
  ssn: yup.string().isSSN(),
  addressStreetNumberAndName: yup.string(),
  addressApartmentRoomOrSuiteNumber: yup.string(),
  addressCity: yup.string(),
  stateAbbreviation: yup.string(),
  addressPostalCode: yup.string(),
  parentGuardianFirstName: yup.string(),
  parentGuardianLastOrSurname: yup.string(),

  // UI Use Only
  isSchoolRequired: yup.boolean(),
});
