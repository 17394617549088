/* eslint-disable max-len */
import { ErrorMessage, Field } from 'formik';
import { ChangeEvent, ReactElement } from 'react';
import {
  FormRadioListOrientation,
  FormRadioListPropTypes,
} from '../../../types/propTypes/FormRadioListPropTypes';
import { applyClassesForGroupedInput } from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';
import './FormRadioList.css';

const FormRadioList = ({
  field,
  options,
  displayName,
  classConfig,
  validationSchema,
  optionOrientation: orientation = FormRadioListOrientation.Vertical,
  onClick,
  disabled,
}: FormRadioListPropTypes): ReactElement => {
  const orientationClass =
    orientation === FormRadioListOrientation.Horizontal
      ? 'horizontal-layout'
      : '';

  const containerClasses = classConfig?.containerClasses || '';
  const labelClasses = classConfig?.labelClasses || '';
  const inputClasses = classConfig?.inputClasses || '';

  return (
    <div
      className={`field-container ${containerClasses} field-radio-list ${
        disabled ? 'disabled' : ''
      }`}
      data-testid={`form-radio-list-${field}`}
    >
      <FormInputLabel
        field={field}
        displayName={displayName}
        validationSchema={validationSchema}
        classes={labelClasses}
      />
      <div
        data-testid="radio-list-container"
        className={`radio-list-container ${orientationClass} ${applyClassesForGroupedInput(
          field,
          `${containerClasses}`
        )}`}
      >
        {options.length > 0 &&
          options.map((opt, index) => (
            <div className="radio-list-option" key={`${field}-opt-${index}`}>
              <Field
                type="radio"
                id={`${field}_${opt.value}`}
                name={field}
                value={opt.value}
                data-testid={`rdo-${opt.value}`}
                className={inputClasses}
                onClick={(e: ChangeEvent<HTMLButtonElement>) => onClick?.(e)}
                disabled={disabled || opt.isDisabled}
              />
              <label htmlFor={`${field}_${opt.value}`}>{opt.text}</label>
            </div>
          ))}
      </div>
      <ErrorMessage name={field} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default FormRadioList;
