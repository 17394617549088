/* eslint-disable max-len */
import { ErrorMessage, Field, FormikErrors, useField } from 'formik';
import { ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormDateSelectorPropTypes } from '../../../types/propTypes/FormDateSelectorPropTypes';
import { formatJSDateToDateString } from '../../../utilities/dateUtilities';
import { applyClassesForInput } from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';

const FormDateSelector = ({
  displayName,
  field: fieldName,
  placeholderText,
  disabled,
  classConfig,
  validationSchema,
}: FormDateSelectorPropTypes): ReactElement => {
  const [field, , { setValue, setTouched }] = useField(fieldName);

  const setFormDetails = (value: Date): void => {
    setTouched(true);

    // Don't cover this since it is the DatePicker
    // itself that deals with the value setting,
    // this is only the formik call
    /* istanbul ignore next */
    setValue(formatJSDateToDateString(value));
  };

  // Needed only for Form library, so not test covered
  /* istanbul ignore next */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOutside = async (): Promise<FormikErrors<any> | void> =>
    setTouched(true);

  const getDateFromFieldValue = (): Date | null => {
    if (!field.value) {
      return null;
    }
    const parsedDateWithTime = new Date(field.value);
    const dateWithoutTime = new Date(
      parsedDateWithTime.getUTCFullYear(),
      parsedDateWithTime.getUTCMonth(),
      parsedDateWithTime.getUTCDate()
    );
    return isNaN(dateWithoutTime.getTime()) ? null : dateWithoutTime;
  };

  return (
    <div
      className={`field-container date-input ${disabled ? 'disabled' : ''} ${
        classConfig?.containerClasses || ''
      }`}
      data-testid={`form-date-selector-${fieldName}`}
    >
      <FormInputLabel
        field={fieldName}
        displayName={displayName}
        validationSchema={validationSchema}
        classes={classConfig?.labelClasses || ''}
      />
      <Field
        name={fieldName}
        id={fieldName}
        component={DatePicker}
        label={displayName}
        format="mm/dd/yyyy"
        placeholderText={placeholderText ?? 'mm/dd/yyyy'}
        className={applyClassesForInput(
          fieldName,
          `${classConfig?.inputClasses || ''} data-input`
        )}
        disabled={disabled}
        selected={getDateFromFieldValue()}
        onChange={(val: Date) => {
          setFormDetails(val);
        }}
        onSelect={(val: Date) => {
          setFormDetails(val);
        }}
        onClickOutside={handleClickOutside}
      />
      <ErrorMessage name={fieldName} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default FormDateSelector;
