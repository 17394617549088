/* eslint-disable max-len */
import { ReactElement } from 'react';
import {
  ApiErrorDisplayOverrideModel,
  getDefaultStatusCodeConfiguration,
} from '../../types/ApiErrorDisplayOverrideModel';
import { MSDE_APP_HELP_EMAIL } from '../../types/Constants';
import { ApiErrorPanelPropTypes } from '../../types/propTypes/ApiErrorPanelPropTypes';
import './ApiErrorPanel.css';

const ApiErrorPanel = ({
  error,
  containerClass,
  includeTitle,
  title,
  supportedNonGenericErrors,
}: ApiErrorPanelPropTypes): ReactElement => {
  const nonModalClass = includeTitle ? 'api-error-panel--container' : '';
  const panelShiftClass = includeTitle ? 'api-error-panel--title-shift' : '';

  const buildErrorMessageOverride = ():
    | ApiErrorDisplayOverrideModel
    | undefined => {
    const customError = supportedNonGenericErrors?.find(
      (x) => x.statusCode == error.status
    );
    const defaultError = getDefaultStatusCodeConfiguration().find(
      (x) => x.statusCode == error.status
    );

    return customError ? customError : defaultError;
  };
  const activeNonGenericError = buildErrorMessageOverride();

  const computedTitle = activeNonGenericError
    ? error.title
      ? error.title
      : 'An error occurred'
    : title ?? 'A system error occurred';

  const computedHowToFix = activeNonGenericError ? (
    activeNonGenericError.howToFix
  ) : (
    <>
      Try refreshing the page and if the problem persists please reach out to
      the Help Desk at{' '}
      <a href={`mailto:${MSDE_APP_HELP_EMAIL}`}>{MSDE_APP_HELP_EMAIL}</a> and
      have your trace ID ready when contacting us.
    </>
  );

  return (
    <div
      data-testid="api-error-panel"
      className={`${nonModalClass} ${containerClass}`}
    >
      {includeTitle && (
        <div
          className="api-error-panel--title-container"
          data-testid="api-error-panel--title-section"
        >
          <div className="api-error-panel--icon"></div>
          <div className="api-error-panel--title">{computedTitle}</div>
        </div>
      )}
      {error.traceId && (
        <div
          data-testid="api-error-panel--trace-section"
          className={panelShiftClass}
        >
          <section className="api-error-panel--trace-id">
            Trace ID: {error.traceId}
          </section>
          {computedHowToFix && (
            <section className="api-error-panel--info-message">
              <h4>How to fix the problem:</h4>
              <p data-testid="how-to-fix-message">{computedHowToFix}</p>
            </section>
          )}
        </div>
      )}
      {!error.traceId && (
        <div
          className={`api-error-panel--content-detail ${panelShiftClass}`}
          data-testid="api-error-panel--detail-section"
        >
          {error.detail}
        </div>
      )}
    </div>
  );
};

export default ApiErrorPanel;
