/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentEntryModel } from '../../../types/NonPublicStudentEntryModel';
import {
  NonPublicStudentFormViewModel,
  NPSESchoolType,
} from '../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../types/propTypes/FormDropdownPropTypes';
import { NonPublicStudentEntryValidationSchema } from '../../../types/Validation/NonPublicStudentEntryValidationSchema';
import { isStateNonAdmin } from '../../../utilities/userUtilities';
import FormDateSelector from '../../FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';
import './NPSE_EnrollmentPanel.css';

const NPSE_EnrollmentPanel = (): ReactElement => {
  const { values } = useFormikContext<NonPublicStudentEntryModel>();

  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  const handleGradeLevelChange = (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    if (values.schoolType === NPSESchoolType.SPED) {
      props.setValueHook &&
        props.setValueHook('ideaEducationalEnvironment', '', false);
    }

    return Promise.resolve();
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Student Enrollment">
      <div className="field-row field-row-enrollment">
        <FormDateSelector
          displayName="Enrollment Entry Date"
          field="enrollmentEntryDate"
          disabled={isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormTextbox
          displayName="Entry Type"
          field="entryType"
          disabled={true}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormDropdown
          displayName="Enrollment Status"
          field="enrollmentStatus"
          options={formViewModel.enrollmentStatusOptions}
          disabled={isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormDropdown
          displayName="Grade Level"
          field="gradeLevel"
          options={formViewModel.gradeLevelOptions}
          validationSchema={NonPublicStudentEntryValidationSchema}
          disabled={isStateNonAdmin()}
          onChangeEvent={handleGradeLevelChange}
        />
      </div>
    </Panel>
  );
};

export default NPSE_EnrollmentPanel;
