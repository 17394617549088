/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExportFieldOption } from '../types/ExportFieldOption';
import { FILE_EXPORT_TYPE } from '../types/FileExportType';
import { createDownloadLinkAndExecute } from '../utilities/formUtilities';

export const convertObjectArrayToCSVString = (
  data: any[],
  fieldDetails: ExportFieldOption[]
): string => {
  const sanitizeAndQuoteValue = (value: string): string => {
    if (!value) {
      return '""';
    }

    const sanitizedValue = value.replace(/"/g, '""');
    return `"${sanitizedValue}"`;
  };

  const getHeaderRow = (): string[] => {
    const headerRow: string[] = [];
    fieldDetails.map((d) =>
      headerRow.push(sanitizeAndQuoteValue(d.columnName))
    );
    return headerRow;
  };

  const getRowData = (element: any): string[] => {
    const rowData: string[] = [];
    fieldDetails.map((d: ExportFieldOption) => {
      const staticVal = element[d.fieldName] || '';

      const manuipulateValue = d.manipulationFunction
        ? d.manipulationFunction(staticVal)
        : staticVal;

      rowData.push(sanitizeAndQuoteValue(manuipulateValue));
    });
    return rowData;
  };

  const results = '';
  if (data && data.length > 0) {
    const fullData = [];

    fullData.push(getHeaderRow());

    data.forEach((element) => {
      fullData.push(getRowData(element));
    });

    // Join them all with commas and hard returns on line end
    const convertedData = fullData.map((e) => e.join(',')).join('\n');
    return convertedData;
  }
  return results;
};

export const prepareDataForDownload = (
  data: unknown,
  fileName: string,
  fileType: FILE_EXPORT_TYPE
): void => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data as unknown as string], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  createDownloadLinkAndExecute(fileName, blob);
};
