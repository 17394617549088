/* eslint-disable max-len */
import { ReactElement } from 'react';
import {
  getAdditionalTextOptions,
  getMaxFileUploadSizeMB,
  getMaxUploadFileCount,
  getMaxUploadFilenameLength,
} from '../../../services/fileUpload.service';
import { FileUploadLegendPropTypes } from '../../../types/propTypes/FileUploadLegendPropTypes';
import './FileUploadLegend.css';

export const FileUploadLegend = ({
  formModel,
}: FileUploadLegendPropTypes): ReactElement => {
  const maxFileSize = getMaxFileUploadSizeMB();
  const maxFileCount = getMaxUploadFileCount();
  const allowedExtensions = [...formModel.allowedFileExtensions];
  const maxFileCharLength = getMaxUploadFilenameLength();

  const maxFileSizeBold = `${maxFileSize} MB`;
  const maxFileSizeExt = ' maximum size per file';

  const maxFileCountBold = `${maxFileCount} files`;
  const maxFileCountExt = ' maximum upload';

  const fileSizeText = 'maximum filename length';
  const allowedFileEndings = [...formModel.allowedFileEndings];

  const getExtendedFormatLabel = (format: string): string => {
    return format;
  };

  return (
    <div className="files--upload--info">
      <p>Please follow this file naming convention:</p>
      <div className="files--upload--info--details">
        <div className="files--upload--info--details-container">
          <div className="files--upload--info--legend">
            <div
              id="files-upload-info-legend-example-school-district"
              className="files--upload--info--legend-example"
            >
              <div
                className="files--upload--info--legend-value"
                aria-describedby="files--upload--info--legend--district-or-number"
              >
                0123
              </div>

              <div
                id="files--upload--info--legend--district-or-number"
                className="files--upload--info--legend-label"
              >
                LEA Number
              </div>
            </div>
          </div>
          <div className="files--upload--info--legend">
            <div
              id="files-upload-info-legend-example-optional-text"
              className="files--upload--info--legend-example"
            >
              <div
                className="files--upload--info--legend-value"
                aria-describedby="files--upload--info--legend--optional-text"
              >
                _OptionalText_
              </div>
              <div
                id="files--upload--info--legend--optional-text"
                className="files--upload--info--legend-label"
              >
                Optional Text
              </div>
            </div>
          </div>
          <div className="files--upload--info--legend">
            <div
              id="files-upload-info-legend-example-data-type"
              className="files--upload--info--legend-example"
            >
              <div
                className="files--upload--info--legend-value"
                aria-describedby="files--upload--info--legend--data-type"
              >
                MsisIdRequest
              </div>
              <div
                id="files--upload--info--legend--data-type"
                className="files--upload--info--legend-label"
              >
                Data Type Name
              </div>
            </div>
          </div>
          <div className="files--upload--info--legend">
            <div
              id="files-upload-info-legend-example-file-extension"
              className="files--upload--info--legend-example"
            >
              <div
                className="files--upload--info--legend-value"
                aria-describedby="files--upload--info--legend--file-extension"
              >
                .csv
              </div>
              <div
                id="files--upload--info--legend--file-extension"
                className="files--upload--info--legend-label"
              >
                File Format
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="files--upload--info--meta-container">
        <div className="files--upload--info--meta">
          <label id="file-size-label">File Name & Size Limits:</label>
          <ul aria-labelledby="file-size-label">
            <li aria-label={`${maxFileSizeBold}${maxFileSizeExt}`}>
              <strong>{maxFileSizeBold}</strong>
              {maxFileSizeExt}
            </li>
            <li aria-label={`${maxFileCharLength} characters ${fileSizeText}`}>
              <strong>{maxFileCharLength} characters</strong> {fileSizeText}
            </li>
          </ul>
        </div>
        {/** TODO: Remove display:none once we have mulitple file upload corrected */}
        <div className="files--upload--info--meta" style={{ display: 'none' }}>
          <label id="file-quantity-label">File Quantity:</label>
          <ul aria-labelledby="file-quantity-label">
            <li aria-label={`${maxFileCountBold}${maxFileCountExt}`}>
              <strong>{maxFileCountBold}</strong>
              {maxFileCountExt}
            </li>
          </ul>
        </div>
        <div className="files--upload--info--meta">
          <label id="optional-text-label">Optional Text:</label>
          <ul aria-labelledby="optional-text-label">
            {getAdditionalTextOptions().map((text: string, index: number) => (
              <li key={`optional-text-${index}`} aria-label={text}>
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className="files--upload--info--meta">
          <label id="file-format-label">Supported File Formats:</label>
          <ul aria-labelledby="file-format-label">
            {allowedExtensions.map((ext: string) => (
              <li key={ext} aria-label={ext}>
                {getExtendedFormatLabel(ext.toUpperCase())}
              </li>
            ))}
          </ul>
        </div>
        <div className="files--upload--info--meta">
          <label id="file-type-label">Supported Data Type Names:</label>
          <ul aria-labelledby="file-type-label">
            {allowedFileEndings.length > 0 &&
              allowedFileEndings.sort().map((type: string, index: number) => (
                <li
                  data-testid={`file-ending-item-${index}`}
                  key={type}
                  aria-label={type}
                >
                  {type}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
