/* eslint-disable max-len */
import { MutableRefObject, ReactElement, useRef } from 'react';
import {
  AuthorizationContext,
  AuthorizationContextType,
} from '../../hooks/useAuthorizationContext';
import { useTokenRefresh } from '../../hooks/useTokenRefresh';
import { getLogoutTriggerMS } from '../../services/app.service';
import {
  getMillisecondsFromAuthTokenRequestDate,
  getMillisecondsFromReportTokenRequestDate,
} from '../../services/token.service';
import { MessageWithObjectDebugLog } from '../../testing/debugFunctions';
import { AuthorizationContextProviderPropTypes } from '../../types/propTypes/AuthorizationContextProviderPropTypes';

const AuthorizationContextProvider = ({
  children,
}: AuthorizationContextProviderPropTypes): ReactElement => {
  const authTimer = useRef<number>();
  const reportTimer = useRef<number>();
  const getTriggerTimeMS = getLogoutTriggerMS();
  const { onAuthTokenExpire, onReportTokenExpiration } = useTokenRefresh();

  const getMSLeftBeforeExpire = (tokenMSLeft: number): number => {
    if (tokenMSLeft <= 0) {
      return 0;
    }
    return tokenMSLeft - getTriggerTimeMS;
  };

  const clearTimer = (timer?: MutableRefObject<number | undefined>): void => {
    if (timer?.current !== undefined) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  };

  const authTokenTimerStart = async (): Promise<void> => {
    const tokenMSLeft = getMillisecondsFromAuthTokenRequestDate();
    const timeLeft = getMSLeftBeforeExpire(tokenMSLeft);
    if (timeLeft <= 0) {
      clearTimer(authTimer);
      await onAuthTokenExpire(authTokenTimerStart);
    } else {
      if (authTimer.current === undefined) {
        MessageWithObjectDebugLog('AUTH TIMER - RESTART/START', timeLeft);
        authTimer.current = window.setTimeout(async () => {
          clearTimer(authTimer);
          await onAuthTokenExpire(authTokenTimerStart);
        }, timeLeft);
      }
    }
  };

  const authTokenTimerStop = (): void => {
    MessageWithObjectDebugLog('CLEAR AUTH TIMER', authTimer);
    clearTimer(authTimer);
  };

  const reportTokenTimerStart = async (): Promise<void> => {
    const tokenMSLeft = getMillisecondsFromReportTokenRequestDate();
    const timeLeft = getMSLeftBeforeExpire(tokenMSLeft);
    if (timeLeft <= 0) {
      clearTimer(reportTimer);
      await onReportTokenExpiration(reportTokenTimerStart);
    } else {
      if (reportTimer.current === undefined) {
        MessageWithObjectDebugLog('REPORT TIMER - RESTART/START', timeLeft);
        reportTimer.current = window.setTimeout(async () => {
          clearTimer(reportTimer);
          await onReportTokenExpiration(reportTokenTimerStart);
        }, timeLeft);
      }
    }
  };

  const reportTokenTimerStop = (): void => {
    MessageWithObjectDebugLog('CLEAR REPORT TIMER', reportTimer);
    clearTimer(reportTimer);
  };

  const context: AuthorizationContextType = {
    authTokenStart: async () => {
      await authTokenTimerStart();
      return true;
    },
    authTokenStop: () => {
      authTokenTimerStop();
      return true;
    },
    reportTokenStart: async () => {
      await reportTokenTimerStart();
      return Promise.resolve(true);
    },
    reportTokenStop: () => {
      reportTokenTimerStop();
      return true;
    },
  };

  return (
    <AuthorizationContext.Provider value={context}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationContextProvider;
