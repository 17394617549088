/* eslint-disable max-len */
import { get } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import AuthenticatedRoute from '../../components/AuthenticatedRoute/AuthenticatedRoute';
import IdleTimer from '../../components/IdleTimer/IdleTimer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectUserName, setLoginError } from '../../redux/slices/loginSlice';
import { TooltipAnchor } from '../../services/app.service';
import { getStoredUserName, tokenIsPresent } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import {
  isNewUser,
  storeCurrentRouteForRelogin,
} from '../../utilities/userUtilities';
import Accreditation from '../Accreditation/Accreditation';
import AccreditationEdits from '../AccreditationEdits/AccreditationEdits';
import Dashboard from '../Dashboard/Dashboard';
import CharterSchoolAuthorizingOrganizationList from '../DataInput/DistrictSchoolInfo/CharterSchools/CharterSchoolAuthorizingOrganizationList';
import NewCharterSchoolAssignment from '../DataInput/DistrictSchoolInfo/CharterSchools/NewCharterSchoolAssignment';
import NewCharterSchoolAuthorizingOrganization from '../DataInput/DistrictSchoolInfo/CharterSchools/NewCharterSchoolAuthorizingOrganization';
import NewCharterSchoolManagementOrganization from '../DataInput/DistrictSchoolInfo/CharterSchools/NewCharterSchoolManagementOrganization';
import LEAManagementInput from '../DataInput/DistrictSchoolInfo/LEAManagement/LEAManagementInput';
import LEAManagementList from '../DataInput/DistrictSchoolInfo/LEAManagement/LEAManagementList';
import LEASharingInput from '../DataInput/DistrictSchoolInfo/LEASharings/LEASharingInput';
import LEASharingList from '../DataInput/DistrictSchoolInfo/LEASharings/LEASharingList';
import SalaryScaleList from '../DataInput/DistrictSchoolInfo/SalaryScale/SalaryScaleList';
import SchoolManagementInput from '../DataInput/DistrictSchoolInfo/SchoolManagement/SchoolManagementInput';
import SchoolManagementList from '../DataInput/DistrictSchoolInfo/SchoolManagement/SchoolManagementList';
import CourseCodeInput from '../DataInput/MdeOtss/CourseCodes/CourseCodeInput';
import CourseCodeList from '../DataInput/MdeOtss/CourseCodes/CourseCodeList';
import SeaOrganizationInput from '../DataInput/MdeOtss/SeaOrganizations/SeaOrganizationInput';
import SeaOrganizationList from '../DataInput/MdeOtss/SeaOrganizations/SeaOrganizationList';
import NonPublicStudentEntryInput from '../DataInput/Student/NonPublicStudentEntry/NonPublicStudentEntryInput';
import NonPublicStudentEntryList from '../DataInput/Student/NonPublicStudentEntry/NonPublicStudentEntryList';
import NonPublicStudentEntryScheduleInput from '../DataInput/Student/NonPublicStudentEntry/NonPublicStudentEntryScheduleInput';
import SummerActivityInput from '../DataInput/Student/SummerActivity/SummerActivityInput';
import SummerActivityList from '../DataInput/Student/SummerActivity/SummerActivityList';
import DataQualityDashboard from '../DataQualityDashboard/DataQualityDashboard';
import DataQualityDetail from '../DataQualityDetail/DataQualityDetail';
import Feedback from '../Feedback/Feedback';
import FileDetail from '../FileDetail/FileDetail';
import Files from '../Files/Files';
import FileUpload from '../FileUpload/FileUpload';
import MSISRequest from '../MSISRequest/MSISRequest';
import MSISRequestDetail from '../MSISRequestDetail/MSISRequestDetail';
import MSISRequestNew from '../MSISRequestNew/MSISRequestNew';
import NewUser from '../NewUser/NewUser';
import NotFound from '../NotFound/NotFound';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import ReportDisplay from '../ReportDisplay/ReportDisplay';
import ShowToken from '../ShowToken/ShowToken';
import Sidebar from '../Sidebar/Sidebar';
import StudentOwnershipConflict from '../StudentOwnershipConflict/StudentOwnershipConflict';
import StudentOwnershipConflictDetail from '../StudentOwnershipConflictDetail/StudentOwnershipConflictDetail';
import UserDetail from '../UserDetail/UserDetail';
import UserEdit from '../UserEdit/UserEdit';
import UserList from '../UserList/UserList';
import './Wireframe.css';

const Wireframe = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedName = useAppSelector(selectUserName);
  const sessionName = getStoredUserName();
  const name =
    selectedName.length === 0 && sessionName ? sessionName : selectedName;
  const getAttemptedPath = get(useParams(), '*', undefined);

  useEffect(() => {
    const handleLoginError = (errorDescription: string): void => {
      if (errorDescription.includes('is not an accepted domain')) {
        dispatch(
          setLoginError(
            'The email entered is invalid. Please enter your district email and password to gain access to MSIS 2.0.'
          )
        );
      }
      navigate(appRoutePaths.Login, { replace: true });
    };

    const handleGoToNewUser = (): void => {
      navigate(appRoutePaths.NewUser, { replace: true });
    };

    const handleGoToLogin = (): void => {
      if (getAttemptedPath) {
        storeCurrentRouteForRelogin(getAttemptedPath);
      }
      navigate(appRoutePaths.Login, { replace: true });
    };

    const handleGetToken = (code: string): void => {
      navigate(`${appRoutePaths.GetToken}?code=${code}`, { replace: true });
    };

    const forceRedirects = (): void => {
      if (!tokenIsPresent()) {
        const params = new URLSearchParams(window.location.search);
        const errorDescription = params.get('error_description');
        const code = params.get('code');
        if (!code) {
          if (errorDescription) {
            // Assuming bad error from identity provider
            handleLoginError(errorDescription);
          } else {
            handleGoToLogin();
          }
        } else {
          handleGetToken(code);
        }
      } else if (isNewUser()) {
        handleGoToNewUser();
      }
    };
    forceRedirects();
  }, [navigate, dispatch, getAttemptedPath]);

  return (
    <div className="authenticated-page grid">
      <Sidebar name={name} />
      <main>
        <IdleTimer debugMode={false} />
        <Routes>
          <Route path={appRoutePaths.Dashboard} element={<Dashboard />} />
          <Route path={appRoutePaths.NewUser} element={<NewUser />} />
          <Route path={appRoutePaths.ShowToken} element={<ShowToken />} />
          <Route
            path={appRoutePaths.UserList}
            element={
              <AuthenticatedRoute route={appRoutePaths.UserList}>
                <UserList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.UserDetail()}
            element={
              <AuthenticatedRoute route={appRoutePaths.UserDetail()}>
                <UserDetail />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.UserEdit()}
            element={
              <AuthenticatedRoute route={appRoutePaths.UserEdit()}>
                <UserEdit />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.DataQualityDashboard}
            element={
              <AuthenticatedRoute route={appRoutePaths.DataQualityDashboard}>
                <DataQualityDashboard />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.DataQualityDetail()}
            element={
              <AuthenticatedRoute route={appRoutePaths.DataQualityDetail()}>
                <DataQualityDetail />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.Accreditation}
            element={
              <AuthenticatedRoute route={appRoutePaths.Accreditation}>
                <Accreditation />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.AccreditationEdits()}
            element={
              <AuthenticatedRoute route={appRoutePaths.Accreditation}>
                <AccreditationEdits />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.ReportDashboard}
            element={
              <AuthenticatedRoute route={appRoutePaths.ReportDashboard}>
                <ReportDashboard />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.ReportDisplay()}
            element={
              <AuthenticatedRoute route={appRoutePaths.ReportDisplay()}>
                <ReportDisplay />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SubmissionStatus}
            element={
              <AuthenticatedRoute route={appRoutePaths.SubmissionStatus}>
                <Files />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.FileUpload}
            element={
              <AuthenticatedRoute route={appRoutePaths.FileUpload}>
                <FileUpload />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.FileDetail()}
            element={
              <AuthenticatedRoute route={appRoutePaths.FileDetail()}>
                <FileDetail />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.MSISRequest}
            element={
              <AuthenticatedRoute route={appRoutePaths.MSISRequest}>
                <MSISRequest />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.MSISRequestNew}
            element={
              <AuthenticatedRoute route={appRoutePaths.MSISRequestNew}>
                <MSISRequestNew />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.MSISRequestDetail()}
            element={
              <AuthenticatedRoute route={appRoutePaths.MSISRequestDetail()}>
                <MSISRequestDetail />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.StudentOwnership}
            element={
              <AuthenticatedRoute route={appRoutePaths.StudentOwnership}>
                <StudentOwnershipConflict />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.StudentOwnershipDetail()}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.StudentOwnershipDetail()}
              >
                <StudentOwnershipConflictDetail />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.CharterSchoolAuthorizingOrganizationList}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.CharterSchoolAuthorizingOrganizationList}
              >
                <CharterSchoolAuthorizingOrganizationList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.LEAManagementList}
            element={
              <AuthenticatedRoute route={appRoutePaths.LEAManagementList}>
                <LEAManagementList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.LEAManagementInput()}
            element={
              <AuthenticatedRoute route={appRoutePaths.LEAManagementInput()}>
                <LEAManagementInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.NewCharterSchoolAuthorizingOrganization}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.NewCharterSchoolAuthorizingOrganization}
              >
                <NewCharterSchoolAuthorizingOrganization />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.NewCharterSchoolManagementOrganization}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.NewCharterSchoolManagementOrganization}
              >
                <NewCharterSchoolManagementOrganization />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.NewCharterSchoolAssignment}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.NewCharterSchoolAssignment}
              >
                <NewCharterSchoolAssignment />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SchoolManagementList}
            element={
              <AuthenticatedRoute route={appRoutePaths.SchoolManagementList}>
                <SchoolManagementList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.LEASharingList}
            element={
              <AuthenticatedRoute route={appRoutePaths.LEASharingList}>
                <LEASharingList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SchoolManagementInput()}
            element={
              <AuthenticatedRoute route={appRoutePaths.SchoolManagementInput()}>
                <SchoolManagementInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.LEASharingInput()}
            element={
              <AuthenticatedRoute route={appRoutePaths.LEASharingInput()}>
                <LEASharingInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SalaryScaleList}
            element={
              <AuthenticatedRoute route={appRoutePaths.SalaryScaleList}>
                <SalaryScaleList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SeaOrganizationList}
            element={
              <AuthenticatedRoute route={appRoutePaths.SeaOrganizationList}>
                <SeaOrganizationList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SeaOrganizationInput()}
            element={
              <AuthenticatedRoute route={appRoutePaths.SeaOrganizationInput()}>
                <SeaOrganizationInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.CourseCodeList}
            element={
              <AuthenticatedRoute route={appRoutePaths.CourseCodeList}>
                <CourseCodeList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.CourseCodeInput()}
            element={
              <AuthenticatedRoute route={appRoutePaths.CourseCodeInput()}>
                <CourseCodeInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.NonPublicStudentEntryList}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.NonPublicStudentEntryList}
              >
                <NonPublicStudentEntryList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.NonPublicStudentEntryScheduleInput()}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.NonPublicStudentEntryScheduleInput()}
              >
                <NonPublicStudentEntryScheduleInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.NonPublicStudentEntryInput()}
            element={
              <AuthenticatedRoute
                route={appRoutePaths.NonPublicStudentEntryInput()}
              >
                <NonPublicStudentEntryInput />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SummerActivityList}
            element={
              <AuthenticatedRoute route={appRoutePaths.SummerActivityList}>
                <SummerActivityList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={appRoutePaths.SummerActivityInput()}
            element={
              <AuthenticatedRoute route={appRoutePaths.SummerActivityInput()}>
                <SummerActivityInput />
              </AuthenticatedRoute>
            }
          />
          <Route path={appRoutePaths.Feedback} element={<Feedback />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Tooltip id={TooltipAnchor} style={{ zIndex: '99999999' }} />
      </main>
    </div>
  );
};

export default Wireframe;
