/* eslint-disable max-len */
import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { FormCheckboxListPropTypes } from '../../../types/propTypes/FormCheckboxListPropTypes';
import { applyClassesForGroupedInput } from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';
import './FormCheckboxList.css';

const FormCheckboxList = ({
  field,
  options,
  displayName,
  addAllCheckbox,
  classConfig,
  validationSchema,
  disabled,
  subLevelConfigNames,
}: FormCheckboxListPropTypes): ReactElement => {
  const { values } = useFormikContext();
  const [, , helper] = useField(field);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  useEffect(() => {
    const fieldValues = (values as Record<string, unknown>)[
      field
    ] as Array<string>;
    setSelectAllChecked(fieldValues.length === options.length);
  }, [field, options.length, values]);

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
    helper.setTouched(true);

    if (event.target.checked) {
      const allValues = options.map((x) => x.value);
      helper.setValue(allValues);
      setSelectAllChecked(true);
    } else {
      helper.setValue([]);
      setSelectAllChecked(false);
    }
  };

  const handleListItemChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const oldValue = (values as Record<string, unknown>)[
      field
    ] as Array<string>;

    helper.setTouched(true);

    if (!event.target.checked) {
      const newValue = oldValue.filter((item) => item !== event.target.value);
      helper.setValue(newValue);
      setSelectAllChecked(false);
    } else {
      const newValue = [...oldValue, event.target.value];
      helper.setValue(newValue);
      if (newValue.length === options.length) {
        setSelectAllChecked(true);
      }
    }
  };

  return (
    <div
      className={`field-container ${classConfig?.containerClasses || ''}`}
      data-testid={`form-checkbox-list-${field}`}
    >
      <FormInputLabel
        field={field}
        displayName={displayName}
        validationSchema={validationSchema}
        classes={classConfig?.labelClasses || ''}
      />
      <div
        data-testid="checkbox-list-container"
        className={`checkbox-list-container ${applyClassesForGroupedInput(
          field,
          `${classConfig?.containerClasses || ''}`
        )}`}
      >
        {addAllCheckbox && (
          <div
            className="checkbox-list-option all-option"
            key={`${field}-opt-all`}
          >
            <Field
              type="checkbox"
              id={`${field}_all`}
              data-testid={`${field}-chk-all`}
              onChange={handleSelectAll}
              className={classConfig?.inputClasses || ''}
              checked={selectAllChecked}
              disabled={disabled}
            />
            <label htmlFor={`${field}_all`}>Select All</label>
          </div>
        )}
        {options.length > 0 &&
          options.map((opt, index) => (
            <div
              className={`checkbox-list-option ${
                addAllCheckbox && 'non-all-option'
              }
              ${
                subLevelConfigNames != null &&
                subLevelConfigNames.indexOf(opt.value) > -1 &&
                'sub-level-option'
              }`}
              key={`${field}-opt-${index}`}
            >
              <Field
                type="checkbox"
                id={`${field}_${opt.value}`}
                name={field}
                value={opt.value}
                disabled={opt.isDisabled || disabled}
                className={classConfig?.inputClasses || ''}
                onChange={handleListItemChange}
                data-testid={`chk-${opt.value}`}
              />
              <label htmlFor={`${field}_${opt.value}`}>{opt.text}</label>
            </div>
          ))}
      </div>
      <ErrorMessage name={field} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default FormCheckboxList;
