import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import StudentMatches from '../../components/StudentMatches/StudentMatches';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectStudentDetails,
  selectStudentLoading,
} from '../../redux/slices/studentSlice';
import { fetchStudentDetails } from '../../redux/thunks/studentThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { MSISRequestDetailModel } from '../../types/MSISRequestDetailModel';
import { formatToDateString } from '../../utilities/dateUtilities';
import './MSISRequestDetail.css';

const MSISRequestDetail = (): ReactElement => {
  const { requestId } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectStudentLoading);
  const studentDetails: MSISRequestDetailModel | undefined =
    useAppSelector(selectStudentDetails);

  const [infoMessage, setInfoMessage] = useState('MSIS ID was assigned.');

  useEffect(() => {
    let promise: unknown = undefined;
    if (requestId) {
      promise = dispatch(fetchStudentDetails(requestId));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, requestId]);

  const handleMatchSuccess = (message: string): Promise<void> => {
    requestId && dispatch(fetchStudentDetails(requestId));
    setInfoMessage(message);
    return Promise.resolve();
  };

  return (
    <>
      <NavigationPage
        heading={studentDetails?.fullName || ''}
        pageClass="msis-request-detail"
        isLoading={isLoading}
        loadingDataId="msis-request-detail-loader"
        loadingText="Getting your MSIS Request Details"
        backBarLocation={appRoutePaths.MSISRequest}
      >
        {studentDetails ? (
          <>
            <div className="msis-request-detail--content">
              <div className="msis-request-detail--container">
                <LabelAndValue
                  displayName="MSIS ID"
                  value={studentDetails.msisId?.toString() || ''}
                  blankValue={' '}
                  showColonOnLabel={false}
                />
                <LabelAndValue
                  displayName="Date of Birth"
                  value={formatToDateString(studentDetails.dateOfBirth)}
                  showColonOnLabel={false}
                />
                <LabelAndValue
                  displayName="SSN"
                  value={studentDetails?.ssn || ''}
                  showColonOnLabel={false}
                  testId={'msis-request-detail-ssn-field'}
                />
              </div>
              <StudentMatches
                model={studentDetails}
                onSuccess={handleMatchSuccess}
              />
            </div>
          </>
        ) : undefined}
      </NavigationPage>
      <div className="page--grid">
        {!isLoading && studentDetails && studentDetails.msisId && (
          <InfoPanel
            containerClass={'msis-request-detail--info-panel'}
            message={infoMessage}
          />
        )}
      </div>
    </>
  );
};

export default MSISRequestDetail;
