import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapToReportTokenResponsePayload } from '../../mappers/TokenMapper';
import { getPowerBIToken } from '../../services/auth.service';
import {
  isValidCode,
  setReportListResponse,
  setReportTokenResponse,
} from '../../services/base.service';
import { getReportListModel } from '../../services/report.service';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { ReportListModel } from '../../types/ReportListModel';
import { defaultReportList } from '../slices/reportSlice';

export const fetchTokenAndReportList = createAsyncThunk(
  'report/fetchTokenAndReportList',
  async (_, thunkAPI) => {
    interface ReportAuthorizationModel {
      data: ReportListModel;
      error?: ApiErrorModel;
    }

    const returnObj: ReportAuthorizationModel = {
      data: defaultReportList,
      error: undefined,
    };

    // Get report list
    const reportListResponse = await getReportListModel({
      signal: thunkAPI.signal,
    });

    // If successful, set session with list and set return value
    if (isValidCode(reportListResponse.status)) {
      setReportListResponse(reportListResponse.data);
      returnObj.data = reportListResponse.data;

      // Only proceed if there is any report in the list
      if (reportListResponse.data.categories.length > 0) {
        // Get powerBI token for embedded reports
        const tokenResponse = await getPowerBIToken({
          signal: thunkAPI.signal,
        });

        // If successful, set mapped token payload into session state
        if (isValidCode(tokenResponse.status)) {
          const tokenPayload = mapToReportTokenResponsePayload(
            tokenResponse.data
          );
          setReportTokenResponse(tokenPayload);
        } else {
          returnObj.error = tokenResponse.error;
        }
      }
    } else {
      returnObj.error = reportListResponse.error;
    }

    return returnObj;
  }
);
