import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getSchoolManagementById,
  getSchoolManagementFormViewModel,
  getSchoolManagementListItems,
} from '../../services/schoolManagement.service';
import { SchoolManagementFormViewModel } from '../../types/SchoolManagementFormViewModel';
import { SchoolManagementListItemModel } from '../../types/SchoolManagementListItemModel';
import { SchoolManagementModel } from '../../types/SchoolManagementModel';

export const fetchSchoolManagementListItems = createAsyncThunk(
  'input/fetchSchoolManagementListItems',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      SchoolManagementListItemModel[]
    >(thunkAPI, getSchoolManagementListItems({ signal: thunkAPI.signal }));
  }
);

export const fetchSchoolManagementById = createAsyncThunk(
  'input/fetchSchoolManagementById',
  async (schoolId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SchoolManagementModel>(
      thunkAPI,
      getSchoolManagementById(schoolId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchSchoolManagementFormViewModel = createAsyncThunk(
  'input/fetchSchoolManagementFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SchoolManagementFormViewModel>(
      thunkAPI,
      getSchoolManagementFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
