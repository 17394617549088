/* eslint-disable max-len */
import { ReactElement, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataQualityFilter from '../../components/DataQualityFilter/DataQualityFilter';
import DataQualityRuleErrorList from '../../components/DataQualityRuleErrorList/DataQualityRuleErrorList';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import TabContainer from '../../components/TabContainer/TabContainer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectCategoryErrors,
  selectDataQualityLoading,
  selectDQDFilter,
} from '../../redux/slices/dataQualitySlice';
import { fetchDataQualityItemsForCategory } from '../../redux/thunks/dataQualityThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { DataQualityErrorsRequest } from '../../types/DataQualityDashboardRequest';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import './DataQualityDetail.css';

const DataQualityDetail = (): ReactElement => {
  const { category } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectDataQualityLoading);
  const filter = useAppSelector(selectDQDFilter);
  const details = useAppSelector(selectCategoryErrors);

  useEffect(() => {
    let promise: unknown = undefined;
    if (category) {
      const params: DataQualityErrorsRequest = {
        domain: category,
        filter: filter,
      };
      promise = dispatch(fetchDataQualityItemsForCategory(params));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, category, filter]);

  const getTabDisplayNames = (): string[] =>
    details.tabs.map((tab) => tab.displayName);

  const isEmptyList = details.tabs.length === 0;

  const buildTabs = (): ReactNode[] => {
    return details.tabs.map((tab, index) => {
      return (
        <div
          key={`rule-${index}`}
          data-testid={`rule-${index}-tab-container`}
          className="data-quality-rule-container"
        >
          <DataQualityRuleErrorList
            headerColumns={tab.orderedHeaders}
            dataRows={tab.rowData}
          />
        </div>
      );
    });
  };

  return (
    <NavigationPage
      heading={details.domain}
      pageClass="data-quality--detail"
      isLoading={isLoading}
      loadingDataId="data-quality-detail-loader"
      loadingText="Getting your details"
      backBarLocation={appRoutePaths.DataQualityDashboard}
    >
      <>
        {!isEmptyList && (
          <>
            <DataQualityFilter
              dataCollectionValue={filter}
              dataCollectionOptions={details.dataCollectionOptions}
            />
            <TabContainer tabNames={getTabDisplayNames()}>
              {buildTabs()}
            </TabContainer>
          </>
        )}
        {isEmptyList && (
          <EmptyGridContent iconType={IconType.FILE}>
            <p>There are no errors</p>
          </EmptyGridContent>
        )}
      </>
    </NavigationPage>
  );
};

export default DataQualityDetail;
