/* eslint-disable max-len */
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import React, { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearNotification,
  hasNotification,
  selectNotification,
} from '../../redux/slices/notificationSlice';
import './Notification.css';

const Notification = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isAlertOpen = useAppSelector(hasNotification);
  const notification = useAppSelector(selectNotification);

  const autoHideDuration = notification.disableAutoHide
    ? null
    : notification.autoHideDuration ?? 6000;
  const anchorOrigin = notification.anchorOrigin ?? {
    vertical: 'top',
    horizontal: 'center',
  };

  const handleAlertClose = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: React.SyntheticEvent<any> | Event,
    reason?: SnackbarCloseReason
  ): void => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    dispatch(clearNotification());
  };

  return (
    <Snackbar
      open={isAlertOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleAlertClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert severity={notification.severity} onClose={handleAlertClose}>
        {notification.children}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
