import { ReactElement } from 'react';
import { appRoutePaths } from '../../../services/route.service';
import DashboardCard from '../DashboardCard/DashboardCard';

const StudentOwnershipCard = (): ReactElement => (
  <DashboardCard
    cardClassNames="dashboard--grid--badge 
    status--warning page--grid--d-6 
    page--grid--m-full"
    message="Ownership conflict to review"
    messageClassNames="dashboard--status-message"
    buttonLink={appRoutePaths.StudentOwnership}
    buttonClassNames="button button--dashboard--small"
    buttonVisible={true}
    buttonText="Review"
    tooltipText="Click to review conflicts"
  />
);

export default StudentOwnershipCard;
