/* eslint-disable @typescript-eslint/no-explicit-any */
import { faker } from '@faker-js/faker';
import { AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from 'axios';
import { ApiErrorModel } from '../types/ApiErrorModel';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';

const getMockedResponse = <T>(
  data: T,
  status: number,
  statusText: string,
  headers: AxiosResponseHeaders,
  config: AxiosRequestConfig,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: any
): AxiosResponse<T> => {
  return {
    data,
    status,
    statusText,
    headers,
    config,
    request,
  };
};

const getApiResponse = <T>(
  data: T,
  status: number,
  error: ApiErrorModel
): ApiResponseModel<T> => {
  return {
    data,
    status,
    error,
    aborted: false,
  };
};

const getErrorApiResponse = <T>(
  data: ApiErrorModel,
  status: number
): ApiResponseModel<T> => {
  return {
    data: {} as T,
    status,
    error: data,
    aborted: false,
  };
};

const getAbortedApiResponse = <T>(data: ApiErrorModel): ApiResponseModel<T> => {
  return {
    data: {} as T,
    status: 500,
    error: data,
    aborted: true,
  };
};

export const generateApiError = (
  status: number,
  dataObj?: any,
  includeTraceId = true
): ApiErrorModel => {
  if (includeTraceId) {
    return {
      title: faker.word.words(3),
      type: faker.string.alpha(10),
      status,
      detail: faker.string.alpha(20),
      dataObj: dataObj,
      traceId: faker.string.alphanumeric(10),
    };
  } else {
    return {
      title: faker.word.words(3),
      type: faker.string.alpha(10),
      status,
      detail: faker.string.alpha(20),
      dataObj: dataObj,
    };
  }
};

export const generateGeneralApiError = (
  status: number,
  detail: string,
  dataObj?: any
): ApiErrorModel => {
  return {
    title: 'Error Occured',
    type: 'Error',
    status,
    detail,
    dataObj: dataObj,
  };
};

export const getMockedOKResponse = <T>(data: T): AxiosResponse<T> => {
  return getMockedResponse(data, 200, 'OK', {}, {});
};

export const getMockedCreatedResponse = <T>(data: T): AxiosResponse<T> => {
  return getMockedResponse(data, 201, 'Created', {}, {});
};

export const getMockedNoContentResponse = (): AxiosResponse => {
  return getMockedResponse(undefined, 204, 'NoContent', {}, {});
};

export const getMockedBadRequestResponse = (
  data: ApiErrorModel
): AxiosResponse => {
  return getMockedResponse(data, 400, 'BadRequest', {}, {});
};

export const getMockedNotFoundResponse = (
  data: ApiErrorModel
): AxiosResponse => {
  return getMockedResponse(data, 404, 'NotFound', {}, {});
};

export const getMockedServerErrorResponse = (
  data: ApiErrorModel
): AxiosResponse => {
  return getMockedResponse(data, 500, 'ServerError', {}, {});
};

export const getMockedOkApiResponse = <T>(data: T): ApiResponseModel<T> => {
  return getApiResponse(data, 200, {} as ApiErrorModel);
};

export const getMockedCreatedApiResponse = <T>(
  data: T
): ApiResponseModel<T> => {
  return getApiResponse(data, 201, {} as ApiErrorModel);
};

export const getMockedNoContentApiResponse =
  (): ApiResponseModel<undefined> => {
    return getApiResponse(undefined, 204, {} as ApiErrorModel);
  };

export const getMockedApiErrorResponse = <T>(
  status: number,
  err: ApiErrorModel
): ApiResponseModel<T> => {
  return getErrorApiResponse(err, status);
};

export const getMockedAbortedApiResponse = <T>(
  err: ApiErrorModel
): ApiResponseModel<T> => {
  return getAbortedApiResponse(err);
};

export const mockDataRequestConfig = (
  config?: DataRequestConfig
): DataRequestConfig => {
  return {
    signal: config?.signal ? config?.signal : new AbortController().signal,
  };
};

export const mockThunkDispatchResponse = (abortFunc?: Function): any => ({
  some_func: () => ({ values: faker.string.alphanumeric(10) }),
  some_const: () => faker.string.alphanumeric(20),
  abort: abortFunc,
});

export const mockedAsyncThunkAPI = (): jest.Expect => {
  return expect.objectContaining({
    requestId: expect.any(String),
    dispatch: expect.any(Function),
    signal: expect.any(AbortSignal),
    abort: expect.any(Function),
    getState: expect.any(Function),
    fulfillWithValue: expect.any(Function),
    rejectWithValue: expect.any(Function),
    extra: expect.any(Object),
  });
};

export const AsycThunkCompleteActionName = (actionName: string): string =>
  `${actionName}/fulfilled`;

// Use this to fake an async response from the API (wrap in the executeApiRequest)
export const fakeServiceResponse = async <T>(theResponse: T): Promise<any> => {
  const delay = (ms: number): Promise<any> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // wait 2 seconds
  await delay(2000);

  // return response payload
  return {
    status: 200,
    data: theResponse,
  };
};
