/* eslint-disable max-len */
import { ReactElement } from 'react';
import { BackBarPropTypes } from '../../types/propTypes/BackBarPropTypes';
import NavigationButton from '../NavigationButton/NavigationButton';
import './BackBar.css';

const BackBar = ({ to, visibleOverride }: BackBarPropTypes): ReactElement => {
  return (
    <div className="page--back-bar">
      <NavigationButton
        route={to}
        visibleOverride={visibleOverride}
        tooltipText="Navigate to the last page"
      >
        <>Back</>
      </NavigationButton>
    </div>
  );
};

export default BackBar;
