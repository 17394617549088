/* eslint-disable max-len */
import { faker } from '@faker-js/faker';
import { FileErrorCode, FileStatus } from '../../generated';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { FileDetailsModel } from '../../types/FileDetailsModel';
import { FileModel } from '../../types/FileModel';
import { FilePermissionMap } from '../../types/FilePermissionMap';
import { FileUploadFormModel } from '../../types/FileUploadFormModel';

export interface RandomFileOptions {
  fileName?: string;
  fileEnding?: string;
  fileExtension?: string;
  fileSize?: number;
}

export const getRandomInputFile = (
  model?: Partial<RandomFileOptions>
): File => {
  const defaultFileEnding = 'K12Course';
  const defaultFileName = faker.string.alphanumeric(10);
  const defaultFileExtension = 'csv';
  const defaultFileSize = 1024;

  const fileStart = model?.fileName ? model?.fileName : defaultFileName;
  const fileEnd = model?.fileEnding ? model?.fileEnding : defaultFileEnding;
  const extension = model?.fileExtension
    ? model?.fileExtension
    : defaultFileExtension;

  const size = model?.fileSize ? model?.fileSize : defaultFileSize;
  const name = `${fileStart}${fileEnd}.${extension}`;
  const blob = new Blob(['a'.repeat(size)]);
  return new File([blob], name);
};

export const getRandomFile = (model?: Partial<FileModel>): FileModel => {
  const validStatus = [
    FileStatus.FAILED,
    FileStatus.PENDING,
    FileStatus.SUCCESS,
  ];

  const errorCodes: FileErrorCode[] = [
    FileErrorCode.COURSE_SECTION_SCHEMA_ERROR,
    FileErrorCode.K12COURSE_SCHEMA_ERROR,
    FileErrorCode.K12SCHOOL_SCHEMA_ERROR,
    FileErrorCode.K12STAFF_SCHEMA_ERROR,
    FileErrorCode.K12STUDENT_SCHEMA_ERROR,
    FileErrorCode.LEASCHEMA_ERROR,
  ];

  const status = model?.status
    ? model?.status
    : faker.helpers.arrayElement(validStatus);

  let codeValue;
  if (model?.errorCode) {
    codeValue = model?.errorCode;
  } else {
    codeValue =
      status === FileStatus.FAILED
        ? faker.helpers.arrayElement(errorCodes)
        : FileErrorCode.NONE;
  }

  return {
    id: model?.id ? model?.id : faker.string.alpha(10),
    uploadedBy: model?.uploadedBy ? model?.uploadedBy : faker.word.words(2),
    fileName: model?.fileName
      ? model?.fileName
      : `${faker.string.alpha(20)};${faker.string.alpha(
          20
        )};${faker.string.alpha(20)};${faker.string.alpha(
          20
        )};${faker.string.alpha(20)};${faker.string.alpha(20)}.csv`,
    uploadDateTimeUtc: model?.uploadDateTimeUtc
      ? model?.uploadDateTimeUtc
      : new Date().toUTCString(),
    status: model?.status
      ? model?.status
      : faker.helpers.arrayElement(validStatus),
    canDownload: model?.canDownload ? model?.canDownload : false,
    errorCount: model?.errorCount
      ? model?.errorCount
      : parseInt(faker.string.numeric(2)),
    errorCode: codeValue,
    districtId: model?.districtId
      ? model?.districtId
      : `${faker.string.numeric(4)}`,
    processingData: {
      successfulRecordsProcessed: 0,
      failedRecordsProcessed: 0,
      errorDetails: [],
      detailHeaders: '',
    },
  };
};

export const getRandomFileDetail = (
  model?: Partial<FileDetailsModel>
): FileDetailsModel => {
  const errorCodes: FileErrorCode[] = [
    FileErrorCode.COURSE_SECTION_SCHEMA_ERROR,
    FileErrorCode.K12COURSE_SCHEMA_ERROR,
    FileErrorCode.K12SCHOOL_SCHEMA_ERROR,
    FileErrorCode.K12STAFF_SCHEMA_ERROR,
    FileErrorCode.K12STUDENT_SCHEMA_ERROR,
    FileErrorCode.LEASCHEMA_ERROR,
    FileErrorCode.SCHEMA_ERROR_LOG,
  ];

  const validStatus = [
    FileStatus.SUCCESS,
    FileStatus.FAILED,
    FileStatus.PENDING,
  ];
  const status = model?.status
    ? model?.status
    : faker.helpers.arrayElement(validStatus);

  let codeValue;
  if (model?.errorCode) {
    codeValue = model?.errorCode;
  } else {
    codeValue =
      status === FileStatus.FAILED
        ? faker.helpers.arrayElement(errorCodes)
        : FileErrorCode.NONE;
  }

  return {
    errorCode: codeValue,
    id: model?.id ? model?.id : faker.string.alpha(5),
    uploadedBy: model?.uploadedBy ? model?.uploadedBy : faker.word.words(2),
    fileName: model?.fileName ? model?.fileName : faker.string.alpha(10),
    uploadDateTimeUtc: model?.uploadDateTimeUtc
      ? model?.uploadDateTimeUtc
      : faker.string.alphanumeric(19),
    status: status,
    canDownload: model?.canDownload ? model?.canDownload : false,
    errorCount: model?.errorCount
      ? model?.errorCount
      : parseInt(faker.string.numeric(2)),
    processingData: {
      successfulRecordsProcessed: 0,
      failedRecordsProcessed: 0,
      errorDetails: [],
      detailHeaders: '',
    },
  };
};

export const getRandomSchemaErrorFile = (): FileModel => {
  return getRandomFile({
    status: FileStatus.FAILED,
    errorCode: FileErrorCode.SCHEMA_ERROR_LOG,
    errorCount: parseInt(faker.string.numeric(2)),
    canDownload: true,
  });
};

export const getRandomErrorFile = (): FileModel => {
  return getRandomFile({
    status: FileStatus.FAILED,
    errorCode: FileErrorCode.LEASCHEMA_ERROR,
    errorCount: parseInt(faker.string.numeric(2)),
    canDownload: false,
  });
};

export const getRandomSchemaErrorFileDetail = (): FileDetailsModel => {
  return getRandomFileDetail({
    status: FileStatus.FAILED,
    errorCode: FileErrorCode.SCHEMA_ERROR_LOG,
    errorCount: parseInt(faker.string.numeric(2)),
    canDownload: true,
  });
};

export const getRandomFileUploadFormModel = (
  model?: Partial<FileUploadFormModel>
): FileUploadFormModel => {
  return {
    allowedFileEndings: model?.allowedFileEndings
      ? model?.allowedFileEndings
      : [faker.string.alphanumeric(20), faker.string.alphanumeric(20)],
    allowedFileExtensions: model?.allowedFileExtensions
      ? model?.allowedFileExtensions
      : [faker.string.alphanumeric(20), faker.string.alphanumeric(20)],
  };
};

export function splitStringByPipe(inputString: string): string[] {
  return inputString.split('|');
}
export const doesUserHaveFileType = (fileType?: string): boolean => {
  if (fileType == 'MSISId') {
    return canUserAccessRoute(appRoutePaths.MSISRequest);
  }
  if (fileType == 'SchoolManagement') {
    return canUserAccessRoute(appRoutePaths.SchoolManagementList);
  }
  return false;
};

export const getRandomFilePermission = (
  model?: Partial<FilePermissionMap>
): FilePermissionMap => {
  const id = model?.id ? model?.id : faker.string.numeric(5);
  const fileType = model?.fileType ? model?.fileType : faker.string.alpha(10);
  const extensions = ['XML', 'CSV'];
  const permissions = ['ABC', 'DEF'];

  return {
    id: id,
    fileType: fileType,
    fileExtensions: extensions,
    permissions: permissions,
  };
};
