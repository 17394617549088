import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { canUserAccessRoute } from '../../services/security.service';
import { BubblePropTypes } from '../../types/propTypes/BubblePropTypes';
import './Bubble.css';

const Bubble = ({
  count,
  title,
  titleTransform,
  classConfig,
  bubbleText,
  navigateTo,
  visibilityOverrideRoute,
}: BubblePropTypes): ReactElement => {
  const navigate = useNavigate();
  const rawCount = parseInt(count);
  const displayCnt = rawCount.toLocaleString();

  let getSVGBackgroundClass = rawCount > 0 ? 'fail-bubble' : 'success-bubble';
  let getTextClass = rawCount > 0 ? 'fail-text' : 'success-text';
  const canAccessRoute = (): boolean => {
    if (visibilityOverrideRoute) {
      return canUserAccessRoute(visibilityOverrideRoute);
    } else if (navigateTo) {
      return canUserAccessRoute(navigateTo);
    }
    return false;
  };

  // VNT: intermin solution, until BA's provide logic for EDITs count & color
  if (classConfig) {
    getSVGBackgroundClass = 'warn-bubble';
    getTextClass = 'warn-text';
  }

  const sizeClass =
    count.length > 8
      ? 'bubble-small-text'
      : count.length >= 6
      ? 'bubble-med-text'
      : '';
  const linkTextClass = navigateTo ? 'clickable' : '';

  const onNavigate = (path: string): void => {
    navigate(path);
  };

  const bubbleContent = (
    <div className="bubble-outer">
      <div
        data-testid="bubble-container"
        className={`bubble-container ${getSVGBackgroundClass} ${linkTextClass}`}
      >
        <label
          data-testid="bubble-count"
          className={`bubble-count ${getTextClass} ${sizeClass} ${linkTextClass}`}
        >
          {displayCnt}
        </label>
        <label
          data-testid="bubble-text"
          className={`getTextClass ${linkTextClass}`}
        >
          {bubbleText}
        </label>
      </div>
      <label
        data-testid="bubble-title"
        className={`
          title-text 
          ${linkTextClass} 
          ${titleTransform ? `title-text-${titleTransform}` : ''}
        `}
      >
        {title}
      </label>
    </div>
  );

  return canAccessRoute() && navigateTo ? (
    <button
      data-testid="bubble-button"
      className={'badge-link'}
      onClick={() => onNavigate(navigateTo)}
    >
      {bubbleContent}
    </button>
  ) : (
    bubbleContent
  );
};

export default Bubble;
