import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { getCurrentUserAccess } from '../../services/base.service';
import { AuthorizeUriModel } from '../../types/AuthorizeUriModel';
import { CurrentUserViewModel } from '../../types/CurrentUserViewModel';
import { FeatureAccessPermissions } from '../../types/FeatureAccessPermissions';
import { GetTokenResponseModel } from '../../types/GetTokenResponseModel';
import { UserType } from '../../types/UserType';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import { executeLogin, fetchAuthorizeUri } from '../thunks/loginThunks';

export const defaultCurrentUserDetailsState: CurrentUserViewModel = {
  userId: '',
  name: '',
  email: '',
  currentDistrict: undefined,
  featureAccessPermissions: [],
  allowedDistricts: [],
  userType: UserType.Unknown,
  isAdmin: false,
};

export const defaultAurthorizeUrlState = { authorize_uri: '' };
export const defaultTokenResponseState = {
  token: '',
  error: '',
};

interface UserState {
  loading: boolean;
  authorize: AuthorizeUriModel;
  tokenResponse: GetTokenResponseModel;
  currentUser: CurrentUserViewModel;
}

export const initialState: UserState = {
  loading: false,
  authorize: defaultAurthorizeUrlState,
  tokenResponse: defaultTokenResponseState,
  currentUser: defaultCurrentUserDetailsState,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setTokenResponseState: (
      state,
      action: PayloadAction<GetTokenResponseModel>
    ) => {
      state.tokenResponse = action.payload;
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state.tokenResponse = { error: action.payload };
    },
    clearLoginError: (state) => {
      state.tokenResponse = { error: '' };
    },
    updateCurrentUserAccess: (
      state,
      action: PayloadAction<CurrentUserViewModel>
    ) => {
      state.currentUser = action.payload;
    },
    clearLoginState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthorizeUri.pending, (state) => {
      state.authorize = defaultAurthorizeUrlState;
      state.loading = true;
    });
    builder.addCase(fetchAuthorizeUri.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.authorize = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchAuthorizeUri.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(executeLogin.pending, (state) => {
      state.tokenResponse = defaultTokenResponseState;
      state.currentUser = defaultCurrentUserDetailsState;
      state.loading = true;
    });
    builder.addCase(executeLogin.fulfilled, (state, action) => {
      state.tokenResponse =
        !isEmptyObject(action.payload) && action.payload.tokenResponse
          ? action.payload.tokenResponse
          : defaultTokenResponseState;
      state.currentUser =
        !isEmptyObject(action.payload) && action.payload.currentUser
          ? action.payload.currentUser
          : defaultCurrentUserDetailsState;
      state.loading = false;
    });
    builder.addCase(executeLogin.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
  },
});

export const {
  clearLoginState,
  setLoginError,
  clearLoginError,
  setTokenResponseState,
  updateCurrentUserAccess,
} = loginSlice.actions;

const areEqualDetails = (
  base: CurrentUserViewModel,
  toCompare: CurrentUserViewModel
): boolean => {
  return (
    base.userId === toCompare.userId &&
    base.name === toCompare.name &&
    base.email === toCompare.email &&
    isEqual(
      base.featureAccessPermissions,
      toCompare.featureAccessPermissions
    ) &&
    isEqual(base.currentDistrict, toCompare.currentDistrict) &&
    isEqual(base.featureAccessPermissions, toCompare.featureAccessPermissions)
  );
};

export const selectLoginLoading = (state: RootState): boolean =>
  state.login.loading;
export const selectAuthorizeUri = (state: RootState): string =>
  state.login.authorize?.authorize_uri;
export const selectAuthorizeToken = (state: RootState): string =>
  state.login.tokenResponse?.token || '';
export const selectAuthorizeTokenError = (state: RootState): string =>
  state.login.tokenResponse?.error || '';
export const selectCurrentUser = (state: RootState): CurrentUserViewModel =>
  !areEqualDetails(defaultCurrentUserDetailsState, state.login.currentUser)
    ? state.login.currentUser
    : getCurrentUserAccess() || defaultCurrentUserDetailsState;
export const selectUserName = (state: RootState): string =>
  state.login.currentUser.name.length > 0
    ? state.login.currentUser.name
    : getCurrentUserAccess()?.name || '';
export const selectFeatureAccessPermissions = (
  state: RootState
): FeatureAccessPermissions[] =>
  state.login.currentUser.featureAccessPermissions.length > 0
    ? state.login.currentUser.featureAccessPermissions
    : getCurrentUserAccess()?.featureAccessPermissions || [];

export default loginSlice.reducer;
