/* eslint-disable max-len */
import React, { ReactElement, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import ActionButton from '../../components/ActionButton/ActionButton';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Pill from '../../components/Pill/Pill';
import StyledSingleDropdown from '../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectMSISRequestFilterDays,
  selectMSISRequests,
  selectStudentLoading,
  setMsisRequestDayFilter,
} from '../../redux/slices/studentSlice';
import { fetchMSISRequests } from '../../redux/thunks/studentThunks';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { deleteMsisIdRequest } from '../../services/student.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { ExportFieldOption } from '../../types/ExportFieldOption';
import { FILE_EXPORT_TYPE } from '../../types/FileExportType';
import {
  defaultRequestAgeInDays,
  getMSISRequestDayList,
} from '../../types/MSISRequestListModel';
import { MSISRequestModel } from '../../types/MSISRequestModel';
import { PillType } from '../../types/PillType';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import { formatToDateString } from '../../utilities/dateUtilities';
import {
  setStyledDropdownSingleValue,
  spawnFileDownload,
} from '../../utilities/helperUtilities';
import { last4Digits } from '../../utilities/textUtilities';
import './MSISRequest.css';

const MSISRequest = (): ReactElement => {
  const confirmContext = useConfirmationModalContext();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectStudentLoading);
  const maxDays = useAppSelector(selectMSISRequestFilterDays);
  const students: MSISRequestModel[] = useAppSelector(selectMSISRequests);

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const maxRequestAgeDays = 30;

  useEffect(() => {
    let promise: unknown;
    if (isInitialLoad) {
      promise = dispatch(fetchMSISRequests(parseInt(maxDays)));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, maxDays, isInitialLoad]);

  const handleExport = (e: React.MouseEvent<HTMLElement>): void => {
    /* istanbul ignore next */
    e && e.preventDefault();

    const orderedFieldList: ExportFieldOption[] = [
      { fieldName: 'requestingDistrictId', columnName: 'District Identifier' },
      { fieldName: 'requestingDistrictName', columnName: 'District Name' },
      { fieldName: 'schoolId', columnName: 'School Identifier' },
      { fieldName: 'schoolName', columnName: 'School Name' },
      {
        fieldName: 'localStudentIdentifier',
        columnName: 'Local Student Identifier',
      },
      { fieldName: 'firstName', columnName: 'First Name' },
      { fieldName: 'middleName', columnName: 'Middle Name' },
      { fieldName: 'lastName', columnName: 'Last Name' },
      {
        fieldName: 'ssn',
        columnName: 'SSN',
        manipulationFunction: last4Digits,
      },
      { fieldName: 'dateOfBirth', columnName: 'Birthdate' },
      { fieldName: 'msisId', columnName: 'MSIS ID' },
    ];

    const downloadFileName = `MSIS_ID_REQUEST_${new Date().toISOString()}`;
    spawnFileDownload(
      students,
      orderedFieldList,
      downloadFileName,
      FILE_EXPORT_TYPE.CSV
    );
  };

  const handleOnChangeSuccess = async (
    props: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    /* istanbul ignore next */
    const maxNumOfDays = props?.value || defaultRequestAgeInDays;

    setIsInitialLoad(false);

    dispatch(setMsisRequestDayFilter(maxNumOfDays));
    dispatch(fetchMSISRequests(parseInt(maxNumOfDays)));
  };

  const filterDropdown = (
    <StyledSingleDropdown
      fieldName="dayFilter"
      showLabel={true}
      labelText="Max Request Age"
      options={getMSISRequestDayList()}
      onChange={handleOnChangeSuccess}
      disabled={isLoading}
      isLoading={isLoading}
      value={setStyledDropdownSingleValue(getMSISRequestDayList(), maxDays)}
    />
  );

  const buttons = (
    <>
      {students.length > 0 && (
        <ActionButton
          onClick={handleExport}
          classes="no-wrap-text export--button"
          tooltipText="Export Ids to CSV"
        >
          <> Export IDs</>
        </ActionButton>
      )}
      <NavigationButton
        route={appRoutePaths.MSISRequestNew}
        classes="button no-wrap-text new-button"
        tooltipText="Add New MSIS ID Request"
        visibleOverride={canUserAccessRoute(appRoutePaths.MSISRequestNew)}
      >
        <>Add New</>
      </NavigationButton>
    </>
  );

  const handleDelete = async (id: string, fullName: string): Promise<void> => {
    confirmContext.setOptions({
      title: `Cancel MSIS ID Request?`,
      content: (
        <div className="confirm--message">
          Are you sure you want to cancel the MSIS ID Request for &quot;
          {fullName}&quot;?
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deleteMsisIdRequest(id);
        if (isValidCode(response.status)) {
          dispatch(fetchMSISRequests(maxRequestAgeDays));
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  return (
    <NavigationPage
      heading="MSIS ID Requests"
      pageClass="msis-request--list"
      isLoading={isLoading}
      loadingDataId="msis-request-loader"
      loadingText="Getting MSIS Requests"
    >
      <div className="filter-container" data-testid="filter-container">
        <div className="filter-dropdown">{filterDropdown}</div>
        <div className="heading-buttons">{buttons}</div>
      </div>
      <div>
        <table cellSpacing={0} className="page-view-table">
          <thead>
            <tr>
              <th data-cy="msis-request-name">Name</th>
              <th data-cy="msis-request-dob">Date of Birth</th>
              <th data-cy="msis-request-days-old">Request Age (Days)</th>
              <th data-cy="msis-request-msis-id">MSIS ID</th>
              <th data-cy="msis-request-status">Status</th>
              <th data-cy="msis-request-action">Actions</th>
            </tr>
          </thead>
          <tbody>
            {students.length > 0 &&
              students.map((student) => (
                <tr
                  key={`${student.id}`}
                  data-testid={`msis-request-${student.id}`}
                  data-status={student.status}
                  data-cy="msis-request-data-row"
                >
                  <td
                    className="msis-request--list--full-name"
                    data-cy="msis-request-data-full-name"
                  >
                    <NavigationButton
                      route={appRoutePaths.MSISRequestDetail(student.id)}
                      visibleOverride={canUserAccessRoute(
                        appRoutePaths.MSISRequestDetail()
                      )}
                      tooltipText="Click to view detail"
                    >
                      <>{student.fullName}</>
                    </NavigationButton>
                  </td>
                  <td
                    className="msis-request--list--date-of-birth"
                    data-cy="msis-request-data-date-of-birth"
                  >
                    {formatToDateString(student.dateOfBirth)}
                  </td>
                  <td data-cy="msis-request-data-days-old">
                    {student.requestAgeDays}
                  </td>
                  <td
                    className="msis-request--list--msis-id"
                    data-cy="msis-request-data-msis-id"
                  >
                    {student.msisId}
                  </td>
                  <td
                    className="msis-request--list--msis-status"
                    data-cy="msis-request-data-status"
                  >
                    <Pill
                      status={student.status}
                      value={student.status}
                      pillType={PillType.MSIS_REQUEST_STATUS}
                    />
                  </td>
                  <td className="msis-request--list--action-container">
                    <div className="action--column">
                      {student.status === 'Review Needed' && (
                        <ActionButton
                          onClick={async () => {
                            await handleDelete(student.id, student.fullName);
                          }}
                          dataTestId={`delete-request-${student.id}`}
                          classes="no-button-padding"
                          tooltipText="Cancel MSIS ID Request"
                        >
                          <img src={'/images/icon-unassign.svg'} />
                        </ActionButton>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!isLoading && students.length === 0 && (
          <EmptyGridContent iconType={IconType.FILE}>
            <p>You currently have no MSIS ID requests</p>
          </EmptyGridContent>
        )}
      </div>
    </NavigationPage>
  );
};

export default MSISRequest;
