/* eslint-disable max-len */
import { ReactElement } from 'react';
import { DataQualityRuleErrorListPropTypes } from '../../types/propTypes/DataQualityRuleErrorListPropTypes';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import EmptyGridContent from '../EmptyGridContent/EmptyGridContent';
import './DataQualityRuleErrorList.css';

const DataQualityRuleErrorList = ({
  headerColumns,
  dataRows,
}: DataQualityRuleErrorListPropTypes): ReactElement => {
  return (
    <>
      {headerColumns && dataRows && (
        <table cellSpacing={0} className="page-view-table data-quality-list">
          <thead>
            <tr>
              {headerColumns.map((header) => (
                <th key={header.key} data-testid={`header-${header.key}`}>
                  {header.displayName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataRows.map((row, index) => (
              <tr key={`row-${index}`} data-testid={`row-${index}`}>
                {headerColumns.map((header) => (
                  <td
                    key={`row-${index}-${header.key}`}
                    data-testid={`data-row-${header.key}-${index}`}
                  >
                    {row[header.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {(dataRows.length === 0 || headerColumns.length === 0) && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>There are currently no errors for this category</p>
        </EmptyGridContent>
      )}
    </>
  );
};

export default DataQualityRuleErrorList;
