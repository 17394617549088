import { createAsyncThunk } from '@reduxjs/toolkit';
import { TokenRequestModel } from '../../generated';
import { mapToAuthTokenResponsePayload } from '../../mappers/TokenMapper';
import { getAuthorizeUri, getTokenFromCode } from '../../services/auth.service';
import {
  errorIsGenericException,
  isValidCode,
  setCurrentUserAccess,
  setTokenResponse,
  thunkExecuteAndReturnResultOrShowError,
} from '../../services/base.service';
import { getCurrentUser } from '../../services/user.service';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { AuthorizeUriModel } from '../../types/AuthorizeUriModel';
import { GetTokenResponseModel } from '../../types/GetTokenResponseModel';
import { LoginThunkResponse } from '../../types/LoginThunkResponse';
import { canChangeViewingDistrict } from '../../utilities/userUtilities';
import { setSelectedDistrict } from '../slices/appSlice';
import { setLoginError } from '../slices/loginSlice';

export const fetchAuthorizeUri = createAsyncThunk(
  'login/fetchAuthorizeUri',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<AuthorizeUriModel>(
      thunkAPI,
      getAuthorizeUri({ signal: thunkAPI.signal })
    );
  }
);

const handleTokenResponse = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thunkAPI: any,
  payload: LoginThunkResponse,
  tokenResponse: ApiResponseModel<GetTokenResponseModel>
): Promise<ApiErrorModel | undefined> => {
  let error: ApiErrorModel | undefined;

  if (isValidCode(tokenResponse.status)) {
    const tokenPayload = mapToAuthTokenResponsePayload(tokenResponse.data);

    setTokenResponse(tokenPayload);
    payload.tokenResponse = tokenResponse.data;

    const currentUserResponse = await getCurrentUser({
      signal: thunkAPI.signal,
    });
    if (isValidCode(currentUserResponse.status)) {
      const userData = currentUserResponse.data;
      setCurrentUserAccess(userData);
      if (canChangeViewingDistrict() && userData.currentDistrict) {
        thunkAPI.dispatch(setSelectedDistrict(userData.currentDistrict));
      }
      payload.currentUser = userData;
    } else {
      error = currentUserResponse.error;
    }
  } else {
    error = tokenResponse.error;
  }

  return error;
};

const getErrorText = (error: ApiErrorModel): string => {
  const fallbackText = 'An error occured during login';
  if (errorIsGenericException(error)) {
    return fallbackText;
  } else {
    return (error.dataObj as GetTokenResponseModel).error || fallbackText;
  }
};

export const executeLogin = createAsyncThunk(
  'login/executeLogin',
  async (tokenRequest: TokenRequestModel, thunkAPI) => {
    const payload: LoginThunkResponse = {
      currentUser: undefined,
      tokenResponse: undefined,
    };
    const tokenResponse = await getTokenFromCode(tokenRequest, {
      signal: thunkAPI.signal,
    });

    const error = await handleTokenResponse(thunkAPI, payload, tokenResponse);

    if (error) {
      thunkAPI.dispatch(setLoginError(getErrorText(error)));
      return {
        currentUser: undefined,
        tokenResponse: {
          error: getErrorText(error),
        },
      };
    } else {
      return payload;
    }
  }
);
