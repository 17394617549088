import { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { IRequestBuilder } from '../types/RequestBuilder';
import http from './axiosInstance';

const endSlash = new RegExp(/\/$/);
const startSlash = new RegExp(/^\//);

const assembleUrl = (base: string) => (path: string) => {
  const cleanBase = base.replace(endSlash, '');
  const cleanPath = path.replace(startSlash, '');

  return `${cleanBase}/${cleanPath}`;
};

export const shouldValidateStatus = (status: number): boolean => {
  // Don't hide results for requests with status under 600
  return status < 600;
};

export const buildUrl = (base: string, path: string): string =>
  assembleUrl(base)(path);

const createRequestConfig = (
  bearerToken?: string,
  onUploadProgress?: (progress: ProgressEvent<XMLHttpRequestUpload>) => void,
  responseType?: ResponseType,
  abortSignal?: AbortSignal
): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    headers: {},
    validateStatus: shouldValidateStatus,
  };

  if (bearerToken) {
    config.headers = {
      Authorization: `Bearer ${bearerToken}`,
    };
  }

  if (onUploadProgress) {
    config.onUploadProgress = onUploadProgress;
  }

  if (responseType) {
    config.responseType = responseType;
  }

  if (abortSignal) {
    config.signal = abortSignal;
  }

  return config;
};

const axiosRequestBuilder = (
  apiBasePath: string,
  relativePath: string,
  bearerToken?: string,
  abortSignal?: AbortSignal,
  onUploadProgress?: (progress: ProgressEvent) => void,
  responseType?: ResponseType
): IRequestBuilder => {
  return {
    url: buildUrl(apiBasePath, relativePath),
    requestConfig: createRequestConfig(
      bearerToken,
      onUploadProgress,
      responseType,
      abortSignal
    ),
  };
};

export class HttpClient {
  apiBasePath: string;
  bearerToken?: string;
  abortSignal?: AbortSignal;

  constructor(
    apiBasePath: string,
    bearerToken?: string,
    abortSignal?: AbortSignal
  ) {
    this.apiBasePath = apiBasePath;
    this.bearerToken = bearerToken;
    this.abortSignal = abortSignal;
  }

  async get<T>(relativePath: string): Promise<AxiosResponse<T>> {
    const { url, requestConfig } = axiosRequestBuilder(
      this.apiBasePath,
      relativePath,
      this.bearerToken,
      this.abortSignal
    );

    return await http.get<T>(url, requestConfig);
  }

  async getFile<T>(relativePath: string): Promise<AxiosResponse<T>> {
    const { url, requestConfig } = axiosRequestBuilder(
      this.apiBasePath,
      relativePath,
      this.bearerToken,
      this.abortSignal,
      undefined,
      'blob'
    );

    return await http.get<T>(url, requestConfig);
  }

  async post<T>(
    relativePath: string,
    data?: Record<string, string | string[] | undefined> | Object
  ): Promise<AxiosResponse<T>> {
    const { url, requestConfig } = axiosRequestBuilder(
      this.apiBasePath,
      relativePath,
      this.bearerToken,
      this.abortSignal
    );

    return await http.post<T>(url, data, requestConfig);
  }

  async put<T>(relativePath: string, data?: Object): Promise<AxiosResponse<T>> {
    const { url, requestConfig } = axiosRequestBuilder(
      this.apiBasePath,
      relativePath,
      this.bearerToken,
      this.abortSignal
    );

    return await http.put<T>(url, data, requestConfig);
  }

  async delete<T>(relativePath: string): Promise<AxiosResponse<T>> {
    const { url, requestConfig } = axiosRequestBuilder(
      this.apiBasePath,
      relativePath,
      this.bearerToken,
      this.abortSignal
    );

    return await http.delete<T>(url, requestConfig);
  }

  async upload<T>(
    relativePath: string,
    data?: FormData,
    onUploadProgress?: (progress: ProgressEvent) => void
  ): Promise<AxiosResponse<T>> {
    const { url, requestConfig } = axiosRequestBuilder(
      this.apiBasePath,
      relativePath,
      this.bearerToken,
      this.abortSignal,
      onUploadProgress
    );

    return await http.post<T>(url, data, requestConfig);
  }
}

export const getHttpClient = (
  apiBasePath: string,
  bearerToken: string,
  abortSignal?: AbortSignal
): HttpClient => {
  return new HttpClient(apiBasePath, bearerToken, abortSignal);
};

export const getAnonymousHttpClient = (
  apiBasePath: string,
  abortSignal?: AbortSignal
): HttpClient => {
  return new HttpClient(apiBasePath, undefined, abortSignal);
};
