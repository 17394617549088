/* eslint-disable max-len */
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ReactElement, useState } from 'react';
import { StyledCheckboxPropTypes } from '../../types/propTypes/StyledCheckboxPropTypes';
import './StyledCheckbox.css';

const StyledCheckbox = ({
  checked,
  onChecked,
  onUnchecked,
  labelText,
  containerClass,
  cypressDataId,
}: StyledCheckboxPropTypes): ReactElement => {
  const defaultChecked = checked ? checked : false;
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const containerClasses = containerClass ? containerClass : '';

  const handleOnChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const toggle = e.target.checked;
    if (toggle) {
      await onChecked();
    } else {
      onUnchecked && onUnchecked();
    }
    setIsChecked(toggle);
  };

  const configurationProps = { inputProps: { 'aria-label': labelText } };

  // TODO - Change hardcoded color to be from SCSS when we make that change

  return (
    <div
      data-testid="checkbox-container"
      className={`styled-checkbox ${containerClasses}`}
    >
      <FormControlLabel
        control={
          <Checkbox
            {...configurationProps}
            checked={isChecked}
            onChange={handleOnChange}
            data-cy={cypressDataId}
            sx={{
              color: '#003b71',
              '&.Mui-checked': {
                color: '#003b71',
              },
            }}
          />
        }
        label={labelText}
      />
    </div>
  );
};

export default StyledCheckbox;
