/* eslint-disable max-len */
import { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TooltipAnchor } from '../../services/app.service';
import { ExpandableNavigationItemPropTypes } from '../../types/propTypes/ExpandableNavigationItemPropTypes';
import {
  isRouteAChildOfSublinks,
  isRouteExactMatchOfSublinks,
} from '../../utilities/routeUtilities';
import './ExpandableNavigationItem.css';

const ExpandableNavigationItem = ({
  isOpen,
  setIsOpen,
  mainLink,
  subLinks,
}: ExpandableNavigationItemPropTypes): ReactElement => {
  const location = useLocation();

  const currentPath = location.pathname;
  const toggleOpen = (): void => setIsOpen(!isOpen);
  const anySubLinkVisible = subLinks.some((link) => link.visible === true);

  const isActive =
    isRouteExactMatchOfSublinks(currentPath, subLinks) ||
    isRouteAChildOfSublinks(currentPath, subLinks);

  return anySubLinkVisible ? (
    <div
      data-is-active={isActive}
      data-is-open={isOpen}
      className="expandable-navigation-item"
      data-testid="expandable-navigation-container"
    >
      <div className="expandable-navigation-item--main no-wrap-text">
        <div
          id={`expandable-navigation-item--main--${mainLink.id}`}
          className={`expandable-navigation-item--main--link 
                      ${mainLink.className}`}
          data-testid="expandable-navigation-main-heading"
        >
          <span>{mainLink.content}</span>
        </div>
        <button
          className="expandable-navigation-item--toggle"
          onClick={toggleOpen}
          aria-expanded={isOpen}
          aria-controls={`expandable-navigation-item--sublinks--${mainLink.id}`}
          aria-labelledby={`expandable-navigation-item--main--${mainLink.id}`}
          data-tooltip-id={TooltipAnchor}
          data-tooltip-content={
            isOpen ? 'Click to Collapse' : 'Click to Expand'
          }
        >
          <span>{isOpen ? 'Close' : 'Open'}</span>
        </button>
      </div>
      {(isActive || isOpen) && (
        <ul
          id={`expandable-navigation-item--sublinks--${mainLink.id}`}
          className="expandable-navigation-item--sublinks"
          data-testid="expandable-navigation-item--sublinks"
        >
          {subLinks.map((subLink) => {
            if (subLink.visible) {
              const isExactMatch = isRouteExactMatchOfSublinks(currentPath, [
                subLink,
              ]);
              const isChildRoute = isRouteAChildOfSublinks(currentPath, [
                subLink,
              ]);

              const forceHighlight = !isExactMatch && isChildRoute;
              const highlightClass = forceHighlight ? 'force-highlight' : '';

              const linkClass = subLink.className
                ? `${subLink.className} ${highlightClass}`
                : highlightClass;

              return (
                <li key={subLink.to}>
                  <NavLink
                    to={subLink.to}
                    className={linkClass}
                    end
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    data-testid={`link-to-${subLink.to}`}
                  >
                    <span>{subLink.content}</span>
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      )}
    </div>
  ) : (
    <></>
  );
};

export default ExpandableNavigationItem;
