/* eslint-disable max-len */
import { ReactElement } from 'react';
import FormDateSelector from '../../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import Panel from '../../../../components/Panel/Panel';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearLEASharing,
  saveLEASharing,
  selectLEASharingDataEntryMode,
  selectLEASharingFormViewModel,
  selectLEASharingLEASharing,
  selectLEASharingLoadingFormViewModel,
  selectLEASharingLoadingLEASharing,
  setLEASharingDataEntryMode,
} from '../../../../redux/slices/leaSharingSlice';
import {
  fetchLEASharingById,
  fetchLEASharingFormViewModel,
} from '../../../../redux/thunks/leaSharingThunks';
import {
  createLEASharing,
  updateLEASharing,
} from '../../../../services/leaSharing.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { LEASharingFormViewModel } from '../../../../types/LEASharingFormViewModel';
import { LEASharingModel } from '../../../../types/LEASharingModel';
import { Tabs_DISTRICT_SCHOOL } from '../../../../types/NavigationTabs';
import { PanelType } from '../../../../types/PanelType';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { LEASharingValidationSchema } from '../../../../types/Validation/LEASharingValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import { isStateAdmin } from '../../../../utilities/userUtilities';
import './LEASharingInput.css';

const LEASharingInput = (): ReactElement => {
  const leaSharingModel: LEASharingModel = useAppSelector(
    selectLEASharingLEASharing
  );
  const formViewModel: LEASharingFormViewModel = useAppSelector(
    selectLEASharingFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectLEASharingLoadingFormViewModel
  );
  const isLoadingLEASharingModel = useAppSelector(
    selectLEASharingLoadingLEASharing
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectLEASharingDataEntryMode
  );
  const isLoading = isLoadingLEASharingModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
  ];

  return (
    <ManualInputPage<LEASharingModel, LEASharingFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'lea-sharing-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_DISTRICT_SCHOOL)}
      classes={{ pageClass: 'data-input--lea-sharing--input' }}
      parameterOptions={{
        routeParameterName: 'leaId',
        identityName: leaSharingModel.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.LEASharingList,
        cancelRoute: appRoutePaths.LEASharingList,
        createSuccessRoute: appRoutePaths.LEASharingInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchLEASharingById,
        loadFormDetail: fetchLEASharingFormViewModel,
        setEntryMode: setLEASharingDataEntryMode,
        setData: saveLEASharing,
        clearData: clearLEASharing,
        onCreate: createLEASharing,
        onUpdate: updateLEASharing,
      }}
      formDetails={{
        validationSchema: LEASharingValidationSchema,
        initialFormValues: leaSharingModel,
        isFormModelEmpty: !formViewModel.sharingFromLEAOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'LEA Sharing',
        pageHeader: 'LEA & School Info',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="lea-sharing-input-child">
        <Panel panelType={PanelType.INFO} heading="LEA Sharing">
          <div className="field-row field-row-1-1-1">
            <FormDropdown
              displayName="Sharing from LEA"
              field="sharingFromLEA"
              disabled={
                !isStateAdmin()
                  ? true
                  : dataEntryMode === DataEntryMode.EDIT
                  ? true
                  : false
              }
              options={formViewModel.sharingFromLEAOptions}
              validationSchema={LEASharingValidationSchema}
            />
            <FormDropdown
              displayName="Sharing to LEA"
              field="sharingToLEA"
              disabled={
                !isStateAdmin()
                  ? true
                  : dataEntryMode === DataEntryMode.EDIT
                  ? true
                  : false
              }
              options={formViewModel.sharingToLEAOptions}
              validationSchema={LEASharingValidationSchema}
            />
            <FormDateSelector
              displayName="Inactive Date"
              field="inactiveDate"
              disabled={!isStateAdmin()}
              validationSchema={LEASharingValidationSchema}
            />
          </div>

          <div className="field-row field-row-1-1-1">
            <FormDropdown
              displayName="Share Type (One-Way or Dual)"
              field="shareType"
              disabled={!isStateAdmin()}
              options={formViewModel.shareTypeOptions}
              validationSchema={LEASharingValidationSchema}
            />
            <FormDropdown
              displayName="Program Type"
              field="programType"
              disabled={!isStateAdmin()}
              options={formViewModel.programTypeOptions}
              validationSchema={LEASharingValidationSchema}
            />
          </div>
        </Panel>
      </div>
    </ManualInputPage>
  );
};

export default LEASharingInput;
