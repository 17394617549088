import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const LEASharingValidationSchema = yup.object().shape({
  sharingFromLEA: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .test(
      'different-from-sharingToLEA',
      'Sharing from LEA must be different',
      function (value) {
        return value !== this.parent.sharingToLEA;
      }
    ),
  sharingToLEA: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .test(
      'different-from-sharingFromLEA',
      'Sharing to LEA must be different',
      function (value) {
        return value !== this.parent.sharingFromLEA;
      }
    ),
  shareType: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  programType: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  inactiveDate: yup.date(),
});
