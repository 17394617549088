/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, AsyncThunk } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { ApiErrorDisplayOverrideModel } from '../ApiErrorDisplayOverrideModel';
import { ApiResponseModel } from '../ApiResponseModel';
import { DataEntryMode } from '../DataEntryMode';
import { NavigationTabModel } from '../NavigationTabModel';
import Yup from '../Validation/CustomValidation/yup-extended';

export enum ManualInputCrudAction {
  CREATE_ONLY = 'Create Only',
  CREATE_AND_EDIT = 'Create and Edit',
}

export interface ManualInputClasses {
  pageClass?: string;
  contentClass?: string;
}

export interface ManualInputData<T, F> {
  loadDataDetailById: AsyncThunk<T, string, any>;
  loadFormDetail: AsyncThunk<F, void, any>;
  setEntryMode: (mode: DataEntryMode) => AnyAction;
  setData: (data: T) => AnyAction;
  clearData: () => AnyAction;
  onCreate: (data: T) => Promise<ApiResponseModel<unknown>>;
  onUpdate: (data: T) => Promise<ApiResponseModel<unknown>>;
}

export interface ManualInputDataCreateOnly<T, F> {
  loadFormDetail: AsyncThunk<F, void, any>;
  onCreate: (data: T) => Promise<ApiResponseModel<unknown>>;
}

export interface ManualInputParameter {
  routeParameterName: string;
  identityName: string;
}

export interface ManualInputRoutes {
  backBarRoute?: string;
  cancelRoute: string;
  createSuccessRoute: (id: string) => string;
}

export interface ManualInputConfiguration {
  pageHeader: string;
  subHeading?: string;
  dataEntryMode: DataEntryMode;
  objectVerbiage: string;
  supportedNonGenericErrors?: ApiErrorDisplayOverrideModel[];
}

export interface ManualInputConfigurationCreateOnly {
  pageHeader: string;
  subHeading?: string;
  objectVerbiage: string;
  supportedNonGenericErrors?: ApiErrorDisplayOverrideModel[];
}

export interface ManualInputLoadingOptions {
  isLoading: boolean;
  loadingDataId?: string;
  loadingText?: string;
}

export interface ManualInputFormDetails<T> {
  validationSchema?: Yup.AnyObjectSchema;
  initialFormValues: T;
  isFormModelEmpty: boolean;
}

interface ManualInputPageCommonProps<T> {
  actions: ManualInputCrudAction;
  loadingOptions: ManualInputLoadingOptions;
  classes?: ManualInputClasses;
  navigationTabs?: NavigationTabModel[];
  routes: ManualInputRoutes;
  formDetails: ManualInputFormDetails<T>;
  children: ReactNode;
  DEV_MODE: boolean;
}

type ManualInputPageConditionalProps<T, F> =
  | {
      // Props that are needed and NOT needed for CREATE ONLY MODE
      actions: ManualInputCrudAction.CREATE_ONLY;
      parameterOptions?: never;
      dataFunctions: {
        loadDataDetailById?: never;
        loadFormDetail: AsyncThunk<F, void, any>;
        setEntryMode?: never;
        setData?: never;
        clearData?: never;
        onCreate: (data: T) => Promise<ApiResponseModel<unknown>>;
        onUpdate?: never;
      };
      config: {
        pageHeader: string;
        subHeading?: string;
        dataEntryMode?: never;
        objectVerbiage: string;
        supportedNonGenericErrors?: ApiErrorDisplayOverrideModel[];
      };
    }
  | {
      // Props that are needed and NOT needed for EDIT MODE
      actions: ManualInputCrudAction.CREATE_AND_EDIT;
      parameterOptions: ManualInputParameter;
      dataFunctions: {
        loadDataDetailById: AsyncThunk<T, string, any>;
        loadFormDetail: AsyncThunk<F, void, any>;
        setEntryMode: (mode: DataEntryMode) => AnyAction;
        setData: (data: T) => AnyAction;
        clearData: () => AnyAction;
        onCreate: (data: T) => Promise<ApiResponseModel<unknown>>;
        onUpdate: (data: T) => Promise<ApiResponseModel<unknown>>;
      };
      config: {
        pageHeader: string;
        subHeading?: string;
        dataEntryMode: DataEntryMode;
        objectVerbiage: string;
        supportedNonGenericErrors?: ApiErrorDisplayOverrideModel[];
      };
    };

export type ManualInputPagePropTypes<T, F> = ManualInputPageCommonProps<T> &
  ManualInputPageConditionalProps<T, F>;
