/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getCharterSchoolAssignmentNewViewModel,
  getCharterSchoolAuthorizingOrganizationNewViewModel,
  getCharterSchoolManagementOrganizationNewViewModel,
} from '../../services/charterSchool.service';
import { CharterSchoolAssignmentNewViewModel } from '../../types/CharterSchoolAssignmentNewViewModel';
import { CharterSchoolAuthorizingOrganizationNewViewModel } from '../../types/CharterSchoolAuthorizingOrganizationNewViewModel';
import { CharterSchoolManagementOrganizationNewViewModel } from '../../types/CharterSchoolManagementOrganizationNewViewModel';

export const fetchCharterSchoolAuthorizingOrganizationNewViewModel =
  createAsyncThunk(
    'input/fetchCharterSchoolAuthorizingOrganizationNewViewModel',
    async (_, thunkAPI) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return await thunkExecuteAndReturnResultOrShowError<CharterSchoolAuthorizingOrganizationNewViewModel>(
        thunkAPI,
        getCharterSchoolAuthorizingOrganizationNewViewModel({
          signal: thunkAPI.signal,
        })
      );
    }
  );

export const fetchCharterSchoolManagementOrganizationNewViewModel =
  createAsyncThunk(
    'input/fetchCharterSchoolManagementOrganizationNewViewModel',
    async (_, thunkAPI) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return await thunkExecuteAndReturnResultOrShowError<CharterSchoolManagementOrganizationNewViewModel>(
        thunkAPI,
        getCharterSchoolManagementOrganizationNewViewModel({
          signal: thunkAPI.signal,
        })
      );
    }
  );

export const fetchCharterSchoolAssignmentNewViewModel = createAsyncThunk(
  'input/fetchCharterSchoolAssignmentNewViewModel',
  async (_, thunkAPI) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return await thunkExecuteAndReturnResultOrShowError<CharterSchoolAssignmentNewViewModel>(
      thunkAPI,
      getCharterSchoolAssignmentNewViewModel({
        signal: thunkAPI.signal,
      })
    );
  }
);
