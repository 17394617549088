/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { selectNonPublicStudentDataEntryMode } from '../../../../redux/slices/nonPublicStudentSlice';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import NPSE_CTEPanel from './NPSE_CTEPanel';
import NPSE_StudentScheduleList from './NPSE_StudentScheduleList';

const NPSE_CTELayout = (): ReactElement => {
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectNonPublicStudentDataEntryMode
  );

  return (
    <>
      <NPSE_CTEPanel />
      {dataEntryMode === DataEntryMode.EDIT && <NPSE_StudentScheduleList />}
    </>
  );
};

export default NPSE_CTELayout;
