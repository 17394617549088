import { DateTime, Interval } from 'luxon';
import { FeatureAccessPermissions } from '../types/FeatureAccessPermissions';
import {
  getAuthTokenExpirationSeconds,
  getAuthTokenRequestDateAsMSInt,
  getCurrentUserAccess,
  getReportList,
  getReportToken,
  getReportTokenExpirationMinutes,
  getReportTokenRequestDateAsMSInt,
} from './base.service';

const getInterval = (startingAt: Date, timeoutDate: Date): Interval => {
  const start = DateTime.fromJSDate(startingAt);
  const end = DateTime.fromJSDate(timeoutDate);
  return Interval.fromDateTimes(start, end);
};

export const getMillisecondsFromAuthTokenRequestDate = (): number => {
  const requestedDate = getAuthTokenRequestDateAsMSInt();
  if (requestedDate) {
    const expiredDate = DateTime.fromMillis(requestedDate)
      .plus({
        seconds: getAuthTokenExpirationSeconds(),
      })
      .toJSDate();
    const interval = getInterval(new Date(), expiredDate);
    const intervalInMili = interval.length('milliseconds');
    if (!isNaN(intervalInMili)) {
      return intervalInMili;
    }
    return -1;
  }
  return -1;
};

export const getMillisecondsFromReportTokenRequestDate = (): number => {
  const requestedDate = getReportTokenRequestDateAsMSInt();
  if (requestedDate) {
    const expiredDate = DateTime.fromMillis(requestedDate)
      .plus({
        minutes: getReportTokenExpirationMinutes(),
      })
      .toJSDate();
    const interval = getInterval(new Date(), expiredDate);
    const intervalInMili = interval.length('milliseconds');
    if (!isNaN(intervalInMili)) {
      return intervalInMili;
    }
    return -1;
  }
  return -1;
};

export const getFeatureAccessPermissions = (): FeatureAccessPermissions[] => {
  const details = getCurrentUserAccess();
  return details ? details.featureAccessPermissions : [];
};

export const hasReportDataAndNotEmpty = (): boolean => {
  const powerBIToken = getReportToken();
  const reportList = getReportList();
  const hasTokenAndListAndNoEmptyReportList =
    powerBIToken !== undefined &&
    reportList !== undefined &&
    reportList.categories.length > 0;

  return hasTokenAndListAndNoEmptyReportList;
};
