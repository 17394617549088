/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getMsisIdRequestFormViewModel,
  getMSISRequestList,
  getStudentDetails,
  getStudentOwnershipConflictDetail,
  getStudentOwnershipConflictList,
} from '../../services/student.service';
import { MsisIdQueryFormViewModel } from '../../types/MSISIdQueryFormViewModel';
import { MSISRequestDetailModel } from '../../types/MSISRequestDetailModel';
import { MSISRequestListModel } from '../../types/MSISRequestListModel';
import { StudentOwnershipConflictDetailModel } from '../../types/StudentOwnershipConflictDetailModel';
import { StudentOwnershipConflictListModel } from '../../types/StudentOwnershipConflictListModel';

export const fetchMSISRequests = createAsyncThunk(
  'student/fetchMSISRequests',
  async (maxRequestAgeDays: number, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<MSISRequestListModel>(
      thunkAPI,
      getMSISRequestList(maxRequestAgeDays, { signal: thunkAPI.signal })
    );
  }
);

export const fetchStudentDetails = createAsyncThunk(
  'student/fetchStudentDetails',
  async (studentId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<MSISRequestDetailModel>(
      thunkAPI,
      getStudentDetails(studentId, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchStudentOwnershipConflict = createAsyncThunk(
  'student/fetchStudentOwnershipConflict',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<StudentOwnershipConflictListModel>(
      thunkAPI,
      getStudentOwnershipConflictList({ signal: thunkAPI.signal })
    );
  }
);

export const fetchStudentOwnershipConflictDetail = createAsyncThunk(
  'student/fetchStudentOwnershipConflictDetail',
  async (conflictId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<StudentOwnershipConflictDetailModel>(
      thunkAPI,
      getStudentOwnershipConflictDetail(conflictId, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchMSISIdQueryFormViewModel = createAsyncThunk(
  'student/fetchMSISIdQueryFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<MsisIdQueryFormViewModel>(
      thunkAPI,
      getMsisIdRequestFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
