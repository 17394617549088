import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { FileDetailsModel } from '../types/FileDetailsModel';
import { FilePermissionMap } from '../types/FilePermissionMap';
import { FilesListModel } from '../types/FilesListModel';
import { FileUploadFormModel } from '../types/FileUploadFormModel';
import { UploadProps } from '../types/UploadProps';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken, isValidCode } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const fileApiPaths = {
  getFileList: (leaId: string) => `file/lea/${leaId}`,
  fileUpload: 'file',
  getUploadFormModel: 'file/form',
  getFileById: (id: string, lea: string): string => `file/${id}/${lea}`,
  deleteById: (id: string, leaId: string): string => `file/${id}/${leaId}`,
  downloadFile: (id: string, lea: string): string =>
    `file/download/${id}/${lea}`,
  getFilePermissions: (): string => `file/uploadPermissions`,
};

export const FileServiceClient = (config?: DataRequestConfig): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getFileList = async (
  leaId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<FilesListModel>> => {
  return executeApiRequest<FilesListModel>(async () => {
    return await FileServiceClient(config).get(fileApiPaths.getFileList(leaId));
  });
};

export const getFileById = async (
  id: string,
  leaId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<FileDetailsModel>> => {
  return executeApiRequest<FileDetailsModel>(async () => {
    return await FileServiceClient(config).get(
      fileApiPaths.getFileById(id, leaId)
    );
  });
};

export const download = async (
  id: string,
  leaId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<Blob>> => {
  return executeApiRequest<Blob>(async () => {
    return await FileServiceClient(config).getFile(
      fileApiPaths.downloadFile(id, leaId)
    );
  });
};

export const deleteFile = async (
  id: string,
  leaId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await FileServiceClient(config).delete(
      fileApiPaths.deleteById(id, leaId)
    );
  });
};

export const getUploadFormModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<FileUploadFormModel>> => {
  return executeApiRequest<FileUploadFormModel>(async () => {
    return await FileServiceClient(config).get(fileApiPaths.getUploadFormModel);
  });
};

export const getFilePermissionsMap = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<FilePermissionMap[]>> => {
  return executeApiRequest<FilePermissionMap[]>(async () => {
    return await FileServiceClient(config).get(
      fileApiPaths.getFilePermissions()
    );
  });
};

export const upload = async ({
  files,
  onProgress,
  onError,
  onDone,
  config,
}: UploadProps): Promise<void> => {
  const formdata = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    formdata.append('files', files[i]);
  }

  const response = await executeApiRequest<unknown>(async () => {
    return await FileServiceClient(config).upload(
      fileApiPaths.fileUpload,
      formdata,
      onProgress
    );
  });

  if (!isValidCode(response.status)) {
    onError();
  }

  onDone(response);
};
