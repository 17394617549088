/* eslint-disable max-len */
import { ReactElement, useRef, useState } from 'react';
import ActionButton from '../../components/ActionButton/ActionButton';
import ApiErrorPanel from '../../components/ApiErrorPanel/ApiErrorPanel';
import Modal from '../../components/Modal/Modal';
import { ConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useModalShow } from '../../hooks/useModalShow';
import { isValidCode } from '../../services/base.service';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { ConfirmationDialogOptions } from '../../types/ConfirmationDialogOptions';
import { DialogType } from '../../types/DialogTypes';
import { ModalContextType } from '../../types/ModalContextType';
import { ConfirmationModalContextProviderPropTypes } from '../../types/propTypes/ConfirmationModalContextProviderPropTypes';
import './ConfirmationModalContextProvider.css';

const ConfirmationModalContextProvider = ({
  children,
}: ConfirmationModalContextProviderPropTypes): ReactElement => {
  const { setShow, show, onHide } = useModalShow();

  const defaultOptions: ConfirmationDialogOptions =
    {} as ConfirmationDialogOptions;

  const [dialogOptions, setDialogOptions] =
    useState<ConfirmationDialogOptions>(defaultOptions);
  const [showError, setShowError] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [error, setError] = useState({} as ApiErrorModel);

  const resolver = useRef<Function>();
  const okFunc = useRef<() => Promise<ApiResponseModel<unknown>>>();
  const cancelFunc = useRef<Function>();

  const handleShow = (): Promise<boolean> => {
    setShow(true);
    setShowError(false);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const handleOptions = (options: ConfirmationDialogOptions): void => {
    setDialogOptions(options);

    okFunc.current = options.onOk;
    cancelFunc.current = options.onCancel;
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
    hideConfirmation: onHide,
    setOptions: handleOptions,
  };

  const handleOk = async (): Promise<void> => {
    if (okFunc.current) {
      setIsExecuting(true);

      const response = await okFunc.current();
      if (isValidCode(response.status)) {
        /* istanbul ignore next */
        resolver.current && resolver.current(true);
        onHide();
      } else {
        setShowError(true);
        setError(response.error);
      }

      setIsExecuting(false);
    }
  };

  const handleCancel = (): void => {
    cancelFunc.current && cancelFunc.current();
    /* istanbul ignore next */
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {children}
      <Modal
        type={DialogType.CONFIRM}
        open={show}
        onClose={handleCancel}
        title={dialogOptions.title}
      >
        <>
          {showError && <ApiErrorPanel error={error} includeTitle={true} />}
          {dialogOptions.content}
          <div className="confirm-action-container">
            <ActionButton
              onClick={handleCancel}
              dataTestId="modal-cancel"
              cypressDataId="modal-cancel"
              tooltipText={
                dialogOptions.cancelText ? dialogOptions.cancelText : 'Cancel'
              }
            >
              <span>
                {dialogOptions.cancelText ? dialogOptions.cancelText : 'Cancel'}
              </span>
            </ActionButton>
            <ActionButton
              classes="button--secondary"
              onClick={handleOk}
              dataTestId="modal-ok"
              cypressDataId="modal-ok"
              disabled={isExecuting}
              loading={isExecuting}
              tooltipText={
                dialogOptions.confirmText
                  ? dialogOptions.confirmText
                  : 'Confirm'
              }
            >
              <span>
                {dialogOptions.confirmText
                  ? dialogOptions.confirmText
                  : 'Confirm'}
              </span>
            </ActionButton>
          </div>
        </>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalContextProvider;
