/* eslint-disable max-len */
import { ReactElement } from 'react';

export const getCloseIcon = (): ReactElement => {
  return (
    <svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.45919 8.45926C8.94735 7.97111 9.73881 7.97111 10.227 8.45926L14.9999 13.2322L19.7729 8.45926C20.2611 7.97111 21.0525 7.97111 21.5407 8.45926C22.0288 8.94742 22.0288 9.73887 21.5407 10.227L16.7677 15L21.5407 19.773C22.0288 20.2611 22.0288 21.0526 21.5407 21.5407C21.0525 22.0289 20.2611 22.0289 19.7729 21.5407L14.9999 16.7678L10.227 21.5407C9.73881 22.0289 8.94735 22.0289 8.45919 21.5407C7.97104 21.0526 7.97104 20.2611 8.45919 19.773L13.2322 15L8.45919 10.227C7.97104 9.73887 7.97104 8.94742 8.45919 8.45926Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const getMoreIcon = (): ReactElement => {
  return (
    <svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.005 11.995v.01m0-4.01v.01m0 7.99v.01"
      />
    </svg>
  );
};
