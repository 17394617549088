import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataQualityDashboardModel } from '../../types/DataQualityDashboardModel';
import { DataQualityErrorList } from '../../types/DataQualityErrorList';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchDataQualityData,
  fetchDataQualityItemsForCategory,
} from '../thunks/dataQualityThunks';

interface DataQualityState {
  loading: boolean;
  dashboardItems: DataQualityDashboardModel;
  categoryErrors: DataQualityErrorList;

  // Using a string here since it is a single value
  // currently, but expect this to expand into an object soon
  filter: string;
}

export const defaultDataQualityItems: DataQualityDashboardModel = {
  domainErrors: [],
  dataCollectionOptions: [],
};

export const defaultDataQualityErrorList: DataQualityErrorList = {
  domain: '',
  tabs: [],
  dataCollectionOptions: [],
};

export const initialState: DataQualityState = {
  loading: false,
  dashboardItems: defaultDataQualityItems,
  categoryErrors: defaultDataQualityErrorList,
  filter: '',
};

export const dataQualitySlice = createSlice({
  name: 'dataQuality',
  initialState,
  reducers: {
    clearDQDFilter: (state) => {
      state.filter = '';
    },
    setDQDFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataQualityData.pending, (state) => {
      state.dashboardItems = defaultDataQualityItems;
      state.loading = true;
    });
    builder.addCase(fetchDataQualityData.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.dashboardItems = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchDataQualityData.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchDataQualityItemsForCategory.pending, (state) => {
      state.categoryErrors = defaultDataQualityErrorList;
      state.loading = true;
    });
    builder.addCase(
      fetchDataQualityItemsForCategory.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.categoryErrors = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchDataQualityItemsForCategory.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loading = false;
        }
      }
    );
  },
});

export const { clearDQDFilter, setDQDFilter } = dataQualitySlice.actions;

export const selectDataQualityLoading = (state: RootState): boolean =>
  state.dataQuality.loading;
export const selectDashboardItems = (
  state: RootState
): DataQualityDashboardModel => state.dataQuality.dashboardItems;
export const selectDQDFilter = (state: RootState): string =>
  state.dataQuality.filter;
export const selectCategoryErrors = (state: RootState): DataQualityErrorList =>
  state.dataQuality.categoryErrors;

export default dataQualitySlice.reducer;
