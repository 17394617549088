import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { PowerBIReportDetails } from '../types/PowerBIReportDetails';
import { ReportListModel } from '../types/ReportListModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const reportApiPaths = {
  getReportList: 'report',
};

export const ReportServiceClient = (config?: DataRequestConfig): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getAllReports = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<PowerBIReportDetails>> => {
  return executeApiRequest<PowerBIReportDetails>(async () => {
    return await ReportServiceClient(config).get(reportApiPaths.getReportList);
  });
};

export const getReportListModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<ReportListModel>> => {
  return executeApiRequest<ReportListModel>(async () => {
    return await ReportServiceClient(config).get(reportApiPaths.getReportList);
  });
};
