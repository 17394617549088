import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/hooks';
import { clearLoginState } from '../redux/slices/loginSlice';
import { clearUserState } from '../redux/slices/userSlice';
import { clearStorageForLogin } from '../services/base.service';
import { appRoutePaths } from '../services/route.service';
import { useAuthorizationContext } from './useAuthorizationContext';
import { useConfirmationModalContext } from './useConfirmationModalContext';

export type UseLogoutType = {
  onLogout: () => void;
};

export const useLogout = (): UseLogoutType => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hideConfirmation } = useConfirmationModalContext();
  const { authTokenStop, reportTokenStop } = useAuthorizationContext();

  const clearLoginSessionState = (): void => {
    clearStorageForLogin();
  };

  const closeAllModals = (): void => {
    hideConfirmation();
  };

  const clearLoginReduxState = (): void => {
    dispatch(clearLoginState());
    dispatch(clearUserState());
  };

  const moveToLogin = (): void => {
    navigate(appRoutePaths.Login);
  };

  const stopAllAuthTimers = (): void => {
    authTokenStop();
    reportTokenStop();
  };

  const handleLogout = (): void => {
    clearLoginSessionState();
    closeAllModals();
    clearLoginReduxState();
    stopAllAuthTimers();
    moveToLogin();
  };

  return {
    onLogout: handleLogout,
  };
};
