/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import NPSE_ScheduleLayout from '../../../../components/FormLayouts/NPSE/CTE/Schedule/NPSE_ScheduleLayout';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearNonPublicStudentSchedule,
  saveNonPublicStudentSchedule,
  selectNonPublicStudentDataEntryMode,
  selectNonPublicStudentLoadingFormViewModel,
  selectNonPublicStudentLoadingNonPublicStudent,
  selectNonPublicStudentScheduleFormViewModel,
  selectNonPublicStudentScheduleModel,
  setNonPublicStudentDataEntryMode,
} from '../../../../redux/slices/nonPublicStudentSlice';
import {
  fetchNonPublicStudentScheduleByScheduleId,
  fetchNonPublicStudentScheduleFormViewModel,
} from '../../../../redux/thunks/nonPublicStudentThunks';
import {
  createNonPublicStudentSchedule,
  updateNonPublicStudentSchedule,
} from '../../../../services/nonPublicStudent.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_STUDENT } from '../../../../types/NavigationTabs';
import { NonPublicStudentScheduleFormViewModel } from '../../../../types/NonPublicStudentScheduleFormViewModel';
import { NonPublicStudentScheduleModel } from '../../../../types/NonPublicStudentScheduleModel';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { NonPublicStudentEntryScheduleValidationSchema } from '../../../../types/Validation/NonPublicStudentEntryScheduleValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';

const NonPublicStudentEntryScheduleInput = (): ReactElement => {
  const queryParams = useParams();

  const model: NonPublicStudentScheduleModel = useAppSelector(
    selectNonPublicStudentScheduleModel
  );
  const formViewModel: NonPublicStudentScheduleFormViewModel = useAppSelector(
    selectNonPublicStudentScheduleFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectNonPublicStudentLoadingFormViewModel
  );
  const isLoadingModel = useAppSelector(
    selectNonPublicStudentLoadingNonPublicStudent
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectNonPublicStudentDataEntryMode
  );
  const isLoading = isLoadingModel || isLoadingFormViewModel;

  const npseId = queryParams['nonPublicStudentId'];
  const supportedNonGenericErrors = [
    {
      statusCode: 409,
      howToFix:
        'The provided record is a duplicate. Change any non-Status value and resubmit.',
    },
  ];

  /* istanbul ignore next */
  const handleScheduleCreateRoute = (id: string): string => {
    return appRoutePaths.NonPublicStudentEntryScheduleInput(npseId, id);
  };

  const statusValue =
    dataEntryMode === DataEntryMode.NEW ? 'Active' : model.status;

  /* istanbul ignore next */
  const intitalValues = {
    ...model,
    npsId: npseId || '',
    status: statusValue,
  };

  return (
    <ManualInputPage<
      NonPublicStudentScheduleModel,
      NonPublicStudentScheduleFormViewModel
    >
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'non-public-student-entry-schedule-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_STUDENT)}
      classes={{
        pageClass:
          'data-input--student--non-public-student-entry-schedule--form',
      }}
      parameterOptions={{
        routeParameterName: 'scheduleId',
        identityName: model.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.NonPublicStudentEntryInput(npseId),
        cancelRoute: appRoutePaths.NonPublicStudentEntryInput(npseId),
        createSuccessRoute: handleScheduleCreateRoute,
      }}
      dataFunctions={{
        loadDataDetailById: fetchNonPublicStudentScheduleByScheduleId,
        loadFormDetail: fetchNonPublicStudentScheduleFormViewModel,
        setEntryMode: setNonPublicStudentDataEntryMode,
        setData: saveNonPublicStudentSchedule,
        clearData: clearNonPublicStudentSchedule,
        onCreate: createNonPublicStudentSchedule,
        onUpdate: updateNonPublicStudentSchedule,
      }}
      formDetails={{
        validationSchema: NonPublicStudentEntryScheduleValidationSchema,
        initialFormValues: intitalValues,
        isFormModelEmpty: formViewModel.extendedCourseCodeOptions.length === 0,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'student schedule',
        pageHeader: 'Student',
        subHeading: 'Student Course Schedule',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <NPSE_ScheduleLayout />
    </ManualInputPage>
  );
};

export default NonPublicStudentEntryScheduleInput;
