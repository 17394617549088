import { ReactElement } from 'react';
import NavigationButton from '../../../components/NavigationButton/NavigationButton';
import { appRoutePaths } from '../../../services/route.service';

const FeedbackMenu = (): ReactElement => {
  const feedbackPath = appRoutePaths.Feedback;

  return (
    <li className="sidebar-nav-feedback-container">
      <NavigationButton
        isNavLink={true}
        route={feedbackPath}
        classes="sidebar--nav--feedback sidebar--nav--link"
        visibleOverride={true}
      >
        <span>Provide Feedback</span>
      </NavigationButton>
    </li>
  );
};

export default FeedbackMenu;
