/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { CharterSchoolAuthorizingOrganizationNewViewModel } from '../../types/CharterSchoolAuthorizingOrganizationNewViewModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import { fetchCharterSchoolAuthorizingOrganizationNewViewModel } from '../thunks/charterSchoolThunks';

interface CharterSchoolAuthorizingOrganizationState {
  loading: boolean;
  newViewModel: CharterSchoolAuthorizingOrganizationNewViewModel;
}

export const defaultViewModelState: CharterSchoolAuthorizingOrganizationNewViewModel =
  {
    schoolYearOptions: [],
    authorizerTypeOptions: [],
    stateOptions: [],
  };

export const initialState: CharterSchoolAuthorizingOrganizationState = {
  loading: false,
  newViewModel: defaultViewModelState,
};

export const charterSchoolAuthorizingOrganizationSlice = createSlice({
  name: 'charterSchoolAuthorizingOrganization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCharterSchoolAuthorizingOrganizationNewViewModel.pending,
      (state) => {
        state.newViewModel = defaultViewModelState;
        state.loading = true;
      }
    );
    builder.addCase(
      fetchCharterSchoolAuthorizingOrganizationNewViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.newViewModel = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchCharterSchoolAuthorizingOrganizationNewViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loading = false;
        }
      }
    );
  },
});

export const selectCharterSchoolAuthorizingOrganizationLoading = (
  state: RootState
): boolean => state.charterSchoolAuthorizingOrganization.loading;
export const selectNewCharterSchoolAuthorizingOrganizationViewModel = (
  state: RootState
): CharterSchoolAuthorizingOrganizationNewViewModel =>
  state.charterSchoolAuthorizingOrganization.newViewModel;

export default charterSchoolAuthorizingOrganizationSlice.reducer;
