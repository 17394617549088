import { AuthTokenRefreshPayload } from '../types/AuthTokenRefreshPayload';
import { GetPowerBITokenResponseModel } from '../types/GetPowerBITokenResponseModel';
import { GetTokenResponseModel } from '../types/GetTokenResponseModel';
import { ReportTokenRefreshPayload } from '../types/ReportTokenRefreshPayload';

export const mapToAuthTokenResponsePayload = (
  response: GetTokenResponseModel
): AuthTokenRefreshPayload => {
  return {
    token: response.token || '',
    requestedDateTimeInMS: new Date().getTime(),
    expiration: response.tokenExpiration || 0,
    refresh_code: response.refreshToken || '',
  };
};

export const mapToReportTokenResponsePayload = (
  response: GetPowerBITokenResponseModel
): ReportTokenRefreshPayload => {
  return {
    token: response.token || '',
    requestedDateTimeInMS: new Date().getTime(),
    expiration: response.expiration || 0,
  };
};
