/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectLEASharingListItems,
  selectLEASharingLoadingListItems,
} from '../../../../redux/slices/leaSharingSlice';
import { appRoutePaths } from '../../../../services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';

import { fetchLEASharingListItems } from '../../../../redux/thunks/leaSharingThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { LEASharingListItemModel } from '../../../../types/LEASharingListItemModel';
import { formatToDateString } from '../../../../utilities/dateUtilities';
import { isStateAdmin } from '../../../../utilities/userUtilities';
import './LEASharingList.css';

const LEASharingList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectLEASharingLoadingListItems);
  const listItems: LEASharingListItemModel[] = useAppSelector(
    selectLEASharingListItems
  );

  useEffect(() => {
    const promise: unknown = dispatch(fetchLEASharingListItems());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const handleEdit = (id: string): void => {
    navigate(appRoutePaths.LEASharingInput(id));
  };

  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--lea-sharing--list"
      isLoading={isLoading}
      loadingDataId={'lea-sharing-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>LEA Sharing</h4>
            {isStateAdmin() && (
              <NavigationButton
                route={appRoutePaths.LEASharingInput('new')}
                visibleOverride={canUserAccessRoute(
                  appRoutePaths.LEASharingInput()
                )}
                classes="button no-wrap-text new-button"
                tooltipText="Add New LEA Sharing"
              >
                <>Add New</>
              </NavigationButton>
            )}
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th className="lea-name">Sharing from LEA</th>
                  <th className="lea-name">Sharing to LEA</th>
                  <th className="share-type">Share Type</th>
                  <th className="program-type">Program Type</th>
                  <th className="inactive-date">Inactive Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((leaSharing) => (
                    <tr key={`${leaSharing.id}`} data-testid={leaSharing.id}>
                      <td>{leaSharing.sharingFromLEA}</td>
                      <td>{leaSharing.sharingToLEA}</td>
                      <td>{leaSharing.shareType}</td>
                      <td>{leaSharing.programType}</td>
                      <td>{formatToDateString(leaSharing.inactiveDate)}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${leaSharing.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(leaSharing.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit/View LEA Sharing'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DistrictSchoolInfoPage>
  );
};

export default LEASharingList;
