import { ApiResponseModel } from '../types/ApiResponseModel';
import { CourseCodeFormViewModel } from '../types/CourseCodeFormViewModel';
import { CourseCodeListItemModel } from '../types/CourseCodeListItemModel';
import { CourseCodeModel } from '../types/CourseCodeModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const courseCodeApiPaths = {
  create: 'courseCode',
  update: 'courseCode',
  getListItems: 'courseCode',
  getById: (courseCodeId: string): string => `courseCode/${courseCodeId}`,
  getForm: 'courseCode/form',
};

export const CourseCodeServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createCourseCode = async (
  body: CourseCodeModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await CourseCodeServiceClient(config).post(
      courseCodeApiPaths.create,
      body
    );
  });
};

export const updateCourseCode = async (
  body: CourseCodeModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await CourseCodeServiceClient(config).put(
      courseCodeApiPaths.update,
      body
    );
  });
};

export const getCourseCodeListItems = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<CourseCodeListItemModel[]>> => {
  return executeApiRequest<CourseCodeListItemModel[]>(async () => {
    return await CourseCodeServiceClient(config).get(
      courseCodeApiPaths.getListItems
    );
  });
};

export const getCourseCodeById = async (
  courseCodeId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<CourseCodeModel>> => {
  return executeApiRequest<CourseCodeModel>(async () => {
    return await CourseCodeServiceClient(config).get(
      courseCodeApiPaths.getById(courseCodeId)
    );
  });
};

export const getCourseCodeFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<CourseCodeFormViewModel>> => {
  return executeApiRequest<CourseCodeFormViewModel>(async () => {
    return await CourseCodeServiceClient(config).get(
      courseCodeApiPaths.getForm
    );
  });
};
