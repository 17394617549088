/* eslint-disable max-len */
import { ReactElement } from 'react';
import Unauthorized from '../../pages/Unauthorized/Unauthorized';
import { canUserAccessRoute } from '../../services/security.service';
import { AuthenticatedRoutePropTypes } from '../../types/propTypes/AuthenticatedRoutePropTypes';

const AuthenticatedRoute = ({
  route,
  children,
}: AuthenticatedRoutePropTypes): ReactElement => {
  const canAccessPage = canUserAccessRoute(route);

  return canAccessPage ? children : <Unauthorized />;
};

export default AuthenticatedRoute;
