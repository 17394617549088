import { ReactElement } from 'react';
import { TooltipAnchor } from '../../../services/app.service';
import { UserMenuPropTypes } from '../../../types/propTypes/UserMenuPropTypes';

const UserMenu = ({
  name,
  isOpen,
  setIsOpen,
  handleLogout,
}: UserMenuPropTypes): ReactElement => {
  return (
    <div className="sidebar--user">
      <button
        type="button"
        data-testid="user-nugget"
        className={isOpen ? 'sidebar--user--showing' : ''}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <img
          src="/images/icon-profile-pic.svg"
          data-tooltip-id={TooltipAnchor}
          data-tooltip-content="User Profile"
        />
        <span>{name}</span>
      </button>
      {isOpen && (
        <div className="sidebar--user--menu">
          <ul>
            <button
              type="button"
              data-testid="logout-link"
              onClick={handleLogout}
              data-tooltip-id={TooltipAnchor}
              data-tooltip-content={'Logout of the Application'}
            >
              Logout
            </button>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
