/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { DashboardModel } from '../../types/DashboardModel';
import { FilesListStatusModel } from '../../types/FilesListStatusModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import { RootState } from '../store';
import { fetchDashboardData } from '../thunks/dashboardThunks';

interface DashboardState {
  loading: boolean;
  dashboardDetails: DashboardModel;
}

export const defaultDashboardDetails = {
  fileStatusList: {
    failureFileIds: [],
    successFileIds: [],
    pendingFileIds: [],
  },
  hasConflicts: false,
  hasDataQualityError: false,
};

export const initialState: DashboardState = {
  loading: false,
  dashboardDetails: defaultDashboardDetails,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.dashboardDetails = defaultDashboardDetails;
      state.loading = true;
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.dashboardDetails = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchDashboardData.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
  },
});

export const selectDashboardLoading = (state: RootState): boolean =>
  state.dashboard.loading;
export const selectFileListStatus = (state: RootState): FilesListStatusModel =>
  state.dashboard.dashboardDetails.fileStatusList;
export const selectFailureIds = (state: RootState): Array<string> =>
  state.dashboard.dashboardDetails.fileStatusList?.failureFileIds;
export const selectSuccessIds = (state: RootState): Array<string> =>
  state.dashboard.dashboardDetails.fileStatusList?.successFileIds;
export const selectPendingIds = (state: RootState): Array<string> =>
  state.dashboard.dashboardDetails.fileStatusList?.pendingFileIds;
export const selectHasConflicts = (state: RootState): boolean =>
  state.dashboard.dashboardDetails.hasConflicts;
export const selectHasDataQualityErrors = (state: RootState): boolean =>
  state.dashboard.dashboardDetails.hasDataQualityError;

export default dashboardSlice.reducer;
