import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getLEASharingById,
  getLEASharingFormViewModel,
  getLEASharingListItems,
} from '../../services/leaSharing.service';
import { LEASharingFormViewModel } from '../../types/LEASharingFormViewModel';
import { LEASharingListItemModel } from '../../types/LEASharingListItemModel';
import { LEASharingModel } from '../../types/LEASharingModel';

export const fetchLEASharingListItems = createAsyncThunk(
  'input/fetchLeaSharingListItems',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      LEASharingListItemModel[]
    >(thunkAPI, getLEASharingListItems({ signal: thunkAPI.signal }));
  }
);

export const fetchLEASharingById = createAsyncThunk(
  'input/fetchLeaSharingById',
  async (leaId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<LEASharingModel>(
      thunkAPI,
      getLEASharingById(leaId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchLEASharingFormViewModel = createAsyncThunk(
  'input/fetchLeaSharingFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<LEASharingFormViewModel>(
      thunkAPI,
      getLEASharingFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
