/* eslint-disable max-len */
import { ReactElement } from 'react';
import { TooltipAnchor } from '../../services/app.service';
import { ButtonSize } from '../../types/ButtonSize';
import { ActionButtonPropTypes } from '../../types/propTypes/ActionButtonPropTypes';
import './ActionButton.css';

const ActionButton = ({
  onClick,
  children,
  dataTestId,
  classes = '',
  size,
  cypressDataId,
  disabled,
  buttonType = 'button',
  tooltipText,
  loading,
}: ActionButtonPropTypes): ReactElement => {
  let buttonSizing = '';
  switch (size) {
    case ButtonSize.SMALL:
      buttonSizing = 'button--small';
      break;
    default:
      buttonSizing = '';
      break;
  }

  const tooltipProps = tooltipText
    ? {
        'data-tooltip-id': TooltipAnchor,
        'data-tooltip-content': tooltipText,
      }
    : {};

  const getRingLoader = (): ReactElement => {
    return (
      <div className="button-loading-ring" data-testid="spin-loader">
        {/* All these empty divs are needed for the final result */}
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  return (
    <>
      <button
        data-cy={cypressDataId}
        data-testid={dataTestId}
        className={`button ${buttonSizing} no-wrap-text ${classes}`}
        type={buttonType}
        disabled={disabled}
        onClick={async (e) => {
          await onClick(e);
        }}
        {...tooltipProps}
      >
        {loading ? getRingLoader() : children}
      </button>
    </>
  );
};

export default ActionButton;
