/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { CharterSchoolManagementOrganizationNewViewModel } from '../../types/CharterSchoolManagementOrganizationNewViewModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import { fetchCharterSchoolManagementOrganizationNewViewModel } from '../thunks/charterSchoolThunks';

interface CharterSchoolManagementOrganizationState {
  loading: boolean;
  newViewModel: CharterSchoolManagementOrganizationNewViewModel;
}

export const defaultViewModelState: CharterSchoolManagementOrganizationNewViewModel =
  {
    schoolYear: '',
    stateOptions: [],
  };

export const initialState: CharterSchoolManagementOrganizationState = {
  loading: false,
  newViewModel: defaultViewModelState,
};

export const charterSchoolManagementOrganizationSlice = createSlice({
  name: 'charterSchoolManagementOrganization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCharterSchoolManagementOrganizationNewViewModel.pending,
      (state) => {
        state.newViewModel = defaultViewModelState;
        state.loading = true;
      }
    );
    builder.addCase(
      fetchCharterSchoolManagementOrganizationNewViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.newViewModel = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchCharterSchoolManagementOrganizationNewViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loading = false;
        }
      }
    );
  },
});

export const selectCharterSchoolManagementOrganizationLoading = (
  state: RootState
): boolean => state.charterSchoolManagementOrganization.loading;
export const selectNewCharterSchoolManagementOrganizationViewModel = (
  state: RootState
): CharterSchoolManagementOrganizationNewViewModel =>
  state.charterSchoolManagementOrganization.newViewModel;

export default charterSchoolManagementOrganizationSlice.reducer;
