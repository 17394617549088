/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserDistrictList from '../../components/UserDistrictList/UserDistrictList';
import { useAuthorizationContext } from '../../hooks/useAuthorizationContext';
import { TooltipAnchor } from '../../services/app.service';
import {
  getCurrentUserAccess,
  tokenIsPresent,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { SidebarPropTypes } from '../../types/propTypes/SidebarPropTypes';
import { storeCurrentRouteForRelogin } from '../../utilities/userUtilities';
import AdminMenu from './Admin/AdminMenu';
import DataInputMenu from './DataInput/DataInputMenu';
import FeedbackMenu from './Feedback/FeedbackMenu';
import FileMenu from './File/FileMenu';
import HomeMenu from './Home/HomeMenu';
import ReportMenu from './Report/ReportMenu';
import './Sidebar.css';
import StudentMenu from './Student/StudentMenu';
import UserMenu from './User/UserMenu';

export const Sidebar = ({ name }: SidebarPropTypes): ReactElement => {
  const navigate = useNavigate();
  const { authTokenStart } = useAuthorizationContext();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [dataSubmissionMenuIsOpen, setDataSubmissionMenuIsOpen] =
    useState(false);
  const [studentMenuIsOpen, setStudentMenuIsOpen] = useState(false);
  const [reportMenuIsOpen, setReportMenuIsOpen] = useState(false);
  const [dataInputMenuIsOpen, setDataInputMenuIsOpen] = useState(false);
  const [adminMenuIsOpen, setAdminMenuIsOpen] = useState(false);

  const expandableNavigationItemSetIsOpens = [
    setDataSubmissionMenuIsOpen,
    setStudentMenuIsOpen,
    setReportMenuIsOpen,
    setShowUserMenu,
    setDataInputMenuIsOpen,
    setAdminMenuIsOpen,
  ];

  const closeAll = (): void => {
    expandableNavigationItemSetIsOpens.forEach((setIsOpen) => setIsOpen(false));
  };

  const handleMenuToggle = (isOpen: boolean, setFunc: Function): void => {
    closeAll();
    setFunc(isOpen);
  };

  const handleLogout = (): void => {
    storeCurrentRouteForRelogin(location.pathname);
    navigate(appRoutePaths.Logout);
  };

  useEffect(() => {
    if (tokenIsPresent() && getCurrentUserAccess()) {
      authTokenStart();
    }
  }, [authTokenStart]);

  return (
    <aside className="sidebar">
      <h1>
        <img
          className="sidebar--logo"
          src="/images/logo.svg"
          data-tooltip-id={TooltipAnchor}
          data-tooltip-content="Mississippi Student Information System"
        />
        <img
          className="sidebar--logo--mobile"
          src="/images/logo-no-tagline.svg"
          data-tooltip-id={TooltipAnchor}
          data-tooltip-content="Mississippi Student Information System"
        />
      </h1>
      <UserDistrictList />
      <nav className="sidebar--nav">
        <ul>
          <HomeMenu />
          <AdminMenu
            isOpen={adminMenuIsOpen}
            setIsOpen={(isOpen: boolean) =>
              handleMenuToggle(isOpen, setAdminMenuIsOpen)
            }
          />
          <FileMenu
            isOpen={dataSubmissionMenuIsOpen}
            setIsOpen={(isOpen: boolean) =>
              handleMenuToggle(isOpen, setDataSubmissionMenuIsOpen)
            }
          />
          <ReportMenu
            isOpen={reportMenuIsOpen}
            setIsOpen={(isOpen: boolean) =>
              handleMenuToggle(isOpen, setReportMenuIsOpen)
            }
          />
          <StudentMenu
            isOpen={studentMenuIsOpen}
            setIsOpen={(isOpen: boolean) =>
              handleMenuToggle(isOpen, setStudentMenuIsOpen)
            }
          />
          <DataInputMenu
            isOpen={dataInputMenuIsOpen}
            setIsOpen={(isOpen: boolean) =>
              handleMenuToggle(isOpen, setDataInputMenuIsOpen)
            }
          />
          <FeedbackMenu />
        </ul>
      </nav>
      <UserMenu
        name={name}
        isOpen={showUserMenu}
        setIsOpen={setShowUserMenu}
        handleLogout={handleLogout}
      />
    </aside>
  );
};

export default Sidebar;
