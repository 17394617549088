/* eslint-disable max-len */
import { ReactElement } from 'react';
import { MSISRequestMatchModel } from '../../../types/MSISRequestMatchModel';
import { MatchDisplayPropTypes } from '../../../types/propTypes/MatchDisplayPropTypes';
import { formatToDateString } from '../../../utilities/dateUtilities';
import { getMatchedFieldName } from '../../../utilities/helperUtilities';
import LabelAndValue from '../../LabelAndValue/LabelAndValue';
import './MatchDisplay.css';

const MatchDisplay = ({ match }: MatchDisplayPropTypes): ReactElement => {
  const getValue = (
    fieldName: string,
    matchedModel: MSISRequestMatchModel
  ): string => {
    const propName = getMatchedFieldName(
      fieldName
    ) as keyof MSISRequestMatchModel;
    switch (fieldName.toLowerCase()) {
      case 'firstname':
      case 'middlename':
      case 'lastname':
      case 'ssn':
        return matchedModel[propName] as string;
      case 'dateofbirth':
        const date = matchedModel[propName] as string;
        return formatToDateString(date);
      /* istanbul ignore next */
      default:
        return '';
    }
  };

  const getLabelName = (fieldName: string): string => {
    switch (fieldName.toLowerCase()) {
      case 'firstname':
        return 'First Name';
      case 'middlename':
        return 'Middle Name';
      case 'lastname':
        return 'Last Name';
      case 'ssn':
        return 'SSN';
      case 'dateofbirth':
        return 'Date of Birth';
      /* istanbul ignore next */
      default:
        return '';
    }
  };

  const constructDisplayComponent = (
    field: string
  ): ReactElement | undefined => {
    const label = getLabelName(field);
    const val = getValue(field, match);

    if (val || label) {
      return <LabelAndValue key={field} displayName={label} value={val} />;
    }
  };

  return (
    <div>
      <div data-testid="matched-container">
        <div className="match-display--title" data-testid="match-title">
          Matched Data
        </div>
        <div
          className="match-display--container"
          data-testid="match-field-list"
        >
          {match.matchedFieldNames.map((x) => {
            return constructDisplayComponent(x);
          })}
          {match.matchedAlias && (
            <LabelAndValue displayName="Alias" value={match.matchedAlias} />
          )}
        </div>
      </div>
      <div
        data-testid="match-separator"
        className="match-display--separator"
      ></div>
      <div data-testid="unmatched-container">
        <div className="match-display--title" data-testid="unmatch-title">
          Unmatched Data
        </div>
        <div
          className="match-display--container"
          data-testid="unmatch-field-list"
        >
          {match.unmatchedFieldNames.map((x) => {
            return constructDisplayComponent(x);
          })}
        </div>
      </div>
    </div>
  );
};

export default MatchDisplay;
