/* eslint-disable max-len */
import { ReactElement } from 'react';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import { appRoutePaths } from '../../../../services/route.service';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';
import './CharterSchoolAuthorizingOrganizationList.css';

const CharterSchoolAuthorizingOrganizationList = (): ReactElement => {
  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--charter-schools--authorizing-organizations--list"
      isLoading={false}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4>Charter School Authorizing Organizations</h4>
            <NavigationButton
              route={appRoutePaths.NewCharterSchoolAuthorizingOrganization}
              classes="button no-wrap-text new-button"
              tooltipText="Add New Charter School Authorizing Organization"
            >
              <>Add New</>
            </NavigationButton>
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>School Year</th>
                  <th>Organization</th>
                  <th>ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </DistrictSchoolInfoPage>
  );
};

export default CharterSchoolAuthorizingOrganizationList;
