export const setItemInStorage = (key: string, value: string): void =>
  sessionStorage.setItem(key, value);

export const getItemInStorage = (key: string): string | null =>
  sessionStorage.getItem(key) || '';

export const clearItemInStorage = (key: string): void =>
  sessionStorage.removeItem(key);

export const clearStorage = (): void => sessionStorage.clear();
