import { ReactElement } from 'react';
import {
  EmptyGridPropTypes,
  IconType,
} from '../../types/propTypes/EmptyGridPropTypes';
import './EmptyGridContent.css';

const EmptyGridContent = ({
  iconType,
  children,
}: EmptyGridPropTypes): ReactElement => {
  let iconClass = '';

  switch (iconType) {
    case IconType.FILE:
      iconClass = 'file--icon';
      break;
  }

  return (
    <div className="inner-grid">
      <div className={`empty-grid-content ${iconClass}`}>{children}</div>
    </div>
  );
};

export default EmptyGridContent;
