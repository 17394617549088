/* eslint-disable max-len */
import { AccreditationEditListModel } from 'src/types/AccreditationEditList';
import { AccreditationModel } from '../types/AccreditationModel';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const accreditationApiPaths = {
  getEditCounts: 'accreditation/edit-count',
  getEditsCategoryListItems: (category: string) =>
    `accreditation/get-all-${category}-edits`,
};

export const AccreditationEditsServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getAccreditationEditsCount = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<AccreditationModel[]>> => {
  return executeApiRequest<AccreditationModel[]>(async () => {
    return await AccreditationEditsServiceClient(config).get(
      accreditationApiPaths.getEditCounts
    );
  });
};

export const getAccreditationEditsByCategory = async (
  category: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<AccreditationEditListModel>> => {
  return executeApiRequest<AccreditationEditListModel>(async () => {
    return await AccreditationEditsServiceClient(config).get(
      accreditationApiPaths.getEditsCategoryListItems(category)
    );
  });
};
