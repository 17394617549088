/* eslint-disable max-len */
import { ErrorMessage, Field } from 'formik';
import { ReactElement } from 'react';
import { FormTextareaPropTypes } from '../../../types/propTypes/FormTextareaPropTypes';
import {
  applyClassesForInput,
  extractMaxfromSchema,
} from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';
import './FormTextarea.css';

const FormTextarea = ({
  displayName,
  field,
  rows,
  disabled,
  classConfig,
  cypressDataId,
  validationSchema,
}: FormTextareaPropTypes): ReactElement => {
  const characterMax = extractMaxfromSchema(validationSchema, field) || -1;

  return (
    <div
      className={`field-container form-textarea-container ${
        disabled ? 'disabled' : ''
      } ${classConfig?.containerClasses || ''}`}
      data-testid={`form-textarea-${field}`}
    >
      <FormInputLabel
        field={field}
        displayName={displayName}
        validationSchema={validationSchema}
        classes={classConfig?.labelClasses || ''}
      />
      <Field
        data-testid={`form-textarea-${field}-input`}
        component="textarea"
        className={applyClassesForInput(
          field,
          `${classConfig?.inputClasses || ''} data-input`
        )}
        id={field}
        name={field}
        rows={rows}
        maxLength={characterMax}
        data-cy={cypressDataId}
        disabled={disabled}
      />
      <ErrorMessage name={field} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default FormTextarea;
