/* eslint-disable max-len */
import { ReactElement } from 'react';
import ExpandableNavigationItem from '../../../components/ExpandableNavigationItem/ExpandableNavigationItem';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import { MenuPropTypes } from '../../../types/propTypes/MenuPropTypes';

const StudentMenu = ({ setIsOpen, isOpen }: MenuPropTypes): ReactElement => {
  return (
    <li>
      <ExpandableNavigationItem
        isOpen={isOpen}
        setIsOpen={(opened: boolean) => {
          setIsOpen(opened);
        }}
        mainLink={{
          id: 'main-link-student',
          content: 'Students',
          className: 'sidebar--nav--students',
        }}
        subLinks={[
          {
            to: appRoutePaths.MSISRequest,
            content: 'MSIS ID Requests',
            visible: canUserAccessRoute(appRoutePaths.MSISRequest),
          },
          {
            to: appRoutePaths.StudentOwnership,
            content: 'Student Ownership',
            visible: canUserAccessRoute(appRoutePaths.StudentOwnership),
          },
        ]}
      />
    </li>
  );
};

export default StudentMenu;
