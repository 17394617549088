/* eslint-disable max-len */
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { MsisIdQueryFormViewModel } from '../types/MSISIdQueryFormViewModel';
import { MsisIdQueryAddModel } from '../types/MSISQueryAddModel';
import { MSISRequestDetailModel } from '../types/MSISRequestDetailModel';
import { MSISRequestListModel } from '../types/MSISRequestListModel';
import { MSISRequestMatchRequest } from '../types/MSISRequestMatchRequest';
import { StudentOwnershipConflictDetailModel } from '../types/StudentOwnershipConflictDetailModel';
import { StudentOwnershipConflictListModel } from '../types/StudentOwnershipConflictListModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const studentApiPaths = {
  getMsisIdQuery: (maxRequestAgeDays: number) =>
    `msisidquery?maxRequestAgeDays=${maxRequestAgeDays}`,
  create: 'msisidquery',
  getForm: 'msisidquery/form',
  matchStudent: (queryId: string) => `msisidquery/match/${queryId}`,
  deleteRequest: (id: string) => `/msisidquery/${id}`,
  getStudentById: (id: string) => `/msisidquery/${id}`,
  getStudentOwnershipConflictList: `student/owner-conflict`,
  getStudentOwnershipConflictDetail: (id: string) =>
    `student/owner-conflict/${id}`,
};

export const StudentServiceClient = (config?: DataRequestConfig): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getMSISRequestList = async (
  maxRequestAgeDays: number,
  config?: DataRequestConfig
): Promise<ApiResponseModel<MSISRequestListModel>> => {
  return executeApiRequest<MSISRequestListModel>(async () => {
    return await StudentServiceClient(config).get(
      studentApiPaths.getMsisIdQuery(maxRequestAgeDays)
    );
  });
};

export const getStudentDetails = async (
  requestId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<MSISRequestDetailModel>> => {
  return executeApiRequest<MSISRequestDetailModel>(async () => {
    return await StudentServiceClient(config).get(
      studentApiPaths.getStudentById(requestId)
    );
  });
};

export const matchStudent = async (
  match: MSISRequestMatchRequest,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest(async () => {
    return await StudentServiceClient(config).put(
      studentApiPaths.matchStudent(match.queryId),
      { selectedMsisId: match.selectedMsisId }
    );
  });
};

export const getStudentOwnershipConflictList = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<StudentOwnershipConflictListModel>> => {
  return executeApiRequest(async () => {
    return await StudentServiceClient(config).get(
      studentApiPaths.getStudentOwnershipConflictList
    );
  });
};

export const getStudentOwnershipConflictDetail = async (
  requestId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<StudentOwnershipConflictDetailModel>> => {
  return executeApiRequest(async () => {
    return await StudentServiceClient(config).get(
      studentApiPaths.getStudentOwnershipConflictDetail(requestId)
    );
  });
};

export const getMsisIdRequestFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<MsisIdQueryFormViewModel>> => {
  return executeApiRequest<MsisIdQueryFormViewModel>(async () => {
    return await StudentServiceClient(config).get(studentApiPaths.getForm);
  });
};

export const createMsisIdRequest = async (
  request: MsisIdQueryAddModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest(async () => {
    return await StudentServiceClient(config).post(
      studentApiPaths.create,
      request
    );
  });
};

export const deleteMsisIdRequest = async (
  requestId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest(async () => {
    return await StudentServiceClient(config).delete(
      studentApiPaths.deleteRequest(requestId)
    );
  });
};
