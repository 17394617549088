import { TokenRequestModel } from '../generated';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { AuthorizeUriModel } from '../types/AuthorizeUriModel';
import { AuthTokenRefreshRequestModel } from '../types/AuthTokenRefreshRequestModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { GetPowerBITokenResponseModel } from '../types/GetPowerBITokenResponseModel';
import { GetTokenResponseModel } from '../types/GetTokenResponseModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import {
  getAnonymousHttpClient,
  getHttpClient,
  HttpClient,
} from './httpClient';

export const authApiPaths = {
  getAuthorizationUri: 'authorizeUri',
  getToken: 'token',
  getTokenRefresh: 'refresh-token',
  getPowerBIToken: 'powerBIToken',
};

const AuthServiceClient = (config?: DataRequestConfig): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

const AuthServiceClientAnonymous = (config?: DataRequestConfig): HttpClient =>
  getAnonymousHttpClient(getApiBaseUrl(), config?.signal);

export const getAuthorizeUri = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<AuthorizeUriModel>> => {
  return executeApiRequest<AuthorizeUriModel>(async () => {
    return await AuthServiceClientAnonymous(config).get(
      authApiPaths.getAuthorizationUri
    );
  });
};

export const getTokenFromCode = async (
  body: TokenRequestModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<GetTokenResponseModel>> => {
  return executeApiRequest<GetTokenResponseModel>(async () => {
    return await AuthServiceClientAnonymous(config).post(
      authApiPaths.getToken,
      body
    );
  });
};

export const getAppRefreshToken = async (
  body: AuthTokenRefreshRequestModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<GetTokenResponseModel>> => {
  return executeApiRequest<GetTokenResponseModel>(async () => {
    return await AuthServiceClient(config).post(
      authApiPaths.getTokenRefresh,
      body
    );
  });
};

export const getPowerBIToken = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<GetPowerBITokenResponseModel>> => {
  return executeApiRequest<GetPowerBITokenResponseModel>(async () => {
    return await AuthServiceClient(config).get(authApiPaths.getPowerBIToken);
  });
};
