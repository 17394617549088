import { DistrictModelExtended } from './DistrictModel';
import { SelectOption } from './SelectOption';

export enum NPSESchoolType {
  CTE = 'CTE',
  SPED = 'SPED',
}

export enum OutcomeType {
  EarlyChildhoodValues = 'EarlyChildhood',
  SchoolAgeValues = 'SchoolAge',
}

export interface IdeaEducationalEnvironmentModel {
  id: string;
  name: string;
  outcomeType: OutcomeType;
}

export interface NonPublicStudentFormViewModel {
  defaultSchoolYear: string;
  districts: DistrictModelExtended[];
  ideaEducationalEnvironments: IdeaEducationalEnvironmentModel[];

  schoolTypeOptions: SelectOption[];
  sexOptions: SelectOption[];
  hispanicOrLatinoEthnicityOptions: SelectOption[];
  raceOptions: SelectOption[];
  specialEducationOptions: SelectOption[];
  englishLearnerStatusOptions: SelectOption[];
  enrollmentStatusOptions: SelectOption[];
  gradeLevelOptions: SelectOption[];
  singleParentOrSinglePregnantWomanStatusOptions: SelectOption[];
  cteaeDisplacedHomemakerIndicatorOptions: SelectOption[];
  perkinsPostProgramPlacementIndicatorOptions: SelectOption[];
  significantCognitiveDisabilityIndicatorOptions: SelectOption[];
  primaryDisabilityTypeOptions: SelectOption[];
  secondaryDisabilityTypeOptions: SelectOption[];
  relatedServicesOptions: SelectOption[];
  projectedHSDiplomaTypeOptions: SelectOption[];
  outcomeExitScoreOptions: SelectOption[];
  outcomeEntryScoreOptions: SelectOption[];
  outcomeProgressOptions: SelectOption[];
}
