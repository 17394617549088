import { ReactElement } from 'react';
// eslint-disable-next-line max-len
import { ReadyToUploadPropTypes } from '../../../types/propTypes/ReadyToUploadPropTypes';
import './ReadyToUpload.css';

const ReadyToUpload = ({
  files,
  onChange,
}: ReadyToUploadPropTypes): ReactElement => {
  return (
    <div className="files--upload--ready-to-upload">
      <h3>Ready to Upload</h3>
      <ul>
        {files.map((file) => (
          <li key={file.name}>
            <span>{file.name}</span>
            <button
              data-cy="remove-file-button"
              onClick={() =>
                onChange(files.filter((f) => f.name !== file.name))
              }
            >
              Remove File
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReadyToUpload;
