import { ReactElement } from 'react';
import { LoaderPropTypes } from '../../types/propTypes/LoaderPropTypes';
import './Loader.css';

const Loader = ({ dataTestId, message }: LoaderPropTypes): ReactElement => {
  return (
    <div data-testid={dataTestId || 'loader'} className="progress">
      <progress className="pure-material-progress-linear" />
      <label className="progress-text">{message}</label>
    </div>
  );
};

export default Loader;
