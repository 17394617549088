/* eslint-disable max-len */
import {
  convertObjectArrayToCSVString,
  prepareDataForDownload,
} from '../services/download.service';
import { ExportFieldOption } from '../types/ExportFieldOption';
import { FILE_EXPORT_TYPE } from '../types/FileExportType';
import { FileSegment } from '../types/FileSegment';
import { FormDropdownSelectOption } from '../types/propTypes/FormDropdownPropTypes';
import { ReactSelectOption } from '../types/ReactSelectOption';
import { ReportListModel } from '../types/ReportListModel';
import { SelectOption } from '../types/SelectOption';
import { StudentOwnershipConflictDetailModel } from '../types/StudentOwnershipConflictDetailModel';

export const getSelectOptionsFromObjectList = <T>(
  valuePropName: string,
  textPropName: string,
  objectList?: T[]
): SelectOption[] => {
  const list: SelectOption[] = [];
  objectList &&
    objectList.map((item) =>
      list.push({
        value: (item as Record<string, unknown>)[valuePropName] as string,
        text: (item as Record<string, unknown>)[textPropName] as string,
      })
    );
  return list;
};

export const formatRoutePath = (path: string): string =>
  path.startsWith('/') ? path : `/${path}`;

export const getSelectOptionsFromStringList = (
  stringList?: string[]
): SelectOption[] => {
  const list: SelectOption[] = [];
  stringList &&
    stringList.map((item) =>
      list.push({
        value: item,
        text: item,
      })
    );
  return list;
};

export const spawnFileDownload = (
  data: unknown[],
  fieldList: ExportFieldOption[],
  fileName: string,
  fileType: FILE_EXPORT_TYPE
): void => {
  if (data && data.length > 0) {
    switch (fileType) {
      case FILE_EXPORT_TYPE.CSV:
        const cvsData = convertObjectArrayToCSVString(data, fieldList);
        const downloadFileName = `${fileName}.csv`;

        prepareDataForDownload(cvsData, downloadFileName, fileType);
        break;
    }
  }
};

export const parseFilename = (filename: string): FileSegment => {
  const segments = filename.split(';');

  if (segments.length != 6) {
    throw new Error('Invalid Azure Filename.');
  }

  return {
    clientId: segments[0],
    blobId: segments[1],
    fieldId: segments[2],
    filename: segments[5],
  };
};

export const sanitizeText = (text: string): string => {
  let sanitizedText = text.toLowerCase();

  // Replace spaces with hyphens
  sanitizedText = sanitizedText.replace(/ /g, '-');

  // Replace quotes/apostrope with blank space
  sanitizedText = sanitizedText.replace(/'/g, '');

  return sanitizedText;
};

export const setStyledDropdownSingleValue = (
  options: SelectOption[],
  selectedValue?: string
): FormDropdownSelectOption => {
  const getSelectOptions = (): ReactSelectOption[] =>
    options.map((x) => ({
      value: x.value,
      label: x.text,
      isDisabled: x.isDisabled,
    }));

  return getSelectOptions().find((rso) => rso.value == selectedValue) ?? null;
};

export const highlightForStudentConflict = (
  conflictDetail: StudentOwnershipConflictDetailModel
): StudentOwnershipConflictDetailModel => {
  const detailItem1 = conflictDetail.studentOwnershipConflictDetailList[0];
  const detailItem2 = conflictDetail.studentOwnershipConflictDetailList[1];

  const item1EntryDate = new Date(detailItem1.claimingLEAEntryDate);
  const item1ExitDateExists =
    (Date.parse(detailItem1.claimingLEAExitDate) || 0) > 0;
  const item1ExitDate = new Date(detailItem1.claimingLEAExitDate);

  const item2EntryDate = new Date(detailItem2.claimingLEAEntryDate);
  const item2ExitDateExists =
    (Date.parse(detailItem2.claimingLEAExitDate) || 0) > 0;
  const item2ExitDate = new Date(detailItem2.claimingLEAExitDate);

  if (!item1ExitDateExists && !item2ExitDateExists) {
    conflictDetail.studentOwnershipConflictDetailList[0].highlightEntryDate =
      false;
    conflictDetail.studentOwnershipConflictDetailList[0].highlightExitDate =
      true;

    conflictDetail.studentOwnershipConflictDetailList[1].highlightEntryDate =
      false;
    conflictDetail.studentOwnershipConflictDetailList[1].highlightExitDate =
      true;
  } else if (
    item1ExitDate > item2EntryDate ||
    (item1ExitDateExists && !detailItem2.claimingLEAEntryDate)
  ) {
    conflictDetail.studentOwnershipConflictDetailList[0].highlightEntryDate =
      false;
    conflictDetail.studentOwnershipConflictDetailList[0].highlightExitDate =
      true;

    conflictDetail.studentOwnershipConflictDetailList[1].highlightEntryDate =
      true;
    conflictDetail.studentOwnershipConflictDetailList[1].highlightExitDate =
      false;
  } else if (
    item2ExitDate > item1EntryDate ||
    (item2ExitDateExists && !detailItem1.claimingLEAEntryDate)
  ) {
    conflictDetail.studentOwnershipConflictDetailList[0].highlightEntryDate =
      true;
    conflictDetail.studentOwnershipConflictDetailList[0].highlightExitDate =
      false;

    conflictDetail.studentOwnershipConflictDetailList[1].highlightEntryDate =
      false;
    conflictDetail.studentOwnershipConflictDetailList[1].highlightExitDate =
      true;
  } else {
    conflictDetail.studentOwnershipConflictDetailList[0].highlightEntryDate =
      false;
    conflictDetail.studentOwnershipConflictDetailList[0].highlightExitDate =
      false;

    conflictDetail.studentOwnershipConflictDetailList[1].highlightEntryDate =
      false;
    conflictDetail.studentOwnershipConflictDetailList[1].highlightExitDate =
      false;
  }
  return conflictDetail;
};

export const isEmptyObject = (obj: Object): boolean =>
  Object.keys(obj).length === 0;

export const getReportEmbedUrlFromId = (
  list?: ReportListModel,
  id?: string
): string => {
  let url = '';

  list &&
    id &&
    list.categories.map((cat) => {
      cat.subcategories.map((sub) => {
        const result = sub.reports.find((r) => r.reportId === id);
        if (result) {
          url = result.embedUrl;
        }
      });
    });

  return url;
};

export const getMatchedFieldName = (apiFieldName: string): string => {
  const matchedFieldNames = [
    'firstName',
    'middleName',
    'lastName',
    'ssn',
    'dateOfBirth',
  ];

  let propName = '';
  matchedFieldNames.map((p) => {
    if (apiFieldName.toLowerCase() === p.toLowerCase()) {
      propName = p;
    }
  });

  return propName;
};
