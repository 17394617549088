/* eslint-disable max-len */
import { ReactElement } from 'react';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import Panel from '../../../../components/Panel/Panel';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearSeaOrganization,
  saveSeaOrganization,
  selectSeaOrganizationDataEntryMode,
  selectSeaOrganizationFormViewModel,
  selectSeaOrganizationLoadingFormViewModel,
  selectSeaOrganizationLoadingSeaOrganization,
  selectSeaOrganizationSeaOrganization,
  setSeaOrganizationDataEntryMode,
} from '../../../../redux/slices/seaOrganizationSlice';
import {
  fetchSeaOrganizationById,
  fetchSeaOrganizationFormViewModel,
} from '../../../../redux/thunks/seaOrganizationThunks';
import { appRoutePaths } from '../../../../services/route.service';
import {
  createSeaOrganization,
  updateSeaOrganization,
} from '../../../../services/seaOrganization.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_MDE_OTSS } from '../../../../types/NavigationTabs';
import { PanelType } from '../../../../types/PanelType';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { SeaOrganizationFormViewModel } from '../../../../types/SeaOrganizationFormViewModel';
import { SeaOrganizationModel } from '../../../../types/SeaOrganizationModel';
import { SeaOrganizationValidationSchema } from '../../../../types/Validation/SeaOrganizationValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import './SeaOrganizationInput.css';

const SeaOrganizationInput = (): ReactElement => {
  const seaOrganizationModel: SeaOrganizationModel = useAppSelector(
    selectSeaOrganizationSeaOrganization
  );
  const formViewModel: SeaOrganizationFormViewModel = useAppSelector(
    selectSeaOrganizationFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectSeaOrganizationLoadingFormViewModel
  );
  const isLoadingSeaOrganizationModel = useAppSelector(
    selectSeaOrganizationLoadingSeaOrganization
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectSeaOrganizationDataEntryMode
  );
  const isLoading = isLoadingSeaOrganizationModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
      howToFix:
        'The provided SEA Organization ID is already in the system. Use another value, or return to the list and edit the existing SEA Organization.',
    },
  ];

  return (
    <ManualInputPage<SeaOrganizationModel, SeaOrganizationFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'sea-organization-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_MDE_OTSS)}
      classes={{ pageClass: 'data-input--sea-organizations--input' }}
      parameterOptions={{
        routeParameterName: 'seaOrganizationId',
        identityName: seaOrganizationModel.stateAgencyIdentifier,
      }}
      routes={{
        backBarRoute: appRoutePaths.SeaOrganizationList,
        cancelRoute: appRoutePaths.SeaOrganizationList,
        createSuccessRoute: appRoutePaths.SeaOrganizationInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchSeaOrganizationById,
        loadFormDetail: fetchSeaOrganizationFormViewModel,
        setEntryMode: setSeaOrganizationDataEntryMode,
        setData: saveSeaOrganization,
        clearData: clearSeaOrganization,
        onCreate: createSeaOrganization,
        onUpdate: updateSeaOrganization,
      }}
      formDetails={{
        validationSchema: SeaOrganizationValidationSchema,
        initialFormValues: seaOrganizationModel,
        isFormModelEmpty: !formViewModel.schoolYearOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'sea organization',
        pageHeader: 'MDE/OTSS',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="sea-organization-input-child">
        <Panel panelType={PanelType.INFO} heading="General Information">
          <div className="field-row">
            <FormTextbox
              displayName="State Agency Identifier"
              field="stateAgencyIdentifier"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="State Agency Identification System"
              field="stateAgencyIdentificationSystem"
              disabled={true}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="Organization Name"
              field="organizationName"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Organization Type"
              field="organizationType"
              disabled={true}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="Mailing Address">
          <div className="field-row">
            <FormTextbox
              displayName="Street Number and Name"
              field="mailingAddressStreetNumberAndName"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Apartment Room or Suite Number"
              field="mailingAddressApartmentRoomOrSuiteNumber"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="City"
              field="mailingAddressCity"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormDropdown
              displayName="State Abbreviation"
              field="mailingStateAbbreviation"
              options={formViewModel.mailingStateAbbreviationOptions}
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Postal Code"
              field="mailingAddressPostalCode"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="Physical Address">
          <div className="field-row">
            <FormTextbox
              displayName="Street Number and Name"
              field="physicalAddressStreetNumberAndName"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Apartment Room or Suite Number"
              field="physicalAddressApartmentRoomOrSuiteNumber"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="City"
              field="physicalAddressCity"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormDropdown
              displayName="State Abbreviation"
              field="physicalStateAbbreviation"
              options={formViewModel.physicalStateAbbreviationOptions}
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Postal Code"
              field="physicalAddressPostalCode"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="Other Information">
          <div className="field-row">
            <FormTextbox
              displayName="State ANSI Code"
              field="stateANSICode"
              disabled={true}
            />
            <FormTextbox
              displayName="Position Title"
              field="positionTitle"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Web Site Address"
              field="webSiteAddress"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="First Name"
              field="firstName"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Middle Name"
              field="middleName"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Last or Surname"
              field="lastOrSurname"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="Generation Code or Suffix"
              field="generationCodeOrSuffix"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Personal Title or Prefix"
              field="personalTitleOrPrefix"
              validationSchema={SeaOrganizationValidationSchema}
            />
            <FormTextbox
              displayName="Electronic Mail Address"
              field="electronicMailAddress"
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="Electronic Mail Address Type"
              field="electronicMailAddressType"
              disabled={true}
            />
            <FormDropdown
              displayName="School Year"
              field="schoolYear"
              options={formViewModel.schoolYearOptions}
              validationSchema={SeaOrganizationValidationSchema}
            />
          </div>
        </Panel>
      </div>
    </ManualInputPage>
  );
};

export default SeaOrganizationInput;
