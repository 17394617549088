import { ReactElement } from 'react';
import NavigationButton from '../../../components/NavigationButton/NavigationButton';
import { DashboardCardPropTypes } from '../../../types/propTypes/DashboardCardPropTypes';

const DashboardCard = ({
  message,
  messageClassNames,
  cardClassNames,
  dataState,
  buttonText,
  buttonLink,
  buttonVisible,
  buttonClassNames,
  tooltipText,
}: DashboardCardPropTypes): ReactElement => (
  <section
    className={cardClassNames}
    data-state={dataState}
    data-testid="dashboard-card-container"
  >
    <div>
      <div
        className={messageClassNames}
        data-testid="dashboard-card-message-container"
      >
        {message}
      </div>
      {buttonLink && (
        <div>
          <NavigationButton
            route={buttonLink}
            classes={buttonClassNames}
            visibleOverride={buttonVisible}
            tooltipText={tooltipText}
          >
            <>{buttonText}</>
          </NavigationButton>
        </div>
      )}
    </div>
  </section>
);

export default DashboardCard;
