/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import ReportTabs from '../../components/ReportTabs/ReportTabs';
import { useAuthorizationContext } from '../../hooks/useAuthorizationContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearReportError,
  selectReportError,
  selectReportList,
  selectReportLoading,
  setReportList,
} from '../../redux/slices/reportSlice';
import { fetchTokenAndReportList } from '../../redux/thunks/reportThunks';
import {
  abortPromiseOnUnmount,
  getReportList,
  getReportToken,
} from '../../services/base.service';
import { hasReportDataAndNotEmpty } from '../../services/token.service';
import {
  debugReportList,
  GLOBAL_DEBUG_MODE,
} from '../../testing/debugFunctions';
import './ReportDashboard.css';

const ReportDashboard = (): ReactElement => {
  const dispatch = useAppDispatch();

  const reportData = useAppSelector(selectReportList);
  const isLoading = useAppSelector(selectReportLoading);
  const reportError = useAppSelector(selectReportError);

  const { reportTokenStart } = useAuthorizationContext();

  useEffect(() => {
    let promise: Promise<unknown> = Promise.resolve(undefined);
    /* istanbul ignore next */
    if (!GLOBAL_DEBUG_MODE && !reportError) {
      if (!hasReportDataAndNotEmpty()) {
        promise = dispatch(fetchTokenAndReportList());
      }
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, reportError]);

  useEffect(() => {
    // If there is a token, make sure to signal
    // the timer to start
    if (getReportToken()) {
      reportTokenStart();
    }
  }, [reportTokenStart, isLoading]);

  // Clear report error when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearReportError());
    };
  }, [dispatch]);

  // On full refresh, state is cleared and so we are
  // grabbing it from the session and then load that back into state
  useEffect(() => {
    /* istanbul ignore next */
    if (GLOBAL_DEBUG_MODE && reportData.categories.length === 0) {
      /* istanbul ignore next */
      dispatch(setReportList(debugReportList()));
    } else {
      const list = getReportList();
      if (
        list &&
        list?.categories.length > 0 &&
        reportData.categories.length !== getReportList()?.categories.length
      ) {
        dispatch(setReportList(list));
      }
    }
  }, [dispatch, reportData]);

  return (
    <NavigationPage
      heading="Data Analytics"
      pageClass="reports--view"
      isLoading={isLoading}
      loadingDataId="reports-loader"
      loadingText="Getting your reports"
      APIError={reportError}
    >
      {!reportError ? (
        <div>{reportData && <ReportTabs reportList={reportData} />}</div>
      ) : (
        <></>
      )}
    </NavigationPage>
  );
};

export default ReportDashboard;
