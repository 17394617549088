/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { CharterSchoolAssignmentNewViewModel } from '../../types/CharterSchoolAssignmentNewViewModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import { fetchCharterSchoolAssignmentNewViewModel } from '../thunks/charterSchoolThunks';

interface CharterSchoolAssignmentState {
  loading: boolean;
  newViewModel: CharterSchoolAssignmentNewViewModel;
}

export const defaultViewModelState: CharterSchoolAssignmentNewViewModel = {
  districtOptions: [],
};

export const initialState: CharterSchoolAssignmentState = {
  loading: false,
  newViewModel: defaultViewModelState,
};

export const charterSchoolAssignmentSlice = createSlice({
  name: 'charterSchoolAssignment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCharterSchoolAssignmentNewViewModel.pending,
      (state) => {
        state.newViewModel = defaultViewModelState;
        state.loading = true;
      }
    );
    builder.addCase(
      fetchCharterSchoolAssignmentNewViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.newViewModel = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchCharterSchoolAssignmentNewViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loading = false;
        }
      }
    );
  },
});

export const selectCharterSchoolAssignmentLoading = (
  state: RootState
): boolean => state.charterSchoolAssignment.loading;
export const selectNewCharterSchoolAssignmentViewModel = (
  state: RootState
): CharterSchoolAssignmentNewViewModel =>
  state.charterSchoolAssignment.newViewModel;

export default charterSchoolAssignmentSlice.reducer;
