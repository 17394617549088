import { ReactElement, ReactNode } from 'react';
import { FeatureAccessPermissions } from '../../types/FeatureAccessPermissions';
import { PanelType } from '../../types/PanelType';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { ReportTabsPropTypes } from '../../types/propTypes/ReportTabsPropTypes';
import { ReportEmbedData } from '../../types/ReportEmbedData';
import {
  ReportCategoryModel,
  ReportSubcategoryModel,
} from '../../types/ReportListModel';
import { hasSpecifiedFeatureAccess } from '../../utilities/userUtilities';
import CertificationPanel from '../CertificationPanel/CertificationPanel';
import EmptyGridContent from '../EmptyGridContent/EmptyGridContent';
import Panel from '../Panel/Panel';
import ReportNavigationButton from '../ReportNavigationButton/ReportNavigationButton';
import TabContainer from '../TabContainer/TabContainer';
import './ReportTabs.css';

const ReportTabs = ({ reportList }: ReportTabsPropTypes): ReactElement => {
  const CERT_TAB_NAME = 'Certification Reports';
  const noReports = reportList.categories.length === 0;
  const isCertificationTabVisible = hasSpecifiedFeatureAccess(
    FeatureAccessPermissions.CERTIFICATION_ACCESS
  );

  const getTabList = (): string[] => {
    const returnedList = reportList.categories.map((category) => category.name);

    if (isCertificationTabVisible) {
      returnedList.push(CERT_TAB_NAME);
    }

    return returnedList;
  };

  const hasSingleUnnamedSubcategory = (cat: ReportCategoryModel): boolean =>
    cat.subcategories.length === 1 && cat.subcategories[0].name === '';

  const getReportNavigationButtons = (
    reports: ReportEmbedData[]
  ): ReactElement => {
    return (
      <>
        {reports.map((report) => (
          <ReportNavigationButton report={report} key={report.reportId} />
        ))}
      </>
    );
  };

  const getSubCategoryPanels = (
    subCategories: ReportSubcategoryModel[]
  ): ReactElement => {
    return (
      <div className="report-subcategory-container">
        {subCategories.map((sub) => (
          <Panel key={sub.name} panelType={PanelType.INFO} heading={sub.name}>
            {getReportNavigationButtons(sub.reports)}
          </Panel>
        ))}
      </div>
    );
  };

  const buildCategoriesDisplay = (): ReactNode[] => {
    const fullTabComponents = reportList.categories.map((cat) => {
      return (
        <div
          key={`category-${cat.name}`}
          data-testid={`category-${cat.name}-tab-container`}
          className="report-category-container"
        >
          {hasSingleUnnamedSubcategory(cat) ? (
            <div className="report-no-subcat-container">
              {getReportNavigationButtons(cat.subcategories[0].reports)}
            </div>
          ) : (
            getSubCategoryPanels(cat.subcategories)
          )}
        </div>
      );
    });

    if (isCertificationTabVisible) {
      fullTabComponents.push(
        <div key="category-certification">
          <CertificationPanel />
        </div>
      );
    }
    return fullTabComponents;
  };

  return (
    <>
      {!noReports && (
        <TabContainer tabNames={getTabList()}>
          {buildCategoriesDisplay()}
        </TabContainer>
      )}
      {noReports && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>You currently do not have access to any reports</p>
        </EmptyGridContent>
      )}
    </>
  );
};

export default ReportTabs;
