/* istanbul ignore file */
import { ReactElement } from 'react';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { getAuthToken } from '../../services/base.service';
import './ShowToken.css';

const ShowToken = (): ReactElement => {
  const token = getAuthToken() || '';

  const handleCopy = async (text: string): Promise<void> => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
  };

  return (
    <NavigationPage heading="My Token" pageClass="show-token" isLoading={false}>
      <textarea
        className="page--token"
        id="token"
        name="token"
        rows={1}
        cols={50}
        readOnly
        value={token}
      />
      <br />
      <button onClick={() => handleCopy(token)}>Copy To Clipboard</button>
    </NavigationPage>
  );
};

export default ShowToken;
