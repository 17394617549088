import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { mapToFormModel } from '../../mappers/UserDetailsMapper';
import { UserDetailsFormModel } from '../../types/UserDetailsViewModel';
import { UserEditViewModel } from '../../types/UserEditViewModel';
import { UserListItem } from '../../types/UserListModel';
import { UserStatus } from '../../types/UserStatus';
import { UserType } from '../../types/UserType';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchUserDetailsViewModelById,
  fetchUserDetailsViewModelByIdAndDistrictId,
  fetchUserEditViewModelById,
  fetchUserEditViewModelByIdAndDistrictId,
  fetchUserList,
} from '../thunks/userThunks';

interface UserState {
  loading: boolean;
  detailLoading: boolean;
  users: UserListItem[];
  userDetail: UserDetailsFormModel;
  editUser: UserEditViewModel;
}

export const defaultUserEditViewModel: UserEditViewModel = {
  user: {
    name: '',
    email: '',
    userType: UserType.District,
    isAdmin: false,
    currentDistrict: undefined,
    statewideDataDomainPermissions: [],
    applicationRole: '',
    origApplicationRole: '',
    isMultiDistrictUser: false,
    editingDistrictId: '',
    assignedDistricts: [],
    permissionsByDataDomainForEditingDistrict: {},
  },
  assignableStatewideDataDomains: [],
  assignableStatewideRoles: [],
  multiDistrictOptions: [],
  assignableSchools: [],
  assignableDataDomainPermissions: [],
};

export const defaultUserDetailFormModel: UserDetailsFormModel = {
  userId: '',
  name: '',
  email: '',
  userType: UserType.District,
  userStatus: UserStatus.InActive,
  currentDistrict: undefined,
  viewingDistrictId: '',
  assignedDistricts: [],
  isMultiDistrictUser: false,
  multiDistrictOptions: [],
  statewideDataDomainPermissions: [],
  applicationRole: '',
  dataDomainPermissionsForViewingDistrict: [],
};

export const initialState: UserState = {
  loading: false,
  detailLoading: false,
  users: [],
  userDetail: defaultUserDetailFormModel,
  editUser: defaultUserEditViewModel,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserState: () => initialState,
    setApplicationRole: (state, action: PayloadAction<string>) => {
      state.editUser.user.applicationRole = action.payload;
    },
    setOrigApplicationRole: (state, action: PayloadAction<string>) => {
      state.editUser.user.origApplicationRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    //fetchUserList
    builder.addCase(fetchUserList.pending, (state) => {
      state.users = [];
      state.loading = true;
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.users = action.payload.users;
      }
      state.loading = false;
    });
    builder.addCase(fetchUserList.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });

    //fetchUserDetailsViewModelById
    builder.addCase(fetchUserDetailsViewModelById.pending, (state) => {
      state.userDetail = defaultUserDetailFormModel;
      state.loading = true;
    });
    builder.addCase(
      fetchUserDetailsViewModelById.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.userDetail = mapToFormModel(action.payload);
        }
        state.loading = false;
      }
    );
    builder.addCase(fetchUserDetailsViewModelById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });

    //fetchUserEditViewModelById
    builder.addCase(fetchUserEditViewModelById.pending, (state) => {
      state.editUser = defaultUserEditViewModel;
      state.loading = true;
    });
    builder.addCase(fetchUserEditViewModelById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        merge(state.editUser, action.payload);
      }
      state.loading = false;
    });
    builder.addCase(fetchUserEditViewModelById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });

    //fetchUserEditViewModelByIdAndDistrictId
    builder.addCase(
      fetchUserEditViewModelByIdAndDistrictId.pending,
      (state) => {
        state.detailLoading = true;
      }
    );
    builder.addCase(
      fetchUserEditViewModelByIdAndDistrictId.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.editUser = action.payload;
        }
        state.detailLoading = false;
      }
    );
    builder.addCase(
      fetchUserEditViewModelByIdAndDistrictId.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.detailLoading = false;
        }
      }
    );

    //fetchUserDetailsViewModelByIdAndDistrictId
    builder.addCase(
      fetchUserDetailsViewModelByIdAndDistrictId.pending,
      (state) => {
        state.detailLoading = true;
      }
    );
    builder.addCase(
      fetchUserDetailsViewModelByIdAndDistrictId.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.userDetail = mapToFormModel(action.payload);
        }
        state.detailLoading = false;
      }
    );
    builder.addCase(
      fetchUserDetailsViewModelByIdAndDistrictId.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.detailLoading = false;
        }
      }
    );
  },
});

export const { clearUserState, setApplicationRole, setOrigApplicationRole } =
  userSlice.actions;

export const selectUserLoading = (state: RootState): boolean =>
  state.user.loading;
export const selectUserDetailLoading = (state: RootState): boolean =>
  state.user.detailLoading;
export const selectUserList = (state: RootState): UserListItem[] =>
  state.user.users;
export const selectUserDetailsFormModel = (
  state: RootState
): UserDetailsFormModel => state.user.userDetail;
export const selectUserEditViewModel = (state: RootState): UserEditViewModel =>
  state.user.editUser;

export default userSlice.reducer;
