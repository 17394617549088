import { ReactNode } from 'react';

export enum IconType {
  FILE = 'file',
}

export interface EmptyGridPropTypes {
  iconType: IconType;
  children: ReactNode;
}
