/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentFormViewModel } from '../../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../../types/PanelType';
import Panel from '../../../Panel/Panel';
import './NPSE_EarlyChildhoodOutcomesPanel.css';
import NPSE_OutcomePanel from './NPSE_OutcomePanel';

const NPSE_EarlyChildhoodOutcomesPanel = (): ReactElement => {
  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  return (
    <Panel panelType={PanelType.INFO} heading="Early Childhood Outcomes">
      <div className="field-row field-row-early-outcomes">
        <NPSE_OutcomePanel
          label="Outcome A"
          fields={{
            entryScore: 'outcomeAEntryScore',
            entryScoreDate: 'outcomeAEntryScoreDate',
            exitScore: 'outcomeAExitScore',
            exitScoreDate: 'outcomeAExitScoreDate',
            progress: 'outcomeAProgress',
          }}
          entryScoreOptions={formViewModel.outcomeEntryScoreOptions}
          exitScoreOptions={formViewModel.outcomeExitScoreOptions}
          progressOptions={formViewModel.outcomeProgressOptions}
        />
        <NPSE_OutcomePanel
          label="Outcome B"
          fields={{
            entryScore: 'outcomeBEntryScore',
            entryScoreDate: 'outcomeBEntryScoreDate',
            exitScore: 'outcomeBExitScore',
            exitScoreDate: 'outcomeBExitScoreDate',
            progress: 'outcomeBProgress',
          }}
          entryScoreOptions={formViewModel.outcomeEntryScoreOptions}
          exitScoreOptions={formViewModel.outcomeExitScoreOptions}
          progressOptions={formViewModel.outcomeProgressOptions}
        />
        <NPSE_OutcomePanel
          label="Outcome C"
          fields={{
            entryScore: 'outcomeCEntryScore',
            entryScoreDate: 'outcomeCEntryScoreDate',
            exitScore: 'outcomeCExitScore',
            exitScoreDate: 'outcomeCExitScoreDate',
            progress: 'outcomeCProgress',
          }}
          entryScoreOptions={formViewModel.outcomeEntryScoreOptions}
          exitScoreOptions={formViewModel.outcomeExitScoreOptions}
          progressOptions={formViewModel.outcomeProgressOptions}
        />
      </div>
    </Panel>
  );
};

export default NPSE_EarlyChildhoodOutcomesPanel;
