import { faker } from '@faker-js/faker';
import { ReportListModel } from '../types/ReportListModel';
import { getRandomReportEmbedData } from './fakers/reportHelpers';

export const GLOBAL_DEBUG_MODE = false;

export const LogMessageWithObject = (message: string, obj: Object): void => {
  console.log(`${message}:`, JSON.stringify(obj, null, 2));
};

export const MessageDebugLog = (message: string): void => {
  GLOBAL_DEBUG_MODE &&
    console.log(`${new Date().toLocaleString()}: ${message}`);
};

export const MessageWithObjectDebugLog = (
  message: string,
  obj?: Object
): void => {
  GLOBAL_DEBUG_MODE &&
    console.log(`${new Date().toLocaleString()}: ${message}`, obj);
};

export const ObjectDebugLog = (obj: Object): void => {
  GLOBAL_DEBUG_MODE && console.log(`${new Date().toLocaleString()}: `, obj);
};

export const debugReportList = (): ReportListModel => {
  return {
    categories: [
      {
        name: faker.animal.dog(),
        subcategories: [
          {
            name: `${faker.color.human().toLocaleUpperCase()} Panel`,
            reports: [
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
            ],
          },
        ],
      },
      {
        name: faker.animal.dog(),
        subcategories: [
          {
            name: `${faker.color.human().toLocaleUpperCase()} Panel`,
            reports: [
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
            ],
          },
          {
            name: `${faker.color.human().toLocaleUpperCase()} Panel`,
            reports: [
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
              getRandomReportEmbedData({
                reportName: faker.airline.airline().name,
              }),
            ],
          },
        ],
      },
    ],
  };
};
