import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { FilePermissionMap } from '../types/FilePermissionMap';
import {
  extractIntegerEnvVar,
  extractStringEnvVar,
} from '../utilities/typeUtilities';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

// TODO: Move these to the FileUploadFormModel; already done for allowedFileEndings

export const fileApiPaths = {
  getPermissionsMap: 'file/permissionMap',
};

export const FileUploadServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getAllowedFileUploadExtensions = (): string[] => {
  const extensions = extractStringEnvVar(
    'REACT_APP_FILEUPLOAD__ALLOWABLEFILEEXTENSIONS'
  );
  return extensions.split(',');
};

export const getMaxFileUploadSizeMB = (): number => {
  return extractIntegerEnvVar('REACT_APP_FILEUPLOAD__MAXFILESIZEMB');
};

export const getMaxUploadFileCount = (): number => {
  return extractIntegerEnvVar('REACT_APP_FILEUPLOAD__MAXFILECOUNT');
};

export const getMaxUploadFilenameLength = (): number => {
  return extractIntegerEnvVar('REACT_APP_FILEUPLOAD__MAXFILENAMELENGTH');
};

export const getAdditionalTextOptions = (): string[] => {
  return [
    'May add date, school, or other helpful information',
    'Alphanumeric characters or underscores only',
  ];
};

export const getFilePermissionsMap = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<FilePermissionMap>> => {
  return executeApiRequest<FilePermissionMap>(async () => {
    return await FileUploadServiceClient(config).get(
      fileApiPaths.getPermissionsMap
    );
  });
};
