/* eslint-disable max-len */
import { ReactElement } from 'react';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { ReportNavigationButtonPropTypes } from '../../types/propTypes/ReportNavigationButtonPropTypes';
import NavigationButton from '../NavigationButton/NavigationButton';
import './ReportNavigationButton.css';

const ReportNavigationButton = ({
  report,
}: ReportNavigationButtonPropTypes): ReactElement => {
  return (
    <NavigationButton
      route={appRoutePaths.ReportDisplay(report.reportId)}
      key={report.reportId}
      visibleOverride={canUserAccessRoute(appRoutePaths.ReportDisplay())}
      classes="report-item"
      tooltipText="Click to load report"
    >
      <div className="report-info-display">
        <div>{report.reportName}</div>
        <div>
          {/* Stub in location for "certification" pill at later time */}
          {/* <Pill
            status={UserStatus.Active}
            pillType={PillType.USER_STATUS}
            value={UserStatus.Active}
          /> */}
        </div>
      </div>
    </NavigationButton>
  );
};

export default ReportNavigationButton;
