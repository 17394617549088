/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearError,
  hasError,
  selectError,
} from '../../redux/slices/errorSlice';
import { getDefaultStatusCodeConfiguration } from '../../types/ApiErrorDisplayOverrideModel';
import { DialogType } from '../../types/DialogTypes';
import ApiErrorPanel from '../ApiErrorPanel/ApiErrorPanel';
import Modal from '../Modal/Modal';

const ApiModalError = (): ReactElement => {
  const dispatch = useAppDispatch();
  const hasAppError = useAppSelector(hasError);
  const error = useAppSelector(selectError);

  const handleClose = (): void => {
    dispatch(clearError());
  };

  const buildModalTitle = (): string => {
    const defaultStatusCodeConfig = getDefaultStatusCodeConfiguration().find(
      (x) => x.statusCode == error.status
    );
    if (defaultStatusCodeConfig) {
      return error.title;
    }
    return 'A system error occurred';
  };

  return (
    <Modal
      open={hasAppError}
      type={DialogType.ERROR}
      title={buildModalTitle()}
      onClose={handleClose}
    >
      <ApiErrorPanel error={error} includeTitle={false} />
    </Modal>
  );
};

export default ApiModalError;
