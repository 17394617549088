/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MsisIdQueryFormViewModel } from '../../types/MSISIdQueryFormViewModel';
import { MSISRequestDetailModel } from '../../types/MSISRequestDetailModel';
import { defaultRequestAgeInDays } from '../../types/MSISRequestListModel';
import { MSISRequestModel } from '../../types/MSISRequestModel';
import { StudentOwnershipConflictDetailModel } from '../../types/StudentOwnershipConflictDetailModel';
import { StudentOwnershipConflictModel } from '../../types/StudentOwnershipConflictModel';
import {
  highlightForStudentConflict,
  isEmptyObject,
} from '../../utilities/helperUtilities';
import { RootState } from '../store';
import {
  fetchMSISIdQueryFormViewModel,
  fetchMSISRequests,
  fetchStudentDetails,
  fetchStudentOwnershipConflict,
  fetchStudentOwnershipConflictDetail,
} from '../thunks/studentThunks';

interface StudentState {
  loading: boolean;
  msisRequests: MSISRequestModel[];
  msisRequestFilterDays: string;
  studentDetails: MSISRequestDetailModel;
  studentOwnershipConflicts: StudentOwnershipConflictModel[];
  studentOwnershipConflictsDetail: StudentOwnershipConflictDetailModel;
  formViewModel: MsisIdQueryFormViewModel;
}

export const defaultOwnershipConflictDetail: StudentOwnershipConflictDetailModel =
  { fullName: '', msisId: '', studentOwnershipConflictDetailList: [] };

export const defaultStudentDetail: MSISRequestDetailModel = {
  id: '',
  dateOfBirth: '',
  fullName: '',
  firstName: '',
  lastName: '',
};

export const defaultFormState: MsisIdQueryFormViewModel = {
  schoolOptions: [],
  stateOptions: [],
};

export const initialState: StudentState = {
  loading: false,
  msisRequests: [],
  msisRequestFilterDays: defaultRequestAgeInDays,
  studentDetails: defaultStudentDetail,
  studentOwnershipConflicts: [],
  studentOwnershipConflictsDetail: defaultOwnershipConflictDetail,
  formViewModel: defaultFormState,
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setMsisRequestDayFilter: (state, action: PayloadAction<string>) => {
      state.msisRequestFilterDays = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMSISRequests.pending, (state) => {
      state.msisRequests = [];
      state.loading = true;
    });
    builder.addCase(fetchMSISRequests.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.msisRequests = action.payload.students;
      }
      state.loading = false;
    });
    builder.addCase(fetchMSISRequests.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchStudentDetails.pending, (state) => {
      state.studentDetails = defaultStudentDetail;
      state.loading = true;
    });
    builder.addCase(fetchStudentDetails.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.studentDetails = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchStudentDetails.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchStudentOwnershipConflict.pending, (state) => {
      state.studentOwnershipConflicts = [];
      state.loading = true;
    });
    builder.addCase(
      fetchStudentOwnershipConflict.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.studentOwnershipConflicts =
            action.payload.studentOwnershipConflictList;
        }
        state.loading = false;
      }
    );
    builder.addCase(fetchStudentOwnershipConflict.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchStudentOwnershipConflictDetail.pending, (state) => {
      state.studentOwnershipConflictsDetail = defaultOwnershipConflictDetail;
      state.loading = true;
    });
    builder.addCase(
      fetchStudentOwnershipConflictDetail.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.studentOwnershipConflictsDetail = highlightForStudentConflict(
            action.payload
          );
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchStudentOwnershipConflictDetail.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loading = false;
        }
      }
    );
    builder.addCase(fetchMSISIdQueryFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loading = true;
    });
    builder.addCase(
      fetchMSISIdQueryFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(fetchMSISIdQueryFormViewModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
  },
});

export const { setMsisRequestDayFilter } = studentSlice.actions;

export const selectStudentLoading = (state: RootState): boolean =>
  state.student.loading;
export const selectMSISRequestFilterDays = (state: RootState): string =>
  state.student.msisRequestFilterDays;
export const selectMSISRequests = (state: RootState): MSISRequestModel[] =>
  state.student.msisRequests;
export const selectStudentOwnershipConflicts = (
  state: RootState
): StudentOwnershipConflictModel[] => state.student.studentOwnershipConflicts;
export const selectStudentOwnershipConflictsDetail = (
  state: RootState
): StudentOwnershipConflictDetailModel | undefined =>
  state.student.studentOwnershipConflictsDetail;
export const selectStudentDetails = (
  state: RootState
): MSISRequestDetailModel | undefined => state.student.studentDetails;
export const selectMsisIdQueryFormViewModel = (
  state: RootState
): MsisIdQueryFormViewModel => state.student.formViewModel;

export default studentSlice.reducer;
