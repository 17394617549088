/* eslint-disable max-len */
import { ReactElement } from 'react';
import ExpandableNavigationItem from '../../../components/ExpandableNavigationItem/ExpandableNavigationItem';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import { MenuPropTypes } from '../../../types/propTypes/MenuPropTypes';

const AdminMenu = ({ setIsOpen, isOpen }: MenuPropTypes): ReactElement => {
  return (
    <li>
      <ExpandableNavigationItem
        isOpen={isOpen}
        setIsOpen={(opened: boolean) => {
          setIsOpen(opened);
        }}
        mainLink={{
          id: 'main-link-admin',
          content: 'Administration',
          className: 'sidebar--nav--admin',
        }}
        subLinks={[
          {
            to: appRoutePaths.UserList,
            content: 'Manage Users',
            visible: canUserAccessRoute(appRoutePaths.UserList),
          },
        ]}
      />
    </li>
  );
};

export default AdminMenu;
