import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { SummerActivityFormViewModel } from '../types/SummerActivityFormViewModel';
import { SummerActivityListItemModel } from '../types/SummerActivityListItemModel';
import { SummerActivityModel } from '../types/SummerActivityModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const summerActivityApiPaths = {
  create: 'summerActivity',
  update: 'summerActivity',
  getSchoolYearListItems: 'summerActivity/getSchoolYearListItems',
  getSchoolListItems: 'summerActivity/getSchoolListItems',
  getListItems: (calendarYear: string, school: string) =>
    `summerActivity?calendarYear=${calendarYear}&school=${school}`,
  getById: (summerActivityId: string): string =>
    `summerActivity/${summerActivityId}`,
  getForm: 'summerActivity/form',
};

export const summerActivityServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createSummerActivity = async (
  body: SummerActivityModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await summerActivityServiceClient(config).post(
      summerActivityApiPaths.create,
      body
    );
  });
};

export const updateSummerActivity = async (
  body: SummerActivityModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await summerActivityServiceClient(config).put(
      summerActivityApiPaths.update,
      body
    );
  });
};

export const getSummerActivityListItems = async (
  calendarYear: string,
  school: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SummerActivityListItemModel[]>> => {
  return executeApiRequest<SummerActivityListItemModel[]>(async () => {
    return await summerActivityServiceClient(config).get(
      summerActivityApiPaths.getListItems(calendarYear, school)
    );
  });
};

export const getSummerActivityById = async (
  summerActivityId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SummerActivityModel>> => {
  return executeApiRequest<SummerActivityModel>(async () => {
    return await summerActivityServiceClient(config).get(
      summerActivityApiPaths.getById(summerActivityId)
    );
  });
};

export const getSummerActivityFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<SummerActivityFormViewModel>> => {
  return executeApiRequest<SummerActivityFormViewModel>(async () => {
    return await summerActivityServiceClient(config).get(
      summerActivityApiPaths.getForm
    );
  });
};
