/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';

export type AuthorizationContextType = {
  authTokenStart: () => Promise<boolean>;
  authTokenStop: () => boolean;
  reportTokenStart: () => Promise<boolean>;
  reportTokenStop: () => boolean;
};

export const AuthorizationContext = createContext<AuthorizationContextType>({
  authTokenStart: () => {
    return Promise.resolve(true);
  },
  authTokenStop: () => {
    return true;
  },
  reportTokenStart: () => {
    return Promise.resolve(true);
  },
  reportTokenStop: () => {
    return true;
  },
} as AuthorizationContextType);

export const useAuthorizationContext = (): AuthorizationContextType =>
  useContext(AuthorizationContext);
