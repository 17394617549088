/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { SummerActivityFormViewModel } from '../../types/SummerActivityFormViewModel';
import { SummerActivityListItemModel } from '../../types/SummerActivityListItemModel';
import { SummerActivityModel } from '../../types/SummerActivityModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchSummerActivityById,
  fetchSummerActivityFormViewModel,
  fetchSummerActivityListItems,
} from '../thunks/summerActivityThunks';

interface SummerActivityState {
  schoolYear: string;
  school: string;
  loadingListItems: boolean;
  listItems: SummerActivityListItemModel[];

  loadingSummerActivity: boolean;
  summerActivity: SummerActivityModel;

  loadingFormViewModel: boolean;
  formViewModel: SummerActivityFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultListState: SummerActivityListItemModel[] = [];

export const defaultSummerActivityState: SummerActivityModel = {
  id: '',
  schoolYear: '',
  leaIdentifier: '',
  leaName: '',
  schoolIdentifier: '',
  schoolName: '',
  msisId: '',
  gradeLevel: '',
  lastName: '',
  firstName: '',
  fullName: '',
  birthDate: '',
  reasonCode: '',
  reasonDescription: '',
  verified: '',
};

export const defaultFormState: SummerActivityFormViewModel = {
  defaultSchoolYear: '',
  defaultSchool: '',
  schoolYears: [],
  schools: [],
  reasonCodeDescriptionOptions: [],
  verifiedOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: SummerActivityState = {
  schoolYear: '',
  school: '',
  loadingListItems: false,
  listItems: [],
  loadingSummerActivity: false,
  summerActivity: defaultSummerActivityState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: dataEntryMode,
};

export const summerActivitySlice = createSlice({
  name: 'summerActivity',
  initialState,
  reducers: {
    clearSummerActivity: (state) => {
      state.summerActivity = defaultSummerActivityState;
    },
    saveSummerActivity: (state, action: PayloadAction<SummerActivityModel>) => {
      state.summerActivity = action.payload;
    },
    setSummerActivityDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchSummerActivityList
    builder.addCase(fetchSummerActivityListItems.pending, (state) => {
      state.listItems = [];
      state.loadingListItems = true;
    });
    builder.addCase(fetchSummerActivityListItems.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.listItems = action.payload;
      }
      state.loadingListItems = false;
    });
    builder.addCase(fetchSummerActivityListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchSummerActivityById
    builder.addCase(fetchSummerActivityById.pending, (state) => {
      state.summerActivity = defaultSummerActivityState;
      state.loadingSummerActivity = true;
    });
    builder.addCase(fetchSummerActivityById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.summerActivity = action.payload;
      }
      state.loadingSummerActivity = false;
    });
    builder.addCase(fetchSummerActivityById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingSummerActivity = false;
      }
    });

    // fetchSummerActivityFormViewModel
    builder.addCase(fetchSummerActivityFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchSummerActivityFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchSummerActivityFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );
  },
});

//export reducers
export const {
  clearSummerActivity,
  saveSummerActivity,
  setSummerActivityDataEntryMode,
} = summerActivitySlice.actions;

// listItems
export const selectSummerActivityLoadingListItems = (
  state: RootState
): boolean => state.summerActivity.loadingListItems;
export const selectSummerActivityListItems = (
  state: RootState
): SummerActivityListItemModel[] => state.summerActivity.listItems;

// summerActivity
export const selectSummerActivityLoadingSummerActivity = (
  state: RootState
): boolean => state.summerActivity.loadingSummerActivity;
export const selectSummerActivitySummerActivity = (
  state: RootState
): SummerActivityModel => state.summerActivity.summerActivity;

// formViewModel
export const selectSummerActivityLoadingFormViewModel = (
  state: RootState
): boolean => state.summerActivity.loadingFormViewModel;
export const selectSummerActivityFormViewModel = (
  state: RootState
): SummerActivityFormViewModel => state.summerActivity.formViewModel;

// dataEntryMode
export const selectSummerActivityDataEntryMode = (
  state: RootState
): DataEntryMode => state.summerActivity.dataEntryMode;

export default summerActivitySlice.reducer;
