/* eslint-disable max-len */
import { ReactElement } from 'react';
import ExpandableNavigationItem from '../../../components/ExpandableNavigationItem/ExpandableNavigationItem';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import { MenuPropTypes } from '../../../types/propTypes/MenuPropTypes';

const ReportMenu = ({ setIsOpen, isOpen }: MenuPropTypes): ReactElement => {
  return (
    <li>
      <ExpandableNavigationItem
        isOpen={isOpen}
        setIsOpen={(opened: boolean) => {
          setIsOpen(opened);
        }}
        mainLink={{
          id: 'main-link-report',
          content: 'Reports',
          className: 'sidebar--nav--reports',
        }}
        subLinks={[
          {
            to: appRoutePaths.ReportDashboard,
            content: 'Data Analytics',
            visible: canUserAccessRoute(appRoutePaths.ReportDashboard),
          },
        ]}
      />
    </li>
  );
};

export default ReportMenu;
