/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { LEASharingFormViewModel } from '../../types/LEASharingFormViewModel';
import { LEASharingListItemModel } from '../../types/LEASharingListItemModel';
import { LEASharingModel } from '../../types/LEASharingModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchLEASharingById,
  fetchLEASharingFormViewModel,
  fetchLEASharingListItems,
} from '../thunks/leaSharingThunks';

interface LEASharingState {
  loadingListItems: boolean;
  listItems: LEASharingListItemModel[];

  loadingLeaSharing: boolean;
  leaSharing: LEASharingModel;

  loadingFormViewModel: boolean;
  formViewModel: LEASharingFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultListState: LEASharingListItemModel[] = [];

export const defaultLEASharingState: LEASharingModel = {
  id: '',
  sharingFromLEA: '',
  sharingToLEA: '',
  inactiveDate: '',
  shareType: '',
  programType: '',
};

export const defaultFormState: LEASharingFormViewModel = {
  sharingFromLEAOptions: [],
  sharingToLEAOptions: [],
  shareTypeOptions: [],
  programTypeOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: LEASharingState = {
  loadingListItems: false,
  listItems: defaultListState,
  loadingLeaSharing: false,
  leaSharing: defaultLEASharingState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: dataEntryMode,
};

export const leaSharingSlice = createSlice({
  name: 'leaSharing',
  initialState,
  reducers: {
    clearLEASharing: (state) => {
      state.leaSharing = defaultLEASharingState;
    },
    saveLEASharing: (state, action: PayloadAction<LEASharingModel>) => {
      state.leaSharing = action.payload;
    },
    setLEASharingDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchLeaSharingList
    builder.addCase(fetchLEASharingListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(fetchLEASharingListItems.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.listItems = action.payload;
      }
      state.loadingListItems = false;
    });
    builder.addCase(fetchLEASharingListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchLeaSharingById
    builder.addCase(fetchLEASharingById.pending, (state) => {
      state.leaSharing = defaultLEASharingState;
      state.loadingLeaSharing = true;
    });
    builder.addCase(fetchLEASharingById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.leaSharing = action.payload;
      }
      state.loadingLeaSharing = false;
    });
    builder.addCase(fetchLEASharingById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingLeaSharing = false;
      }
    });

    // fetchLeaSharingFormViewModel
    builder.addCase(fetchLEASharingFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(fetchLEASharingFormViewModel.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.formViewModel = action.payload;
      }
      state.loadingFormViewModel = false;
    });
    builder.addCase(fetchLEASharingFormViewModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingFormViewModel = false;
      }
    });
  },
});

//export reducers
export const { clearLEASharing, saveLEASharing, setLEASharingDataEntryMode } =
  leaSharingSlice.actions;

// listItems
export const selectLEASharingLoadingListItems = (state: RootState): boolean =>
  state.leaSharing.loadingListItems;
export const selectLEASharingListItems = (
  state: RootState
): LEASharingListItemModel[] => state.leaSharing.listItems;

// leaSharing
export const selectLEASharingLoadingLEASharing = (state: RootState): boolean =>
  state.leaSharing.loadingLeaSharing;
export const selectLEASharingLEASharing = (state: RootState): LEASharingModel =>
  state.leaSharing.leaSharing;

// formViewModel
export const selectLEASharingLoadingFormViewModel = (
  state: RootState
): boolean => state.leaSharing.loadingFormViewModel;
export const selectLEASharingFormViewModel = (
  state: RootState
): LEASharingFormViewModel => state.leaSharing.formViewModel;

// dataEntryMode
export const selectLEASharingDataEntryMode = (
  state: RootState
): DataEntryMode => state.leaSharing.dataEntryMode;

export default leaSharingSlice.reducer;
