/* eslint-disable max-len */
import { Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirmationModalContext } from '../../../hooks/useConfirmationModalContext';
import { CertifyIcon, ViewErrorsIcon } from '../../../icons/Icons';
import { useAppDispatch } from '../../../redux/hooks';
import { setDQDFilter } from '../../../redux/slices/dataQualitySlice';
import { createNotification } from '../../../redux/slices/notificationSlice';
import { fetchCertificationDataCollectionDetails } from '../../../redux/thunks/certificationThunks';
import { isValidCode } from '../../../services/base.service';
import { createCertification } from '../../../services/certification.service';
import { appRoutePaths } from '../../../services/route.service';
import { ApiResponseModel } from '../../../types/ApiResponseModel';
import {
  CertificationDistrictItem,
  CertificationStatus,
} from '../../../types/CertificationDetailModel';
import { CertificationCreationModel } from '../../../types/CertificationViewModel';
import { PillType } from '../../../types/PillType';
import { DistrictCertificationGridPropTypes } from '../../../types/propTypes/DistrictCertificationGridPropTypes';
import { formatToDateString } from '../../../utilities/dateUtilities';
import Pill from '../../Pill/Pill';
import StyledGrid from '../../StyledGrid/StyledGrid';

const DistrictCertificationGrid = ({
  items,
  schoolYear,
  dataCollection,
}: DistrictCertificationGridPropTypes): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirmContext = useConfirmationModalContext();

  const handleRowId = (row: CertificationDistrictItem): string => {
    return row.leaName;
  };

  const handleDateFormat = (value: string): string => {
    /* istanbul ignore next */
    if (!value) {
      return value;
    }
    return formatToDateString(value);
  };

  const handleDistrictCertify = async (
    model: CertificationCreationModel,
    leaDisplay: string
  ): Promise<void> => {
    confirmContext.setOptions({
      title: 'Are you sure?',
      content: (
        <div className="confirm-container">
          Are you sure you want to certify&nbsp;
          {leaDisplay}?
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await createCertification(model);
        if (isValidCode(response.status)) {
          dispatch(
            fetchCertificationDataCollectionDetails({
              schoolYear,
              collectionId: dataCollection.id,
            })
          );
          dispatch(
            createNotification({
              severity: 'success',
              children: 'District certification successful',
            })
          );
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleViewErrors = (): void => {
    dispatch(setDQDFilter(dataCollection.name));
    navigate(appRoutePaths.DataQualityDashboard);
  };

  const columns: GridColDef<CertificationDistrictItem>[] = [
    {
      field: 'leaNumber',
      headerName: 'LEA Number',
      description: 'Number of LEA',
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'leaName',
      headerName: 'LEA Name',
      description: 'Name of LEA',
      flex: 1,
    },
    {
      field: 'certificationDate',
      headerName: 'Certification Date',
      description: 'Date of certification',
      flex: 1,
      valueGetter: handleDateFormat,
    },
    {
      field: 'certifiedBy',
      headerName: 'Certified By',
      description: 'Who certified the record',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status of certification',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<CertificationDistrictItem>) => {
        return (
          params.value && (
            <Pill
              status={params.value}
              value={params.value}
              pillType={PillType.CERTIFICATION_STATUS}
              key={`pill-status-${params.row.leaNumber}`}
            />
          )
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      type: 'actions',
      description:
        'Actions to allow you to certify certifications for the entire LEA',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CertificationDistrictItem>) => {
        const leaDisplay = `${params.row.leaName} (${params.row.leaNumber})`;
        const canCertify =
          params.row.status === CertificationStatus.READY_TO_CERTIFY;
        const canViewErrors = params.row.status === CertificationStatus.ERRORS;

        const model: CertificationCreationModel = {
          leaIdentifier: params.row.leaNumber,
          dataCollectionSchoolYear: schoolYear,
          dataCollectionId: dataCollection.id,
        };

        return [
          canCertify && (
            <Tooltip
              title="Certify"
              key={`certify-school-${params.row.leaNumber}`}
            >
              <GridActionsCellItem
                icon={<CertifyIcon />}
                label="Certify District"
                data-testid={`certify-button-${params.row.leaNumber}`}
                onClick={() => handleDistrictCertify(model, leaDisplay)}
              />
            </Tooltip>
          ),
          canViewErrors && (
            <Tooltip
              title="View Errors"
              key={`view-errors-school-${params.row.leaNumber}`}
            >
              <GridActionsCellItem
                icon={<ViewErrorsIcon />}
                label="View Errors"
                data-testid={`view-errors-button-${params.row.leaNumber}`}
                onClick={() => handleViewErrors()}
              />
            </Tooltip>
          ),
        ];
      },
    },
  ];

  return (
    <div>
      <StyledGrid<CertificationDistrictItem>
        columns={columns}
        rows={items}
        getRowId={handleRowId}
        hideFooter
      ></StyledGrid>
    </div>
  );
};

export default DistrictCertificationGrid;
