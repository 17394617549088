/* eslint-disable max-len */
import { Form, Formik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import Panel from '../../../../components/Panel/Panel';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectCharterSchoolAuthorizingOrganizationLoading,
  selectNewCharterSchoolAuthorizingOrganizationViewModel,
} from '../../../../redux/slices/charterSchoolAuthorizingOrganizationSlice';
import { fetchCharterSchoolAuthorizingOrganizationNewViewModel } from '../../../../redux/thunks/charterSchoolThunks';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import { CharterSchoolAuthorizingOrganizationModel } from '../../../../types/CharterSchoolAuthorizingOrganizationModel';
import { CharterSchoolAuthorizingOrganizationNewViewModel } from '../../../../types/CharterSchoolAuthorizingOrganizationNewViewModel';
import { PanelType } from '../../../../types/PanelType';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';
import './NewCharterSchoolAuthorizingOrganization.css';

const NewCharterSchoolAuthorizingOrganization = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    selectCharterSchoolAuthorizingOrganizationLoading
  );
  const viewModel: CharterSchoolAuthorizingOrganizationNewViewModel =
    useAppSelector(selectNewCharterSchoolAuthorizingOrganizationViewModel);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);
  const [addNewEnabled, setAddNewEnabled] = useState<boolean>(false);

  useEffect(() => {
    let promise: unknown = undefined;
    if (!viewModel.schoolYearOptions?.length) {
      promise = dispatch(
        fetchCharterSchoolAuthorizingOrganizationNewViewModel()
      );
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, viewModel]);

  const initialFormValues: CharterSchoolAuthorizingOrganizationModel = {
    schoolYear: '',
    authorizingOrganizationName: '',
    authorizingOrganizationId: '',
    authorizerIdSystem: 'SEA',
    authorizerType: '',
    mailingStreet: '',
    mailingAptEtc: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    physicalStreet: '',
    physicalAptEtc: '',
    physicalCity: '',
    physicalState: '',
    physicalZipCode: '',
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveUpdates = async (values: unknown): Promise<void> => {
    setSubmitEnabled(false);

    // TODO: make call to save

    setSubmitEnabled(true);
    setAddNewEnabled(true);
  };

  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--charter-schools--authorizing-organizations--new"
      isLoading={isLoading}
      loadingDataId="new-charter-school-authorizing-organization-loader"
      backBarLocation={appRoutePaths.CharterSchoolAuthorizingOrganizationList}
    >
      <Formik
        validateOnChange={true}
        validateOnMount={true}
        initialValues={initialFormValues}
        onSubmit={async (values) => {
          await saveUpdates(values);
        }}
      >
        {({}) => (
          <Form>
            <div className="subheading-and-button-container">
              <span className="subheading">
                Charter School Authorizing Organization
              </span>
              <div className="button-group">
                <NavigationButton
                  route={appRoutePaths.CharterSchoolAuthorizingOrganizationList}
                  classes="button no-wrap-text cancel-button"
                  tooltipText="Navigate to the last page"
                >
                  <>Cancel</>
                </NavigationButton>
                <ActionButton
                  buttonType="submit"
                  classes="button--secondary submit-button"
                  onClick={() => {
                    return undefined;
                  }}
                  dataTestId="new-submit"
                  disabled={isLoading || !submitEnabled}
                  tooltipText="Submit New Charter School Authorizing Organization"
                >
                  <>Save</>
                </ActionButton>
              </div>
            </div>

            <Panel panelType={PanelType.INFO} heading="General Information">
              <div className="field-row field-row-2-equal">
                <FormDropdown
                  displayName="School Year"
                  field="schoolYear"
                  options={viewModel.schoolYearOptions}
                />
                <FormTextbox
                  displayName="Authorizing Organization Name"
                  field="authorizingOrganizationName"
                />
              </div>

              <div className="field-row field-row-3-equal">
                <FormTextbox
                  displayName="Authorizing Organization ID"
                  field="authorizingOrganizationId"
                />
                <FormTextbox
                  displayName="Authorizer ID System"
                  field="authorizerIdSystem"
                  disabled={true}
                />
                <FormDropdown
                  displayName="Authorizer Type"
                  field="authorizerType"
                  options={viewModel.authorizerTypeOptions}
                />
              </div>
            </Panel>

            <Panel panelType={PanelType.INFO} heading="Mailing Address">
              <div className="field-row">
                <FormTextbox
                  displayName="Address Street Number and Name"
                  field="mailingStreet"
                />
                <FormTextbox
                  displayName="Apt., Suite, Unit, Building, Floor, etc."
                  field="mailingAptEtc"
                />
              </div>

              <div className="field-row field-row-city-state-zip">
                <FormTextbox displayName="City" field="mailingCity" />
                <FormDropdown
                  displayName="State"
                  field="mailingState"
                  options={viewModel.stateOptions}
                />
                <FormTextbox displayName="Zip Code" field="mailingZipCode" />
              </div>
            </Panel>

            <Panel panelType={PanelType.INFO} heading="Physical Address">
              <div className="field-row">
                <FormTextbox
                  displayName="Address Street Number and Name"
                  field="physicalStreet"
                />
                <FormTextbox
                  displayName="Apt., Suite, Unit, Building, Floor, etc."
                  field="physicalAptEtc"
                />
              </div>

              <div className="field-row field-row-city-state-zip">
                <FormTextbox displayName="City" field="physicalCity" />
                <FormDropdown
                  displayName="State"
                  field="physicalState"
                  options={viewModel.stateOptions}
                />
                <FormTextbox displayName="Zip Code" field="physicalZipCode" />
              </div>
            </Panel>

            <Panel
              panelType={PanelType.INFO}
              heading="Management Organizations"
            >
              <div className="table-row">
                <div className="button-container">
                  <ActionButton
                    onClick={() => {
                      navigate(
                        appRoutePaths.NewCharterSchoolManagementOrganization
                      );
                    }}
                    classes="button no-wrap-text new-button"
                    tooltipText="Add New Charter Management Organization"
                    disabled={!addNewEnabled}
                  >
                    <>Add New</>
                  </ActionButton>
                </div>
                <div className="table-container">
                  <table cellSpacing={0} className="page-view-table">
                    <thead>
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </Panel>
          </Form>
        )}
      </Formik>
    </DistrictSchoolInfoPage>
  );
};

export default NewCharterSchoolAuthorizingOrganization;
