/* eslint-disable max-len*/
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import { appRoutePaths } from '../../../../services/route.service';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';
import './SchoolManagementList.css';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectSelectedDistrict } from '../../../../redux/slices/appSlice';
import {
  selectSchoolManagementListItems,
  selectSchoolManagementLoadingListItems,
  setSchoolDistrictFilter,
} from '../../../../redux/slices/schoolManagementSlice';
import { fetchSchoolManagementListItems } from '../../../../redux/thunks/schoolManagementThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import { SchoolManagementListItemModel } from '../../../../types/SchoolManagementListItemModel';
import { isStateAdmin } from '../../../../utilities/userUtilities';

const SchoolManagementList = (): ReactElement => {
  const isLoading = useAppSelector(selectSchoolManagementLoadingListItems);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleEdit = (id: string): void => {
    navigate(appRoutePaths.SchoolManagementInput(id));
  };
  const selectedDistrict = useAppSelector(selectSelectedDistrict);

  const listItems: SchoolManagementListItemModel[] = useAppSelector(
    selectSchoolManagementListItems
  );

  useEffect(() => {
    const promise: unknown = dispatch(fetchSchoolManagementListItems());
    dispatch(setSchoolDistrictFilter(selectedDistrict.name));
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, selectedDistrict.name]);

  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--school-management--list"
      isLoading={isLoading}
      loadingDataId={'school-management-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>School Management</h4>
            {isStateAdmin() && (
              <NavigationButton
                route={appRoutePaths.SchoolManagementInput('new')}
                classes="button no-wrap-text new-button"
                tooltipText="Add New School Management"
                visibleOverride={canUserAccessRoute(
                  appRoutePaths.SchoolManagementInput()
                )}
              >
                <>Add New</>
              </NavigationButton>
            )}
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>LEA</th>
                  <th>School Name</th>
                  <th>School Identifier</th>
                  <th>School Type</th>
                  <th>School Operational Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((schoolManagement) => (
                    <tr
                      key={`${schoolManagement.id}`}
                      data-testid={schoolManagement.id}
                    >
                      <td>{schoolManagement.leaName}</td>
                      <td>{schoolManagement.schoolName}</td>
                      <td>{schoolManagement.schoolIdentifier}</td>
                      <td>{schoolManagement.schoolType}</td>
                      <td>{schoolManagement.schoolOperationalStatus}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${schoolManagement.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(schoolManagement.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit/View School Management'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DistrictSchoolInfoPage>
  );
};

export default SchoolManagementList;
