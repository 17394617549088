/* eslint-disable max-len */
import { ReactElement } from 'react';
import NavigationPage from '../../../components/NavigationPage/NavigationPage';
import NavigationTabToolbar from '../../../components/NavigationTabToolbar/NavigationTabToolbar';
import { Tabs_STUDENT } from '../../../types/NavigationTabs';
import { StudentPagePropTypes } from '../../../types/propTypes/StudentPagePropTypes';
import { buildUserAccessNavigationTabItems } from '../../../utilities/routeUtilities';
import './StudentPage.css';

const StudentPage = ({
  children,
  isLoading,
  loadingDataId,
  backBarLocation,
  pageClass,
  APIError,
}: StudentPagePropTypes): ReactElement => {
  return (
    <NavigationPage
      heading={'Student'}
      pageClass={`student-page ${pageClass}`}
      isLoading={isLoading}
      loadingDataId={loadingDataId}
      backBarLocation={backBarLocation}
      headerChild={
        <>
          <p>{/* Description purposefully empty for now */}</p>
          <NavigationTabToolbar
            navigationTabs={buildUserAccessNavigationTabItems(Tabs_STUDENT)}
          />
        </>
      }
      APIError={APIError}
    >
      {children}
    </NavigationPage>
  );
};

export default StudentPage;
