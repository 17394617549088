import { createSlice } from '@reduxjs/toolkit';
import { FileErrorCode, FileStatus } from '../../generated';
import { FileDetailsModel } from '../../types/FileDetailsModel';
import { FileModel } from '../../types/FileModel';
import { FilePermissionMap } from '../../types/FilePermissionMap';
import { FileUploadFormModel } from '../../types/FileUploadFormModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import { RootState } from '../store';
import {
  downloadFileById,
  fetchFileById,
  fetchFiles,
  fetchPermissionsMap,
  fetchUploadFormModel,
} from '../thunks/filesThunks';

interface FileState {
  loading: boolean;
  loadingPermissions: boolean;
  files: FileModel[];
  fileDetail: FileDetailsModel;
  uploadFormModel: FileUploadFormModel;
  permissionMap: FilePermissionMap[];
}

export const fileUploadFormModelDefault: FileUploadFormModel = {
  allowedFileEndings: [],
  allowedFileExtensions: [],
};

export const fileDetailDefault = {
  errorCode: FileErrorCode.NONE,
  errorCount: 0,
  canDownload: false,
  id: '',
  uploadedBy: '',
  fileName: '',
  uploadDateTimeUtc: '',
  status: FileStatus.PENDING,
  processingData: {
    successfulRecordsProcessed: 0,
    failedRecordsProcessed: 0,
    errorDetails: ['Sample Error', 'Sample Error'],
    detailHeaders: '',
  },
};

export const defaultPermissionMap = [
  {
    id: '1',
    fileType: '',
    permissions: ['SchoolManagement'],
    fileExtensions: ['CSV'],
  },
];

export const initialState: FileState = {
  loading: false,
  loadingPermissions: false,
  files: [],
  fileDetail: fileDetailDefault,
  uploadFormModel: fileUploadFormModelDefault,
  permissionMap: defaultPermissionMap,
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFiles.pending, (state) => {
      state.files = [];
      state.loading = true;
    });
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        const files = action.payload;
        files.sort((a, b) => {
          const statusOrder = {
            [FileStatus.FAILED]: 1,
            [FileStatus.PENDING]: 2,
            [FileStatus.SUCCESS]: 3,
          };

          if (statusOrder[a.status] !== statusOrder[b.status]) {
            return statusOrder[a.status] - statusOrder[b.status];
          }

          return (
            new Date(b.uploadDateTimeUtc).getTime() -
            new Date(a.uploadDateTimeUtc).getTime()
          );
        });
        state.files = files;
      }
      state.loading = false;
    });
    builder.addCase(fetchFiles.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchFileById.pending, (state) => {
      state.fileDetail = fileDetailDefault;
      state.loading = true;
    });
    builder.addCase(fetchFileById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.fileDetail = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchFileById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(downloadFileById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadFileById.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadFileById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchUploadFormModel.pending, (state) => {
      state.uploadFormModel = fileUploadFormModelDefault;
      state.loading = true;
    });
    builder.addCase(fetchUploadFormModel.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.uploadFormModel = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchUploadFormModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchPermissionsMap.pending, (state) => {
      state.files = [];
      state.loadingPermissions = true;
    });
    builder.addCase(fetchPermissionsMap.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.permissionMap = action.payload;
      }
      state.loadingPermissions = false;
    });
    builder.addCase(fetchPermissionsMap.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingPermissions = false;
      }
    });
  },
});

export const selectFileLoading = (state: RootState): boolean =>
  state.file.loading;
export const selectLoadingPermissions = (state: RootState): boolean =>
  state.file.loadingPermissions;
export const selectFiles = (state: RootState): FileModel[] => state.file.files;
export const selectFileDetail = (state: RootState): FileDetailsModel =>
  state.file.fileDetail;
export const selectFileUploadFormModel = (
  state: RootState
): FileUploadFormModel => state.file.uploadFormModel;
export const selectFilePermissions = (state: RootState): FilePermissionMap[] =>
  state.file.permissionMap;

export default fileSlice.reducer;
