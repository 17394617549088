import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getCourseCodeById,
  getCourseCodeFormViewModel,
  getCourseCodeListItems,
} from '../../services/courseCode.service';
import { CourseCodeFormViewModel } from '../../types/CourseCodeFormViewModel';
import { CourseCodeListItemModel } from '../../types/CourseCodeListItemModel';
import { CourseCodeModel } from '../../types/CourseCodeModel';

export const fetchCourseCodeListItems = createAsyncThunk(
  'input/fetchCourseCodeListItems',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      CourseCodeListItemModel[]
    >(thunkAPI, getCourseCodeListItems({ signal: thunkAPI.signal }));
  }
);

export const fetchCourseCodeById = createAsyncThunk(
  'input/fetchCourseCodeById',
  async (courseCodeId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<CourseCodeModel>(
      thunkAPI,
      getCourseCodeById(courseCodeId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchCourseCodeFormViewModel = createAsyncThunk(
  'input/fetchCourseCodeFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<CourseCodeFormViewModel>(
      thunkAPI,
      getCourseCodeFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
