/* eslint-disable max-len */
import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { CustomFormTextboxPropTypes } from '../../../types/propTypes/CustomFormTextboxPropTypes';
import {
  applyClassesForInput,
  extractMaxfromSchema,
} from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';
import './CustomFormTextbox.css';

const CustomFormTextbox = ({
  displayName,
  field1: fieldName1,
  field2: fieldName2,
  classConfig,
  cypressDataId,
  validationSchema,
  onChangeEvent,
  disabled,
}: CustomFormTextboxPropTypes): ReactElement => {
  const [, , { setValue, setTouched }] = useField(fieldName2);
  const characterMax = extractMaxfromSchema(validationSchema, fieldName2) || -1;
  const { resetForm, setFieldValue } = useFormikContext();

  const handleOnChange = async (
    event: React.ChangeEvent<HTMLButtonElement>
  ): Promise<void> => {
    const newValue = event.target.value;
    setTouched(true);
    setValue(newValue);

    onChangeEvent &&
      (await onChangeEvent({
        value: newValue,
        field: fieldName2,
        resetHook: resetForm,
        setValueHook: setFieldValue,
      }));
  };

  return (
    <div
      className={`field-container ${classConfig?.containerClasses || ''} `}
      data-testid={`form-textbox-${fieldName2}`}
    >
      <FormInputLabel
        field={fieldName2}
        displayName={displayName}
        validationSchema={validationSchema}
        classes={classConfig?.labelClasses || ''}
      />
      <div>
        <Field
          data-testid={`form-textbox-${fieldName1}-input`}
          className={applyClassesForInput(
            fieldName1,
            `${classConfig?.inputClasses || ''} data-input`
          )}
          id={fieldName1}
          name={fieldName1}
          type="text"
          disabled={true}
        />
        -
        <Field
          data-testid={`form-textbox-${fieldName2}-input`}
          className={applyClassesForInput(
            fieldName2,
            `${classConfig?.inputClasses || ''} data-input`
          )}
          id={fieldName2}
          name={fieldName2}
          type="text"
          maxLength={characterMax}
          data-cy={cypressDataId}
          onChange={handleOnChange}
          disabled={disabled}
        />
      </div>
      <ErrorMessage name={fieldName2} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default CustomFormTextbox;
