/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearLookupError,
  selectViewModel,
  selectViewModelLoading,
} from '../../redux/slices/certificationSlice';
import {
  fetchCertificationDataCollectionDetails,
  fetchCertificationViewModel,
} from '../../redux/thunks/certificationThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { CertificationViewModel } from '../../types/CertificationViewModel';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import { SelectOption } from '../../types/SelectOption';
import { setStyledDropdownSingleValue } from '../../utilities/helperUtilities';
import Loader from '../Loader/Loader';
import StyledSingleDropdown from '../StyledSingleDropdown/StyledSingleDropdown';
import CertificationCollectionDetail from './CertificationCollectionDetail/CertificationCollectionDetail';
import './CertificationPanel.css';

const CertificationPanel = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isViewModelLoading = useAppSelector(selectViewModelLoading);
  const viewModel: CertificationViewModel = useAppSelector(selectViewModel);
  const [collectionId, setCollectionId] = useState<string | undefined>(
    undefined
  );
  const [schoolYear, setSchoolYear] = useState<string>('');

  useEffect(() => {
    const promise = dispatch(fetchCertificationViewModel());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const getSchoolYearOptions = (): SelectOption[] => {
    return viewModel.schoolYears;
  };

  const getDataCollectionOptions = (schoolYearId: string): SelectOption[] => {
    const schoolYearSet = viewModel.schoolYears.filter(
      (x) => x.value === schoolYearId
    );

    if (schoolYearSet.length === 1) {
      return schoolYearSet[0].dataCollections;
    } else {
      return [];
    }
  };

  const handleSchoolYearChange = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    /* istanbul ignore next */
    const value = selected?.value || '';
    setSchoolYear(value);
    setCollectionId(undefined);
  };

  const handleCollectionChange = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    const value = selected?.value;
    /* istanbul ignore next */
    if (value)
      dispatch(
        fetchCertificationDataCollectionDetails({
          schoolYear,
          collectionId: value,
        })
      );
    setCollectionId(value);
    dispatch(clearLookupError());
  };

  return (
    <div>
      {isViewModelLoading ? (
        <div className="loading-container">
          <Loader
            dataTestId={'loading-view-model'}
            message={'Loading Data Collections...'}
          />
        </div>
      ) : (
        <div className="certification-panel">
          <div className="data-filter-row">
            <div className="grow-input">
              <StyledSingleDropdown
                fieldName="schoolYear"
                options={getSchoolYearOptions()}
                onChange={handleSchoolYearChange}
                labelText="School Year"
                showLabel={true}
              />
            </div>
            <div className="grow-input">
              <StyledSingleDropdown
                fieldName="dataCollection"
                options={getDataCollectionOptions(schoolYear)}
                onChange={handleCollectionChange}
                value={setStyledDropdownSingleValue(
                  getDataCollectionOptions(schoolYear),
                  collectionId
                )}
                labelText="Data Collection"
                showLabel={true}
                disabled={schoolYear === ''}
              />
            </div>
          </div>
          {collectionId && (
            <CertificationCollectionDetail schoolYear={schoolYear} />
          )}
        </div>
      )}
    </div>
  );
};

export default CertificationPanel;
