/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectCourseCodeListItems,
  selectCourseCodeLoadingListItems,
} from '../../../../redux/slices/courseCodeSlice';
import { fetchCourseCodeListItems } from '../../../../redux/thunks/courseCodeThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import { CourseCodeListItemModel } from '../../../../types/CourseCodeListItemModel';
import { formatToDateString } from '../../../../utilities/dateUtilities';
import MdeOtssPage from '../MdeOtssPage';
import './CourseCodeList.css';

const CourseCodeList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectCourseCodeLoadingListItems);
  const listItems: CourseCodeListItemModel[] = useAppSelector(
    selectCourseCodeListItems
  );

  useEffect(() => {
    const promise: unknown = dispatch(fetchCourseCodeListItems());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const handleEdit = (courseCodeId: string): void => {
    navigate(appRoutePaths.CourseCodeInput(courseCodeId));
  };

  return (
    <MdeOtssPage
      pageClass="data-input--course-codes--list"
      isLoading={isLoading}
      loadingDataId={'course-code-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid="page-heading-text">Course Codes</h4>
            <NavigationButton
              route={appRoutePaths.CourseCodeInput('new')}
              visibleOverride={canUserAccessRoute(
                appRoutePaths.CourseCodeInput()
              )}
              classes="button no-wrap-text new-button"
              tooltipText="Add New Course Code"
            >
              <>Add New</>
            </NavigationButton>
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>Course Code</th>
                  <th>Course Title</th>
                  <th>Course Department</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((courseCode) => (
                    <tr
                      key={`${courseCode.courseCode}`}
                      data-testid={courseCode.courseCode}
                    >
                      <td>{courseCode.courseCode}</td>
                      <td>{courseCode.courseTitle}</td>
                      <td>{courseCode.courseDepartmentName}</td>
                      <td>{formatToDateString(courseCode.courseEndDate)}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${courseCode.courseCode}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(courseCode.courseCode)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit Course Code'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MdeOtssPage>
  );
};

export default CourseCodeList;
