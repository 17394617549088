import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Pill from '../../components/Pill/Pill';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectUserList,
  selectUserLoading,
} from '../../redux/slices/userSlice';
import { fetchUserList } from '../../redux/thunks/userThunks';
import { TooltipAnchor } from '../../services/app.service';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { activateUser, deactivateUser } from '../../services/user.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { PillType } from '../../types/PillType';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { UserListItem } from '../../types/UserListModel';
import { isActive, isCurrentUser } from '../../utilities/userUtilities';
import './UserList.css';

const UserList = (): ReactElement => {
  const { setOptions, showConfirmation } = useConfirmationModalContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectUserLoading);
  const users: UserListItem[] = useAppSelector(selectUserList);

  useEffect(() => {
    const promise = dispatch(fetchUserList());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const handleEdit = (userId: string): void => {
    navigate(appRoutePaths.UserEdit(userId));
  };

  const handleActivate = async (userId: string): Promise<void> => {
    setOptions({
      title: 'Activate user for district?',
      content: (
        <div className="confirm-container">
          Are you sure you want to activate this user for the district &quot;
          {getCurrentUserAccess()?.currentDistrict?.name}&quot;?
        </div>
      ),
      confirmText: 'Yes, activate user',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await activateUser(userId);
        if (isValidCode(response.status)) {
          dispatch(fetchUserList());
        }
        return response;
      },
    });

    await showConfirmation();
  };

  const handleDeactivate = async (userId: string): Promise<void> => {
    setOptions({
      title: 'Deactivate user for district?',
      content: (
        <div className="confirm-container">
          Are you sure you want to deactivate the user for the district &quot;
          {getCurrentUserAccess()?.currentDistrict?.name}&quot;?
        </div>
      ),
      confirmText: 'Yes, deactivate user',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deactivateUser(userId);
        if (isValidCode(response.status)) {
          dispatch(fetchUserList());
        }
        return response;
      },
    });

    await showConfirmation();
  };

  return (
    <NavigationPage
      heading="Administration Portal"
      pageClass="user-management--list"
      isLoading={isLoading}
      loadingDataId="user-management-loader"
      loadingText="Getting user list"
    >
      <table cellSpacing={0} className="page-view-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 &&
            users.map((user) => (
              <tr key={`${user.userId}`} data-testid={user.userId}>
                <td>
                  <NavigationButton
                    route={appRoutePaths.UserDetail(user.userId)}
                    visibleOverride={canUserAccessRoute(
                      appRoutePaths.UserDetail()
                    )}
                    tooltipText="Click to go to User Detail View"
                  >
                    <>{user.name}</>
                  </NavigationButton>
                </td>
                <td className="users--list--email">{user.email}</td>
                <td>
                  <Pill
                    value={user.userStatus}
                    pillType={PillType.USER_STATUS}
                    status={user.userStatus}
                  />
                </td>
                <td>
                  <div className="action--column">
                    {isActive(user.userStatus) ? (
                      <>
                        <button
                          data-testid={`edit-button-${user.userId}`}
                          aria-label="Edit"
                          onClick={() => handleEdit(user.userId)}
                          data-tooltip-id={TooltipAnchor}
                          data-tooltip-content={'Edit User Access'}
                        >
                          <img src={'/images/icon-edit.svg'} />
                        </button>
                        {!isCurrentUser(user.userId) && (
                          <button
                            data-testid={`deactivate-button-${user.userId}`}
                            aria-label="Deactivate"
                            onClick={async () =>
                              await handleDeactivate(user.userId)
                            }
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Deactivate User'}
                          >
                            <img src={'/images/icon-unassign.svg'} />
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        data-testid={`activate-button-${user.userId}`}
                        aria-label="Activate"
                        onClick={async () => await handleActivate(user.userId)}
                        data-tooltip-id={TooltipAnchor}
                        data-tooltip-content={'Activate User'}
                      >
                        <img src={'/images/icon-activate.svg'} />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {users.length === 0 && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>No users found</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default UserList;
