/* eslint-disable max-len */
import { ReactElement } from 'react';
import { MergeStudentPanelType } from '../../../types/MergeStudentPanelType';
import { ConfirmMatchPropTypes } from '../../../types/propTypes/ConfirmMatchPropTypes';
import { getMatchedFieldName } from '../../../utilities/helperUtilities';
import MergeStudentPanel from '../MergeStudentPanel/MergeStudentPanel';
import './ConfirmMatch.css';

const ConfirmMatch = ({
  model,
  match,
}: ConfirmMatchPropTypes): ReactElement => {
  const highlights = match.unmatchedFieldNames.map((x) =>
    getMatchedFieldName(x)
  );

  return (
    <div className="confirm-container">
      <p>Highlighted fields do not match.</p>
      <div className="match-container">
        <MergeStudentPanel
          panelType={MergeStudentPanelType.CURRENT}
          student={model}
          highlights={highlights}
          cypressDataId="current-student-panel"
        />
        <div className="match-operator">
          {/* Right Arrow Character */}
          <span className="operator">&#8594;</span>
        </div>
        <MergeStudentPanel
          panelType={MergeStudentPanelType.EXISTING}
          student={match}
          highlights={highlights}
          cypressDataId="existing-student-panel"
        />
      </div>
    </div>
  );
};

export default ConfirmMatch;
