import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getSeaOrganizationById,
  getSeaOrganizationFormViewModel,
  getSeaOrganizationListItems,
} from '../../services/seaOrganization.service';
import { SeaOrganizationFormViewModel } from '../../types/SeaOrganizationFormViewModel';
import { SeaOrganizationListItemModel } from '../../types/SeaOrganizationListItemModel';
import { SeaOrganizationModel } from '../../types/SeaOrganizationModel';

export const fetchSeaOrganizationListItems = createAsyncThunk(
  'input/fetchSeaOrganizationListItems',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      SeaOrganizationListItemModel[]
    >(thunkAPI, getSeaOrganizationListItems({ signal: thunkAPI.signal }));
  }
);

export const fetchSeaOrganizationById = createAsyncThunk(
  'input/fetchSeaOrganizationById',
  async (stateAgencyIdentifier: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SeaOrganizationModel>(
      thunkAPI,
      getSeaOrganizationById(stateAgencyIdentifier, { signal: thunkAPI.signal })
    );
  }
);

export const fetchSeaOrganizationFormViewModel = createAsyncThunk(
  'input/fetchSeaOrganizationFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SeaOrganizationFormViewModel>(
      thunkAPI,
      getSeaOrganizationFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
