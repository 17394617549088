import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getCertificationDataCollectionDetails,
  getCertificationViewModel,
} from '../../services/certification.service';
import { CertificationDetailModel } from '../../types/CertificationDetailModel';
import { CertificationRequestModel } from '../../types/CertificationRequestModel';
import { CertificationViewModel } from '../../types/CertificationViewModel';

export const fetchCertificationDataCollectionDetails = createAsyncThunk(
  'certification/fetchCertificationDataCollectionDetails',
  async (args: CertificationRequestModel, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<CertificationDetailModel>(
      thunkAPI,
      getCertificationDataCollectionDetails(
        args.schoolYear,
        args.collectionId,
        {
          signal: thunkAPI.signal,
        }
      )
    );
  }
);

export const fetchCertificationViewModel = createAsyncThunk(
  'certification/fetchCertificationViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<CertificationViewModel>(
      thunkAPI,
      getCertificationViewModel({ signal: thunkAPI.signal })
    );
  }
);
