/* istanbul ignore file */
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { VALIDATION_MESSAGE } from '../../ValidationMessage';

enum DATE_VALIDATION_KEYS {
  COME_AFTER_DATE_OR_EQUAL = 'mustComeAfterOrEqualSpecifiedDate',
}

export const AddCustomDateValidation = (): void => {
  Yup.addMethod(
    Yup.date,
    DATE_VALIDATION_KEYS.COME_AFTER_DATE_OR_EQUAL,
    function (compareDateFieldName, compareDisplayName, errorMessage) {
      return this.test(
        DATE_VALIDATION_KEYS.COME_AFTER_DATE_OR_EQUAL,
        errorMessage,
        function (value) {
          const { parent, path, createError } =
            this as Yup.TestContext<AnyObject>;
          const compareValue = parent[compareDateFieldName];

          let endDateBeforeBegin = null;
          const data = value as unknown as Date;
          if (compareValue && data && compareValue > data) {
            // Signifies that the error we are looking for has occured
            endDateBeforeBegin = null;
          } else {
            return true;
          }

          return (
            endDateBeforeBegin !== null ||
            createError({
              path,
              message:
                errorMessage ||
                VALIDATION_MESSAGE.BEFORE_OR_EQUAL_TO_DATE(compareDisplayName),
            })
          );
        }
      );
    }
  );
};
