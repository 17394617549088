/* eslint-disable @typescript-eslint/no-unused-vars */
/* istanbul ignore file */
import { Embed, models, Report, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { getReportToken } from '../../services/base.service';
import { EmbeddedReportPropTypes } from '../../types/propTypes/EmbeddedReportPopTypes';
import './PowerBiReport.css';

// Root Component to demonstrate usage of wrapper component
const PowerBiReport = ({
  embedUrl,
  onLoaded,
  onRendered,
  onError,
}: EmbeddedReportPropTypes): JSX.Element => {
  const [report, setReport] = useState<Report>();
  const [reportUrl, setReportUrl] = useState<string>();
  const [token, setToken] = useState<string>();
  const [displayMessage, setMessage] = useState(``);

  // Report config useState hook
  // Values for properties like embedUrl, accessToken and settings will
  // be set on click of buttons below
  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: 'report',
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
    });

  const configureReport = (): void => {
    // Set the fetched embedUrl and embedToken in the report config
    setReportConfig({
      ...reportConfig,
      embedUrl: reportUrl,
      accessToken: token,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
        },
        background: models.BackgroundType.Transparent,
      },
    });
  };

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        configureReport();

        if (onLoaded != undefined) {
          onLoaded();
        }
      },
    ],
    [
      'rendered',
      function () {
        // Update display message
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        // setMessage('report is rendered');

        if (onRendered != undefined) {
          onRendered();
        }
      },
    ],
    [
      'error',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }

        if (onError != undefined) {
          onError();
        }
      },
    ],
  ]);

  // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
  const changeSettings = (): void => {
    // Update the state "reportConfig" and re-render DemoApp component
    setReportConfig({
      ...reportConfig,
      embedUrl: reportUrl,
      accessToken: token,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
        },
        background: models.BackgroundType.Transparent,
      },
    });
  };

  useEffect(() => {
    if (embedUrl) {
      setReportUrl(embedUrl);
      setToken(getReportToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedUrl]);

  useEffect(() => {
    if (reportUrl && token) {
      changeSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportUrl, token]);

  return (
    <div className="reports">
      <div className="displayMessage">{displayMessage}</div>
      <PowerBIEmbed
        embedConfig={reportConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={'report-style-class'}
        getEmbeddedComponent={(embedObject: Embed) => {
          setReport(embedObject as Report);
        }}
      />
    </div>
  );
};

export default PowerBiReport;
