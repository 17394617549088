import { ReactElement } from 'react';
import NavigationButton from '../../../components/NavigationButton/NavigationButton';
import { appRoutePaths } from '../../../services/route.service';
import { getFeatureAccessPermissions } from '../../../services/token.service';

const HomeMenu = (): ReactElement => {
  const featureAccessPermissions = getFeatureAccessPermissions();
  const homePath =
    featureAccessPermissions.length > 0
      ? appRoutePaths.Dashboard
      : appRoutePaths.NewUser;

  return (
    <li>
      <NavigationButton
        isNavLink={true}
        route={homePath}
        classes="sidebar--nav--home sidebar--nav--link"
        visibleOverride={true}
      >
        <span>Home</span>
      </NavigationButton>
    </li>
  );
};

export default HomeMenu;
