import { get } from 'lodash';
import { AppActions } from '../types/AppActions';
import { FeatureAccessPermissions } from '../types/FeatureAccessPermissions';
import { getRoutingAccessObject } from '../utilities/routeUtilities';
import { getFeatureAccessPermissions } from './token.service';

export const canUserAccessRoute = (route: string): boolean => {
  let canAccessRoute = false;
  if (route) {
    const featureAccessPermissions = getFeatureAccessPermissions();
    const access = getRoutingAccessObject();
    const featureAccessPermissionsWithAccess = get(
      access,
      route,
      []
    ) as unknown as FeatureAccessPermissions[];
    canAccessRoute = featureAccessPermissions.some(
      (r) => featureAccessPermissionsWithAccess.indexOf(r) >= 0
    );
  }
  return canAccessRoute;
};

export const canExecuteAppAction = (action: AppActions): boolean => {
  let allowed = false;
  const featureAccessPermissions = getFeatureAccessPermissions();

  switch (action) {
    case AppActions.DELETE_FILE:
      allowed = featureAccessPermissions.some(
        (r) => r === FeatureAccessPermissions.APPLICATION_ACCESS
      );
      break;
    default:
      allowed = false;
  }
  return allowed;
};
