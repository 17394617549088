/* eslint-disable max-len */
import { ReactElement } from 'react';
import { FileErrorDetailsPropTypes } from '../../types/propTypes/FileErrorDetailsPropTypes';
import './FileErrorDetails.css';

const FileErrorDetails = ({
  title,
  errorCount,
}: FileErrorDetailsPropTypes): ReactElement => (
  <div className="file-error-details" data-status="Failed">
    {title}
    {' There were '}
    {errorCount}
    {' errors in your data that need your attention.'}
  </div>
);

export default FileErrorDetails;
