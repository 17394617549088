/* eslint-disable max-len */
import { ReactElement } from 'react';
import FormDateSelector from '../../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropDown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import Panel from '../../../../components/Panel/Panel';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearSummerActivity,
  saveSummerActivity,
  selectSummerActivityDataEntryMode,
  selectSummerActivityFormViewModel,
  selectSummerActivityLoadingFormViewModel,
  selectSummerActivityLoadingSummerActivity,
  selectSummerActivitySummerActivity,
  setSummerActivityDataEntryMode,
} from '../../../../redux/slices/summerActivitySlice';
import {
  fetchSummerActivityById,
  fetchSummerActivityFormViewModel,
} from '../../../../redux/thunks/summerActivityThunks';
import { appRoutePaths } from '../../../../services/route.service';
import {
  createSummerActivity,
  updateSummerActivity,
} from '../../../../services/SummerActivity.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_STUDENT } from '../../../../types/NavigationTabs';
import { PanelType } from '../../../../types/PanelType';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { SummerActivityFormViewModel } from '../../../../types/SummerActivityFormViewModel';
import { SummerActivityModel } from '../../../../types/SummerActivityModel';
import { SummerActivityValidationSchema } from '../../../../types/Validation/SummerActivityValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import { isStateAdmin } from '../../../../utilities/userUtilities';
import './SummerActivityInput.css';

const SummerActivityInput = (): ReactElement => {
  const summerActivityModel: SummerActivityModel = useAppSelector(
    selectSummerActivitySummerActivity
  );
  const formViewModel: SummerActivityFormViewModel = useAppSelector(
    selectSummerActivityFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectSummerActivityLoadingFormViewModel
  );
  const isLoadingSummerActivityModel = useAppSelector(
    selectSummerActivityLoadingSummerActivity
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectSummerActivityDataEntryMode
  );
  const isLoading = isLoadingSummerActivityModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
  ];

  return (
    <ManualInputPage<SummerActivityModel, SummerActivityFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'summer-activity-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_STUDENT)}
      classes={{ pageClass: 'data-input--summer-activity--input' }}
      parameterOptions={{
        routeParameterName: 'summerActivityId',
        identityName: summerActivityModel.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.SummerActivityList,
        cancelRoute: appRoutePaths.SummerActivityList,
        createSuccessRoute: appRoutePaths.SummerActivityInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchSummerActivityById,
        loadFormDetail: fetchSummerActivityFormViewModel,
        setEntryMode: setSummerActivityDataEntryMode,
        setData: saveSummerActivity,
        clearData: clearSummerActivity,
        onCreate: createSummerActivity,
        onUpdate: updateSummerActivity,
      }}
      formDetails={{
        validationSchema: SummerActivityValidationSchema,
        initialFormValues: summerActivityModel,
        isFormModelEmpty: !formViewModel.verifiedOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'Summer Activity',
        pageHeader: 'Student',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="summer-activity-input-child">
        <Panel panelType={PanelType.INFO} heading="LEA Information">
          <div className="field-row field-row-1-1-2-1">
            <FormTextbox
              displayName="School Year"
              field="schoolYear"
              validationSchema={SummerActivityValidationSchema}
              disabled={true}
            />
            <FormTextbox
              displayName="LEA Identifier"
              field="leaIdentifier"
              validationSchema={SummerActivityValidationSchema}
              disabled={true}
            />
            <FormTextbox
              displayName="LEA Name"
              field="leaName"
              validationSchema={SummerActivityValidationSchema}
              disabled={true}
            />
          </div>

          <div className="field-row field-row-1-2-2">
            <FormTextbox
              displayName="School Identifier"
              field="schoolIdentifier"
              validationSchema={SummerActivityValidationSchema}
              disabled={true}
            />
            <FormTextbox
              displayName="School Name"
              field="schoolName"
              validationSchema={SummerActivityValidationSchema}
              disabled={true}
            />
          </div>
        </Panel>
        <Panel panelType={PanelType.INFO} heading="Student Information">
          <div className="field-row field-row-1-1-2-1">
            <FormTextbox displayName="MSIS ID" field="msisId" disabled={true} />
            <FormTextbox
              displayName="Grade Level"
              field="gradeLevel"
              disabled={true}
            />
            <FormTextbox
              displayName="Student Name"
              field="fullName"
              disabled={true}
            />
            <FormDateSelector
              displayName="Birthdate"
              field="birthDate"
              disabled={true}
            />
          </div>

          <div className="field-row field-row-4-1-1">
            <FormDropDown
              displayName="Reason Code and Description"
              field="reasonCode"
              disabled={true}
              options={formViewModel.reasonCodeDescriptionOptions}
            />
            <FormDropDown
              displayName="Verified"
              field="verified"
              disabled={!isStateAdmin()}
              options={formViewModel.verifiedOptions}
            />
          </div>
        </Panel>
      </div>
    </ManualInputPage>
  );
};

export default SummerActivityInput;
