import { ReactElement } from 'react';
import './NotFound.css';

const NotFound = (): ReactElement => {
  return (
    <div className="page--not-found">
      <section className="page--not-found-section">
        <h1>OOPS!</h1>
        <h4>Not Found</h4>
        <div className="content">Sorry, we can&apos;t find that page.</div>
      </section>
    </div>
  );
};

export default NotFound;
