import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import { getDashboardDetails } from '../../services/dashboard.service';
import { DashboardModel } from '../../types/DashboardModel';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<DashboardModel>(
      thunkAPI,
      getDashboardDetails({ signal: thunkAPI.signal })
    );
  }
);
