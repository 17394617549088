/* eslint-disable max-len */
import { ReactElement } from 'react';
import ExpandableNavigationItem from '../../../components/ExpandableNavigationItem/ExpandableNavigationItem';
import { baseAppRoutes } from '../../../services/route.service';
import {
  Tabs_DISTRICT_SCHOOL,
  Tabs_MDE_OTSS,
  Tabs_STUDENT,
} from '../../../types/NavigationTabs';
import { MenuPropTypes } from '../../../types/propTypes/MenuPropTypes';
import { buildUserAccessNavigationTabItems } from '../../../utilities/routeUtilities';

const DataInputMenu = ({ setIsOpen, isOpen }: MenuPropTypes): ReactElement => {
  const builtMDEOTSSTabs = buildUserAccessNavigationTabItems(Tabs_MDE_OTSS);
  const builtStudentTabs = buildUserAccessNavigationTabItems(Tabs_STUDENT);
  const builtLEASchoolTabs =
    buildUserAccessNavigationTabItems(Tabs_DISTRICT_SCHOOL);

  const hasAccessToAnyMDEOTSSTab: boolean = builtMDEOTSSTabs.length > 0;
  const hasAccessToAnyStudentTab: boolean = builtStudentTabs.length > 0;
  const hasAccessToAnyLEASchoolTab: boolean = builtLEASchoolTabs.length > 0;

  return (
    <li>
      <ExpandableNavigationItem
        isOpen={isOpen}
        setIsOpen={(opened: boolean) => {
          setIsOpen(opened);
        }}
        mainLink={{
          id: 'main-link-data-input',
          content: 'Data Input',
          className: 'sidebar--nav--manual-input',
        }}
        subLinks={[
          {
            to: builtLEASchoolTabs[0]?.routePath,
            content: 'LEA & School Info',
            visible: hasAccessToAnyLEASchoolTab,
            baseRoute: baseAppRoutes.LEASchoolInput, // Needed for times when the starting tab is dynamic
          },
          {
            to: builtMDEOTSSTabs[0]?.routePath,
            content: 'MDE/OTSS',
            visible: hasAccessToAnyMDEOTSSTab,
            baseRoute: baseAppRoutes.OTSSInput, // Needed for times when the starting tab is dynamic
          },
          {
            to: builtStudentTabs[0]?.routePath,
            content: 'Student',
            visible: hasAccessToAnyStudentTab,
            baseRoute: baseAppRoutes.StudentInput, // Needed for times when the starting tab is dynamic
          },
        ]}
      />
    </li>
  );
};

export default DataInputMenu;
