/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import PowerBiReport from '../../components/PowerBiReport/PowerBiReport';
import { useAuthorizationContext } from '../../hooks/useAuthorizationContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearReportError,
  selectReportError,
  selectReportList,
  selectReportLoading,
  setReportList,
} from '../../redux/slices/reportSlice';
import { fetchTokenAndReportList } from '../../redux/thunks/reportThunks';
import {
  abortPromiseOnUnmount,
  getReportList,
  getReportToken,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { hasReportDataAndNotEmpty } from '../../services/token.service';
import { ReportRetrievalStatus } from '../../types/ReportRetrievalStatus';
import { getReportEmbedUrlFromId } from '../../utilities/helperUtilities';
import './ReportDisplay.css';

const ReportDisplay = (): ReactElement => {
  const { reportId } = useParams();
  const dispatch = useAppDispatch();

  const reports = useAppSelector(selectReportList);
  const isLoading = useAppSelector(selectReportLoading);
  const reportError = useAppSelector(selectReportError);

  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(true);
  const [reportRetrievalStatus, setReportRetrievalStatus] =
    useState<ReportRetrievalStatus>(ReportRetrievalStatus.LOADING);

  const embedUrl = getReportEmbedUrlFromId(reports, reportId);
  const { reportTokenStart } = useAuthorizationContext();

  useEffect(() => {
    let promise: Promise<unknown> = Promise.resolve(undefined);
    if (!reportError) {
      if (!hasReportDataAndNotEmpty()) {
        promise = dispatch(fetchTokenAndReportList());
      }
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, reportError]);

  useEffect(() => {
    // If there is a token, make sure to signal
    // the timer to start
    if (getReportToken()) {
      reportTokenStart();
    }
  }, [reportTokenStart, isLoading]);

  // Clear report error when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearReportError());
    };
  }, [dispatch]);

  // On full refresh, state is cleared and so we are
  // grabbing it from the session and then load that back into state
  useEffect(() => {
    const list = getReportList();
    if (
      list &&
      list?.categories.length > 0 &&
      reports.categories.length !== getReportList()?.categories.length
    ) {
      dispatch(setReportList(list));
    }
  }, [dispatch, reports]);

  const handleReportLoaded = (): void => {
    // Only set to RENDERING from LOADING.
    // This avoids getting into a weird state if Rendered is called before Loaded.
    if (reportRetrievalStatus == ReportRetrievalStatus.LOADING) {
      setReportRetrievalStatus(ReportRetrievalStatus.RENDERING);
    }
  };

  const handleReportRendered = (): void => {
    setReportRetrievalStatus(ReportRetrievalStatus.INACTIVE);
    setIsLoadingReport(false);
  };

  const handleReportError = (): void => {
    setReportRetrievalStatus(ReportRetrievalStatus.INACTIVE);
    setIsLoadingReport(false);
  };

  return (
    <NavigationPage
      heading="View Report"
      pageClass="reports--view"
      isLoading={isLoadingReport && isLoading}
      loadingDataId="reports-loader"
      loadingText="Getting your report"
      backBarLocation={appRoutePaths.ReportDashboard}
      APIError={reportError}
    >
      {!reportError && (
        <>
          {!embedUrl && (
            <div
              className="report--view--none empty-content"
              data-testid="report-empty-message"
            >
              <p>No report found or there was an issue loading your report</p>
            </div>
          )}
          {embedUrl && (
            <>
              {reportRetrievalStatus != ReportRetrievalStatus.INACTIVE && (
                <div className="report-retrieval-loader-container">
                  <Loader
                    message={`Report is ${reportRetrievalStatus}`}
                    dataTestId="report-retrieval-loader"
                  />
                </div>
              )}
              <PowerBiReport
                embedUrl={embedUrl}
                onLoaded={handleReportLoaded}
                onRendered={handleReportRendered}
                onError={handleReportError}
              />
            </>
          )}
        </>
      )}
    </NavigationPage>
  );
};

export default ReportDisplay;
