/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getNonPublicStudentById,
  getNonPublicStudentFormViewModel,
  getNonPublicStudentListItems,
  getNonPublicStudentScheduleFormViewModel,
  getScheduleByScheduleId,
  getSchedulesByNpsId,
} from '../../services/nonPublicStudent.service';
import { NonPublicStudentEntryModel } from '../../types/NonPublicStudentEntryModel';
import { NonPublicStudentFormViewModel } from '../../types/NonPublicStudentFormViewModel';
import { NonPublicStudentListItemModel } from '../../types/NonPublicStudentListItemModel';
import { NonPublicStudentScheduleFormViewModel } from '../../types/NonPublicStudentScheduleFormViewModel';
import { NonPublicStudentScheduleListItemModel } from '../../types/NonPublicStudentScheduleListItemModel';
import { NonPublicStudentScheduleModel } from '../../types/NonPublicStudentScheduleModel';

export const fetchNonPublicStudentListItems = createAsyncThunk(
  'input/fetchNonPublicStudentListItems',
  async (calendarYear: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      NonPublicStudentListItemModel[]
    >(
      thunkAPI,
      getNonPublicStudentListItems(calendarYear, { signal: thunkAPI.signal })
    );
  }
);

export const fetchNonPublicStudentById = createAsyncThunk(
  'input/fetchNonPublicStudentById',
  async (nonPublicStudentId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<NonPublicStudentEntryModel>(
      thunkAPI,
      getNonPublicStudentById(nonPublicStudentId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchNonPublicStudentFormViewModel = createAsyncThunk(
  'input/fetchNonPublicStudentFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<NonPublicStudentFormViewModel>(
      thunkAPI,
      getNonPublicStudentFormViewModel({ signal: thunkAPI.signal })
    );
  }
);

export const fetchNonPublicStudentScheduleFormViewModel = createAsyncThunk(
  'input/fetchNonPublicStudentScheduleFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<NonPublicStudentScheduleFormViewModel>(
      thunkAPI,
      getNonPublicStudentScheduleFormViewModel({ signal: thunkAPI.signal })
    );
  }
);

export const fetchNonPublicStudentScheduleByNpsId = createAsyncThunk(
  'input/fetchNonPublicStudentScheduleByNpsId',
  async (npsId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      NonPublicStudentScheduleListItemModel[]
    >(thunkAPI, getSchedulesByNpsId(npsId, { signal: thunkAPI.signal }));
  }
);

export const fetchNonPublicStudentScheduleByScheduleId = createAsyncThunk(
  'input/fetchNonPublicStudentScheduleByScheduleId',
  async (scheduleId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<NonPublicStudentScheduleModel>(
      thunkAPI,
      getScheduleByScheduleId(scheduleId, {
        signal: thunkAPI.signal,
      })
    );
  }
);
