import { faker } from '@faker-js/faker';
import { ReportEmbedData } from '../../types/ReportEmbedData';
import {
  ReportCategoryModel,
  ReportListModel,
  ReportSubcategoryModel,
} from '../../types/ReportListModel';
import { ReportTokenRefreshPayload } from '../../types/ReportTokenRefreshPayload';

export const getRandomReportEmbedData = (
  model?: Partial<ReportEmbedData>
): ReportEmbedData => {
  return {
    reportId: model?.reportId ? model?.reportId : faker.string.alphanumeric(10),
    reportName: model?.reportName ? model?.reportName : faker.word.words(8),
    embedUrl: model?.embedUrl ? model?.embedUrl : faker.string.alphanumeric(40),
  };
};

export const getRandomReportSubcategoryModel = (
  model?: Partial<ReportSubcategoryModel>
): ReportSubcategoryModel => {
  const reportCnt = 1;
  const prepEmbedDataList = (): ReportEmbedData[] => {
    const list = [];
    for (let index = 0; index < reportCnt; index++) {
      list.push(getRandomReportEmbedData());
    }
    return list;
  };

  return {
    // Allow blank names for special case
    name: model?.name !== undefined ? model?.name : faker.string.alpha(10),
    reports: model?.reports ? model?.reports : prepEmbedDataList(),
  };
};

export const getRandomReportCategoryModel = (
  model?: Partial<ReportCategoryModel>
): ReportCategoryModel => {
  const subcateogryCnt = 1;
  const prepSubcategoryList = (): ReportSubcategoryModel[] => {
    const list = [];
    for (let index = 0; index < subcateogryCnt; index++) {
      list.push(getRandomReportSubcategoryModel());
    }
    return list;
  };

  return {
    name: model?.name ? model?.name : faker.word.words(),
    subcategories: model?.subcategories
      ? model?.subcategories
      : prepSubcategoryList(),
  };
};

export const getRandomReportListModel = (
  model?: Partial<ReportListModel>
): ReportListModel => {
  const categoryCnt = 1;
  const prepSubcategoryList = (): ReportCategoryModel[] => {
    const list = [];
    for (let index = 0; index < categoryCnt; index++) {
      list.push(getRandomReportCategoryModel());
    }
    return list;
  };

  return {
    categories: model?.categories ? model?.categories : prepSubcategoryList(),
  };
};

export const getRandomReportTokenRefreshPayload = (
  model?: Partial<ReportTokenRefreshPayload>
): ReportTokenRefreshPayload => {
  return {
    token: model?.token ? model?.token : faker.string.alphanumeric(20),
    requestedDateTimeInMS: model?.requestedDateTimeInMS
      ? model?.requestedDateTimeInMS
      : new Date().getTime(),
    expiration: model?.expiration
      ? model?.expiration
      : parseInt(faker.string.numeric(10)),
  };
};
