import {
  IdeaEducationalEnvironmentModel,
  OutcomeType,
} from '../types/NonPublicStudentFormViewModel';
import { SelectOption } from '../types/SelectOption';
import { getAgeByDate } from './dateUtilities';

const getDecFirstOfEvaluationYear = (year: string): string => `${year}-12-01`;

export const isEducationEnvironmentDisabled = (
  evaluationYear: string,
  dob: string
): boolean => {
  if (!dob || !evaluationYear) {
    return true;
  }

  const age = getAgeByDate(dob, getDecFirstOfEvaluationYear(evaluationYear));
  return age < 3 || age > 21;
};

export const buildEducationEnvironmentOptions = (
  modelList: IdeaEducationalEnvironmentModel[],
  evaluationYear: string,
  dob: string,
  gradeLevel: string
): SelectOption[] => {
  let options: SelectOption[] = [];

  // Missing values
  if (!dob || !gradeLevel || !evaluationYear || modelList.length === 0) {
    return [];
  }

  if (isEducationEnvironmentDisabled(evaluationYear, dob)) {
    return [];
  }

  const studentAge = getAgeByDate(
    dob,
    getDecFirstOfEvaluationYear(evaluationYear)
  );
  const earlyChildhoodList = modelList
    .filter((x) => x.outcomeType === OutcomeType.EarlyChildhoodValues)
    .map((x) => ({ text: x.name, value: x.id }));
  const schoolAgeList = modelList
    .filter((x) => x.outcomeType === OutcomeType.SchoolAgeValues)
    .map((x) => ({ text: x.name, value: x.id }));

  /* istanbul ignore next */
  switch (studentAge) {
    case 3:
    case 4:
      options = earlyChildhoodList;
      break;
    case 5:
      if (gradeLevel === 'PK') {
        options = earlyChildhoodList;
      } else {
        options = schoolAgeList;
      }
      break;
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
      options = schoolAgeList;
      break;
  }

  return options;
};
