import { ReportEmbedData } from './ReportEmbedData';

export enum CertificationStatus {
  CERTIFIED = 'Certified',
  ERRORS = 'Errors',
  READY_TO_CERTIFY = 'Needs Certification',
  ALL_NEEDS_CERTIFICATION = 'Must Certify All Schools',
  OUTSIDE_DATE_RANGE = 'Outside of Date Range',
  TEMP_DISABLED = 'Temp. Disabled',
}

export enum CertifiableWithReason {
  UNKNOWN = '',
  YES = 'Yes',
  OUTSIDE_DATE_RANGE = 'NoOutsideOfDateRange',
  FEATURE_OVERRIDE_DISABLED = 'NoFeatureOverrideStatusDisabled',
}

export interface CertificationDistrictItem {
  itemId: string;
  leaName: string;
  leaNumber: string;
  certificationDate: string;
  certifiedBy: string;
  status: CertificationStatus;
  hasErrors: boolean;
  AreAnySchoolsNotCertified: boolean;
  isDataCollectionCertifiableWithReason: CertifiableWithReason;
}

export interface CertificationSchoolItem {
  itemId: string;
  schoolName: string;
  schoolNumber: string;
  certificationDate: string;
  certifiedBy: string;
  status: CertificationStatus;
  hasErrors: boolean;
  isDataCollectionCertifiableWithReason: CertifiableWithReason;
}

export interface CertificationDetailModel {
  id: string;
  name: string;
  headingText: string;
  certificationOpenDate: string;
  certificationCloseDate: string;
  statistics: CertificationStatistics;
  reportReferences: ReportReference[];
  schoolCertifications: CertificationSchoolItem[];
  districtCertifications: CertificationDistrictItem[];
}

export interface CertificationStatistics {
  totalSchoolCount: string;
  certifiedSchoolCount: string;
  isDistrictCertified: boolean;
}

export interface ReportReference {
  categoryName: string;
  subcategoryName: string;
  reportName: string;
}

export interface ResolvedReportList {
  reports: ReportEmbedData[];
  errors: string;
}
