import { ApiResponseModel } from '../types/ApiResponseModel';
import { DashboardModel } from '../types/DashboardModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const dashboardApiPaths = {
  getDashboardDetails: 'dashboard',
};

export const DashboardServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getDashboardDetails = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<DashboardModel>> => {
  return executeApiRequest<DashboardModel>(async () => {
    return await DashboardServiceClient(config).get(
      dashboardApiPaths.getDashboardDetails
    );
  });
};
