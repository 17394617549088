import { MSISRequestModel } from './MSISRequestModel';
import { SelectOption } from './SelectOption';

export interface MSISRequestListModel {
  students: MSISRequestModel[];
}

export const defaultRequestAgeInDays = '30';
export const getMSISRequestDayList = (): SelectOption[] => {
  const list: SelectOption[] = [];

  list.push({ text: '1 Day', value: '1' });
  list.push({ text: '3 Days', value: '3' });
  list.push({ text: '10 Days', value: '10' });
  list.push({ text: '30 Days', value: '30' });
  list.push({ text: '60 Days', value: '60' });
  list.push({ text: '90 Days', value: '90' });

  return list;
};
