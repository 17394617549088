/* eslint-disable max-len */
import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { FormTextboxPropTypes } from '../../../types/propTypes/FormTextboxPropTypes';
import {
  applyClassesForInput,
  extractMaxfromSchema,
} from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';
import './FormTextbox.css';

const FormTextbox = ({
  displayName,
  field: fieldName,
  disabled,
  classConfig,
  cypressDataId,
  validationSchema,
  onChangeEvent,
}: FormTextboxPropTypes): ReactElement => {
  const [, , { setValue, setTouched }] = useField(fieldName);
  const characterMax = extractMaxfromSchema(validationSchema, fieldName) || -1;
  const { resetForm, setFieldValue } = useFormikContext();

  const handleOnChange = async (
    event: React.ChangeEvent<HTMLButtonElement>
  ): Promise<void> => {
    const newValue = event.target.value;
    setTouched(true);
    setValue(newValue);

    onChangeEvent &&
      (await onChangeEvent({
        value: newValue,
        field: fieldName,
        resetHook: resetForm,
        setValueHook: setFieldValue,
      }));
  };

  return (
    <div
      className={`field-container ${classConfig?.containerClasses || ''} ${
        disabled ? 'disabled' : ''
      }`}
      data-testid={`form-textbox-${fieldName}`}
    >
      <FormInputLabel
        field={fieldName}
        displayName={displayName}
        validationSchema={validationSchema}
        classes={classConfig?.labelClasses || ''}
      />
      <Field
        data-testid={`form-textbox-${fieldName}-input`}
        className={applyClassesForInput(
          fieldName,
          `${classConfig?.inputClasses || ''} data-input`
        )}
        id={fieldName}
        name={fieldName}
        type="text"
        maxLength={characterMax}
        data-cy={cypressDataId}
        disabled={disabled}
        onChange={handleOnChange}
      />
      <ErrorMessage name={fieldName} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default FormTextbox;
