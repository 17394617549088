/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useLogout } from '../../hooks/useLogout';

const Logout = (): ReactElement => {
  const logoutHook = useLogout();

  useEffect(() => {
    const logoutUser = (): void => {
      logoutHook.onLogout();
    };
    logoutUser();
  }, [logoutHook]);

  return <></>;
};

export default Logout;
