/* eslint-disable max-len */
/* istanbul ignore file */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';

// These functions are used in formik content and this is very difficul to mock
// These functions will not be unit tested, but should be covered in integration tests
import { useField } from 'formik';

export const applyClassesForInput = (
  fieldName: string,
  inputClasses = ''
): string => {
  const [_field, meta, _helpers] = useField(fieldName);

  return meta.error && meta.touched
    ? `${inputClasses} is-invalid`
    : inputClasses;
};

export const applyClassesForGroupedInput = (
  fieldName: string,
  inputClasses = ''
): string => {
  const [_field, meta, _helpers] = useField(fieldName);

  return meta.error && meta.touched
    ? `${inputClasses} is-group-invalid`
    : inputClasses;
};

export const createDownloadLinkAndExecute = (
  fileName: string,
  blob: Blob
): void => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const extractMaxfromSchema = (
  schema: Yup.AnyObjectSchema | undefined,
  fieldName: string
): number | undefined => {
  if (schema) {
    const describeSchema = schema.describe();
    const fieldValidation = describeSchema.fields[fieldName] as any;
    if (fieldValidation && fieldValidation.tests) {
      const testArray = fieldValidation.tests;
      const maxObject = testArray.filter((x: any) => x.name === 'max');
      if (maxObject.length > 0) {
        const maxNumber = maxObject[0].params.max;
        return maxNumber;
      }
    }
  }
  return undefined;
};

export const extractRequiredFromSchema = (
  schema: Yup.AnyObjectSchema | undefined,
  fieldName: string
): boolean => {
  if (schema) {
    const describeSchema = schema.describe();
    const fieldValidation = describeSchema.fields[fieldName] as any;

    if (fieldValidation && fieldValidation.tests) {
      const testArray = fieldValidation.tests;
      const item = testArray.filter((x: any) => x.name === 'required');
      if (item.length > 0) {
        return true;
      }
    }
  }
  return false;
};
