/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectLEAManagementListItems,
  selectLEAManagementLoadingListItems,
} from '../../../../redux/slices/leaManagementSlice';
import { appRoutePaths } from '../../../../services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';

import { fetchLEAManagementListItems } from '../../../../redux/thunks/leaManagementThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { LEAManagementListItemModel } from '../../../../types/LEAManagementListItemModel';
import { isStateAdmin } from '../../../../utilities/userUtilities';
import './LEAManagementList.css';

const LEAManagementList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectLEAManagementLoadingListItems);
  const listItems: LEAManagementListItemModel[] = useAppSelector(
    selectLEAManagementListItems
  );

  useEffect(() => {
    const promise: unknown = dispatch(fetchLEAManagementListItems());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const handleEdit = (id: string): void => {
    navigate(appRoutePaths.LEAManagementInput(id));
  };

  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--lea-management--list"
      isLoading={isLoading}
      loadingDataId={'lea-management-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>LEA Management</h4>
            {isStateAdmin() && (
              <NavigationButton
                route={appRoutePaths.LEAManagementInput('new')}
                visibleOverride={canUserAccessRoute(
                  appRoutePaths.LEAManagementInput()
                )}
                classes="button no-wrap-text new-button"
                tooltipText="Add New LEA Management"
              >
                <>Add New</>
              </NavigationButton>
            )}
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>LEA Name</th>
                  <th>LEA Type</th>
                  <th>LEA Identifier</th>
                  <th>LEA Operational Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((leaManagement) => (
                    <tr
                      key={`${leaManagement.id}`}
                      data-testid={leaManagement.id}
                    >
                      <td>{leaManagement.leaName}</td>
                      <td>{leaManagement.leaType}</td>
                      <td>{leaManagement.leaIdentifier}</td>
                      <td>{leaManagement.leaOperationalStatus}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${leaManagement.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(leaManagement.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit/View LEA Management'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DistrictSchoolInfoPage>
  );
};

export default LEAManagementList;
