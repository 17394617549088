/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectStudentLoading,
  selectStudentOwnershipConflicts,
} from '../../redux/slices/studentSlice';
import { fetchStudentOwnershipConflict } from '../../redux/thunks/studentThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { StudentOwnershipConflictModel } from '../../types/StudentOwnershipConflictModel';

const StudentOwnershipConflict = (): ReactElement => {
  const dispatch = useAppDispatch();
  const studentOwnerConflicts: StudentOwnershipConflictModel[] = useAppSelector(
    selectStudentOwnershipConflicts
  );

  useEffect(() => {
    const promise = dispatch(fetchStudentOwnershipConflict());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const isLoading = useAppSelector(selectStudentLoading);
  const showEmptyState = !isLoading && studentOwnerConflicts.length === 0;

  return (
    <NavigationPage
      heading="Student Ownership"
      pageClass="student-owner-conflict--list"
      isLoading={isLoading}
      loadingDataId="student-owner-conflict-loader"
      loadingText="Loading student ownership conflicts"
    >
      <table cellSpacing={0} className="page-view-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>MSIS ID</th>
            <th>Ownership Conflict</th>
          </tr>
        </thead>
        <tbody>
          {studentOwnerConflicts.length > 0 &&
            studentOwnerConflicts.map((studentOwnerConflict) => (
              <tr
                key={`${studentOwnerConflict.ownerConflictId}-${studentOwnerConflict.fullName}`}
              >
                <td
                  className="student-owner-conflict--list--name"
                  data-cy="student-owner-conflict-data-name"
                >
                  <>
                    <NavigationButton
                      route={appRoutePaths.StudentOwnershipDetail(
                        studentOwnerConflict.ownerConflictId
                      )}
                      visibleOverride={true}
                      tooltipText="Navigate to the conflict detail page"
                    >
                      <>{studentOwnerConflict.fullName}</>
                    </NavigationButton>
                  </>
                </td>
                <td
                  className="student-owner-conflict--list--msis-id"
                  data-cy="student-owner-conflict-data-msis-id"
                >
                  {studentOwnerConflict.msisId}
                </td>
                <td
                  className="student-owner-conflict--list--district-name"
                  data-cy="student-owner-conflict-data-district-name"
                >
                  {studentOwnerConflict.conflictDistrictName}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {showEmptyState && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>You currently have no student ownership conflicts</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default StudentOwnershipConflict;
