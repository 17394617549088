import { Form, Formik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModalShow } from '../../hooks/useModalShow';
import { useAppDispatch } from '../../redux/hooks';
import { createNotification } from '../../redux/slices/notificationSlice';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { DataEntryMode } from '../../types/DataEntryMode';
import { DialogType } from '../../types/DialogTypes';
import {
  ManualInputCrudAction,
  ManualInputPagePropTypes,
} from '../../types/propTypes/ManualInputPagePropTypes';
import {
  capitalizeFirstLetterOnly,
  convertAllWordsToBeginWithCapitals,
} from '../../utilities/textUtilities';
import ActionButton from '../ActionButton/ActionButton';
import ApiErrorPanel from '../ApiErrorPanel/ApiErrorPanel';
import BackBar from '../BackBar/BackBar';
import Loader from '../Loader/Loader';
import Modal from '../Modal/Modal';
import NavigationTabToolbar from '../NavigationTabToolbar/NavigationTabToolbar';
import './ManualInputPage.css';

export const MIP_NEW_ROUTE_NAME = 'new';

const ManualInputPage = <T, F>({
  actions,
  navigationTabs,
  classes,
  loadingOptions,
  parameterOptions,
  routes,
  dataFunctions,
  formDetails,
  config,
  children,
  DEV_MODE,
}: ManualInputPagePropTypes<T, F>): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryParams = useParams();

  let dataEntryMode: DataEntryMode;
  let dataId: string | undefined;
  let dataIdentityId: string | undefined;

  if (actions === ManualInputCrudAction.CREATE_ONLY) {
    dataEntryMode = DataEntryMode.NEW;
  } else {
    dataEntryMode = config.dataEntryMode;
    dataId = queryParams[parameterOptions.routeParameterName];
    dataIdentityId = parameterOptions.identityName;
  }

  // Used for useEffect
  const isFormModelEmpty = formDetails.isFormModelEmpty;
  const loadingDetailFunc = dataFunctions.loadDataDetailById;
  const loadingFormFunc = dataFunctions.loadFormDetail;
  const clearFunc = dataFunctions.clearData;
  const setEntryMode = dataFunctions.setEntryMode;

  const isLoading = loadingOptions.isLoading;
  const pageClasses = classes?.pageClass ? classes?.pageClass : '';
  const contentClasses = classes?.contentClass ? classes?.contentClass : '';
  const loaderText = loadingOptions?.loadingText || 'Loading';

  const getSubmitButtonText = (): string =>
    dataEntryMode == DataEntryMode.NEW ? 'Create' : 'Save';
  const getNotificationText = (): string =>
    dataEntryMode == DataEntryMode.NEW
      ? `New ${capitalizeFirstLetterOnly(config.objectVerbiage)} created`
      : `${capitalizeFirstLetterOnly(config.objectVerbiage)} edited`;
  const getSubHeaderText = (): string =>
    config.subHeading ||
    convertAllWordsToBeginWithCapitals(config.objectVerbiage);

  const [error, setError] = useState<ApiErrorModel | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { show, setShow } = useModalShow();

  useEffect(() => {
    if (
      actions !== ManualInputCrudAction.CREATE_ONLY &&
      dataId === MIP_NEW_ROUTE_NAME
    ) {
      setEntryMode && dispatch(setEntryMode(DataEntryMode.NEW));
      clearFunc && dispatch(clearFunc());
    }
  }, [actions, dataId, dispatch, clearFunc, setEntryMode]);

  useEffect(() => {
    let promise: unknown = undefined;
    if (
      actions !== ManualInputCrudAction.CREATE_ONLY &&
      dataId &&
      dataId !== MIP_NEW_ROUTE_NAME
    ) {
      setEntryMode && dispatch(setEntryMode(DataEntryMode.EDIT));

      if (dataIdentityId !== dataId) {
        clearFunc && dispatch(clearFunc());
        promise = loadingDetailFunc && dispatch(loadingDetailFunc(dataId));
      }
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [
    actions,
    dataId,
    dataIdentityId,
    loadingDetailFunc,
    clearFunc,
    setEntryMode,
    dispatch,
  ]);

  useEffect(() => {
    let promise: unknown = undefined;
    if (isFormModelEmpty) {
      promise = dispatch(loadingFormFunc());
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, isFormModelEmpty, loadingFormFunc]);

  const handleSave = async (values: T): Promise<void> => {
    setIsSaving(true);

    const buttonAction =
      actions === ManualInputCrudAction.CREATE_ONLY
        ? dataFunctions.onCreate
        : dataEntryMode == DataEntryMode.NEW
        ? dataFunctions.onCreate
        : dataFunctions.onUpdate;
    const result = await buttonAction(values);

    if (isValidCode(result.status)) {
      setError(undefined);
      dispatch(
        createNotification({
          severity: 'success',
          children: getNotificationText(),
        })
      );

      dataFunctions.setData && dispatch(dataFunctions.setData(values));

      if (dataEntryMode == DataEntryMode.NEW) {
        const newId = result.data as string;
        navigate(routes.createSuccessRoute(newId));
      }
    } else {
      setError(result.error);
    }

    setIsSaving(false);
  };

  const handleDialogClose = (): void => {
    setShow(false);
  };

  const getSubmitDisabled = (
    isValid: boolean,
    dirty: boolean
  ): boolean | undefined => {
    return (
      !isValid || isSaving || (dataEntryMode == DataEntryMode.EDIT && !dirty)
    );
  };

  const getSubmitButtonTooltip = (isValid: boolean, dirty: boolean): string => {
    if (getSubmitDisabled(isValid, dirty)) {
      if (dataEntryMode == DataEntryMode.EDIT && !dirty && isValid) {
        return '** No changes have been made **';
      }
      return '** Make sure all required fields are complete **';
    } else {
      return dataEntryMode == DataEntryMode.NEW
        ? `Create New ${convertAllWordsToBeginWithCapitals(
            config.objectVerbiage
          )}`
        : 'Save Changes';
    }
  };

  const handleCancelClick = (isDirty: boolean): void => {
    if (!isDirty) {
      navigate(routes.cancelRoute);
    } else {
      setShow(true);
    }
  };

  const handleLeavePage = (): void => {
    navigate(routes.cancelRoute);
  };

  return (
    <>
      <div
        data-testid={'manual-input-page'}
        className={`page--grid manual-input ${pageClasses}`}
      >
        {routes.backBarRoute && (
          <BackBar to={routes.backBarRoute} visibleOverride={true} />
        )}
        <h2 className="page--heading">
          <span className="page-heading-content" data-testid="page-header">
            {config.pageHeader}
          </span>
        </h2>
        <div className="page--tab-bar">
          {navigationTabs && (
            <NavigationTabToolbar navigationTabs={navigationTabs} />
          )}
        </div>
        {isLoading ? (
          <div className="manual-loading-container">
            <Loader
              dataTestId={loadingOptions?.loadingDataId}
              message={loaderText}
            />
          </div>
        ) : (
          <div className="page--content">
            <span
              data-testid="content-panel"
              className={`page--content ${contentClasses}`}
            >
              <Formik
                enableReinitialize={true}
                validateOnChange={true}
                validateOnMount={true}
                validationSchema={formDetails.validationSchema}
                initialValues={formDetails.initialFormValues as Object}
                onSubmit={async (formValues) => {
                  await handleSave(formValues as T);
                }}
              >
                {({ dirty, isValid, values, touched, errors }) => (
                  <Form>
                    <div className="heading-button-error-container">
                      <div className="manual-button-container">
                        <span
                          className="subheading"
                          data-testid="page-subheading"
                        >
                          {getSubHeaderText()}
                        </span>
                        <div className="button-group">
                          <p className="required-label">* Required Field</p>
                          <ActionButton
                            classes="button no-wrap-text cancel-button"
                            onClick={() => handleCancelClick(dirty)}
                            dataTestId="cancel-button"
                            tooltipText={'Navigate to the last page'}
                          >
                            <>Cancel</>
                          </ActionButton>
                          <ActionButton
                            buttonType="submit"
                            classes="button--secondary no-wrap-text submit-button"
                            onClick={(x: Object) => {
                              DEV_MODE &&
                                console.log(
                                  'Submitting form via button type',
                                  JSON.stringify(x, null, 2)
                                );
                            }}
                            dataTestId="new-submit-button"
                            disabled={getSubmitDisabled(isValid, dirty)}
                            tooltipText={getSubmitButtonTooltip(isValid, dirty)}
                            loading={isSaving}
                          >
                            <>{getSubmitButtonText()}</>
                          </ActionButton>
                        </div>
                      </div>
                      {error && (
                        <ApiErrorPanel
                          error={error}
                          containerClass={'page-error-panel'}
                          includeTitle={true}
                          supportedNonGenericErrors={
                            config.supportedNonGenericErrors
                          }
                        />
                      )}
                    </div>
                    <div>{children}</div>
                    {DEV_MODE && (
                      <div data-testid="dev-mode-panel">
                        <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                        <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                        <pre>Is Valid: {isValid.toString()}</pre>
                        <pre>Is Dirty: {dirty.toString()}</pre>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </span>
          </div>
        )}
      </div>

      {show && (
        <Modal
          type={DialogType.CONFIRM}
          open={show}
          onClose={handleDialogClose}
          title={'Are you sure?'}
        >
          <div className="confirm-container" data-testid="confirmation-modal">
            <p data-testid="cancel-message">
              Your changes have not been saved. Do you wish to continue?
            </p>
            <div className="button-row">
              <ActionButton
                onClick={handleLeavePage}
                dataTestId="yes-button"
                tooltipText={'Navigate to the last page'}
              >
                <span>Yes</span>
              </ActionButton>
              <ActionButton
                classes="button--secondary"
                onClick={handleDialogClose}
                dataTestId="no-button"
                tooltipText={'Stay on existing page'}
              >
                <span>No</span>
              </ActionButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManualInputPage;
