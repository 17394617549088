import { ReactElement } from 'react';
import { PanelPropTypes } from '../../types/propTypes/PanelPropTypes';
import { sanitizeText } from '../../utilities/helperUtilities';
import './Panel.css';

const Panel = ({
  panelType,
  heading,
  children,
}: PanelPropTypes): ReactElement => {
  return (
    <div
      data-testid={`panel-container-${sanitizeText(heading)}`}
      className={`panel-block`}
    >
      <div role="panel-header" className={`panel-heading panel-${panelType}`}>
        {heading}
      </div>
      <div role="panel-content" className="panel-content">
        {children}
      </div>
    </div>
  );
};

export default Panel;
