import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectHasDataQualityErrors } from '../../../redux/slices/dashboardSlice';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import FailureCard from '../FailureCard/FailureCard';

const DataQualityCard = (): ReactElement => {
  const hasErrors = useAppSelector(selectHasDataQualityErrors);

  if (!hasErrors) {
    return <></>;
  } else {
    return (
      <FailureCard
        message={'Your data has errors'}
        buttonText={'Review'}
        buttonLink={appRoutePaths.DataQualityDashboard}
        buttonVisible={canUserAccessRoute(appRoutePaths.DataQualityDashboard)}
        tooltipText={'Click to review errors'}
      />
    );
  }
};

export default DataQualityCard;
