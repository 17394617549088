/* eslint-disable max-len */
import { ReactElement } from 'react';
import { MergeStudentPanelType } from '../../../types/MergeStudentPanelType';
import { MergeStudentPanelPropTypes } from '../../../types/propTypes/MergeStudentPanelPropTypes';
import { formatToDateString } from '../../../utilities/dateUtilities';
import LabelAndValue from '../../LabelAndValue/LabelAndValue';
import './MergeStudentPanel.css';

const MergeStudentPanel = ({
  student,
  panelType,
  highlights,
  cypressDataId,
}: MergeStudentPanelPropTypes): ReactElement => {
  let panelClass = '';
  let headerText = '';

  switch (panelType) {
    case MergeStudentPanelType.CURRENT:
      panelClass = 'current';
      headerText = 'MSIS ID Request Data';
      break;
    case MergeStudentPanelType.EXISTING:
      panelClass = 'match';
      headerText = 'Matched Student';
      break;
    case MergeStudentPanelType.NEW:
      panelClass = 'new';
      headerText = 'MSIS ID Request Data';
      break;
  }

  const shouldHighlightField = (fieldName: string): boolean => {
    if (highlights) {
      return highlights.includes(fieldName);
    }
    return false;
  };

  return (
    <div
      className={`${panelClass}-section`}
      data-testid={`${panelClass}-panel`}
      data-cy={cypressDataId}
    >
      <div
        className={`${panelClass}-heading`}
        data-testid={`${panelClass}-panel-header`}
      >
        {headerText}
      </div>
      <div
        className={`${panelClass}-fields`}
        data-testid={`${panelClass}-panel-fields`}
      >
        <LabelAndValue
          displayName={'First Name'}
          value={student.firstName}
          containerClass={`modal-field-container`}
          highlighted={shouldHighlightField('firstName')}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'Middle Name'}
          value={student.middleName}
          containerClass={`modal-field-container`}
          highlighted={shouldHighlightField('middleName')}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'Last Name'}
          value={student.lastName}
          containerClass={`modal-field-container`}
          highlighted={shouldHighlightField('lastName')}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'DOB'}
          value={formatToDateString(student.dateOfBirth)}
          containerClass={`modal-field-container`}
          highlighted={shouldHighlightField('dateOfBirth')}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'SSN'}
          value={student.ssn}
          containerClass={`modal-field-container`}
          highlighted={shouldHighlightField('ssn')}
          valueClass={`field-value`}
        />
        <div className="section-separator">Additional Context</div>
        <LabelAndValue
          displayName={'Address'}
          value={student.address}
          containerClass={`modal-field-container`}
          highlighted={false}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'Parent/Guardian'}
          value={student.parentGuardian}
          containerClass={`modal-field-container`}
          highlighted={false}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'District'}
          value={student.district}
          containerClass={`modal-field-container`}
          highlighted={false}
          valueClass={`field-value`}
        />
        <LabelAndValue
          displayName={'School'}
          value={student.school}
          containerClass={`modal-field-container`}
          highlighted={false}
          valueClass={`field-value`}
        />
      </div>
    </div>
  );
};

export default MergeStudentPanel;
