import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DistrictModel } from '../../types/DistrictModel';
import type { RootState } from '../store';

interface AppState {
  loading: boolean;
  selectedDistrict: DistrictModel;
}

const defaultDistrict = {
  districtId: '',
  name: '',
  type: '',
};

export const initialState: AppState = {
  loading: false,
  selectedDistrict: defaultDistrict,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSelectedDistrict: (state, action: PayloadAction<DistrictModel>) => {
      state.selectedDistrict = action.payload;
    },
  },
});

export const { setSelectedDistrict } = appSlice.actions;

export const selectAppLoading = (state: RootState): boolean =>
  state.app.loading;
export const selectSelectedDistrict = (state: RootState): DistrictModel =>
  state.app.selectedDistrict;

export default appSlice.reducer;
