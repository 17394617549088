/* eslint-disable max-len */
import { Form, Formik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import FormDateSelector from '../../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import Panel from '../../../../components/Panel/Panel';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectCharterSchoolAssignmentLoading,
  selectNewCharterSchoolAssignmentViewModel,
} from '../../../../redux/slices/charterSchoolAssignmentSlice';
import { fetchCharterSchoolAssignmentNewViewModel } from '../../../../redux/thunks/charterSchoolThunks';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import { CharterSchoolAssignmentModel } from '../../../../types/CharterSchoolAssignmentModel';
import { CharterSchoolAssignmentNewViewModel } from '../../../../types/CharterSchoolAssignmentNewViewModel';
import { PanelType } from '../../../../types/PanelType';
import { SelectOption } from '../../../../types/SelectOption';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';
import './NewCharterSchoolAssignment.css';

const NewCharterSchoolAssignment = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectCharterSchoolAssignmentLoading);
  const viewModel: CharterSchoolAssignmentNewViewModel = useAppSelector(
    selectNewCharterSchoolAssignmentViewModel
  );
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);

  useEffect(() => {
    let promise: unknown = undefined;
    if (!viewModel.districtOptions?.length) {
      promise = dispatch(fetchCharterSchoolAssignmentNewViewModel());
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, viewModel]);

  const initialFormValues: CharterSchoolAssignmentModel = {
    district: '',
    school: '',
    charterContractId: '',
    approvalDate: '',
    renewalDate: '',
  };

  const schoolOptionsForDistrict = (district: string): SelectOption[] => {
    if (!district) {
      return [];
    }

    const matchingDistrictOption = viewModel.districtOptions.find(
      (x) => x.value == district
    );

    /* istanbul ignore next */
    return matchingDistrictOption ? matchingDistrictOption.schoolOptions : [];
  };

  const saveUpdates = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    values: CharterSchoolAssignmentModel
  ): Promise<void> => {
    setSubmitEnabled(false);
    // TODO: make call to save
    setSubmitEnabled(true);
  };

  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--charter-schools--assignments--new"
      isLoading={isLoading}
      loadingDataId="new-charter-school-assignment-loader"
      backBarLocation={appRoutePaths.NewCharterSchoolManagementOrganization}
    >
      <Formik
        validateOnChange={true}
        validateOnMount={true}
        initialValues={initialFormValues}
        onSubmit={async (values) => {
          await saveUpdates(values);
        }}
      >
        {({ values }) => (
          <Form>
            <div className="subheading-and-button-container">
              <span className="subheading">School Assignment</span>
              <div className="button-group">
                <NavigationButton
                  route={appRoutePaths.NewCharterSchoolManagementOrganization}
                  classes="button no-wrap-text cancel-button"
                  tooltipText="Navigate to the last page"
                >
                  <>Cancel</>
                </NavigationButton>
                <ActionButton
                  buttonType="submit"
                  classes="button--secondary submit-button"
                  onClick={() => {
                    return undefined;
                  }}
                  dataTestId="new-submit"
                  disabled={isLoading || !submitEnabled}
                  tooltipText="Submit New Charter School Assignment"
                >
                  <>Save</>
                </ActionButton>
              </div>
            </div>

            <Panel panelType={PanelType.INFO} heading="General Information">
              <div className="field-row">
                <FormDropdown
                  displayName="District"
                  field="district"
                  options={viewModel.districtOptions}
                />
                <FormDropdown
                  displayName="School"
                  field="school"
                  options={schoolOptionsForDistrict(values.district)}
                  disabled={!values.district}
                />
              </div>

              <div className="field-row date-selector-row">
                <FormTextbox
                  displayName="Charter Contract ID"
                  field="school"
                  disabled={true}
                />
                <FormDateSelector
                  displayName="Approval Date"
                  field="approvalDate"
                />
                <FormDateSelector
                  displayName="Renewal Date"
                  field="renewalDate"
                />
              </div>
            </Panel>
          </Form>
        )}
      </Formik>
    </DistrictSchoolInfoPage>
  );
};

export default NewCharterSchoolAssignment;
