/* eslint-disable max-len*/
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import {
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowsProp,
} from '@mui/x-data-grid-pro';
import { Form, Formik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import ActionButton from 'src/components/ActionButton/ActionButton';
import FormDropdown from 'src/components/FormInputs/FormDropdown/FormDropdown';
import { abortPromiseOnUnmount } from 'src/services/base.service';
import { updateSalaryScale } from 'src/services/salaryScale.service';
import { FormDropdownChangeEventConfig } from 'src/types/propTypes/FormDropdownPropTypes';
import { SalaryScaleFormViewModel } from 'src/types/SalaryScaleFormViewModel';
import { SalaryScaleListItemModel } from 'src/types/SalaryScaleListItemModel';
import { SalaryScaleValidationSchema } from 'src/types/Validation/SalaryScaleValidationSchema';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import Modal from '../../../../components/Modal/Modal';
import StyledGrid from '../../../../components/StyledGrid/StyledGrid';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { createNotification } from '../../../../redux/slices/notificationSlice';
import {
  defaultAdditionalSalaryState,
  selectAdditionalSalary,
  selectMinimumSalarySchedule,
  selectSalaryScaleFormViewModel,
  selectSalaryScaleListItems,
  selectSalaryScaleLoadingFormViewModel,
  selectSalaryScaleLoadingListItems,
  selectSchoolYear,
  selectUpdatedListItems,
  setAdditionalSalary,
  setDefaultUpdatedListItems,
  setIsListItemLoading,
  setSchoolYearFilter,
  setUpdatedListItems,
} from '../../../../redux/slices/salaryScaleSlice';
import {
  fetchMinSalaryScaleListItems,
  fetchSalaryScaleFormViewModel,
  fetchSalaryScaleListItems,
  updateAllSalaryScaleListItems,
} from '../../../../redux/thunks/salaryScaleThunks';
import { DialogType } from '../../../../types/DialogTypes';
import {
  isDistrictAdmin,
  isStateAdmin,
} from '../../../../utilities/userUtilities';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';
import './SalaryScaleList.css';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

//eslint-disable-next-line
function NameEditInputCell(props: GridRenderEditCellParams): any {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

//eslint-disable-next-line
function renderEditName(params: GridRenderEditCellParams): any {
  return <NameEditInputCell {...params} />;
}

const SalaryScaleList = (): ReactElement => {
  const schoolYear = useAppSelector(selectSchoolYear);
  const isListItemsLoading = useAppSelector(selectSalaryScaleLoadingListItems);
  const isFormViewLoading = useAppSelector(
    selectSalaryScaleLoadingFormViewModel
  );
  const listItems = useAppSelector(selectSalaryScaleListItems);
  const updatedList: SalaryScaleListItemModel[] = useAppSelector(
    selectUpdatedListItems
  );
  const addlSalary: string = useAppSelector(selectAdditionalSalary);
  const formViewModel: SalaryScaleFormViewModel = useAppSelector(
    selectSalaryScaleFormViewModel
  );
  const minimumSalarySchedule = useAppSelector((state) =>
    selectMinimumSalarySchedule(state, schoolYear)
  );

  const isLoading = isListItemsLoading || isFormViewLoading;
  const dispatch = useAppDispatch();
  const [isPreviousYearsDisabled, setIsPreviousYearsDisabled] = useState(false);
  const [isApplyToAllDisabled, setIsApplyToAllDisabled] = useState(false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const regExpression = new RegExp(
    /^(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
  );

  useEffect(() => {
    dispatch(fetchSalaryScaleFormViewModel());
  }, [dispatch]);

  useEffect(() => {
    if (schoolYear === '') {
      dispatch(
        setSchoolYearFilter(
          formViewModel?.historicalSchoolYearOptions[1]?.value
        )
      );
    }
  }, [dispatch, schoolYear, formViewModel]);

  useEffect(() => {
    let promise: unknown;
    if (schoolYear) {
      dispatch(fetchMinSalaryScaleListItems(schoolYear));
      promise = dispatch(fetchSalaryScaleListItems(schoolYear));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, schoolYear, refreshData]);

  const [showApplyToAllModal, setShowAllyToAllModal] = useState(false);
  const [showCancelModal, setCancelModal] = useState(false);

  const handleYesInCancel = (): void => {
    setCancelModal(false);
    dispatch(setDefaultUpdatedListItems());
    setRefreshData(!refreshData);
  };

  const handleNoInCancel = (): void => {
    setCancelModal(false);
  };

  const handleCancel = (): void => {
    setCancelModal(true);
  };

  useEffect(() => {
    const setShowForCancel = updatedList.length > 0 ? false : true;
    setIsCancelDisabled(setShowForCancel);
  }, [updatedList]);

  const handleSave = async (): Promise<void> => {
    try {
      dispatch(setIsListItemLoading(true));
      await updateSalaryScale(updatedList);
      dispatch(
        createNotification({
          severity: 'success',
          children: 'Salary Scale edited',
        })
      );
    } catch {
      dispatch(
        createNotification({
          severity: 'error',
          children: 'Salary Scale edit failed, resetting values',
        })
      );
    }
    dispatch(setDefaultUpdatedListItems());
    setRefreshData(!refreshData);
  };

  const handleUpdateAll = async (): Promise<void> => {
    const paramsObj = {
      schoolYear: schoolYear,
      additionalSalary: addlSalary,
    };
    setShowAllyToAllModal(false);
    await dispatch(updateAllSalaryScaleListItems(paramsObj));
    setRefreshData(!refreshData);
  };

  //eslint-disable-next-line
  const handleApplyToAll = (values: any, resetForm: any): void => {
    let salValue = values.additionalSalary;
    if (salValue.charAt(0) === '$') {
      salValue = Number(salValue.substring(1)).toString();
    }
    dispatch(setAdditionalSalary(salValue.toString()));

    resetForm(defaultAdditionalSalaryState);
    setShowAllyToAllModal(true);
  };

  // eslint-disable-next-line
  const processRowUpdate = (newRow: any, oldRow: any): void => {
    if (JSON.stringify(oldRow) !== JSON.stringify(newRow)) {
      dispatch(setUpdatedListItems(newRow));
    }
  };

  const updateAdditionalSalary = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const defaultAdditionalSalary = '$0.00';
    /* istanbul ignore next */
    if (config.setValueHook && defaultAdditionalSalary) {
      config.setValueHook('additionalSalary', defaultAdditionalSalary);
    }

    if (config.value) {
      const showValue =
        config.value.toString() ===
          formViewModel.historicalSchoolYearOptions[0].value ||
        config.value.toString() ===
          formViewModel.historicalSchoolYearOptions[1].value
          ? false
          : true;
      setIsPreviousYearsDisabled(showValue);
      setIsApplyToAllDisabled(true);
      dispatch(setSchoolYearFilter(config.value?.toString()));
      dispatch(setDefaultUpdatedListItems());
    }
  };

  const handleDialogClose = (): void => {
    setShowAllyToAllModal(false);
  };

  const getMinimumSalaryScheduleByYearsOfExperience = (
    yoe: string
  ): SalaryScaleListItemModel[] => {
    const minSalary = minimumSalarySchedule?.filter(
      (eachRecord) => eachRecord.yearsOfExperience === yoe
    );

    return minSalary;
  };

  const isParamValid = (
    paramValue: number,
    stateMinSal: number
  ): string | null => {
    let isError;

    if (!regExpression.test(paramValue.toString())) {
      isError = 'Must be in this format 00000.00 without commas or $ symbol';
    } else {
      isError =
        paramValue < stateMinSal
          ? `Selected value cannot be lower than the State minimum salary $${stateMinSal.toLocaleString()}`
          : null;
    }

    return isError;
  };

  const preProcessEditCellPropsA = (
    params: GridPreProcessEditCellProps
    //eslint-disable-next-line
  ): any => {
    const stateMinimumSalaryFoAssociateDegree = Number(
      getMinimumSalaryScheduleByYearsOfExperience(
        params.row.yearsOfExperience
      )[0]?.aAssociateDegreeSalary
    );

    const errorMessage = isParamValid(
      params.props.value,
      stateMinimumSalaryFoAssociateDegree
    );

    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellPropsAA = (
    params: GridPreProcessEditCellProps
    //eslint-disable-next-line
  ): any => {
    const stateMinimumSalaryForBachelorsDegree = Number(
      getMinimumSalaryScheduleByYearsOfExperience(
        params.row.yearsOfExperience
      )[0]?.aaBachelorDegreeSalary
    );

    const errorMessage = isParamValid(
      params.props.value,
      stateMinimumSalaryForBachelorsDegree
    );

    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellPropsAAA = (
    params: GridPreProcessEditCellProps
    //eslint-disable-next-line
  ): any => {
    const stateMinimumSalaryForMastersDegree = Number(
      getMinimumSalaryScheduleByYearsOfExperience(
        params.row.yearsOfExperience
      )[0]?.aaaMasterDegreeSalary
    );

    const errorMessage = isParamValid(
      params.props.value,
      stateMinimumSalaryForMastersDegree
    );

    if (errorMessage !== '') {
      setIsCancelDisabled(true);
    }

    return { ...params.props, error: errorMessage };
  };

  //eslint-disable-next-line
  const preProcessEditCellPropsAAAA = (
    params: GridPreProcessEditCellProps
    //eslint-disable-next-line
  ): any => {
    const stateMinimumSalaryForPhDDegree = Number(
      getMinimumSalaryScheduleByYearsOfExperience(
        params.row.yearsOfExperience
      )[0]?.aaaaPhDDegreeSalary
    );

    const errorMessage = isParamValid(
      params.props.value,
      stateMinimumSalaryForPhDDegree
    );

    return { ...params.props, error: errorMessage };
  };

  const columns: GridColDef[] = [
    {
      field: 'yearsOfExperience',
      headerName: 'Years of Experience',
      type: 'number',
      editable: false,
      align: 'center',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'aAssociateDegreeSalary',
      headerName: "A - Bachelor's Degree",
      type: 'string',
      editable:
        !isPreviousYearsDisabled && (isDistrictAdmin() || isStateAdmin()),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      valueFormatter: (cellValues) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(cellValues);
      },
      preProcessEditCellProps: preProcessEditCellPropsA,
      renderEditCell: renderEditName,
    },
    {
      field: 'aaBachelorDegreeSalary',
      headerName: "AA - Master's Degree",
      type: 'string',
      editable:
        !isPreviousYearsDisabled && (isDistrictAdmin() || isStateAdmin()),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      valueFormatter: (cellValues) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(cellValues);
      },
      preProcessEditCellProps: preProcessEditCellPropsAA,
      renderEditCell: renderEditName,
    },
    {
      field: 'aaaMasterDegreeSalary',
      headerName: "AAA - Specialist's Degree",
      type: 'string',
      editable:
        !isPreviousYearsDisabled && (isDistrictAdmin() || isStateAdmin()),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      valueFormatter: (cellValues) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(cellValues);
      },
      preProcessEditCellProps: preProcessEditCellPropsAAA,
      renderEditCell: renderEditName,
    },
    {
      field: 'aaaaPhDDegreeSalary',
      headerName: 'AAAA - Doctorate Degree',
      type: 'string',
      editable:
        !isPreviousYearsDisabled && (isDistrictAdmin() || isStateAdmin()),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      valueFormatter: (cellValues) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(cellValues);
      },
      preProcessEditCellProps: preProcessEditCellPropsAAAA,
      renderEditCell: renderEditName,
    },
  ];
  const rows: GridRowsProp = listItems;

  const cancelTooltip = (): string => {
    const retVal =
      updatedList.length > 0
        ? 'Reset table data'
        : '** No changes have been made **';
    return retVal;
  };

  const saveTooltip = (): string => {
    const retVal =
      updatedList.length > 0
        ? 'Save Changes'
        : '** No changes have been made **';
    return retVal;
  };

  const applyToAllTooltip = (isValid: boolean, dirty: boolean): string => {
    const retVal =
      isValid && dirty
        ? 'Apply to all records'
        : '** No additional salary provided **';
    return retVal;
  };

  const getApplyToAllDisabled = (
    isValid: boolean,
    dirty: boolean
  ): boolean | undefined => {
    return !isValid || !dirty;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={defaultAdditionalSalaryState}
        validationSchema={SalaryScaleValidationSchema}
        onSubmit={handleSave}
      >
        {({ dirty, isValid, values, resetForm }) => (
          <Form>
            <DistrictSchoolInfoPage
              pageClass="data-input--district-school-info--salary-scale--list"
              isLoading={isLoading}
              loadingDataId={'salary-scale-list-loader'}
            >
              <div className="content-outer-container">
                <div className="content-inner-container">
                  <div>
                    <p>
                      To apply a{' '}
                      <strong>standard amount to all salaries</strong>, use the
                      Additional Salary field and select{' '}
                      <strong>&#39;Apply to All&#39;</strong>.
                      <br />
                      To adjust a <strong>singular salary amount</strong>, click
                      in the cell to edit the value.
                    </p>
                  </div>
                  <div className="button-group">
                    <ActionButton
                      classes="button no-wrap-text cancel-button"
                      onClick={handleCancel}
                      dataTestId="cancel-button"
                      tooltipText={cancelTooltip()}
                      disabled={
                        isCancelDisabled ||
                        !(isDistrictAdmin() || isStateAdmin())
                      }
                    >
                      <span>Cancel</span>
                    </ActionButton>
                    <ActionButton
                      classes="button--secondary submit-button"
                      onClick={handleSave}
                      dataTestId="save-button"
                      tooltipText={saveTooltip()}
                      disabled={
                        isCancelDisabled ||
                        !(isDistrictAdmin() || isStateAdmin())
                      }
                    >
                      <span>Save</span>
                    </ActionButton>
                  </div>

                  <div className="school-year-selection-container">
                    <FormDropdown
                      displayName="School Year"
                      field="schoolYear"
                      options={formViewModel.historicalSchoolYearOptions}
                      onChangeEvent={updateAdditionalSalary}
                    />
                    <FormTextbox
                      displayName="Additional Salary"
                      field="additionalSalary"
                      disabled={
                        isPreviousYearsDisabled ||
                        !(isDistrictAdmin() || isStateAdmin())
                      }
                      validationSchema={SalaryScaleValidationSchema}
                    />

                    <ActionButton
                      classes="button--secondary no-wrap-text apply-all-button "
                      dataTestId="apply-to-all"
                      onClick={() => handleApplyToAll(values, resetForm)}
                      tooltipText={applyToAllTooltip(isValid, dirty)}
                      disabled={
                        getApplyToAllDisabled(isValid, dirty) ||
                        isApplyToAllDisabled ||
                        !(isDistrictAdmin() || isStateAdmin())
                      }
                    >
                      <>+ Apply to All</>
                    </ActionButton>
                  </div>

                  <div className="table-container">
                    <StyledGrid
                      editMode="row"
                      rows={rows}
                      columns={columns}
                      processRowUpdate={processRowUpdate}
                      onProcessRowUpdateError={(error) => console.log(error)}
                    />
                  </div>
                </div>
              </div>
            </DistrictSchoolInfoPage>
          </Form>
        )}
      </Formik>

      {showCancelModal && (
        <Modal
          type={DialogType.CONFIRM}
          open={showCancelModal}
          onClose={handleDialogClose}
          title={'Are you sure?'}
        >
          <div className="confirm-container" data-testid="confirmation-modal">
            <p data-testid="cancel-message">
              Your changes have not been saved. Do you wish to continue?
            </p>
            <div className="button-row">
              <ActionButton
                onClick={handleYesInCancel}
                dataTestId="yes-button"
                tooltipText={'Navigate to the last page'}
              >
                <span>Yes</span>
              </ActionButton>
              <ActionButton
                classes="button--secondary"
                onClick={handleNoInCancel}
                dataTestId="no-button"
                tooltipText={'Stay on existing page'}
              >
                <span>No</span>
              </ActionButton>
            </div>
          </div>
        </Modal>
      )}

      {showApplyToAllModal && (
        <Modal
          type={DialogType.CONFIRM}
          open={showApplyToAllModal}
          onClose={handleDialogClose}
          title={'Are you sure?'}
        >
          <div className="confirm-container" data-testid="confirmation-modal">
            <p data-testid="cancel-message">
              You are about to apply ${addlSalary} to all rows for School Year{' '}
              {schoolYear}. Do you wish to continue?
            </p>
            <div className="button-row">
              <ActionButton
                onClick={handleUpdateAll}
                dataTestId="yes-button"
                tooltipText={'Navigate to the last page'}
              >
                <span>Yes</span>
              </ActionButton>
              <ActionButton
                classes="button--secondary"
                onClick={handleDialogClose}
                dataTestId="no-button"
                tooltipText={'Stay on existing page'}
              >
                <span>No</span>
              </ActionButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SalaryScaleList;
