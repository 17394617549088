import { ReactElement } from 'react';
import { TabPanelPropTypes } from '../../types/propTypes/TabPanelPropTypes';

const TabPanel = ({
  value,
  index,
  children,
}: TabPanelPropTypes): ReactElement => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default TabPanel;
