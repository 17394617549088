import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { noop } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { DialogType } from '../../types/DialogTypes';
import { getCloseIcon } from '../../types/Icons';
import { ModalPropTypes } from '../../types/propTypes/ModalPropTypes';
import './Modal.css';

const Modal = ({
  open,
  title,
  type = DialogType.NONE,
  children,
  onClose,
}: ModalPropTypes): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = (): void => {
    setModalOpen(false);
    if (onClose) {
      // This is needed to remove the flicker on close
      // (showing an empty modal before close)
      setTimeout(() => {
        onClose();
      }, 150);
    }
  };

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const dialogClass = `modal-type-${type}`;

  return (
    <Dialog open={modalOpen} onClose={noop}>
      <div className={dialogClass}>
        <DialogTitle>
          <div
            className="modal-title-container"
            data-testid="modal-title-container"
          >
            <div className="modal-icon" />
            <div className="modal-title">{title}</div>

            <button
              onClick={handleClose}
              className="modal-close"
              aria-label="Close"
            >
              {getCloseIcon()}
            </button>
          </div>
        </DialogTitle>
        <DialogContent className="modal-content">{children}</DialogContent>
      </div>
    </Dialog>
  );
};

export default Modal;
