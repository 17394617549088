import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CertificationDetailModel } from '../../types/CertificationDetailModel';
import { CertificationViewModel } from '../../types/CertificationViewModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchCertificationDataCollectionDetails,
  fetchCertificationViewModel,
} from '../thunks/certificationThunks';

interface CertificationState {
  loadingViewModel: boolean;
  loadingDetails: boolean;
  viewModel: CertificationViewModel;
  collectionData: CertificationDetailModel;
  collectionReportErrors: string;
}

export const defaultViewModelState: CertificationViewModel = {
  schoolYears: [],
};

export const defaultCollectionDataState: CertificationDetailModel = {
  id: '',
  name: '',
  headingText: '',
  certificationOpenDate: '',
  certificationCloseDate: '',
  statistics: {
    totalSchoolCount: '',
    certifiedSchoolCount: '',
    isDistrictCertified: false,
  },
  reportReferences: [],
  schoolCertifications: [],
  districtCertifications: [],
};

export const initialState: CertificationState = {
  loadingViewModel: false,
  loadingDetails: false,
  viewModel: defaultViewModelState,
  collectionData: defaultCollectionDataState,
  collectionReportErrors: '',
};

export const certificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {
    clearLookupError: (state) => {
      state.collectionReportErrors = '';
    },
    setLookupError: (state, action: PayloadAction<string>) => {
      state.collectionReportErrors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCertificationViewModel.pending, (state) => {
      state.viewModel = defaultViewModelState;
      state.loadingViewModel = true;
    });
    builder.addCase(fetchCertificationViewModel.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.viewModel = action.payload;
      }
      state.loadingViewModel = false;
    });
    builder.addCase(fetchCertificationViewModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingViewModel = false;
      }
    });
    builder.addCase(
      fetchCertificationDataCollectionDetails.pending,
      (state) => {
        state.collectionData = defaultCollectionDataState;
        state.loadingDetails = true;
      }
    );
    builder.addCase(
      fetchCertificationDataCollectionDetails.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.collectionData = action.payload;
        }
        state.loadingDetails = false;
      }
    );
    builder.addCase(
      fetchCertificationDataCollectionDetails.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingDetails = false;
        }
      }
    );
  },
});

export const { clearLookupError, setLookupError } = certificationSlice.actions;

export const selectViewModelLoading = (state: RootState): boolean =>
  state.certification.loadingViewModel;
export const selectDetailLoading = (state: RootState): boolean =>
  state.certification.loadingDetails;
export const selectViewModel = (state: RootState): CertificationViewModel =>
  state.certification.viewModel;
export const selectCertificationReportErrors = (state: RootState): string =>
  state.certification.collectionReportErrors;
export const selectCollectionDetail = (
  state: RootState
): CertificationDetailModel => state.certification.collectionData;

export default certificationSlice.reducer;
