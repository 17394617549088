import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const LEAManagementValidationSchema = yup.object().shape(
  {
    leaName: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
      .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
    leaType: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    leaIdentifier: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .mustBeNumber()
      .min(4, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(4))
      .max(4, VALIDATION_MESSAGE.AT_MOST_CHARS(4))
      .notOneOf(['0000'], VALIDATION_MESSAGE.MUST_NOT_BE_EQUAL_TO('0000')),
    ncesIdentifier: yup
      .string()
      .mustBeNumber()
      .min(7, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(7))
      .max(7, VALIDATION_MESSAGE.AT_MOST_CHARS(7)),
    leaOperationalStatus: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    operationalStatusEffectiveDate: yup
      .date()
      .required(VALIDATION_MESSAGE.REQUIRED),
    charterSchoolIndicator: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    reportedFederallyIndicator: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED),
    website: yup.string().max(300, VALIDATION_MESSAGE.AT_MOST_CHARS(300)),
    telephoneNumber: yup
      .string()
      .matches(
        /^[1-9]\d{2}-\d{3}-\d{4}$/,
        VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
      ),
    grades: yup
      .array()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .min(1, VALIDATION_MESSAGE.MUST_SELECT_AT_LEAST(1)),
    physicalAddressStreetNumberAndName: yup
      .string()
      .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150))
      .ensure()
      .when(['physicalCity', 'physicalState', 'physicalZipCode'], {
        is: (
          physicalCity: string,
          physicalState: string,
          physicalZipCode: string
        ) =>
          physicalCity !== '' || physicalState !== '' || physicalZipCode !== '',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    physicalAptSuiteBuildingFloorNumber: yup
      .string()
      .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
    physicalCity: yup
      .string()
      .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30))
      .ensure()
      .when(
        [
          'physicalAddressStreetNumberAndName',
          'physicalState',
          'physicalZipCode',
        ],
        {
          is: (
            physicalAddressStreetNumberAndName: string,
            physicalState: string,
            physicalZipCode: string
          ) =>
            physicalAddressStreetNumberAndName !== '' ||
            physicalState !== '' ||
            physicalZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    physicalState: yup
      .string()
      .ensure()
      .when(
        [
          'physicalAddressStreetNumberAndName',
          'physicalCity',
          'physicalZipCode',
        ],
        {
          is: (
            physicalAddressStreetNumberAndName: string,
            physicalCity: string,
            physicalZipCode: string
          ) =>
            physicalAddressStreetNumberAndName !== '' ||
            physicalCity !== '' ||
            physicalZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    physicalZipCode: yup
      .string()
      .max(17, VALIDATION_MESSAGE.AT_MOST_CHARS(17))
      .ensure()
      .when(
        ['physicalAddressStreetNumberAndName', 'physicalCity', 'physicalState'],
        {
          is: (
            physicalAddressStreetNumberAndName: string,
            physicalCity: string,
            physicalState: string
          ) =>
            physicalAddressStreetNumberAndName !== '' ||
            physicalCity !== '' ||
            physicalState !== '',
          then: yup
            .string()
            .required(VALIDATION_MESSAGE.REQUIRED)
            .matches(
              /^[0-9\-]{5,17}$/,
              VALIDATION_MESSAGE.MUST_BE_IN_ZIP_CODE_FORMAT
            ),
          otherwise: yup.string().notRequired(),
        }
      ),
    physicalCounty: yup
      .string()
      .ensure()
      .when(['physicalState'], {
        is: (physicalState: string) => physicalState === 'Mississippi',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    mailingAddressStreetNumberAndName: yup
      .string()
      .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150))
      .ensure()
      .when(['mailingCity', 'mailingState', 'mailingZipCode'], {
        is: (
          mailingCity: string,
          mailingState: string,
          mailingZipCode: string
        ) => mailingCity !== '' || mailingState !== '' || mailingZipCode !== '',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    mailingAptSuiteBuildingFloorNumber: yup
      .string()
      .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
    mailingCity: yup
      .string()
      .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30))
      .ensure()
      .when(
        ['mailingAddressStreetNumberAndName', 'mailingState', 'mailingZipCode'],
        {
          is: (
            mailingAddressStreetNumberAndName: string,
            mailingState: string,
            mailingZipCode: string
          ) =>
            mailingAddressStreetNumberAndName !== '' ||
            mailingState !== '' ||
            mailingZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    mailingState: yup
      .string()
      .ensure()
      .when(
        ['mailingAddressStreetNumberAndName', 'mailingCity', 'mailingZipCode'],
        {
          is: (
            mailingAddressStreetNumberAndName: string,
            mailingCity: string,
            mailingZipCode: string
          ) =>
            mailingAddressStreetNumberAndName !== '' ||
            mailingCity !== '' ||
            mailingZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    mailingZipCode: yup
      .string()
      .max(17, VALIDATION_MESSAGE.AT_MOST_CHARS(17))
      .ensure()
      .when(
        ['mailingAddressStreetNumberAndName', 'mailingCity', 'mailingState'],
        {
          is: (
            mailingAddressStreetNumberAndName: string,
            mailingCity: string,
            mailingState: string
          ) =>
            mailingAddressStreetNumberAndName !== '' ||
            mailingCity !== '' ||
            mailingState !== '',
          then: yup
            .string()
            .required(VALIDATION_MESSAGE.REQUIRED)
            .matches(
              /^[0-9\-]{5,17}$/,
              VALIDATION_MESSAGE.MUST_BE_IN_ZIP_CODE_FORMAT
            ),
          otherwise: yup.string().notRequired(),
        }
      ),
    mailingCounty: yup
      .string()
      .ensure()
      .when(['mailingState'], {
        is: (mailingState: string) => mailingState === 'Mississippi',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    primaryPositionTitle: yup
      .string()
      .max(45, VALIDATION_MESSAGE.AT_MOST_CHARS(45)),
    primaryFirstName: yup
      .string()
      .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
    primaryLastName: yup.string().max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
    primaryEmailAddress: yup
      .string()
      .min(7, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
      .max(128, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
      .email(VALIDATION_MESSAGE.MUST_BE_IN_VALID_EMAIL_FORMAT),
    primaryTelephoneNumber: yup
      .string()
      .matches(
        /^[1-9]\d{2}-\d{3}-\d{4}$/,
        VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
      ),
    secondaryPositionTitle: yup
      .string()
      .max(45, VALIDATION_MESSAGE.AT_MOST_CHARS(45)),
    secondaryFirstName: yup
      .string()
      .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
    secondaryLastName: yup
      .string()
      .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
    secondaryEmailAddress: yup
      .string()
      .min(7, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
      .max(128, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
      .email(VALIDATION_MESSAGE.MUST_BE_IN_VALID_EMAIL_FORMAT),
    secondaryTelephoneNumber: yup
      .string()
      .matches(
        /^[1-9]\d{2}-\d{3}-\d{4}$/,
        VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
      ),
  },
  [
    ['mailingAddressStreetNumberAndName', 'mailingCity'],
    ['mailingAddressStreetNumberAndName', 'mailingState'],
    ['mailingAddressStreetNumberAndName', 'mailingZipCode'],
    ['mailingCity', 'mailingState'],
    ['mailingState', 'mailingZipCode'],
    ['mailingCity', 'mailingZipCode'],
    ['physicalAddressStreetNumberAndName', 'physicalCity'],
    ['physicalAddressStreetNumberAndName', 'physicalState'],
    ['physicalAddressStreetNumberAndName', 'physicalZipCode'],
    ['physicalCity', 'physicalState'],
    ['physicalState', 'physicalZipCode'],
    ['physicalCity', 'physicalZipCode'],
  ]
);
