import * as Yup from 'yup';
import { FormInputClasses } from '../FormInputClasses';
import { SelectOption } from '../SelectOption';

export enum FormRadioListOrientation {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}

export interface FormRadioListPropTypes {
  field: string;
  options: SelectOption[];
  displayName?: string;
  validationSchema?: Yup.AnyObjectSchema;
  optionOrientation?: FormRadioListOrientation;
  classConfig?: FormInputClasses;
  onClick?: Function;
  disabled?: boolean;
}
