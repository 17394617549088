import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { LEASharingFormViewModel } from '../types/LEASharingFormViewModel';
import { LEASharingListItemModel } from '../types/LEASharingListItemModel';
import { LEASharingModel } from '../types/LEASharingModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const leaSharingApiPaths = {
  create: 'leaSharing',
  update: 'leaSharing',
  getListItems: 'leaSharing',
  getById: (leaSharingId: string): string => `leaSharing/${leaSharingId}`,
  getForm: 'leaSharing/form',
};

export const LeaSharingServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createLEASharing = async (
  body: LEASharingModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await LeaSharingServiceClient(config).post(
      leaSharingApiPaths.create,
      body
    );
  });
};

export const updateLEASharing = async (
  body: LEASharingModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await LeaSharingServiceClient(config).put(
      leaSharingApiPaths.update,
      body
    );
  });
};

export const getLEASharingListItems = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<LEASharingListItemModel[]>> => {
  return executeApiRequest<LEASharingListItemModel[]>(async () => {
    return await LeaSharingServiceClient(config).get(
      leaSharingApiPaths.getListItems
    );
  });
};

export const getLEASharingById = async (
  leaSharingId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<LEASharingModel>> => {
  return executeApiRequest<LEASharingModel>(async () => {
    return await LeaSharingServiceClient(config).get(
      leaSharingApiPaths.getById(leaSharingId)
    );
  });
};

export const getLEASharingFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<LEASharingFormViewModel>> => {
  return executeApiRequest<LEASharingFormViewModel>(async () => {
    return await LeaSharingServiceClient(config).get(
      leaSharingApiPaths.getForm
    );
  });
};
