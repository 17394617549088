import { useNavigate } from 'react-router-dom';
import {
  mapToAuthTokenResponsePayload,
  mapToReportTokenResponsePayload,
} from '../mappers/TokenMapper';
import { useAppDispatch } from '../redux/hooks';
import {
  setLoginError,
  setTokenResponseState,
} from '../redux/slices/loginSlice';
import { setReportError, setReportList } from '../redux/slices/reportSlice';
import { getAppRefreshToken, getPowerBIToken } from '../services/auth.service';
import {
  clearReportTokenAndList,
  getAuthTokenRefreshCode,
  isValidCode,
  setReportListResponse,
  setReportTokenResponse,
  setTokenResponse,
} from '../services/base.service';
import { getReportListModel } from '../services/report.service';
import { appRoutePaths } from '../services/route.service';
import { isReportPage } from '../utilities/routeUtilities';
import { storeCurrentRouteForRelogin } from '../utilities/userUtilities';

export type UseTokenRefreshType = {
  onAuthTokenExpire: (restartTimer: () => Promise<void>) => Promise<boolean>;
  onReportTokenExpiration: (
    restartTimer: () => Promise<void>
  ) => Promise<boolean>;
};

export const useTokenRefresh = (): UseTokenRefreshType => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onAuthTokenExpiration = async (
    restartTimer: () => Promise<void>
  ): Promise<boolean> => {
    const result = await getAppRefreshToken({
      refresh_token: getAuthTokenRefreshCode() || '',
    });

    if (isValidCode(result.status)) {
      const tokenPayload = mapToAuthTokenResponsePayload(result.data);

      setTokenResponse(tokenPayload);
      dispatch(setTokenResponseState(result.data));

      await restartTimer();
      return true;
    } else {
      dispatch(setLoginError('Token refresh error. Please login again.'));
      storeCurrentRouteForRelogin(location.pathname);
      navigate(appRoutePaths.Logout);
    }
    return false;
  };

  const fetchReportDataAndStoreOrError = async (): Promise<boolean> => {
    // Get report list
    const reportListResponse = await getReportListModel();

    // If successful, set session with list
    if (isValidCode(reportListResponse.status)) {
      setReportListResponse(reportListResponse.data);
      dispatch(setReportList(reportListResponse.data));

      // Only proceed if there is any report in the list
      if (reportListResponse.data.categories.length > 0) {
        // Get powerBI token for embedded reports
        const tokenResponse = await getPowerBIToken();

        // If successful, set mapped token payload into session state
        // and set the report list in state
        if (isValidCode(tokenResponse.status)) {
          const tokenPayload = mapToReportTokenResponsePayload(
            tokenResponse.data
          );
          setReportTokenResponse(tokenPayload);
          return true;
        } else {
          dispatch(setReportError(tokenResponse.error));
        }
      }
    } else {
      dispatch(setReportError(reportListResponse.error));
    }
    return false;
  };

  const onReportTokenExpiration = async (
    restartTimer: () => Promise<void>
  ): Promise<boolean> => {
    if (!isReportPage(location.pathname)) {
      clearReportTokenAndList();
      return true;
    } else {
      const refreshSuccess = await fetchReportDataAndStoreOrError();
      if (refreshSuccess) {
        await restartTimer();
        return true;
      } else {
        clearReportTokenAndList();
      }
    }
    return false;
  };

  return {
    onAuthTokenExpire: onAuthTokenExpiration,
    onReportTokenExpiration: onReportTokenExpiration,
  };
};
