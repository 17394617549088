/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, ReactNode } from 'react';
import './InlineError.css';

type PropsType = {
  children?: ReactNode;
};

export const InlineError = ({ children }: PropsType): ReactElement => {
  return (
    <>
      <small className="inline-error">{children}</small>
    </>
  );
};

export default InlineError;
