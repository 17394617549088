import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const CourseCodeValidationSchema = yup.object().shape({
  courseCode: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .mustBeNumber()
    .min(6, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(6))
    .max(6, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(6)),
  courseTitle: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .alphaNumericWithSpaces()
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  courseDepartmentName: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .alphaNumericWithSpaces()
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  courseApplicableEducationLevel: yup
    .array()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.MUST_SELECT_AT_LEAST(1)),
  scedGradeSpan: yup
    .string()
    .alphaNumeric()
    .min(4, VALIDATION_MESSAGE.EXACT_LENGTH(4))
    .max(4, VALIDATION_MESSAGE.AT_MOST_CHARS(4)),
  scedSequenceOfCourse: yup
    .string()
    .alphaNumeric()
    .max(10, VALIDATION_MESSAGE.AT_MOST_CHARS(10)),
  certificateRequired: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  courseLevelCharacteristic: yup
    .array()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.MUST_SELECT_AT_LEAST(1)),
  scedCourseSubjectArea: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  courseDescription: yup
    .string()
    .max(1024, VALIDATION_MESSAGE.AT_MOST_CHARS(1024)),
  courseCertificationDescription: yup
    .string()
    .max(300, VALIDATION_MESSAGE.AT_MOST_CHARS(300)),
  courseBeginDate: yup.date().required(VALIDATION_MESSAGE.REQUIRED),
  courseEndDate: yup
    .date()
    .mustComeAfterOrEqualSpecifiedDate('courseBeginDate', 'Course Begin Date'),
});
