import { ReactElement, useEffect, useState } from 'react';
import { IIdleTimer, PresenceType, useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useModalShow } from '../../hooks/useModalShow';
import { useAppDispatch } from '../../redux/hooks';
import { setLoginError } from '../../redux/slices/loginSlice';
import {
  getInactivityPromptMS,
  getInactivityTimeoutMS,
} from '../../services/app.service';
import { appRoutePaths } from '../../services/route.service';
import { DialogType } from '../../types/DialogTypes';
import { IdleTimerPropTypes } from '../../types/propTypes/IdleTimerPropTypes';
import { storeCurrentRouteForRelogin } from '../../utilities/userUtilities';
import ActionButton from '../ActionButton/ActionButton';
import Modal from '../Modal/Modal';
import './IdleTimer.css';

const IdleTimer = ({
  onAction,
  onActive,
  onPrompt,
  onIdle,
  onPresenceChange,
  promptBeforeIdleOverride,
  timeoutOverride,
  debugMode,
}: IdleTimerPropTypes): ReactElement => {
  const timeout = timeoutOverride ? timeoutOverride : getInactivityTimeoutMS();
  const promptBeforeIdle = promptBeforeIdleOverride
    ? promptBeforeIdleOverride
    : getInactivityPromptMS();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setShow, show } = useModalShow();
  const [state, setState] = useState<string>('Active');
  const [remaining, setRemaining] = useState<number>(timeout);

  const debugEvent = (...args: string[]): void => {
    const timeStamp = new Date();
    if (debugMode) console.log(`Timer ${timeStamp.toLocaleString()} - ${args}`);
  };

  const handleLogout = (isIdle?: boolean): void => {
    storeCurrentRouteForRelogin(location.pathname);
    navigate(appRoutePaths.Logout, { replace: true });
    isIdle && dispatch(setLoginError('Logged out due to inactivity'));
  };

  const executeIdle = async (
    event?: Event,
    idleTimer?: IIdleTimer
  ): Promise<void> => {
    setState('Idle');
    onIdle && (await onIdle(event, idleTimer));
    setShow(false);

    handleLogout(true);

    debugEvent('On Idle');
  };

  const executeActive = async (
    event?: Event,
    idleTimer?: IIdleTimer
  ): Promise<void> => {
    setState('Active');
    onActive && (await onActive(event, idleTimer));
    setShow(false);

    debugEvent('On Active');
  };

  const executePrompt = async (
    event?: Event,
    idleTimer?: IIdleTimer
  ): Promise<void> => {
    setState('Prompted');
    onPrompt && (await onPrompt(event, idleTimer));
    setShow(true);

    debugEvent('On Prompt');
  };

  const executeAction = async (
    event?: Event,
    idleTimer?: IIdleTimer
  ): Promise<void> => {
    setState('Active');
    onAction && (await onAction(event, idleTimer));

    debugEvent('On Action');
  };

  const executePresenceChange = async (
    presence: PresenceType,
    idleTimer?: IIdleTimer
  ): Promise<void> => {
    onPresenceChange && (await onPresenceChange(presence, idleTimer));

    debugEvent('On Presence Change', JSON.stringify(presence));
  };

  const promptWrapperCall = (): void => {
    executePrompt();
  };
  const actionWrapperCall = (): void => {
    executeAction();
  };
  const activeWrapperCall = (): void => {
    executeActive();
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle: executeIdle,
    onActive: executeActive,
    onAction: executeAction,
    onPrompt: executePrompt,
    onPresenceChange: executePresenceChange,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    eventsThrottle: 500,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = (): void => {
    debugEvent('Still Here Button');
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

  return (
    <>
      {debugMode && (
        <div data-testid="debug-panel">
          <p data-testid="timeout">Timeout: {timeout}</p>
          <p data-testid="prompt-timeout">Prompt Timeout: {promptBeforeIdle}</p>
          <p data-testid="current-state">Current State: {state}</p>
          {timeTillPrompt > 0 && (
            <p data-testid="prompt-time-display">
              {timeTillPrompt} {seconds} until prompt
            </p>
          )}
          <div>
            <button data-testid="active-button" onClick={activeWrapperCall}>
              Force Active
            </button>
            <button data-testid="action-button" onClick={actionWrapperCall}>
              Force Action
            </button>
            <button data-testid="prompt-button" onClick={promptWrapperCall}>
              Force Prompt
            </button>
          </div>
        </div>
      )}
      <Modal
        type={DialogType.CONFIRM}
        open={show}
        onClose={handleStillHere}
        title={'Are you still here?'}
      >
        <div className="confirm-container">
          <p data-testid="logout-message">
            You will be logged out in {remaining} seconds for inactivity.
          </p>
          <div className="button-row">
            <ActionButton
              classes="button--secondary"
              onClick={handleStillHere}
              dataTestId="idle-still-here-btn"
              cypressDataId="idle-still-here-btn"
              tooltipText={'Reset Timer'}
            >
              <span>I&apos;m still here</span>
            </ActionButton>
            <ActionButton
              classes="button--secondary"
              onClick={handleLogout}
              dataTestId="idle-logout-btn"
              cypressDataId="idle-logout-btn"
              tooltipText={'Logout'}
            >
              <span>Logout</span>
            </ActionButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IdleTimer;
