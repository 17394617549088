/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import StyledSingleDropdown from '../../../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectSummerActivityFormViewModel,
  selectSummerActivityListItems,
  selectSummerActivityLoadingListItems,
} from '../../../../redux/slices/summerActivitySlice';
import {
  fetchSummerActivityFormViewModel,
  fetchSummerActivityListItems,
} from '../../../../redux/thunks/summerActivityThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import { ReactSelectOption } from '../../../../types/ReactSelectOption';
import { SelectOption } from '../../../../types/SelectOption';
import { SummerActivityFormViewModel } from '../../../../types/SummerActivityFormViewModel';
import { SummerActivityListItemModel } from '../../../../types/SummerActivityListItemModel';
import { formatToDateString } from '../../../../utilities/dateUtilities';
import StudentPage from '../StudentPage';
import './SummerActivityList.css';

const SummerActivityList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectSummerActivityLoadingListItems);
  const viewModel: SummerActivityFormViewModel = useAppSelector(
    selectSummerActivityFormViewModel
  );
  const listItems: SummerActivityListItemModel[] = useAppSelector(
    selectSummerActivityListItems
  );
  const [school, setSchool] = useState<string>('');
  const [schoolName, setSchoolName] = useState<string>('');
  const [schoolYear, setSchoolYear] = useState<string>('');

  const getSchoolYearOptions = (): SelectOption[] => {
    return viewModel.schoolYears;
  };

  const getSchoolOptions = (): SelectOption[] => {
    return viewModel.schools;
  };

  const schoolYearOptions = getSchoolYearOptions();
  const schoolOptions = getSchoolOptions();

  const getSchoolName = (value: string): string => {
    const name = viewModel.schools.filter((x) => x.value === value);
    if (name.length === 1) {
      return name[0].text;
    } else {
      return '';
    }
  };

  const handleSchoolYearChange = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    /* istanbul ignore next */
    const value = selected?.value || '';
    if (value)
      dispatch(
        fetchSummerActivityListItems({
          schoolYear: value,
          school,
        })
      );
    setSchoolYear(value);
  };

  const handleSchoolChange = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    const value = selected?.value || '';
    /* istanbul ignore next */
    if (value)
      dispatch(
        fetchSummerActivityListItems({
          schoolYear,
          school: value,
        })
      );
    setSchool(value);
    setSchoolName(getSchoolName(value));
  };

  const selectedOption = schoolYear
    ? { value: schoolYear, label: schoolYear }
    : {
        value: viewModel.defaultSchoolYear,
        label: viewModel.defaultSchoolYear,
      };

  const selectedSchoolOption = school
    ? { value: school, label: schoolName }
    : {
        value: '',
        label: '',
      };
  useEffect(() => {
    const promise = dispatch(fetchSummerActivityFormViewModel());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  useEffect(() => {
    let promise: unknown;
    if (viewModel.defaultSchoolYear) {
      promise = dispatch(
        fetchSummerActivityListItems({
          schoolYear: viewModel.defaultSchoolYear,
          school: '',
        })
      );
      setSchoolYear(viewModel.defaultSchoolYear);
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, viewModel.defaultSchoolYear]);

  const handleEdit = (id: string): void => {
    navigate(appRoutePaths.SummerActivityInput(id));
  };

  return (
    <StudentPage
      pageClass="data-input--student--summer-activity--list"
      isLoading={isLoading}
      loadingDataId={'summer-activity-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="school-year-selection-container">
            <div>
              <StyledSingleDropdown
                fieldName="schoolYear"
                options={schoolYearOptions ? schoolYearOptions : []}
                onChange={handleSchoolYearChange}
                value={selectedOption}
                labelText="School Year"
                showLabel={true}
              />
            </div>
            <div className="school-selection">
              <StyledSingleDropdown
                fieldName="school"
                options={schoolOptions ? schoolOptions : []}
                onChange={handleSchoolChange}
                value={selectedSchoolOption}
                class="no-wrap-text"
                labelText="School"
                showLabel={true}
              />
            </div>
          </div>
          <div className="subheading-and-button-container subheading-container-padding">
            <h4 data-testid={'component-heading-text'}>Summer Activity</h4>
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>MSIS ID</th>
                  <th>Student Name</th>
                  <th>Birthdate</th>
                  <th>Grade Level</th>
                  <th>Reason Description</th>
                  <th>Verified</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((summerActivity) => (
                    <tr
                      key={`${summerActivity.id}`}
                      data-testid={summerActivity.id}
                    >
                      <td className="msis-id">{summerActivity.msisId}</td>
                      <td className="student-name">
                        {summerActivity.fullName}
                      </td>
                      <td className="birth-date">
                        {formatToDateString(summerActivity.birthDate)}
                      </td>
                      <td className="grade-level">
                        {summerActivity.gradeLevel}
                      </td>
                      <td className="reason">
                        {summerActivity.reasonDescription}
                      </td>
                      <td className="verified">{summerActivity.verified}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${summerActivity.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(summerActivity.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit/View Summer Activity'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StudentPage>
  );
};

export default SummerActivityList;
