/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ErrorDetails from '../../components/ErrorDetails/ErrorDetails';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectStudentLoading,
  selectStudentOwnershipConflictsDetail,
} from '../../redux/slices/studentSlice';
import { fetchStudentOwnershipConflictDetail } from '../../redux/thunks/studentThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { PanelType } from '../../types/PanelType';
import { StudentOwnershipConflictDetailModel } from '../../types/StudentOwnershipConflictDetailModel';
import { formatToDateString } from '../../utilities/dateUtilities';
import './StudentOwnershipConflictDetail.css';

const StudentOwnershipConflictDetail = (): ReactElement => {
  const { ownerConflictId } = useParams();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectStudentLoading);

  const studentOwnerConflictsDetail:
    | StudentOwnershipConflictDetailModel
    | undefined = useAppSelector(selectStudentOwnershipConflictsDetail);

  useEffect(() => {
    let promise: unknown = undefined;
    if (ownerConflictId) {
      promise = dispatch(fetchStudentOwnershipConflictDetail(ownerConflictId));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, ownerConflictId]);

  return (
    <NavigationPage
      heading={studentOwnerConflictsDetail?.fullName || ''}
      pageClass="student-owner-conflict-detail"
      isLoading={isLoading}
      loadingDataId="student-owner-conflict-detail-loader"
      loadingText="Loading student ownership conflict details"
      backBarLocation={appRoutePaths.StudentOwnership}
    >
      <>
        {studentOwnerConflictsDetail ? (
          <div
            className="student-owner-conflict-detail--content"
            data-testid="student-owner-conflict-detail-content"
          >
            <LabelAndValue
              containerClass="msis-display"
              displayName="MSIS ID"
              showColonOnLabel={true}
              value={`#${studentOwnerConflictsDetail.msisId}`}
            />
            <ErrorDetails
              title="Student is being claimed by more than one district"
              subtitle="Student ownership conflicts found. Please correct the student’s data."
              instructionsToFix={
                <p>
                  Please reach out to the listed district to resolve ownership
                  issue. Students may not be enrolled in different districts for
                  overlapping days. Once owner is resolved, resend the data to
                  clear.
                </p>
              }
            />

            {studentOwnerConflictsDetail.studentOwnershipConflictDetailList.map(
              (detail) => (
                <Panel
                  key={`${detail.msisId}-${detail.claimingLEAName}`}
                  heading={detail.claimingLEAName}
                  panelType={PanelType.INFO}
                >
                  <div className="student-owner-conflict-detail-district-content">
                    <LabelAndValue
                      displayName="School"
                      value={detail.claimingK12SchoolName}
                    />
                    <LabelAndValue
                      displayName="MSIS Primary Contact"
                      value={detail.claimingK12SchoolContactPersonName}
                    />
                    <LabelAndValue
                      displayName="MSIS Primary Email"
                      value={
                        detail.claimingK12SchoolContactPersonElectronicMailAddress
                      }
                    />

                    <LabelAndValue
                      displayName="Entry Date"
                      value={formatToDateString(detail.claimingLEAEntryDate)}
                      valueClass={`date-field ${
                        detail.highlightEntryDate && 'date-conflict-field'
                      }`}
                    />
                    <LabelAndValue
                      displayName="Entry Type"
                      value={detail.claimingLEAEntryType}
                    />
                    <LabelAndValue
                      displayName="Exit Date"
                      value={
                        detail.claimingLEAExitDate
                          ? formatToDateString(detail.claimingLEAExitDate)
                          : 'Currently Enrolled'
                      }
                      valueClass={`date-field ${
                        detail.highlightExitDate && 'date-conflict-field'
                      }`}
                    />
                    <LabelAndValue
                      displayName="Exit Type"
                      value={detail.claimingLEAExitType}
                    />
                  </div>
                </Panel>
              )
            )}
          </div>
        ) : undefined}
      </>
    </NavigationPage>
  );
};

export default StudentOwnershipConflictDetail;
