import { ReactElement } from 'react';
// eslint-disable-next-line max-len
import { FileUploadStatusPropTypes } from '../../types/propTypes/FileUploadStatusPropTypes';
import './FileUploadStatus.css';

const FileUploadStatus = ({
  name,
  percent = 0,
  isCompleted = false,
  isFailed = false,
  error,
}: FileUploadStatusPropTypes): ReactElement => {
  let status;
  let statusText;

  if (isCompleted) {
    status = 'completed';
    statusText = 'Completed';
  } else if (isFailed) {
    status = 'failed';
    statusText = 'Failed';
  } else if (percent < 1) {
    statusText = `${Math.round(percent * 100)}%`;
  } else {
    status = 'awaiting';
    statusText = 'Awaiting Server Response';
  }

  return (
    <div
      className={`file-upload-status ${
        status ? `file-upload-status--${status}` : ''
      }`}
    >
      <div className="file-upload-status--name-and-status">
        <div className="file-upload-status--name">{name}</div>
        <div className="file-upload-status--status">{statusText}</div>
      </div>

      <progress value={`${percent}`} />
      {isFailed && error && (
        <div className="file-upload-status--error">{error}</div>
      )}
    </div>
  );
};

export default FileUploadStatus;
