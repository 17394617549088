export enum MSISMatchStrength {
  Strong = 'STRONG',
  Medium = 'POSSIBLE',
  Weak = 'WEAK',
}

export interface MSISRequestMatchModel {
  id: string;
  dateOfBirth: string;
  msisId: string;
  fullName: string;
  status: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  ssn?: string;
  address?: string;
  parentGuardian?: string;
  district: string;
  school: string;
  aliases: string[];
  matchStrength: MSISMatchStrength;
  matchedFieldNames: string[];
  unmatchedFieldNames: string[];
  matchedAlias?: string;
}
