import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationModel } from '../../types/NotificationModel';
import type { RootState } from '../store';

interface NotificationState {
  notification: NotificationModel;
}

export const initialState: NotificationState = {
  notification: {
    severity: undefined,
    children: undefined,
  },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotification: () => initialState,
    createNotification: (state, action: PayloadAction<NotificationModel>) => {
      state.notification = action.payload;
    },
  },
});

export const { clearNotification, createNotification } =
  notificationSlice.actions;

export const hasNotification = (state: RootState): boolean =>
  state.notification.notification.severity !== undefined;
export const selectNotification = (state: RootState): NotificationModel =>
  state.notification.notification;

export default notificationSlice.reducer;
