/* eslint-disable max-len */
import { ReactElement } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { TooltipAnchor } from '../../services/app.service';
import { canUserAccessRoute } from '../../services/security.service';
import { NavigationButtonPropTypes } from '../../types/propTypes/NavigationButtonPropTypes';

const NavigationButton = ({
  route,
  classes,
  visibleOverride,
  isNavLink,
  dataTestId,
  children,
  tooltipText,
}: NavigationButtonPropTypes): ReactElement => {
  const hasAccessToRoute =
    visibleOverride === undefined ? canUserAccessRoute(route) : visibleOverride;
  const assignedTestId = dataTestId ? dataTestId : '';

  const toolTipProps = tooltipText
    ? {
        'data-tooltip-id': TooltipAnchor,
        'data-tooltip-content': tooltipText,
      }
    : {};

  return hasAccessToRoute ? (
    isNavLink ? (
      <NavLink
        to={route}
        data-testid={assignedTestId}
        className={classes}
        {...toolTipProps}
        end
      >
        {children}
      </NavLink>
    ) : (
      <Link
        to={route}
        data-testid={assignedTestId}
        className={classes}
        {...toolTipProps}
      >
        {children}
      </Link>
    )
  ) : (
    <></>
  );
};

export default NavigationButton;
