import { ReactElement } from 'react';
import { SuccessCardPropTypes } from '../../../types/propTypes/SuccessCardPropTypes';
import DashboardCard from '../DashboardCard/DashboardCard';

const SuccessCard = ({ message }: SuccessCardPropTypes): ReactElement => (
  <DashboardCard
    cardClassNames="dashboard--grid--badge 
  status--success page--grid--d-6 
  page--grid--m-full"
    message={message}
    dataState="good"
  />
);

export default SuccessCard;
