/* eslint-disable max-len */
import { ReactElement } from 'react';
import NPSE_EarlyChildhoodOutcomesPanel from './NPSE_EarlyChildhoodOutcomesPanel';
import NPSE_SPEDPanel from './NPSE_SPEDPanel';

const NPSE_SPEDLayout = (): ReactElement => {
  return (
    <>
      <NPSE_SPEDPanel />
      <NPSE_EarlyChildhoodOutcomesPanel />
    </>
  );
};

export default NPSE_SPEDLayout;
