import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const SeaOrganizationValidationSchema = yup.object().shape({
  stateAgencyIdentifier: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(40, VALIDATION_MESSAGE.AT_MOST_CHARS(40)),
  organizationName: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  mailingAddressStreetNumberAndName: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150)),
  mailingAddressApartmentRoomOrSuiteNumber: yup
    .string()
    .alphaNumericWithSpaces()
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  mailingAddressCity: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30)),
  mailingStateAbbreviation: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  mailingAddressPostalCode: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .mustBeNumber()
    .min(5, VALIDATION_MESSAGE.EXACT_LENGTH(5))
    .max(5, VALIDATION_MESSAGE.AT_MOST_CHARS(5)),
  physicalAddressStreetNumberAndName: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150)),
  physicalAddressApartmentRoomOrSuiteNumber: yup
    .string()
    .alphaNumericWithSpaces()
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  physicalAddressCity: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30)),
  physicalStateAbbreviation: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  physicalAddressPostalCode: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .mustBeNumber()
    .min(5, VALIDATION_MESSAGE.EXACT_LENGTH(5))
    .max(5, VALIDATION_MESSAGE.AT_MOST_CHARS(5)),
  positionTitle: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(45, VALIDATION_MESSAGE.AT_MOST_CHARS(45)),
  webSiteAddress: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(300, VALIDATION_MESSAGE.AT_MOST_CHARS(300)),
  firstName: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  middleName: yup
    .string()
    .alphaNumericWithSpaces()
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  lastOrSurname: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  generationCodeOrSuffix: yup
    .string()
    .alphaNumericWithSpaces()
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(10, VALIDATION_MESSAGE.AT_MOST_CHARS(10)),
  personalTitleOrPrefix: yup
    .string()
    .alphaNumericWithSpaces()
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30)),
  electronicMailAddress: yup
    .string()
    .alphaNumericWithSpaces()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .min(7, VALIDATION_MESSAGE.EXACT_LENGTH(7))
    .max(128, VALIDATION_MESSAGE.AT_MOST_CHARS(128)),
  schoolYear: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
});
