/* eslint-disable max-len*/
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../../../components/NavigationButton/NavigationButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectSeaOrganizationListItems,
  selectSeaOrganizationLoadingListItems,
} from '../../../../redux/slices/seaOrganizationSlice';
import { fetchSeaOrganizationListItems } from '../../../../redux/thunks/seaOrganizationThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import { SeaOrganizationListItemModel } from '../../../../types/SeaOrganizationListItemModel';
import MdeOtssPage from '../MdeOtssPage';
import './SeaOrganizationList.css';

const SeaOrganizationList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectSeaOrganizationLoadingListItems);
  const listItems: SeaOrganizationListItemModel[] = useAppSelector(
    selectSeaOrganizationListItems
  );

  useEffect(() => {
    const promise: unknown = dispatch(fetchSeaOrganizationListItems());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const handleEdit = (stateAgencyIdentifier: string): void => {
    navigate(appRoutePaths.SeaOrganizationInput(stateAgencyIdentifier));
  };

  return (
    <MdeOtssPage
      pageClass="data-input--mde-otss--sea-organizations--list"
      isLoading={isLoading}
      loadingDataId={'sea-organization-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>SEA Organizations</h4>
            <NavigationButton
              route={appRoutePaths.SeaOrganizationInput('new')}
              visibleOverride={canUserAccessRoute(
                appRoutePaths.SeaOrganizationInput()
              )}
              classes="button no-wrap-text new-button"
              tooltipText="Add New SEA Organization"
            >
              <>Add New</>
            </NavigationButton>
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>State Agency Identifier</th>
                  <th>Organization Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((seaOrganization) => (
                    <tr
                      key={`${seaOrganization.stateAgencyIdentifier}`}
                      data-testid={seaOrganization.organizationName}
                    >
                      <td>{seaOrganization.stateAgencyIdentifier}</td>
                      <td>{seaOrganization.organizationName}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${seaOrganization.stateAgencyIdentifier}`}
                            aria-label="Edit"
                            onClick={() =>
                              handleEdit(seaOrganization.stateAgencyIdentifier)
                            }
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit SEA Organization'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MdeOtssPage>
  );
};

export default SeaOrganizationList;
