/* eslint-disable max-len */
import { ApiResponseModel } from '../types/ApiResponseModel';
import { CharterSchoolAssignmentNewViewModel } from '../types/CharterSchoolAssignmentNewViewModel';
import { CharterSchoolAuthorizingOrganizationNewViewModel } from '../types/CharterSchoolAuthorizingOrganizationNewViewModel';
import { CharterSchoolManagementOrganizationNewViewModel } from '../types/CharterSchoolManagementOrganizationNewViewModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const charterSchoolApiPaths = {
  newAuthorizingOrganizationViewModel:
    'charterSchool/authorizingOrganization/new',
  newManagementOrganizationViewModel:
    'charterSchool/managementOrganization/new',
  newAssignmentViewModel: 'charterSchool/assignment/new',
};

export const CharterSchoolServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getCharterSchoolAuthorizingOrganizationNewViewModel = async (
  config?: DataRequestConfig
): Promise<
  ApiResponseModel<CharterSchoolAuthorizingOrganizationNewViewModel>
> => {
  return executeApiRequest<CharterSchoolAuthorizingOrganizationNewViewModel>(
    async () => {
      return await CharterSchoolServiceClient(config).get(
        charterSchoolApiPaths.newAuthorizingOrganizationViewModel
      );
    }
  );
};

export const getCharterSchoolManagementOrganizationNewViewModel = async (
  config?: DataRequestConfig
): Promise<
  ApiResponseModel<CharterSchoolManagementOrganizationNewViewModel>
> => {
  return executeApiRequest<CharterSchoolManagementOrganizationNewViewModel>(
    async () => {
      return await CharterSchoolServiceClient(config).get(
        charterSchoolApiPaths.newManagementOrganizationViewModel
      );
    }
  );
};

export const getCharterSchoolAssignmentNewViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<CharterSchoolAssignmentNewViewModel>> => {
  return executeApiRequest<CharterSchoolAssignmentNewViewModel>(async () => {
    return await CharterSchoolServiceClient(config).get(
      charterSchoolApiPaths.newAssignmentViewModel
    );
  });
};
