/* eslint-disable max-len */
import { ReactElement } from 'react';
import { EmptyStateCardPropTypes } from '../../../types/propTypes/EmptyStateCardPropTypes';
import DashboardCard from '../DashboardCard/DashboardCard';

const EmptyStateCard = ({
  message,
  buttonText,
  buttonVisible,
  buttonLink,
  tooltipText,
}: EmptyStateCardPropTypes): ReactElement => (
  <DashboardCard
    cardClassNames="dashboard--grid--content 
    empty-state page--grid--d-6"
    messageClassNames="dashboard--upload-message"
    message={message}
    buttonClassNames="button button--dashboard--small no-wrap-text"
    buttonLink={buttonLink}
    buttonText={buttonText}
    buttonVisible={buttonVisible}
    tooltipText={tooltipText}
  />
);

export default EmptyStateCard;
