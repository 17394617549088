/* eslint-disable max-len */
import { Field } from 'formik';
import { ReactElement } from 'react';
import { FormCheckboxPropTypes } from '../../../types/propTypes/FormCheckboxPropTypes';
import { applyClassesForInput } from '../../../utilities/formUtilities';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import './FormCheckbox.css';

const FormCheckbox = ({
  displayName,
  field,
  classConfig,
  cypressDataId,
  validationSchema,
  disabled,
  disableLabelOnCheckboxDisabled,
}: FormCheckboxPropTypes): ReactElement => {
  return (
    <div
      className={`field-container form-checkbox-container ${
        classConfig?.containerClasses || ''
      }`}
      data-testid={`form-checkbox-${field}`}
    >
      <Field
        className={applyClassesForInput(
          field,
          `${classConfig?.inputClasses || ''} data-input`
        )}
        type="checkbox"
        id={field}
        name={field}
        data-testid={`chk-${field}`}
        data-cy={cypressDataId}
        disabled={disabled}
      />
      <FormInputLabel
        field={field}
        validationSchema={validationSchema}
        displayName={displayName}
        disabled={disableLabelOnCheckboxDisabled && disabled}
        classes={classConfig?.labelClasses || ''}
      />
    </div>
  );
};

export default FormCheckbox;
