/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { NonPublicStudentEntryModel } from '../../../types/NonPublicStudentEntryModel';
import { NPSESchoolType } from '../../../types/NonPublicStudentFormViewModel';
import NPSE_CTELayout from './CTE/NPSE_CTELayout';
import NPSE_DemographicPanel from './NPSE_DemographicPanel';
import NPSE_EnrollmentPanel from './NPSE_EnrollmentPanel';
import NPSE_LEAPanel from './NPSE_LEAPanel';
import NPSE_StudentPanel from './NPSE_StudentPanel';
import NPSE_SPEDLayout from './SPED/NPSE_SPEDLayout';

const NPSE_Layout = (): ReactElement => {
  const { values } = useFormikContext<NonPublicStudentEntryModel>();

  return (
    <>
      <NPSE_StudentPanel />
      <NPSE_LEAPanel />
      <NPSE_DemographicPanel />
      <NPSE_EnrollmentPanel />
      {values.schoolType === NPSESchoolType.CTE && <NPSE_CTELayout />}
      {values.schoolType === NPSESchoolType.SPED && <NPSE_SPEDLayout />}
    </>
  );
};

export default NPSE_Layout;
