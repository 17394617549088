/* eslint-disable max-len */
import { ReactElement } from 'react';
import ExpandableNavigationItem from '../../../components/ExpandableNavigationItem/ExpandableNavigationItem';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import { MenuPropTypes } from '../../../types/propTypes/MenuPropTypes';

const FileMenu = ({ setIsOpen, isOpen }: MenuPropTypes): ReactElement => {
  return (
    <li>
      <ExpandableNavigationItem
        isOpen={isOpen}
        setIsOpen={(opened: boolean) => {
          setIsOpen(opened);
        }}
        mainLink={{
          id: 'main-link-file',
          content: 'Data Submission',
          className: 'sidebar--nav--data-management',
        }}
        subLinks={[
          {
            to: appRoutePaths.FileUpload,
            content: 'Upload a File',
            visible: canUserAccessRoute(appRoutePaths.FileUpload),
          },
          {
            to: appRoutePaths.SubmissionStatus,
            content: 'Submission Status',
            visible: canUserAccessRoute(appRoutePaths.SubmissionStatus),
          },
          {
            to: appRoutePaths.DataQualityDashboard,
            content: 'Data Quality Dashboard',
            visible: canUserAccessRoute(appRoutePaths.DataQualityDashboard),
          },
          {
            to: appRoutePaths.Accreditation,
            content: 'Accreditation',
            visible: canUserAccessRoute(appRoutePaths.Accreditation),
          },
        ]}
      />
    </li>
  );
};

export default FileMenu;
