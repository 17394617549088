import { ReactElement, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearLoginError,
  selectAuthorizeTokenError,
  selectAuthorizeUri,
} from '../../redux/slices/loginSlice';
import { fetchAuthorizeUri } from '../../redux/thunks/loginThunks';
import {
  abortPromiseOnUnmount,
  clearStorageForLogin,
} from '../../services/base.service';
import './Login.css';

const Login = (): ReactElement => {
  const dispatch = useAppDispatch();
  const authorizeUri = useAppSelector(selectAuthorizeUri);
  const error = useAppSelector(selectAuthorizeTokenError);

  const handleLogin = (): void => {
    clearStorageForLogin();
    dispatch(clearLoginError());
  };

  useEffect(() => {
    const promise = dispatch(fetchAuthorizeUri());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  return (
    <div id="login" className="grid">
      <main>
        <h1>MSIS</h1>
        <p>Welcome</p>
        <a
          data-cy="sign-in"
          href={authorizeUri}
          onClick={handleLogin}
          className="button"
        >
          Sign In
        </a>
        <div className={`login--error ${error ? 'has-error' : ''}`}>
          {error}
        </div>
      </main>
    </div>
  );
};

export default Login;
