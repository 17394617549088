import { SchemaErrors } from './SchemaErrors';

const CourseSectionSchemaError = [
  'LocalEducationAgencyIdentifierState',
  'SchoolIdentifierState',
  'CourseSectionIdentifier',
  'SessionType',
  'SessionBeginDate',
  'SessionEndDate',
  'Course_CourseIdentifierState',
  'Enrollment_StudentIdentifierState',
  'Enrollment_EntryDate',
  'Enrollment_ExitDate',
  'Staff_StaffMemberIdentifierState',
];

const K12CourseSchemaError = ['CourseIdentifierState', 'CourseTitle'];

const K12SchoolSchemaError = [
  'LocalEducationAgencyIdentifierState',
  'Identification_SchoolIdentifierState',
  'Identification_NameOfInstitution',
];

const K12StaffSchemaError = [
  'Identity_StaffMemberIdentifierState',
  'Identity_LastorSurname',
  'Identity_FirstName',
  'Assignment_LocalEducationAgencyIdentifierState',
  'Assignment_SchoolIdentifierState',
];

const K12StudentSchemaError = [
  'Identity_StudentIdentifierState',
  'Identity_LastorSurname',
  'Identity_FirstName',
  'Identity_MiddleName',
  'Demographics_Birthdate',
  'Demographic_Sex',
  'Demographic_HispanicorLatinoEthnicity',
  'Demographic_Race',
  'Language_LanguageTypeHome',
  'Language_ISO6392LanguageCode',
  'Disability_PrimaryDisabilityType',
  'IndividualizedProgram_IndividualizedProgramType',
  'Homeless_HomelessnessStatus',
  'Enrollment_EntryGradeLevel',
];

const LEASchemaError = [
  'Identification_LocalEducationAgencyIdentifierState',
  'Identification_OrganizationName',
];

const FileSchemaErrors: SchemaErrors = {
  CourseSectionSchemaError,
  K12CourseSchemaError,
  K12SchoolSchemaError,
  K12StaffSchemaError,
  K12StudentSchemaError,
  LEASchemaError,
};

export default FileSchemaErrors;
