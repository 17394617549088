/* eslint-disable max-len */
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { ReactElement, useState } from 'react';
import { TabContainerPropTypes } from '../../types/propTypes/TabContainerPropTypes';
import './TabContainer.css';
import TabPanel from './TabPanel';

const TabContainer = ({
  tabNames,
  ariaLabel,
  children,
}: TabContainerPropTypes): ReactElement => {
  const [value, setValue] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValue(newValue);
  };

  const a11yProps = (index: number): Object => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  return (
    <div>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={ariaLabel}
        >
          {tabNames.map((name, index) => (
            <Tab
              key={`tab-text-${index}`}
              className="tab-text"
              label={name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </div>
      <div className="tab-content-container">
        <div className="tab-content">
          {children &&
            Array.isArray(children) &&
            children.map((item, index) => (
              <TabPanel key={`panel-${index}`} value={value} index={index}>
                {item}
              </TabPanel>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TabContainer;
