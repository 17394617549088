import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getLEAManagementById,
  getLEAManagementFormViewModel,
  getLEAManagementListItems,
} from '../../services/leaManagement.service';
import { LEAManagementFormViewModel } from '../../types/LEAManagementFormViewModel';
import { LEAManagementListItemModel } from '../../types/LEAManagementListItemModel';
import { LEAManagementModel } from '../../types/LEAManagementModel';

export const fetchLEAManagementListItems = createAsyncThunk(
  'input/fetchLeaManagementListItems',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      LEAManagementListItemModel[]
    >(thunkAPI, getLEAManagementListItems({ signal: thunkAPI.signal }));
  }
);

export const fetchLEAManagementById = createAsyncThunk(
  'input/fetchLeaManagementById',
  async (leaId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<LEAManagementModel>(
      thunkAPI,
      getLEAManagementById(leaId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchLEAManagementFormViewModel = createAsyncThunk(
  'input/fetchLeaManagementFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<LEAManagementFormViewModel>(
      thunkAPI,
      getLEAManagementFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
