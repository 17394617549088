/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import { createContext, useContext } from 'react';
import { ConfirmationDialogOptions } from '../types/ConfirmationDialogOptions';
import { ModalContextType } from '../types/ModalContextType';

export const ConfirmationModalContext = createContext<ModalContextType>({
  showConfirmation: () => {
    return Promise.resolve(true);
  },
  hideConfirmation: () => {},
  setOptions: (options: ConfirmationDialogOptions) => {},
} as unknown as ModalContextType);

export const useConfirmationModalContext = (): ModalContextType =>
  useContext(ConfirmationModalContext);
