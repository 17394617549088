/* eslint-disable max-len */
const stringToEnum = <T>(obj: T, str: string): T[keyof T] | undefined => {
  return Object.values(obj as Object).includes(str as keyof T)
    ? (str as unknown as T[keyof T])
    : undefined;
};

export const stringArrayToEnumArray = <ET>(
  stringArray: string[],
  enumType: ET
): ET[] => {
  const enumArray: ET[] = [];
  stringArray.forEach((str) => {
    const value = stringToEnum(enumType, str);
    if (value) {
      enumArray.push(value as unknown as ET);
    }
  });
  return enumArray;
};

export const ENVIRONMENT_VARIABLE_ERRORS = {
  STRING_UNDEFINED: (key: string) =>
    `The environment variable "${key}" cannot be "undefined".`,
  FLOAT_INCORRECT: (key: string, value: string) =>
    `The environment variable "${key}" has to hold a stringified number value - not ${value}`,
  INT_INCORRECT: (key: string, value: string) =>
    `The environment variable "${key}" has to hold a stringified integer value - not ${value}`,
  LESS_THAN_OR_EQUAL: (valueKey: string, compareKey: string) =>
    `"${valueKey}" must be less than or equal too "${compareKey}".`,
};

export const extractStringEnvVar = (key: keyof ENV_VARS): string => {
  const value = window._env_[key] as string;

  if (value === undefined) {
    const message = ENVIRONMENT_VARIABLE_ERRORS.STRING_UNDEFINED(key);
    throw new Error(message);
  }
  return value;
};

export const extractFloatEnvVar = (key: keyof ENV_VARS): number => {
  const stringValue = extractStringEnvVar(key);
  const numberValue = parseFloat(stringValue);

  if (Number.isNaN(numberValue)) {
    const message = ENVIRONMENT_VARIABLE_ERRORS.FLOAT_INCORRECT(
      key,
      stringValue
    );
    throw new Error(message);
  }
  return numberValue;
};

export const extractIntegerEnvVar = (key: keyof ENV_VARS): number => {
  const stringValue = extractStringEnvVar(key);
  const numberValue = parseInt(stringValue, 10);

  if (Number.isNaN(numberValue)) {
    const message = ENVIRONMENT_VARIABLE_ERRORS.INT_INCORRECT(key, stringValue);
    throw new Error(message);
  }
  return numberValue;
};

export const extractBooleanEnvVar = (key: keyof ENV_VARS): boolean => {
  const stringValue = extractStringEnvVar(key);
  return stringValue === '1' || stringValue === 'true';
};
